import React from "react";

const PythonCasting = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python Casting</h2>
      <div>
        <div>
          <p>
            Python casting is the process of changing a variable&apos;s data
            type. Python provides built-in functions for casting. Let&apos;s
            explore Python casting in different scenarios:
          </p>

          <h3>Integers (int)</h3>
          <p>
            You can cast other data types to integers using the{" "}
            <code>int()</code> function:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`# Cast a float to an integer
x = int(3.5)
print(x)  # Output: 3

# Cast a string to an integer
y = int("42")
print(y)  # Output: 42`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent("x = int(3.5)\ny = int('42')"),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>Floats (float)</h3>
          <p>
            You can cast other data types to floats using the{" "}
            <code>float()</code> function:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`# Cast an integer to a float
x = float(5)
print(x)  # Output: 5.0

# Cast a string to a float
y = float("3.14")
print(y)  # Output: 3.14`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent("x = float(5)\ny = float('3.14')"),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>Strings (str)</h3>
          <p>
            You can cast other data types to strings using the{" "}
            <code>str()</code> function:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`# Cast an integer to a string
x = str(42)
print(x)  # Output: '42'

# Cast a float to a string
y = str(3.14)
print(y)  # Output: '3.14'`}
            </code>
          </pre>
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent("x = str(42)\ny = str(3.14)"),
                "_blank"
              )
            }
          >
            Run Code
          </button>
        </div>
      </div>
    </div>
  );
};

export default PythonCasting;
