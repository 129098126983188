import React from 'react';

const CppUserInput = () => {
  return (
    <div>
      <h2>C++ User Input</h2>
      <p>
        In C++, you can take user input using the <code>cin</code> object. It allows you to read data from the standard input (usually the keyboard) and store it in variables.
      </p>

      <h3>Code Examples:</h3>

      <p>Here are some examples of taking user input and displaying the results:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
using namespace std;

int main() {
    int age;
    cout << "Enter your age: ";
    cin >> age;
    cout << "Your age is: " << age << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>
using namespace std;

int main() {
    int age;
    cout << "Enter your age: ";
    cin >> age;
    cout << "Your age is: " << age << endl;

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <p>
        You can also take input for other data types, such as double or string:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
using namespace std;

int main() {
    double price;
    string productName;
    
    cout << "Enter the price: ";
    cin >> price;
    cout << "Enter the product name: ";
    cin >> productName;
    
    cout << "Product: " << productName << endl;
    cout << "Price: " << price << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>
using namespace std;

int main() {
    double price;
    string productName;
    
    cout << "Enter the price: ";
    cin >> price;
    cout << "Enter the product name: ";
    cin >> productName;
    
    cout << "Product: " << productName << endl;
    cout << "Price: " << price << endl;

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <p>
        You can also use a combination of cin and getline to input strings with spaces:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
using namespace std;

int main() {
    string fullName;
    cout << "Enter your full name: ";
    cin.ignore();  // Ignore the newline character in the input buffer
    getline(cin, fullName);
    cout << "Your full name is: " << fullName << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>
using namespace std;

int main() {
    string fullName;
    cout << "Enter your full name: ";
    cin.ignore();  // Ignore the newline character in the input buffer
    getline(cin, fullName);
    cout << "Your full name is: " << fullName << endl;

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppUserInput;
