import React from "react";
import Background from "./Background";
import Cards from "./Cards";
import Footer from "./Footer";
import HomeSearchBar from "./HomeSearchBar";
import Glucotrust from "../ads/Glucotrust";
import Alpilea from "../ads/Alpilea";
import { CardGroup, Card } from "react-bootstrap";

function Homepage() {
  return (
    <div>
      <HomeSearchBar />
      <Background />
      <CardGroup className="container">
        <Card>
         <Card.Body>
         <Glucotrust
            productLink="https://hop.clickbank.net/?affiliate=okyerejr&vendor=glucotrust&pid=11"
            imageUrl="https://getglucotrust.com/glucotrust/banners/Ad_10.png"
            altText="Glucotrust"
            title="Glucotrust"
          />
         </Card.Body>
        </Card>
        <Card>
       <Card.Body>
       <Alpilea
          productLink="https://hop.clickbank.net/?affiliate=okyerejr&vendor=alpilean&pg=vid"
          imageUrl="https://alpilean.com/assets/images/full-product.png"
          altText="Alpilea"
          title="Alpilea"
        />
       </Card.Body>

        </Card>
      </CardGroup>

      <Cards />
      <Footer />
    </div>
  );
}

export default Homepage;
