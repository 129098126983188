import React, { Component } from 'react';
import '../Python/python.css';
import ProgrammingTips from '../Python/topics/Tips';
import CodeNavigationBar from '../CodeNavigationBar';
import Footer from '../../components/Footer';
import IntroductionToJavaScript from './topics/IntroductionToJavascript';
import JavaScriptSyntax from './topics/Syntax';
import JavaScriptComments from './topics/Comments';
import JavaScriptVariables from './topics/Variables';
import JavaScriptConst from './topics/Const';
import JavaScriptLet from './topics/let';
import JavaScriptDataTypes from './topics/Datatypes';
import JavaScriptOperators from './topics/Operators';
import JavaScriptFunctions from './topics/Functions';
import JavaScriptObjects from './topics/Objects';
import JavaScriptStrings from './topics/Strings';
import JavaScriptNumbers from './topics/Numbers';
import JavaScriptAndHTMLEvents from './topics/Events';
import JavaScriptNumberMethods from './topics/NumberMethods';
import JavaScriptArrays from './topics/Arrays';
import JavaScriptArraySort from './topics/ArraySort';
import JavaScriptArrayMethods from './topics/ArrayMethods';
import JavaScriptArrayIteration from './topics/ArrayIteration';
import JavaScriptMath from './topics/Math';
import JavaScriptBooleans from './topics/Boolean';
import JavaScriptIfElse from './topics/IFElseStatement';
import JavaScriptSwitchStatements from './topics/Switch';
import JavaScriptForLoops from './topics/ForLoop';
import JavaScriptWhileLoop from './topics/WhileLoop';
import JavaScriptArrowFunctions from './topics/ArrowFunctions';
import JavaScriptSets from './topics/JSSets';
import JavaScriptMaps from './topics/JSMaps';
import JavaScriptConversions from './topics/Conversions';
import JavaScriptRegExp from './topics/JSReg';
import JavaScriptClasses from './topics/Classes';
import JavaScriptModules from './topics/Modules';
import JavaScriptHoisting from './topics/Hoisting.JS';
import JavaScriptJSON from './topics/JSON';
import JavaScriptAsync from './topics/JSAsync';
import JavaScriptDOM from './topics/JSHTMLDOM';
import JavaScriptBrowserBOM from './topics/JSBrowserBOM';
import JavaScriptProjects from './topics/JavascriptProjects';


class JavaScriptTutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTopic: null,
      isMobile: window.innerWidth < 768, // Define the breakpoint for mobile devices
      showMenu: true,
    };
  }
  scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  handleTopicClick = (topic) => {
    this.setState({ selectedTopic: topic, showMenu: false });
    this.scrollToTop();
  };

  handleMenuClick = () => {
    this.setState({ showMenu: true, selectedTopic: null });
  };

  render() {
    const topics = [
      "Introduction to JavaScript",
      "Basic JavaScript Syntax",
      "Comments",
      "Variables",
      "JS Const",
      "JS Let",
      "Data Types",
      "Operators",
      "Functions",
      "Arrow Functions",
      "Objects",
      "Strings",
      "Numbers",
      "Number Methods",
      "Events",
      "Arrays",
      "Array Sort",
      "Array Methods",
      "Array Iteration",
      "JS Math",
      "JS Booleans",
      "If and Else",
      "Switch",
      "For Loop",
      "While Loop",
      "JS Iterables",
      "JS Sets",
      "JS Maps",
      "JS Type Conversions",
      "JS RegEx",
      "Classes",
      "JS Modules",
      "JS Hoisting",
      "JS JSON",
      "JS Async",
      "JS HTML DOM",
      "JS Browser BOM",
      "JS Projects",
      
      
      
    ];

    return (
      <div>
        <CodeNavigationBar />

<div className="container py-5">
        <div className="row">
          {this.state.isMobile && (
            <div className="col-12">
              {this.state.showMenu ? (
                <button className="btn btn-primary btn-block mb-3" onClick={this.handleMenuClick}>
                  Show Menu
                </button>
              ) : (
                <button className="btn btn-primary btn-block mb-3" onClick={this.handleMenuClick}>
                  Back to Menu
                </button>
              )}
            </div>
          )}
          {this.state.isMobile ? (
            <div className="col-12">
              {this.state.showMenu && (
                <div className="navbar">
                  <h2>JavaScript Tutorial</h2>
                  <ul className="list-group">
                    {topics.map((topic, index) => (
                      <li
                        key={index}
                        onClick={() => this.handleTopicClick(topic)}
                        className={`list-group-item ${topic === this.state.selectedTopic ? "active" : ""}`}
                      >
                        {topic}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {!this.state.showMenu && (
                <div className="content">
                  {this.state.selectedTopic === "Introduction to JavaScript" && <IntroductionToJavaScript />}
                  {this.state.selectedTopic === "Basic JavaScript Syntax" && <JavaScriptSyntax />}
                  {this.state.selectedTopic === "Comments" && <JavaScriptComments />}
                  {this.state.selectedTopic === "Variables" && <JavaScriptVariables />}
                  {this.state.selectedTopic === "JS Const" && <JavaScriptConst />}
                  {this.state.selectedTopic === "JS Let" && <JavaScriptLet />}
                  {this.state.selectedTopic === "Data Types" && <JavaScriptDataTypes />}
                  {this.state.selectedTopic === "Operators" && <JavaScriptOperators />}
                  {this.state.selectedTopic === "Functions" && <JavaScriptFunctions />}
                  {this.state.selectedTopic === "Arrow Functions" && <JavaScriptArrowFunctions />}
                  {this.state.selectedTopic === "Objects" && <JavaScriptObjects />}
                  {this.state.selectedTopic === "Strings" && <JavaScriptStrings />}
                  {this.state.selectedTopic === "Numbers" && <JavaScriptNumbers />}
                  {this.state.selectedTopic === "Number Methods" && <JavaScriptNumberMethods />}
                  {this.state.selectedTopic === "Events" && <JavaScriptAndHTMLEvents />}
                  {this.state.selectedTopic === "Arrays" && <JavaScriptArrays />}
                  {this.state.selectedTopic === "Array Sort" && <JavaScriptArraySort />}
                  {this.state.selectedTopic === "Array Methods" && <JavaScriptArrayMethods />}
                  {this.state.selectedTopic === "Array Iteration" && <JavaScriptArrayIteration />}
                  {this.state.selectedTopic === "JS Math" && <JavaScriptMath />}
                  {this.state.selectedTopic === "JS Booleans" && <JavaScriptBooleans />}
                  {this.state.selectedTopic === "If and Else" && <JavaScriptIfElse />}
                  {this.state.selectedTopic === "Switch" && <JavaScriptSwitchStatements />}
                  {this.state.selectedTopic === "For Loop" && <JavaScriptForLoops />}
                  {this.state.selectedTopic === "While Loop" && <JavaScriptWhileLoop />}
                  {this.state.selectedTopic === "JS Iterables" && <JavaScriptWhileLoop />}
                  {this.state.selectedTopic === "JS Sets" && <JavaScriptSets />}
                  {this.state.selectedTopic === "JS Maps" && <JavaScriptMaps />}
                  {this.state.selectedTopic === "JS Type Conversions" && <JavaScriptConversions />}
                  {this.state.selectedTopic === "JS RegEx" && <JavaScriptRegExp />}
                  {this.state.selectedTopic === "Classes" && <JavaScriptClasses />}
                  {this.state.selectedTopic === "JS Modules" && <JavaScriptModules />}
                  {this.state.selectedTopic === "JS Hoisting" && <JavaScriptHoisting />}
                  {this.state.selectedTopic === "JS JSON" && <JavaScriptJSON />}
                   {this.state.selectedTopic === "JS Async" && <JavaScriptAsync />}
                   {this.state.selectedTopic === "JS HTML DOM" && <JavaScriptDOM />}
                   {this.state.selectedTopic === "JS Browser BOM" && <JavaScriptBrowserBOM />}
                   {this.state.selectedTopic === "JS Projects" && <JavaScriptProjects />}

                 
                  <button className="btn btn-primary btn-block mt-3" onClick={this.handleMenuClick}>
                    Back to Menu
                  </button>
                </div>
              )}
            </div>
          ) : (
            <React.Fragment>
              <div className="col-md-3">
                <div className="navbar">
                  <h2>JavaScript Tutorial</h2>
                  <ul className="list-group">
                    {topics.map((topic, index) => (
                      <li
                        key={index}
                        onClick={() => this.handleTopicClick(topic)}
                        className={`list-group-item ${topic === this.state.selectedTopic ? "active" : ""}`}
                      >
                        {topic}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="content">
                  {this.state.selectedTopic ? (
                    <div>
                      {this.state.selectedTopic === "Introduction to JavaScript" && <IntroductionToJavaScript />}
                      {this.state.selectedTopic === "Basic JavaScript Syntax" && <JavaScriptSyntax />}
                      {this.state.selectedTopic === "Comments" && <JavaScriptComments />}
                      {this.state.selectedTopic === "Variables" && <JavaScriptVariables />}
                      {this.state.selectedTopic === "JS Const" && <JavaScriptConst />}
                      {this.state.selectedTopic === "JS Let" && <JavaScriptLet />}
                      {this.state.selectedTopic === "Data Types" && <JavaScriptDataTypes />}
                      {this.state.selectedTopic === "Operators" && <JavaScriptOperators />}
                      {this.state.selectedTopic === "Functions" && <JavaScriptFunctions />}
                      {this.state.selectedTopic === "Arrow Functions" && <JavaScriptArrowFunctions />}
                      {this.state.selectedTopic === "Objects" && <JavaScriptObjects />}
                      {this.state.selectedTopic === "Strings" && <JavaScriptStrings />}
                      {this.state.selectedTopic === "Numbers" && <JavaScriptNumbers />}
                      {this.state.selectedTopic === "Number Methods" && <JavaScriptNumberMethods />}
                      {this.state.selectedTopic === "Events" && <JavaScriptAndHTMLEvents />}
                      {this.state.selectedTopic === "Arrays" && <JavaScriptArrays />}
                      {this.state.selectedTopic === "Array Sort" && <JavaScriptArraySort />}
                      {this.state.selectedTopic === "Array Methods" && <JavaScriptArrayMethods />}
                      {this.state.selectedTopic === "Array Iteration" && <JavaScriptArrayIteration />}
                      {this.state.selectedTopic === "JS Math" && <JavaScriptMath />}
                      {this.state.selectedTopic === "JS Booleans" && <JavaScriptBooleans />}
                      {this.state.selectedTopic === "If and Else" && <JavaScriptIfElse />}
                      {this.state.selectedTopic === "Switch" && <JavaScriptSwitchStatements />}
                      {this.state.selectedTopic === "For Loop" && <JavaScriptForLoops />}
                      {this.state.selectedTopic === "While Loop" && <JavaScriptWhileLoop />}
                      {this.state.selectedTopic === "JS Iterables" && <JavaScriptWhileLoop />}
                      {this.state.selectedTopic === "JS Sets" && <JavaScriptSets />}
                      {this.state.selectedTopic === "JS Maps" && <JavaScriptMaps />}
                      {this.state.selectedTopic === "JS Type Conversions" && <JavaScriptConversions />}
                      {this.state.selectedTopic === "JS RegEx" && <JavaScriptRegExp />}
                      {this.state.selectedTopic === "Classes" && <JavaScriptClasses />}
                      {this.state.selectedTopic === "JS Modules" && <JavaScriptModules />}
                      {this.state.selectedTopic === "JS Hoisting" && <JavaScriptHoisting />}
                      {this.state.selectedTopic === "JS JSON" && <JavaScriptJSON />}
                      {this.state.selectedTopic === "JS Async" && <JavaScriptAsync />}
                      {this.state.selectedTopic === "JS HTML DOM" && <JavaScriptDOM />}
                      {this.state.selectedTopic === "JS Browser BOM" && <JavaScriptBrowserBOM />}
                      {this.state.selectedTopic === "JS Projects" && <JavaScriptProjects />}
                      
                    </div>
                  ) : (
                    <ProgrammingTips  />
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
                    <Footer />

      </div>
    );
  }
}

export default JavaScriptTutorial;
