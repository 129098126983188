import React from 'react';

const JavaScriptConst = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript const Keyword</h2>
      <p>The <code>const</code> keyword in JavaScript is used to declare a variable with a constant (unchangeable) value. Once assigned, the value cannot be reassigned or changed.</p>

      <h3>Declaring Constants</h3>
      <p>Use <code>const</code> to declare a constant variable:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const pi = 3.14159;
const appName = 'MyApp';`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const pi = 3.14159;\nconst appName = 'MyApp';"), '_blank')}>Run Code</button>

      <h3>Reassigning Constants (Not Possible)</h3>
      <p>Attempting to reassign a constant variable will result in an error:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const age = 25;
age = 30; // This will throw an error`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const age = 25;\nage = 30; // This will throw an error"), '_blank')}>Run Code</button>

      <h3>Constant Arrays and Objects</h3>
      <p>While a constant array or object cannot be re-assigned, its contents can be modified:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const colors = ['red', 'green', 'blue'];
colors.push('yellow'); // This is allowed

const person = { name: 'Alice', age: 30 };
person.age = 31; // This is allowed`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const colors = ['red', 'green', 'blue'];\ncolors.push('yellow'); // This is allowed\n\nconst person = { name: 'Alice', age: 30 };\nperson.age = 31; // This is allowed"), '_blank')}>Run Code</button>

      <h3>Block Scoping</h3>
      <p>Constants declared with <code>const</code> are block-scoped, meaning they are only available within the block where they are defined:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`if (true) {
  const blockScopedVar = 'This is block-scoped';
}
console.log(blockScopedVar); // This will throw an error`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("if (true) {\n  const blockScopedVar = 'This is block-scoped';\n}\nconsole.log(blockScopedVar); // This will throw an error"), '_blank')}>Run Code</button>

      <h3>Global Constants</h3>
      <p>Constants declared in the global scope can be accessed from anywhere in the code:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const GLOBAL_CONSTANT = 'I am a global constant';

function someFunction() {
  console.log(GLOBAL_CONSTANT);
}

someFunction(); // Outputs 'I am a global constant'`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const GLOBAL_CONSTANT = 'I am a global constant';\n\nfunction someFunction() {\n  console.log(GLOBAL_CONSTANT);\n}\n\nsomeFunction(); // Outputs 'I am a global constant'"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptConst;
