import React, { Component } from "react";
import "../Python/python.css";
import ProgrammingTips from "../Python/topics/Tips";
import CodeNavigationBar from "../CodeNavigationBar";
import Footer from "../../components/Footer";
import DataScienceIntroduction from "./topics/IntroductionToDataScience";
import DataCollectionAndTypes from "./topics/DataCollectionAndTypes";
import DataExplorationVisualization from "./topics/DataExplorationVisualization";
import DataAnalysis from "./topics/DataAnalysis";
import FeatureEngineering from "./topics/FeatureEngineering";
import MachineLearningModels from "./topics/MachineLearningModels";
import ModelEvaluationValidation from "./topics/ModelEvaluationValidation";
import DeepLearning from "./topics/DeepLearning";
import BigDataCloudComputing from "./topics/BigDataCloudComputing";
import DatabaseManagement from "./topics/DatabaseManagement";
import DataEthicsPrivacy from "./topics/DataEthicsPrivacy";
import DataScienceProjects from "./topics/DataScienceProjects";

class DataScienceTutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTopic: null,
      isMobile: window.innerWidth < 768, // Define the breakpoint for mobile devices
      showMenu: true,
    };
  }
  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  handleTopicClick = (topic) => {
    this.setState({ selectedTopic: topic, showMenu: false });
    this.scrollToTop();
  };

  handleMenuClick = () => {
    this.setState({ showMenu: true, selectedTopic: null });
  };

  render() {
    const topics = [
      "Introduction to Data Science",
      "Data Collection and Data Types",
      "Data Exploration and Visualization",
      "Data Analysis",
      "Feature Engineering",
      "Machine Learning Models",
      "Model Evaluation and Validation",
      "Deep Learning",
      "Big Data and Cloud Computing",
      "Database Management",
      "Data Ethics and Privacy",
      "Data Science Projects",
    ];

    return (
      <div>
        <CodeNavigationBar />

        <div className="container py-5">
          <div className="row">
            {this.state.isMobile && (
              <div className="col-12">
                {this.state.showMenu ? (
                  <button
                    className="btn btn-primary btn-block mb-3"
                    onClick={this.handleMenuClick}
                  >
                    Show Menu
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-block mb-3"
                    onClick={this.handleMenuClick}
                  >
                    Back to Menu
                  </button>
                )}
              </div>
            )}
            {this.state.isMobile ? (
              <div className="col-12">
                {this.state.showMenu && (
                  <div className="navbar">
                    <h2>Data Science Tutorial</h2>
                    <ul className="list-group">
                      {topics.map((topic, index) => (
                        <li
                          key={index}
                          onClick={() => this.handleTopicClick(topic)}
                          className={`list-group-item ${
                            topic === this.state.selectedTopic ? "active" : ""
                          }`}
                        >
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {!this.state.showMenu && (
                  <div className="content">
                    {this.state.selectedTopic ===
                      "Introduction to Data Science" && (
                      <DataScienceIntroduction />
                    )}
                    {this.state.selectedTopic ===
                      "Data Collection and Data Types" && (
                      <DataCollectionAndTypes />
                    )}
                    {this.state.selectedTopic ===
                      "Data Exploration and Visualization" && (
                      <DataExplorationVisualization />
                    )}
                    {this.state.selectedTopic === "Data Analysis" && (
                      <DataAnalysis />
                    )}
                    {this.state.selectedTopic === "Feature Engineering" && (
                      <FeatureEngineering />
                    )}
                    {this.state.selectedTopic === "Machine Learning Models" && (
                      <MachineLearningModels />
                    )}
                    {this.state.selectedTopic ===
                      "Model Evaluation and Validation" && (
                      <ModelEvaluationValidation />
                    )}
                    {this.state.selectedTopic === "Deep Learning" && (
                      <DeepLearning />
                    )}
                    {this.state.selectedTopic ===
                      "Big Data and Cloud Computing" && (
                      <BigDataCloudComputing />
                    )}
                    {this.state.selectedTopic === "Database Management" && (
                      <DatabaseManagement />
                    )}
                    {this.state.selectedTopic === "Data Ethics and Privacy" && (
                      <DataEthicsPrivacy />
                    )}
                    {this.state.selectedTopic === "Data Science Projects" && (
                      <DataScienceProjects />
                    )}

                    <button
                      className="btn btn-primary btn-block mt-3"
                      onClick={this.handleMenuClick}
                    >
                      Back to Menu
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <React.Fragment>
                <div className="col-md-3">
                  <div className="navbar">
                    <h2>Data Science Tutorial</h2>
                    <ul className="list-group">
                      {topics.map((topic, index) => (
                        <li
                          key={index}
                          onClick={() => this.handleTopicClick(topic)}
                          className={`list-group-item ${
                            topic === this.state.selectedTopic ? "active" : ""
                          }`}
                        >
                          {topic}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="content">
                    {this.state.selectedTopic ? (
                      <div>
                        {this.state.selectedTopic ===
                          "Introduction to Data Science" && (
                          <DataScienceIntroduction />
                        )}
                        {this.state.selectedTopic ===
                          "Data Collection and Data Types" && (
                          <DataCollectionAndTypes />
                        )}
                        {this.state.selectedTopic ===
                          "Data Exploration and Visualization" && (
                          <DataExplorationVisualization />
                        )}
                        {this.state.selectedTopic === "Data Analysis" && (
                          <DataAnalysis />
                        )}
                        {this.state.selectedTopic === "Feature Engineering" && (
                          <FeatureEngineering />
                        )}
                        {this.state.selectedTopic ===
                          "Machine Learning Models" && (
                          <MachineLearningModels />
                        )}
                        {this.state.selectedTopic ===
                          "Model Evaluation and Validation" && (
                          <ModelEvaluationValidation />
                        )}
                        {this.state.selectedTopic === "Deep Learning" && (
                          <DeepLearning />
                        )}
                        {this.state.selectedTopic ===
                          "Big Data and Cloud Computing" && (
                          <BigDataCloudComputing />
                        )}
                        {this.state.selectedTopic === "Database Management" && (
                          <DatabaseManagement />
                        )}
                        {this.state.selectedTopic ===
                          "Data Ethics and Privacy" && <DataEthicsPrivacy />}
                        {this.state.selectedTopic ===
                          "Data Science Projects" && <DataScienceProjects />}
                      </div>
                    ) : (
                      <ProgrammingTips />
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default DataScienceTutorial;
