import React from 'react';

const PythonMath = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>Python Math</h2>
      <p>Python provides various mathematical functions and operations that you can use for performing mathematical calculations. Here are some common mathematical operations and functions:</p>

      <h3>Basic Mathematical Operations</h3>
      <p>You can perform basic mathematical operations like addition, subtraction, multiplication, and division in Python:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`a = 10
b = 5

addition = a + b
subtraction = a - b
multiplication = a * b
division = a / b

print(addition, subtraction, multiplication, division)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("a = 10\nb = 5\n\naddition = a + b\nsubtraction = a - b\nmultiplication = a * b\ndivision = a / b\n\nprint(addition, subtraction, multiplication, division)"), '_blank')}>Run Code</button>

      <h3>Math Functions</h3>
      <p>Python has built-in math functions for more complex operations. You can use functions like <code>abs()</code>, <code>pow()</code>, <code>sqrt()</code>, and <code>round()</code>:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import math

x = -10
y = 3
z = 16

absolute_value = abs(x)
power = pow(y, 2)
square_root = math.sqrt(z)
rounded_number = round(9.456)

print(absolute_value, power, square_root, rounded_number)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("import math\n\nx = -10\ny = 3\nz = 16\n\nabsolute_value = abs(x)\npower = pow(y, 2)\nsquare_root = math.sqrt(z)\nrounded_number = round(9.456)\n\nprint(absolute_value, power, square_root, rounded_number)"), '_blank')}>Run Code</button>

      <h3>Random Numbers</h3>
      <p>You can generate random numbers using the <code>random</code> module:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import random

random_integer = random.randint(1, 10)
random_float = random.random()

print(random_integer, random_float)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("import random\n\nrandom_integer = random.randint(1, 10)\nrandom_float = random.random()\n\nprint(random_integer, random_float)"), '_blank')}>Run Code</button>

      <h3>Math Constants</h3>
      <p>Python provides math constants like <code>pi</code> and <code>e</code>:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import math

pi = math.pi
euler = math.e

print(pi, euler)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("import math\n\npi = math.pi\neuler = math.e\n\nprint(pi, euler)"), '_blank')}>Run Code</button>

      <h3>Trigonometric Functions</h3>
      <p>Python provides trigonometric functions like <code>sin()</code>, <code>cos()</code>, and <code>tan()</code>:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import math

angle = math.radians(45)
sine = math.sin(angle)
cosine = math.cos(angle)
tangent = math.tan(angle)

print(sine, cosine, tangent)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("import math\n\nangle = math.radians(45)\nsine = math.sin(angle)\ncosine = math.cos(angle)\ntangent = math.tan(angle)\n\nprint(sine, cosine, tangent)"), '_blank')}>Run Code</button>

      <h3>Math Module Functions</h3>
      <p>The math module provides various mathematical functions. Here&apos;s an example of using the <code>factorial()</code> and <code>log()</code> functions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import math

factorial_5 = math.factorial(5)
log_e_2 = math.log(2)

print(factorial_5, log_e_2)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("import math\n\nfactorial_5 = math.factorial(5)\nlog_e_2 = math.log(2)\n\nprint(factorial_5, log_e_2)"), '_blank')}>Run Code</button>

      <h3>Additional Math Methods</h3>
      <p>Python&apos;s math module provides other useful methods:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import math

numbers = [3.5, 1.2, 9.8, 5.4]
min_value = min(numbers)
max_value = max(numbers)
ceiling_value = math.ceil(7.3)
floor_value = math.floor(4.7)

print(min_value, max_value, ceiling_value, floor_value)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("import math\n\nnumbers = [3.5, 1.2, 9.8, 5.4]\nmin_value = min(numbers)\nmax_value = max(numbers)\nceiling_value = math.ceil(7.3)\nfloor_value = math.floor(4.7)\n\nprint(min_value, max_value, ceiling_value, floor_value)"), '_blank')}>Run Code</button>
    </div>
  );
}

export default PythonMath;
