import React from 'react';

const JavaScriptDOM = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript HTML DOM</h2>
      <p>The HTML Document Object Model (DOM) is a programming interface for web documents. It represents the page so that programs can change the document structure, style, and content. Here are some common usages:</p>

      <h3>Accessing Elements by ID</h3>
      <p>Use JavaScript to access and manipulate elements in an HTML document:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`<div id="example">This is a div.</div>
<script>
  const element = document.getElementById("example");
  element.innerHTML = "Updated content.";
</script>`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent('<div id="example">This is a div.</div>\n<script>\n  const element = document.getElementById("example");\n  element.innerHTML = "Updated content.";\n</script>'), '_blank')}>Run Code</button>

      <h3>Accessing Elements by Class Name</h3>
      <p>You can access elements by class name and apply changes:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`<p class="example">This is a paragraph.</p>
<p class="example">This is another paragraph.</p>
<script>
  const elements = document.getElementsByClassName("example");
  for (let i = 0; i < elements.length; i++) {
    elements[i].innerHTML = "Updated paragraph.";
  }
</script>`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent('<p class="example">This is a paragraph.</p>\n<p class="example">This is another paragraph.</p>\n<script>\n  const elements = document.getElementsByClassName("example");\n  for (let i = 0; i < elements.length; i++) {\n    elements[i].innerHTML = "Updated paragraph.";\n  }\n</script>'), '_blank')}>Run Code</button>

      <h3>Modifying Styles</h3>
      <p>Change the style of an element using JavaScript:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`<p id="example" style="color: red;">This is a red paragraph.</p>
<script>
  const element = document.getElementById("example");
  element.style.color = "blue";
</script>`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent('<p id="example" style="color: red;">This is a red paragraph.</p>\n<script>\n  const element = document.getElementById("example");\n  element.style.color = "blue";\n</script>'), '_blank')}>Run Code</button>

      <h3>Creating New Elements</h3>
      <p>You can create new elements and add them to the document:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`<div id="container"></div>
<script>
  const container = document.getElementById("container");
  const newElement = document.createElement("p");
  newElement.innerHTML = "This is a new paragraph.";
  container.appendChild(newElement);
</script>`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent('<div id="container"></div>\n<script>\n  const container = document.getElementById("container");\n  const newElement = document.createElement("p");\n  newElement.innerHTML = "This is a new paragraph.";\n  container.appendChild(newElement);\n</script>'), '_blank')}>Run Code</button>

      <h3>Handling Events</h3>
      <p>Add event listeners to respond to user actions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`<button id="btn">Click me</button>
<script>
  const button = document.getElementById("btn");
  button.addEventListener("click", () => {
    alert("Button clicked!");
  });
</script>`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent('<button id="btn">Click me</button>\n<script>\n  const button = document.getElementById("btn");\n  button.addEventListener("click", () => {\n    alert("Button clicked!");\n  });\n</script>'), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptDOM;
