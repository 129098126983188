import React from "react";

const PythonLists = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";
  return (
    <div>
      <h2>Python Lists</h2>
      <p>
        Multiple items can be stored in a single variable using lists. One of
        the four built-in data types in Python for storing data collections is
        the list; the other three are the tuple, set, and dictionary, each with
        a unique set of features and applications. Square brackets are used to
        make lists:
      </p>
      <p>
        Lists are ordered, mutable collections in Python. They can contain
        elements of different data types. Here are some common list operations:
      </p>

      <h3>Accessing List Elements</h3>
      <p>You can access elements in a list by their index (0-based).</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_list = [10, 20, 30, 40, 50]
print(my_list[0])  # Access the first element
print(my_list[2])  # Access the third element`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>

      <h3>Changing List Elements</h3>
      <p>Lists are mutable, so you can change their elements by index.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_list = [10, 20, 30, 40, 50]
my_list[1] = 25  # Change the second element to 25
print(my_list)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>

      <h3>Adding Elements to a List</h3>
      <p>
        You can add elements to the end of a list using the{" "}
        <code>append()</code> method.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_list = [10, 20, 30]
my_list.append(40)  # Add 40 to the end
print(my_list)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>

      <h3>Removing Elements from a List</h3>
      <p>
        You can remove elements by value using the <code>remove()</code> method
        or by index using <code>pop()</code>.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_list = [10, 20, 30, 40, 50]
my_list.remove(30)  # Remove the element with value 30
popped_element = my_list.pop(1)  # Remove and return the second element
print(my_list)
print(popped_element)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>

      <h3>Sorting a List</h3>
      <p>
        You can sort a list using the <code>sort()</code> method.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_list = [50, 10, 40, 30, 20]
my_list.sort()  # Sort in ascending order
print(my_list)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>

      <h3>Looping Through a List</h3>
      <p>Iterate through a list to access each element.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_list = [10, 20, 30, 40, 50]
for item in my_list:
    print(item)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>

      <h3>Copying a List</h3>
      <p>
        To create a copy of a list, you can use either slicing or the{" "}
        <code>copy()</code> method.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_list = [10, 20, 30]
# Using slicing
copied_list = my_list[:]
# Using copy()
copied_list = my_list.copy()
print(copied_list)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>

      <h3>Joining Lists</h3>
      <p>
        You can join two or more lists using the <code>extend()</code> method or
        the <code>+</code> operator.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`list1 = [10, 20, 30]
list2 = [40, 50, 60]
list1.extend(list2)  # Use extend to combine lists
print(list1)

# Alternatively, you can use the + operator
combined_list = list1 + list2
print(combined_list)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>

      <h3>List Methods</h3>
      <p>Here are some commonly used list methods:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_list = [10, 20, 30]
my_list.append(40)         # Add an element to the end
my_list.insert(1, 15)      # Insert an element at a specific index
my_list.remove(20)         # Remove an element by value
popped_element = my_list.pop(2)  # Remove and return an element by index
my_list.sort()             # Sort the list
my_list.reverse()          # Reverse the list
index = my_list.index(30)  # Find the index of an element
count = my_list.count(10)  # Count occurrences of an element
my_list.clear()            # Clear all elements from the list
print(my_list)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
    </div>
  );
};

export default PythonLists;
