import React from 'react';

const JavaScriptArraySort = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Array Sorting</h2>
      <p>JavaScript provides several methods to sort arrays. Let&apos;s explore different sorting techniques:</p>

      <h3>Sort Numbers</h3>
      <p>Sort an array of numbers in ascending and descending order:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numbers = [5, 1, 3, 7, 2, 4];

// Ascending order
const ascending = numbers.slice().sort((a, b) => a - b);

// Descending order
const descending = numbers.slice().sort((a, b) => b - a);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numbers = [5, 1, 3, 7, 2, 4];\n\n// Ascending order\nconst ascending = numbers.slice().sort((a, b) => a - b);\n\n// Descending order\nconst descending = numbers.slice().sort((a, b) => b - a);"), '_blank')}>Run Code</button>

      <h3>Sort Strings</h3>
      <p>Sort an array of strings alphabetically:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry', 'date'];

// Alphabetical order
const alphabetical = fruits.slice().sort();`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry', 'date'];\n\n// Alphabetical order\nconst alphabetical = fruits.slice().sort();"), '_blank')}>Run Code</button>

      <h3>Sort Objects</h3>
      <p>Sort an array of objects based on a specific property:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const products = [
  { name: 'Laptop', price: 1000 },
  { name: 'Phone', price: 500 },
  { name: 'Tablet', price: 300 }
];

// Sort by price in ascending order
const byPriceAscending = products.slice().sort((a, b) => a.price - b.price);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const products = [\n  { name: 'Laptop', price: 1000 },\n  { name: 'Phone', price: 500 },\n  { name: 'Tablet', price: 300 }\n];\n\n// Sort by price in ascending order\nconst byPriceAscending = products.slice().sort((a, b) => a.price - b.price);"), '_blank')}>Run Code</button>

      <h3>Custom Sorting</h3>
      <p>Sort an array using custom comparison logic:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const customSort = [10, 100, 1, 1000, 10000];

// Custom sort: 10000 -> 1000 -> 100 -> 10 -> 1
customSort.sort((a, b) => {
  if (a > b) return -1;
  if (a < b) return 1;
  return 0;
});`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const customSort = [10, 100, 1, 1000, 10000];\n\n// Custom sort: 10000 -> 1000 -> 100 -> 10 -> 1\ncustomSort.sort((a, b) => {\n  if (a > b) return -1;\n  if (a < b) return 1;\n  return 0;\n});"), '_blank')}>Run Code</button>

      <h3>Reverse an Array</h3>
      <p>Reverse the order of elements in an array:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const originalArray = [1, 2, 3, 4, 5];

// Reverse the array
const reversedArray = originalArray.slice().reverse();`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const originalArray = [1, 2, 3, 4, 5];\n\n// Reverse the array\nconst reversedArray = originalArray.slice().reverse();"), '_blank')}>Run Code</button>
      
      <h3>Sorting Arrays of Objects by Property</h3>
      <p>Sort an array of objects based on a specific property:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const employees = [
  { name: 'Alice', age: 30 },
  { name: 'Bob', age: 25 },
  { name: 'Charlie', age: 35 }
];

// Sort employees by age in ascending order
employees.sort((a, b) => a.age - b.age);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const employees = [\n  { name: 'Alice', age: 30 },\n  { name: 'Bob', age: 25 },\n  { name: 'Charlie', age: 35 }\n];\n\n// Sort employees by age in ascending order\nemployees.sort((a, b) => a.age - b.age);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptArraySort;
