import React from 'react';

const CppVariables = () => {
  return (
    <div>
      <h2>C++ Variables, Identifiers, and Constants</h2>
      <p>
        In C++, variables are used to store data. They must be declared before use and must have a specific data type.
      </p>

      <h3>Declaring Variables</h3>
      <p>
        You can declare variables with the following syntax:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
< data_type > < variable_name >;
          `}
        </code>
      </pre>

      <h3>Code Examples:</h3>
      <p>Here are some examples of variable declarations and assignments:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int integerVar; // Declaring an integer variable
    double doubleVar; // Declaring a double variable
    char charVar; // Declaring a character variable
    bool boolVar; // Declaring a boolean variable

    integerVar = 42; // Assigning a value to integerVar
    doubleVar = 3.14; // Assigning a value to doubleVar
    charVar = 'A'; // Assigning a value to charVar
    boolVar = true; // Assigning a value to boolVar

    cout << "integerVar: " << integerVar << endl;
    cout << "doubleVar: " << doubleVar << endl;
    cout << "charVar: " << charVar << endl;
    cout << "boolVar: " << boolVar << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int integerVar; // Declaring an integer variable
    double doubleVar; // Declaring a double variable
    char charVar; // Declaring a character variable
    bool boolVar; // Declaring a boolean variable

    integerVar = 42; // Assigning a value to integerVar
    doubleVar = 3.14; // Assigning a value to doubleVar
    charVar = 'A'; // Assigning a value to charVar
    boolVar = true; // Assigning a value to boolVar

    cout << "integerVar: " << integerVar << endl;
    cout << "doubleVar: " << doubleVar << endl;
    cout << "charVar: " << charVar << endl;
    cout << "boolVar: " << boolVar << endl;

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Identifiers</h3>
      <p>
        Identifiers are names given to various program elements such as variables, functions, classes, etc. They must follow certain rules:
      </p>
      <ul>
        <li>Must begin with a letter (a-z, A-Z) or an underscore (_).</li>
        <li>Subsequent characters can be letters, digits (0-9), or underscores.</li>
        <li>Case-sensitive.</li>
        <li>Cannot be a C++ keyword.</li>
      </ul>

      <h3>Constants</h3>
      <p>
        In C++, constants are values that cannot be altered during the execution of a program. Two types of constants are supported: literal constants and symbolic constants.
      </p>
      <ul>
        <li>
          <strong>Literal Constants:</strong> Constants specified directly in the program, e.g., <code>42</code> or <code>3.14</code>.
        </li>
        <li>
          <strong>Symbolic Constants:</strong> Constants declared using the <code>const</code> keyword, e.g., <code>const int maxCount = 100;</code>.
        </li>
      </ul>

      <p>Let&apos;s add some examples of constants:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    // Literal Constants
    int literalConstant = 42;
    double pi = 3.14;

    // Symbolic Constants
    const int maxCount = 100;
    const double gravity = 9.81;

    cout << "Literal Constant: " << literalConstant << endl;
    cout << "Literal Constant (Pi): " << pi << endl;
    cout << "Symbolic Constant (Max Count): " << maxCount << endl;
    cout << "Symbolic Constant (Gravity): " << gravity << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    // Literal Constants
    int literalConstant = 42;
    double pi = 3.14;

    // Symbolic Constants
    const int maxCount = 100;
    const double gravity = 9.81;

    cout << "Literal Constant: " << literalConstant << endl;
    cout << "Literal Constant (Pi): " << pi << endl;
    cout << "Symbolic Constant (Max Count): " << maxCount << endl;
    cout << "Symbolic Constant (Gravity): " << gravity << endl;

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppVariables;
