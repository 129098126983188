import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer";
import NavBar from "../../Navbar";

function ChemistryTopicsPage() {
  const Topics = [
    {
      id: 1,
      topic: "INTRODUCTION: MATTER,ENERGY, AND MEASUREMENT",
      link: "/chemistry/matter-energy-and-measurement",
      
    },
    {
      id: 2,
      topic: "ATOMS,MOLECULES, AND IONS",
      link: "/chemistry/atoms-molecules-ions",
    },
    {
      id: 3,
      topic: "CHEMICAL REACTIONS AND REACTIONS STOICHIOMETRY",
      link: "/chemistry/chemical-reactions-and-reactions-stoichiometry",
    },
    {
      id: 4,
      topic: "THERMOCHEMISTRY",
      link: "/chemistry/thermochemistry",
    },
    {
      id: 5,
      topic: "ELECTRONIC STRUCTURE AND PERIODIC PROPERTIES OF ELEMENTS",
      link: "/chemistry/electronic-structure-and-periodic-properties-of-elements",
    },
    {
      id: 5,
      topic: "CHEMICAL BONDING AND MOLECULAR GEOMETRY",
      link: "/chemistry/chemical-bonding-and-molecular-geometry",
    },
    {
      id: 6,
      topic: "GASES",
      link: "/chemistry/gases",
    },
    {
      id: 7,
      topic: "LIQUIDS AND SOLIDS",
      link: "/chemistry/liquids-and-solids",
    },
    {
      id: 8,
      topic: "SOLUTIONS AND COLLOIDS",
      link: "/chemistry/solutions-and-colloids",
    },
    {
      id: 9,
      topic: "KINETICS",
      link: "/chemistry/kinetics",
    },
    {
      id: 10,
      topic: "FUNDAMENTAL AND EQUILIBRIUM CONCEPTS",
      link: "/chemistry/fundamental-and-equilibrium-concepts",
    },
    {
      id: 11,
      topic: "ACID-BASE EQUILIBRIA",
      link: "/chemistry/acid-base-equilibria",
    },
    {
      id: 12,
      topic: "THERMODYNAMICS",
      link: "/chemistry/thermodynamics",
    },
    {
      id: 13,
      topic: "ELECTROCHEMISTRY",
      link: "/chemistry/electrochemistry",
    },
    {
      id: 14,
      topic: "METALS, METALLOID AND NON-METALS",
      link: "/chemistry/metals-metalloid-and-non-metals",
    },
    {
      id: 15,
      topic: "ORGANIC CHEMISTRY",
      link: "/chemistry/organic-chemistry",
    },
    {
      id: 16,
      topic: "NUCLEAR CHEMISTRY",
      link: "/chemistry/nuclear-chemistry",
    }
  ];

  return (
    <div>
      <NavBar />
      <div
        className="container mt-5 mb-5 p-3"
      
      >
        <h6 className="topics-border-btn">Topics</h6>
        {Topics.map((topic) => (
          <div key={topic.id}>
            <Link to={topic.link} style={{textDecoration:"none"}}>
              <p className = "p-1   topic-hover" > {topic.topic}</p>
            </Link>
           
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default ChemistryTopicsPage;
