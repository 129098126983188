import React from 'react';

const CppStructures = () => {
  return (
    <div>
      <h2>C++ Structures</h2>
      <p>
        In C++, a structure is a user-defined data type that groups together variables under a single name. Here&apos;s how you can define a structure:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
struct Person {
    string name;
    int age;
    double height;
};

Person person1; // Creating an instance of the structure
person1.name = "Alice";
person1.age = 30;
person1.height = 1.75;
          `}
        </code>
      </pre>

      <h3>Code Examples:</h3>

      <h3>Structure Definition and Usage</h3>
      <p>
        Define a structure and create an instance to store information about a person.
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
using namespace std;

struct Person {
    string name;
    int age;
    double height;
};

int main() {
    Person person1;
    person1.name = "Alice";
    person1.age = 30;
    person1.height = 1.75;

    cout << "Person 1 Info:" << endl;
    cout << "Name: " << person1.name << endl;
    cout << "Age: " << person1.age << endl;
    cout << "Height: " << person1.height << " meters" << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>
using namespace std;

struct Person {
    string name;
    int age;
    double height;
};

int main() {
    Person person1;
    person1.name = "Alice";
    person1.age = 30;
    person1.height = 1.75;

    cout << "Person 1 Info:" << endl;
    cout << "Name: " << person1.name << endl;
    cout << "Age: " << person1.age << endl;
    cout << "Height: " << person1.height << " meters" << endl;

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Arrays of Structures</h3>
      <p>
        Create an array of structures to store information about multiple people.
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
using namespace std;

struct Person {
    string name;
    int age;
    double height;
};

int main() {
    Person people[3];

    people[0].name = "Alice";
    people[0].age = 30;
    people[0].height = 1.75;

    people[1].name = "Bob";
    people[1].age = 25;
    people[1].height = 1.82;

    people[2].name = "Charlie";
    people[2].age = 35;
    people[2].height = 1.68;

    cout << "People Information:" << endl;
    for (int i = 0; i < 3; i++) {
        cout << "Person " << i + 1 << " Info:" << endl;
        cout << "Name: " << people[i].name << endl;
        cout << "Age: " << people[i].age << endl;
        cout << "Height: " << people[i].height << " meters" << endl;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>
using namespace std;

struct Person {
    string name;
    int age;
    double height;
};

int main() {
    Person people[3];

    people[0].name = "Alice";
    people[0].age = 30;
    people[0].height = 1.75;

    people[1].name = "Bob";
    people[1].age = 25;
    people[1].height = 1.82;

    people[2].name = "Charlie";
    people[2].age = 35;
    people[2].height = 1.68;

    cout << "People Information:" << endl;
    for (int i = 0; i < 3; i++) {
        cout << "Person " << i + 1 << " Info:" << endl;
        cout << "Name: " << people[i].name << endl;
        cout << "Age: " << people[i].age << endl;
        cout << "Height: " << people[i].height << " meters" << endl;
    }

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppStructures;
