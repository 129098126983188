import React from "react";

const PythonFunctions = () => {
  // URL for the code editor
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python Functions</h2>
      <p>
        A function is a block of reusable code that performs a specific task. It
        allows you to organize your code into manageable pieces. Here are some
        common usages:
      </p>

      <h3>Defining a Function</h3>
      <p>
        Use the <code>def</code> keyword to define a function. Here&apos;s a simple
        function that prints a message:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def greet(name):
    print("Hello, " + name)

# Call the function
greet("Alice")`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'def greet(name):\n    print("Hello, " + name)\n\ngreet("Alice")'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Function Parameters</h3>
      <p>
        Parameters are placeholders in the function definition, while arguments
        are the actual values passed when calling a function. Here&apos;s a function
        that calculates the square of a number:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def square(x):
    return x * x

result = square(5)
print("Square:", result)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'def square(x):\n    return x * x\n\nresult = square(5)\nprint("Square:", result)'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Default Parameter Values</h3>
      <p>
        You can provide default values for parameters, making them optional. If
        a value is not passed, the default value is used:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def greet(name="Guest"):
    print("Hello, " + name)

greet()
greet("Alice")`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'def greet(name="Guest"):\n    print("Hello, " + name)\n\ngreet()\ngreet("Alice")'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Return Statement</h3>
      <p>
        Functions can return values using the <code>return</code> statement.
        Here&apos;s a function that adds two numbers:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def add(x, y):
    return x + y

result = add(3, 4)
print("Sum:", result)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'def add(x, y):\n    return x + y\n\nresult = add(3, 4)\nprint("Sum:", result)'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Variable Scope</h3>
      <p>
        Variables defined within a function have local scope and are not
        accessible outside the function. Variables outside the function have
        global scope:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`x = 10

def print_x():
    x = 5
    print("Inside Function:", x)

print_x()
print("Outside Function:", x)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'x = 10\ndef print_x():\n    x = 5\n    print("Inside Function:", x)\n\nprint_x()\nprint("Outside Function:", x)'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Looping Within Functions</h3>
      <p>
        Functions can contain loops for repetitive tasks. Here are some examples
        of looping within functions:
      </p>

      <h4>Example 1: Loop with Range</h4>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def count_to_n(n):
    for i in range(1, n + 1):
        print(i)

count_to_n(5)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "def count_to_n(n):\n    for i in range(1, n + 1):\n        print(i)\n\ncount_to_n(5)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h4>Example 2: Loop Through a List</h4>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def print_list_elements(my_list):
    for item in my_list:
        print(item)

fruits = ['apple', 'banana', 'cherry']
print_list_elements(fruits)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "def print_list_elements(my_list):\n    for item in my_list:\n        print(item)\n\nfruits = ['apple', 'banana', 'cherry']\nprint_list_elements(fruits)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h4>Example 3: Loop Through a String</h4>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def count_vowels(text):
    vowels = 'aeiou'
    count = 0
    for char in text:
        if char in vowels:
            count += 1
    return count

result = count_vowels('hello')
print('Vowel count:', result)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "def count_vowels(text):\n    vowels = 'aeiou'\n    count = 0\n    for char in text:\n        if char in vowels:\n            count += 1\n    return count\n\nresult = count_vowels('hello')\nprint('Vowel count:', result)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h4>Example 4: Loop Using Enumerate</h4>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def print_list_with_indices(my_list):
    for index, item in enumerate(my_list):
        print(f'Index {index}: {item}')

fruits = ['apple', 'banana', 'cherry']
print_list_with_indices(fruits)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "def print_list_with_indices(my_list):\n    for index, item in enumerate(my_list):\n        print(f'Index {index}: {item}')\n\nfruits = ['apple', 'banana', 'cherry']\nprint_list_with_indices(fruits)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Recursive Functions</h3>
      <p>
        A recursive function is a function that calls itself. Here&apos;s a simple
        example of a recursive function that calculates the factorial of a
        number:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def factorial(n):
    if n == 1:
        return 1
    else:
        return n * factorial(n - 1)

result = factorial(5)
print("Factorial:", result)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'def factorial(n):\n    if n == 1:\n        return 1\n    else:\n        return n * factorial(n - 1)\n\nresult = factorial(5)\nprint("Factorial:", result)'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Parameters vs. Arguments</h3>
      <p>
        Parameters are the variables used in the function definition. Arguments
        are the values passed when calling the function. For example:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def example(parameter):
    print("Parameter:", parameter)

argument = 42
example(argument)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'def example(parameter):\n    print("Parameter:", parameter)\n\nargument = 42\nexample(argument)'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Arbitrary Arguments (*args)</h3>
      <p>
        The special syntax <code>*args</code> in a function parameter allows you
        to pass a variable number of non-keyword arguments. These arguments are
        received as a tuple:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def sum_all(*args):
    total = 0
    for num in args:
        total += num
    return total

result = sum_all(1, 2, 3, 4, 5)
print("Sum:", result)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'def sum_all(*args):\n    total = 0\n    for num in args:\n        total += num\n    return total\n\nresult = sum_all(1, 2, 3, 4, 5)\nprint("Sum:", result)'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Multiple Arguments</h3>
      <p>
        You can also use <code>*args</code> to pass multiple arguments:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def display_arguments(arg1, arg2, *args):
    print("Argument 1:", arg1)
    print("Argument 2:", arg2)
    print("Additional Arguments:", args)

display_arguments("A", "B", "C", "D", "E")`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'def display_arguments(arg1, arg2, *args):\n    print("Argument 1:", arg1)\n    print("Argument 2:", arg2)\n    print("Additional Arguments:", args)\n\ndisplay_arguments("A", "B", "C", "D", "E")'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default PythonFunctions;
