import React from 'react';

const CppFileHandling = () => {
  return (
    <div>
      <h2>C++ File Handling</h2>
      <p>
        C++ provides file handling capabilities for reading and writing files. You can perform various file operations such as opening, reading, writing, and closing files. Here are some common file handling operations:
      </p>

      <h3>1. Opening and Closing Files</h3>
      <p>
        You can open a file using the <code>ifstream</code> (for reading) or <code>ofstream</code> (for writing) objects and close it when done.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`#include <iostream>
#include <fstream>

int main() {
    std::ifstream inFile("input.txt");
    if (!inFile) {
        std::cerr << "Failed to open the file." << std::endl;
        return 1;
    }

    // Read data from the file

    inFile.close();  // Close the file
    return 0;
}
`}
        </code>
      </pre>

      <h3>2. Reading from a File</h3>
      <p>Use <code>ifstream</code> to read data from a file.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`#include <iostream>
#include <fstream>
#include <string>

int main() {
    std::ifstream inFile("input.txt");
    if (!inFile) {
        std::cerr << "Failed to open the file." << std::endl;
        return 1;
    }

    std::string line;
    while (std::getline(inFile, line)) {
        std::cout << line << std::endl;
    }

    inFile.close();
    return 0;
}
`}
        </code>
      </pre>

      <h3>3. Writing to a File</h3>
      <p>Use <code>ofstream</code> to write data to a file.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`#include <iostream>
#include <fstream>

int main() {
    std::ofstream outFile("output.txt");
    if (!outFile) {
        std::cerr << "Failed to create the file." << std::endl;
        return 1;
    }

    outFile << "Hello, world!" << std::endl;
    outFile.close();
    return 0;
}
`}
        </code>
      </pre>

      <h3>4. Appending to a File</h3>
      <p>
        To append data to an existing file, use <code>std::ofstream</code> with the <code>std::ios::app</code> flag.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`#include <iostream>
#include <fstream>

int main() {
    std::ofstream outFile("output.txt", std::ios::app);
    if (!outFile) {
        std::cerr << "Failed to open the file." << std::endl;
        return 1;
    }

    outFile << " Appended text." << std::endl;
    outFile.close();
    return 0;
}
`}
        </code>
      </pre>

      <h3>5. Checking for End of File (EOF)</h3>
      <p>Use the <code>eof()</code> function to check for the end of the file while reading.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`#include <iostream>
#include <fstream>

int main() {
    std::ifstream inFile("input.txt");
    if (!inFile) {
        std::cerr << "Failed to open the file." << std::endl;
        return 1;
    }

    int number;
    while (!inFile.eof()) {
        inFile >> number;
        std::cout << number << std::endl;
    }

    inFile.close();
    return 0;
}
`}
        </code>
      </pre>

      <h3>6. Handling Binary Files</h3>
      <p>
        C++ allows reading and writing binary files using <code>std::ios::binary</code> mode in addition to text files.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`#include <iostream>
#include <fstream>

int main() {
    std::ofstream outFile("binary.dat", std::ios::binary);
    if (!outFile) {
        std::cerr << "Failed to create the binary file." << std::endl;
        return 1;
    }

    int data[] = {1, 2, 3, 4, 5};
    outFile.write(reinterpret_cast<char*>(data), sizeof(data));
    outFile.close();
    return 0;
}
`}
        </code>
      </pre>

      <p>These are some common file handling operations in C++. You can open, read, write, and manipulate files for various applications.</p>
    </div>
  );
};

export default CppFileHandling;
