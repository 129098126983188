import React from 'react';

const PythonVariables = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div >
      <h2>Python Variables</h2>
      <div >
        <div>
          <p>In Python, variables are used to store data. Python is dynamically typed, meaning you don&quot;t need to declare a variable&quot;s data type. Here&quot;s how you can work with variables:</p>

          <h3>Variable Declaration</h3>
          <p>Variables are declared and assigned values using the assignment operator (<code>=</code>). Variable names must follow certain rules:</p>
          <ul>
            <li>Must start with a letter or underscore.</li>
            <li>Can only contain letters, numbers, and underscores.</li>
            <li>Are case-sensitive (e.g., <code>myVariable</code> and <code>myvariable</code> are different).</li>
          </ul>
          <p>For example:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`name = "Alice"
age = 30
_underscore_var = "Legal"

# Illegal variable names
123variable = "Illegal"
my-variable = "Illegal"
`} 
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("name = 'Alice'\nage = 30\n_underscore_var = 'Legal'\n\n# Illegal variable names\n123variable = 'Illegal'\nmy-variable = 'Illegal'"), '_blank')}>Run Code</button>

          <h3>Assigning Multiple Variables</h3>
          <p>You can assign values to multiple variables in a single line:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`name, age, city = "Alice", 30, "New York"`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("name, age, city = 'Alice', 30, 'New York'"), '_blank')}>Run Code</button>

          <h3>Naming Techniques</h3>
          <p>When naming variables, you can use different techniques:</p>
          <ul>
            <li>Camel Case: e.g., <code>myVariableName</code></li>
            <li>Snake Case: e.g., <code>my_variable_name</code></li>
            <li>Pascal Case: e.g., <code>MyVariableName</code></li>
          </ul>
          <p>Choose a naming convention that suits your style and maintain consistency.</p>

          <h3>Variable Data Types</h3>
          <p>Python supports various data types for variables, including:</p>
          <ul>
            <li>Integer (int)</li>
            <li>Float (float)</li>
            <li>String (str)</li>
            <li>Boolean (bool)</li>
            <li>List</li>
            <li>Tuple</li>
            <li>Dictionary</li>
          </ul>
          <p>For example:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`# Integer
age = 30
print(age)

# Float
height = 5.7
print(height)

# String
name = "Alice"
print(name)

# Boolean
is_student = True
print(is_student)

# List
fruits = ["apple", "banana", "cherry"]
print(fruits)

# Tuple
coordinates = (3, 4)
print(coordinates)

# Dictionary
person = {"name": "Alice", "age": 30}
print(person)`}
            </code>
          </pre>
          <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("# Integer\nage = 30\n\n# Float\nheight = 5.7\n\n# String\nname = 'Alice'\n\n# Boolean\nis_student = True\n\n# List\nfruits = ['apple', 'banana', 'cherry']\n\n# Tuple\ncoordinates = (3, 4)\n\n# Dictionary\nperson = {'name': 'Alice', 'age': 30}"), '_blank')}>Run Code</button>
          <h3 className='mt-4'>Variable Scope</h3>
          <p>Variables can have different scopes in Python:</p>
          <ul>
            <li>Local variables: Defined within a function and have local scope.</li>
            <li>Global variables: Defined outside of functions and have global scope.</li>
          </ul>
          <p>For example:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`# Global variable
global_var = "I am global"

def my_function():
    # Local variable
    local_var = "I am local"
    print(global_var)
    print(local_var)

my_function()
print(global_var)
# This will result in an error
# print(local_var)`}
            </code>
          </pre>
          <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("# Global variable\nglobal_var = 'I am global'\n\ndef my_function():\n    # Local variable\n    local_var = 'I am local'\n    print(global_var)\n    print(local_var)\n\nmy_function()\nprint(global_var)\n# This will result in an error\n# print(local_var)"), '_blank')}>Run Code</button>
        </div>
      </div>
    </div>
  );
}

export default PythonVariables;
