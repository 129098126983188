import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const ReplyForm = ({ postId,  handleReply }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      handleReply(postId, inputValue);
      setInputValue('');
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="replyContent">
        <Form.Label>Reply:</Form.Label>
        <Form.Control
          as="textarea"
          rows={2}
          value={inputValue}
          onChange={handleInputChange}
          placeholder="Type your reply here..."
        />
      </Form.Group>
      <Button variant="primary" type="submit" className='m-2'>
        Reply
      </Button>
    </Form>
  );
};



ReplyForm.propTypes = {
  postId: PropTypes.number.isRequired, // Change 'number' to the appropriate data type
  handleReply: PropTypes.func.isRequired,
};


export default ReplyForm;
