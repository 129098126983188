import React from 'react';

const PythonTryExcept = () => {
  return (
    <div>
      <h2>Python Try-Except</h2>
      <p>Python provides the <code>try</code> and <code>except</code> blocks to handle exceptions, allowing you to gracefully manage errors that might occur during program execution.</p>

      <h3>Basic Try-Except Block</h3>
      <p>Use a <code>try</code> block to enclose code that might raise an exception. If an exception occurs, it&quot;s caught by the corresponding <code>except</code> block.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`try:
    # Code that might raise an exception
    x = 10 / 0
except ZeroDivisionError:
    # Handle the exception
    x = 0

print(x)`}
        </code>
      </pre>

      <h3>Catching Multiple Exceptions</h3>
      <p>You can catch multiple exceptions in a single <code>except</code> block:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`try:
    num = int("abc")
except (ValueError, TypeError):
    # Handle multiple exceptions
    num = 0

print(num)`}
        </code>
      </pre>

      <h3>Using an <code>else</code> Block</h3>
      <p>The <code>else</code> block is executed if no exceptions occur:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`try:
    x = 10 / 2
except ZeroDivisionError:
    x = 0
else:
    # This block is executed when no exceptions occur
    x *= 2

print(x)`}
        </code>
      </pre>

      <h3>Handling Unspecified Exceptions</h3>
      <p>You can use a generic <code>except</code> block to catch any unspecified exceptions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`try:
    value = int("123")
except:
    # Handle any exception
    value = 0

print(value)`}
        </code>
      </pre>

      <h3>Using <code>finally</code> Block</h3>
      <p>The <code>finally</code> block is executed regardless of whether an exception occurred or not. It&quot;s commonly used for cleanup tasks:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`try:
    file = open("example.txt", "r")
    data = file.read()
except FileNotFoundError:
    data = "File not found"
finally:
    file.close()

print(data)`}
        </code>
      </pre>

      <h3>Raising Custom Exceptions</h3>
      <p>You can raise custom exceptions using the <code>raise</code> statement:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def validate_age(age):
    if age < 0:
        raise ValueError("Age cannot be negative")

try:
    validate_age(-5)
except ValueError as error:
    print(error)`}
        </code>
      </pre>
    </div>
  );
}

export default PythonTryExcept;
