import React from "react";

const CppFunctions = () => {
  return (
    <div>
      <h2>C++ Functions</h2>
      <p>
        In C++, functions are blocks of code that perform a specific task. They
        are defined with a return type, a name, and parameters. Functions allow
        you to organize and reuse code. Here&apos;s the basic syntax of a
        function:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
return_type function_name(parameters) {
    // Function body
    // ...
    return value; // Return a value (if return type is not void)
}
          `}
        </code>
      </pre>

      <h3>Code Examples:</h3>

      <h3>Defining a Function</h3>
      <p>
        Here&apos;s an example of a simple function that calculates the sum of
        two integers:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

// Function to calculate the sum of two integers
int add(int a, int b) {
    return a + b;
}

int main() {
    int num1 = 5, num2 = 3;
    int result = add(num1, num2);
    cout << "Sum: " << result << endl;
    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

// Function to calculate the sum of two integers
int add(int a, int b) {
    return a + b;
}

int main() {
    int num1 = 5, num2 = 3;
    int result = add(num1, num2);
    cout << "Sum: " << result << endl;
    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Function with No Return Value (void)</h3>
      <p>
        Functions can have a return type of <code>void</code> if they do not
        return a value:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

// Function to display a message
void displayMessage() {
    cout << "Hello from the function!" << endl;
}

int main() {
    displayMessage();
    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

// Function to display a message
void displayMessage() {
    cout << "Hello from the function!" << endl;
}

int main() {
    displayMessage();
    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Function with Default Parameters</h3>
      <p>You can provide default values for function parameters:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

// Function with default parameters
void greet(string name = "Guest") {
    cout << "Hello, " << name << "!" << endl;
}

int main() {
    greet();         // Will greet "Guest"
    greet("Alice");  // Will greet "Alice"
    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

// Function with default parameters
void greet(string name = "Guest") {
    cout << "Hello, " << name << "!" << endl;
}

int main() {
    greet();         // Will greet "Guest"
    greet("Alice");  // Will greet "Alice"
    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Recursive Function</h3>
      <p>
        Functions can call themselves. This is called a recursive function.
        Here&apos;s an example of a recursive function that calculates the
        factorial of a number:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

// Recursive function to calculate factorial
int factorial(int n) {
    if (n == 0) {
        return 1;
    } else {
        return n * factorial(n - 1);
    }
}

int main() {
    int num = 5;
    int result = factorial(num);
    cout << "Factorial of " << num << " is " << result << endl;
    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

// Recursive function to calculate factorial
int factorial(int n) {
    if (n == 0) {
        return 1;
    } else {
        return n * factorial(n - 1);
    }
}

int main() {
    int num = 5;
    int result = factorial(num);
    cout << "Factorial of " << num << " is " << result << endl;
    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Function Overloading</h3>
      <p>
        Function overloading allows you to define multiple functions with the
        same name but different parameter lists. The appropriate function is
        called based on the number and types of arguments provided.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

// Function to calculate the sum of two integers
int add(int a, int b) {
    return a + b;
}

// Overloaded function to calculate the sum of three integers
int add(int a, int b, int c) {
    return a + b + c;
}

int main() {
    int num1 = 5, num2 = 3, num3 = 2;
    int result1 = add(num1, num2);
    int result2 = add(num1, num2, num3);
    cout << "Sum (two arguments): " << result1 << endl;
    cout << "Sum (three arguments): " << result2 << endl;
    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

// Function to calculate the sum of two integers
int add(int a, int b) {
    return a + b;
}

// Overloaded function to calculate the sum of three integers
int add(int a, int b, int c) {
    return a + b + c;
}

int main() {
    int num1 = 5, num2 = 3, num3 = 2;
    int result1 = add(num1, num2);
    int result2 = add(num1, num2, num3);
    cout << "Sum (two arguments): " << result1 << endl;
    cout << "Sum (three arguments): " << result2 << endl;
    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppFunctions;
