import React from 'react';

const JavaScriptComments = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Comments</h2>
      <p>In JavaScript, comments are used to provide explanations within your code. Comments are ignored by the JavaScript interpreter and are meant for human readers. Here are the types of comments:</p>

      <h3>Single-Line Comments</h3>
      <p>Single-line comments start with // and continue until the end of the line:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`// This is a single-line comment
console.log(&apos;This is not a comment&apos;);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("// This is a single-line comment\nconsole.log(&apos;This is not a comment&apos;);"), '_blank')}>Run Code</button>

      <h3>Multi-Line Comments</h3>
      <p>Multi-line comments start with /* and end with */. They can span across multiple lines:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`/*
This is a multi-line comment.
It can span across multiple lines.
*/`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("/*\nThis is a multi-line comment.\nIt can span across multiple lines.\n*/"), '_blank')}>Run Code</button>

      <h3>Comments for Code Documentation</h3>
      <p>Comments are essential for documenting your code, especially for functions and modules. You can use single-line or multi-line comments for documentation. Here&apos;s an example:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`function add(a, b) {
  /*
  This function adds two numbers.

  Parameters:
      a (number): The first number.
      b (number): The second number.

  Returns:
      number: The sum of a and b.
  */
  return a + b;
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("function add(a, b) {\n  /*\n  This function adds two numbers.\n\n  Parameters:\n      a (number): The first number.\n      b (number): The second number.\n\n  Returns:\n      number: The sum of a and b.\n  */\n  return a + b;\n}"), '_blank')}>Run Code</button>

      <h3>Commenting Best Practices</h3>
      <p>When writing comments in your JavaScript code, consider the following best practices:</p>
      <ul>
        <li>Keep comments concise and to the point.</li>
        <li>Use comments to explain complex code or provide context.</li>
        <li>Update comments when you modify the code to keep them accurate.</li>
        <li>Avoid excessive commenting for obvious or self-explanatory code.</li>
      </ul>
    </div>
  );
}

export default JavaScriptComments;
