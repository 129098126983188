import React from "react";

const PythonClassesAndObjects = () => {
  // URL for the code editor
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python Classes and Objects</h2>
      <p>
        A class is a blueprint for creating objects, and an object is an
        instance of a class. Classes allow you to define attributes and methods
        that are common to a group of objects. Here&apos;s an introduction with code
        examples:
      </p>

      <h3>Creating a Class</h3>
      <p>
        To create a class, use the <code>class</code> keyword:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Dog:
    def __init__(self, name, age):
        self.name = name
        self.age = age

dog1 = Dog("Buddy", 3)
print("Name:", dog1.name)
print("Age:", dog1.age)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'class Dog:\n    def __init__(self, name, age):\n        self.name = name\n        self.age = age\n\ndog1 = Dog("Buddy", 3)\nprint("Name:", dog1.name)\nprint("Age:", dog1.age)'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Class Methods</h3>
      <p>You can define methods within a class to perform actions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Dog:
    def __init__(self, name, age):
        self.name = name
        self.age = age

    def bark(self):
        print(f"{self.name} is barking!")

dog1 = Dog("Buddy", 3)
dog1.bark()`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'class Dog:\n    def __init(self, name, age):\n        self.name = name\n        self.age = age\n\n    def bark(self):\n        print(f"{self.name} is barking!")\n\ndog1 = Dog("Buddy", 3)\ndog1.bark()'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Inheritance</h3>
      <p>Classes can inherit attributes and methods from other classes:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Animal:
    def __init__(self, species):
        self.species = species

class Dog(Animal):
    def __init__(self, name, age):
        super().__init__("Dog")
        self.name = name
        self.age = age

dog1 = Dog("Buddy", 3)
print("Species:", dog1.species)
print("Name:", dog1.name)
print("Age:", dog1.age)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'class Animal:\n    def __init__(self, species):\n        self.species = species\n\nclass Dog(Animal):\n    def __init__(self, name, age):\n        super().__init__("Dog")\n        self.name = name\n        self.age = age\n\ndog1 = Dog("Buddy", 3)\nprint("Species:", dog1.species)\nprint("Name:", dog1.name)\nprint("Age:", dog1.age)'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Class Attributes</h3>
      <p>Class attributes are shared by all instances of a class:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Dog:
    species = "Canine"  # Class attribute

    def __init__(self, name, age):
        self.name = name
        self.age = age

dog1 = Dog("Buddy", 3)
print("Species:", dog1.species)

dog2 = Dog("Rex", 2)
print("Species:", dog2.species)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'class Dog:\n    species = "Canine"  # Class attribute\n\n    def __init__(self, name, age):\n        self.name = name\n        self.age = age\n\ndog1 = Dog("Buddy", 3)\nprint("Species:", dog1.species)\n\ndog2 = Dog("Rex", 2)\nprint("Species:", dog2.species)'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Object Methods</h3>
      <p>Object methods can modify object attributes:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Dog:
    def __init__(self, name, age):
        self.name = name
        self.age = age

    def change_name(self, new_name):
        self.name = new_name

dog1 = Dog("Buddy", 3)
print("Name (Before Change):", dog1.name)

dog1.change_name("Max")
print("Name (After Change):", dog1.name)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'class Dog:\n    def __init__(self, name, age):\n        self.name = name\n        self.age = age\n\ndog1 = Dog("Buddy", 3)\nprint("Name (Before Change):", dog1.name)\n\ndog1.change_name("Max")\nprint("Name (After Change):", dog1.name)'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Delete Object</h3>
      <p>
        You can delete objects using the <code>del</code> statement:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Dog:
    def __init__(self, name, age):
        self.name = name
        self.age = age

dog1 = Dog("Buddy", 3)
print("Name (Before Deletion):", dog1.name)

del dog1  # Deleting the object
# Accessing dog1 after deletion would result in an error

print("Name (After Deletion):", dog1.name)  # This line will cause an error`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'class Dog:\n    def __init__(self, name, age):\n        self.name = name\n        self.age = age\n\ndog1 = Dog("Buddy", 3)\nprint("Name (Before Deletion):", dog1.name)\n\ndel dog1  # Deleting the object\n# Accessing dog1 after deletion would result in an error\n\nprint("Name (After Deletion):", dog1.name)  # This line will cause an error'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Using the pass Statement</h3>
      <p>
        The <code>pass</code> statement is a null operation that does nothing.
        It can be used when no action is required within a class or method:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Dog:
    def __init__(self, name, age):
        self.name = name
        self.age = age
        pass  # Do nothing

dog1 = Dog("Buddy", 3)

# The pass statement has no effect on object creation
print("Name:", dog1.name)

def some_function():
    pass  # Do nothing

# The pass statement can be used in methods or functions

print("Object created and functions executed successfully.")`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'class Dog:\n    def __init__(self, name, age):\n        self.name = name\n        self.age = age\n        pass  # Do nothing\ndog1 = Dog("Buddy", 3)\n\n# The pass statement has no effect on object creation\nprint("Name:", dog1.name)\n\ndef some_function():\n    pass  # Do nothing\n\n# The pass statement can be used in methods or functions\n\nprint("Object created and functions executed successfully.")'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Additional Class and Object Examples</h3>
      <p>Here are four more examples of classes and objects:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Car:
    def __init__(self, make, model, year):
        self.make = make
        self.model = model
        self.year = year

car1 = Car("Ford", "Mustang", 2020)

class Circle:
    def __init__(self, radius):
        self.radius = radius

    def area(self):
        return 3.14 * self.radius * self.radius

circle1 = Circle(5)

class Student:
    def __init__(self, name, age):
        self.name = name
        self.age = age

    def introduce(self):
        print(f"Hello, my name is {self.name} and I am {self.age} years old.")

student1 = Student("Alice", 25)

class Book:
    def __init__(self, title, author):
        self.title = title
        self.author = author

book1 = Book("Harry Potter", "J.K. Rowling")
`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "class Car:\n    def __init__(self, make, model, year):\n        self.make = make\n        self model = model\n        self.year = year\ncar1 = Car('Ford', 'Mustang', 2020)\n\nclass Circle:\n    def __init__(self, radius):\n        self.radius = radius\n\ncircle1 = Circle(5)\n\nclass Student:\n    def __init__(self, name, age):\n        self.name = name\n        self.age = age\n    \n    def introduce(self):\n        print(f'Hello, my name is {self.name} and I am {self.age} years old.')\n\nstudent1 = Student('Alice', 25)\n\nclass Book:\n    def __init__(self, title, author):\n        self.title = title\n        self.author = author\n\nbook1 = Book('Harry Potter', 'J.K. Rowling')"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default PythonClassesAndObjects;
