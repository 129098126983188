import React from 'react';

const JavaScriptForLoops = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript For Loops</h2>
      <p>A for loop is used in JavaScript to iterate over a sequence or perform a set of actions a specific number of times. Here are some common ways to use for loops:</p>

      <h3>Basic For Loop</h3>
      <p>Use a basic for loop to iterate a specified number of times:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`for (let i = 0; i < 5; i++) {
  console.log(i);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("for (let i = 0; i < 5; i++) {\n  console.log(i);\n}"), '_blank')}>Run Code</button>

      <h3>For Loop with Arrays</h3>
      <p>Iterate through an array using a for loop:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
for (let i = 0; i < fruits.length; i++) {
  console.log(fruits[i]);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nfor (let i = 0; i < fruits.length; i++) {\n  console.log(fruits[i]);\n}"), '_blank')}>Run Code</button>

      <h3>For...of Loop</h3>
      <p>Use a for...of loop to iterate through the elements of an array:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const colors = ['red', 'green', 'blue'];
for (const color of colors) {
  console.log(color);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const colors = ['red', 'green', 'blue'];\nfor (const color of colors) {\n  console.log(color);\n}"), '_blank')}>Run Code</button>

      <h3>For...in Loop</h3>
      <p>Iterate through the properties of an object using a for...in loop:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const person = {
  name: 'Alice',
  age: 30,
  city: 'New York'
};
for (const key in person) {
  console.log(key, person[key]);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const person = {\n  name: 'Alice',\n  age: 30,\n  city: 'New York'\n};\nfor (const key in person) {\n  console.log(key, person[key]);\n}"), '_blank')}>Run Code</button>

      <h3>Nested For Loops</h3>
      <p>You can use nested for loops to perform more complex iterations:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`for (let i = 0; i < 3; i++) {
  for (let j = 0; j < 2; j++) {
    console.log(i, j);
  }
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("for (let i = 0; i < 3; i++) {\n  for (let j = 0; j < 2; j++) {\n    console.log(i, j);\n  }\n}"), '_blank')}>Run Code</button>

      <h3>For Loop with Break</h3>
      <p>Use the <code>break</code> statement to exit a loop based on a condition:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`for (let i = 0; i < 5; i++) {
  if (i === 3) {
    break;
  }
  console.log(i);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("for (let i = 0; i < 5; i++) {\n  if (i === 3) {\n    break;\n  }\n  console.log(i);\n}"), '_blank')}>Run Code</button>

      <h3>For Loop with Continue</h3>
      <p>Use the <code>continue</code> statement to skip an iteration based on a condition:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`for (let i = 0; i < 5; i++) {
  if (i === 3) {
    continue;
  }
  console.log(i);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("for (let i = 0; i < 5; i++) {\n  if (i === 3) {\n    continue;\n  }\n  console.log(i);\n}"), '_blank')}>Run Code</button>

    </div>
  );
}

export default JavaScriptForLoops;
