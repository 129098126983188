import React from "react";

const JavaScriptVariables = () => {
  // URL for the code editor
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>JavaScript Variables</h2>
      <p>
        Variables are used to store data in JavaScript. You can declare
        variables using keywords like <code>var</code>, <code>let</code>, or{" "}
        <code>const</code>.
      </p>

      <h3>Variable Declaration</h3>
      <p>Here&apos;s how you declare variables:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`var name = 'Alice'; // Using var (function or global scope)
let age = 30;        // Using let (block scope)
const PI = 3.1416;    // Using const (block scope)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "var name = 'Alice';\nlet age = 30;\nconst PI = 3.1416;"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Data Types</h3>
      <p>JavaScript supports various data types:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`var num = 42;           // Number
var price = 19.99;       // Floating-point number
var message = 'Hello';   // String
var isActive = true;     // Boolean
var fruits = ['apple', 'banana'];  // Array
var person = {name: 'Bob', age: 25};  // Object`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "var num = 42;\nvar price = 19.99;\nvar message = 'Hello';\nvar isActive = true;\nvar fruits = ['apple', 'banana'];\nvar person = {name: 'Bob', age: 25};"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Variable Scope</h3>
      <p>
        Variables declared with <code>var</code> have function or global scope,
        while variables declared with <code>let</code> and <code>const</code>{" "}
        have block scope:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`if (true) {
  var x = 10; // Accessible outside the block
}

if (true) {
  let y = 20; // Not accessible outside the block
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "if (true) {\n  var x = 10;\n}\n\nif (true) {\n  let y = 20;\n}"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Variable Hoisting</h3>
      <p>
        JavaScript variables are hoisted, which means they are moved to the top
        of their scope during compilation:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`console.log(a); // Output: undefined
var a = 5;

console.log(b); // Error: Cannot access 'b' before initialization
let b = 10;`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "console.log(a); // Output: undefined\nvar a = 5;\n\nconsole.log(b); // Error: Cannot access 'b' before initialization\nlet b = 10;"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Variable Naming</h3>
      <p>
        Variable names should follow certain rules and conventions in
        JavaScript:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`var validName = 'Good variable name';
var 1invalidName = 'Invalid variable name'; // Error: Invalid variable name`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "var validName = 'Good variable name';\nvar 1invalidName = 'Invalid variable name'; // Error: Invalid variable name"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Difference Between var, let, and const</h3>
      <p>
        Here&apos;s the key difference between <code>var</code>, <code>let</code>,
        and <code>const</code>:
      </p>
      <ul>
        <li>
          <code>var</code> has function or global scope and is hoisted. It can
          be redeclared.
        </li>
        <li>
          <code>let</code> has block scope, is hoisted, and can be reassigned,
          but not redeclared.
        </li>
        <li>
          <code>const</code> has block scope, is hoisted, and cannot be
          reassigned or redeclared.
        </li>
      </ul>
    </div>
  );
};

export default JavaScriptVariables;
