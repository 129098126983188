import React from 'react';
import PropTypes from 'prop-types';

const LiveChat = ({ productLink, imageUrl, altText }) => {
  return (
    <div className="clickbank-ad container">
      <a href={productLink} target="_blank" rel="noopener noreferrer">
        <img src={imageUrl} alt={altText} />
      </a>
    </div>
  );
};

LiveChat.propTypes = {
  productLink: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
};

export default LiveChat;
