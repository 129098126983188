import React from "react";

const PythonBooleans = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python Booleans</h2>
      <div>
        <div>
          <p>
            Booleans represent two values: <code>True</code> and{" "}
            <code>False</code>. They are often used for making decisions and
            controlling the flow of a program. Let&apos;s explore Python
            Booleans with code examples:
          </p>

          <h3>Comparison Operators</h3>
          <p>
            Comparison operators are used to compare values and return a Boolean
            result:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`x = 5
y = 3

# Equal
is_equal = x == y
print(is_equal)  # Output: False

# Not Equal
not_equal = x != y
print(not_equal)  # Output: True

# Greater Than
greater_than = x > y
print(greater_than)  # Output: True

# Less Than
less_than = x < y
print(less_than)  # Output: False`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "x = 5\ny = 3\nis_equal = x == y\nnot_equal = x != y\ngreater_than = x > y\nless_than = x < y"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>Logical Operators</h3>
          <p>Logical operators are used to combine Boolean values:</p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`a = True
b = False

# AND
and_result = a and b
print(and_result)  # Output: False

# OR
or_result = a or b
print(or_result)  # Output: True

# NOT
not_result = not a
print(not_result)  # Output: False`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "a = True\nb = False\nand_result = a and b\nor_result = a or b\nnot_result = not a"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>If Statements</h3>
          <p>
            Boolean values are often used with if statements to control program
            flow:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`# Using if statements
x = 10

if x > 5:
    print("x is greater than 5")
else:
    print("x is not greater than 5")`}
            </code>
          </pre>
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "x = 10\nif x > 5:\n    print('x is greater than 5')\nelse:\n    print('x is not greater than 5')"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>
        </div>
      </div>
    </div>
  );
};

export default PythonBooleans;
