import React from "react";

const CppInheritance = () => {
  return (
    <div>
      <h2>C++ Inheritance</h2>
      <p>
        In C++, inheritance allows a class to inherit properties and behavior
        from another class. The class that is inherited from is called the base
        class, and the class that inherits is called the derived class.
        Here&apos;s the syntax:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
class BaseClass {
public:
    // Base class members
};

class DerivedClass : public BaseClass {
public:
    // Derived class members
};
          `}
        </code>
      </pre>

      <h3>Code Examples:</h3>

      <h3>Single Inheritance</h3>
      <p>Single inheritance involves one base class and one derived class:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

class Animal {
public:
    void speak() {
        cout << "Animal speaks" << endl;
    }
};

class Dog : public Animal {
public:
    void bark() {
        cout << "Dog barks" << endl;
    }
};

int main() {
    Dog dog;
    dog.speak();
    dog.bark();
    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

class Animal {
public:
    void speak() {
        cout << "Animal speaks" << endl;
    }
};

class Dog : public Animal {
public:
    void bark() {
        cout << "Dog barks" << endl;
    }
};

int main() {
    Dog dog;
    dog.speak();
    dog.bark();
    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Multiple Inheritance</h3>
      <p>
        Multiple inheritance involves a derived class inheriting from more than
        one base class:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

class Animal {
public:
    void speak() {
        cout << "Animal speaks" << endl;
    }
};

class Vehicle {
public:
    void drive() {
        cout << "Vehicle is driving" << endl;
    }
};

class Hybrid : public Animal, public Vehicle {
public:
    void charge() {
        cout << "Hybrid is charging" << endl;
    }
};

int main() {
    Hybrid hybrid;
    hybrid.speak();
    hybrid.drive();
    hybrid.charge();
    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

class Animal {
public:
    void speak() {
        cout << "Animal speaks" << endl;
    }
};

class Vehicle {
public:
    void drive() {
        cout << "Vehicle is driving" << endl;
    }
};

class Hybrid : public Animal, public Vehicle {
public:
    void charge() {
        cout << "Hybrid is charging" << endl;
    }
};

int main() {
    Hybrid hybrid;
    hybrid.speak();
    hybrid.drive();
    hybrid.charge();
    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Hierarchical Inheritance</h3>
      <p>
        Hierarchical inheritance involves multiple derived classes inheriting
        from a single base class:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

class Shape {
public:
    void draw() {
        cout << "Shape is drawn" << endl;
    }
};

class Circle : public Shape {
public:
    void drawCircle() {
        cout << "Circle is drawn" << endl;
    }
};

class Rectangle : public Shape {
public:
    void drawRectangle() {
        cout << "Rectangle is drawn" << endl;
    }
};

int main() {
    Circle circle;
    Rectangle rectangle;
    circle.draw();
    circle.drawCircle();
    rectangle.draw();
    rectangle.drawRectangle();
    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

class Shape {
public:
    void draw() {
        cout << "Shape is drawn" << endl;
    }
};

class Circle : public Shape {
public:
    void drawCircle() {
        cout << "Circle is drawn" << endl;
    }
};

class Rectangle : public Shape {
public:
    void drawRectangle() {
        cout << "Rectangle is drawn" << endl;
    }
};

int main() {
    Circle circle;
    Rectangle rectangle;
    circle.draw();
    circle.drawCircle();
    rectangle.draw();
    rectangle.drawRectangle();
    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppInheritance;
