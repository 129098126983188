import React from "react";

const PythonStrings = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python Strings</h2>
      <div>
        <div>
          <p>
            Strings in Python are sequences of characters. They are versatile
            and used for a wide range of operations. Let&quot;s explore
            everything about Python strings with detailed explanations and code
            examples:
          </p>

          <h3>String Creation</h3>
          <p>
            Strings can be created using single quotes, double quotes, or triple
            quotes:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`single_quoted = 'Hello, Python'
double_quoted = "Python is great"
triple_quoted = '''This is a
multiline string'''`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>String Concatenation</h3>
          <p>
            Strings can be concatenated using the <code>+</code> operator:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`first_name = 'John'
last_name = 'Doe'
full_name = first_name + ' ' + last_name
print(full_name)  # Output: 'John Doe'`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "first_name = 'John'\nlast_name = 'Doe'\nfull_name = first_name + ' ' + last_name\nprint(full_name)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>String Length</h3>
          <p>
            Find the length of a string using the <code>len()</code> function:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`text = 'Python is fun'
length = len(text)
print(length)  # Output: 13`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "text = 'Python is fun'\nlength = len(text)\nprint(length)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>String Slicing</h3>
          <p>You can extract parts of a string using slicing:</p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`text = 'Python is fun'
substring = text[7:9]
print(substring)  # Output: 'is'`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "text = 'Python is fun'\nsubstring = text[7:9]\nprint(substring)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h4>Slicing from the Start</h4>
          <pre className="bg-dark text-light p-2">
            <code>
              {`text = 'Python is fun'
start_slice = text[:6]
print(start_slice)  # Output: 'Python'`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "text = 'Python is fun'\nstart_slice = text[:6]\nprint(start_slice)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h4>Slicing to the End</h4>
          <pre className="bg-dark text-light p-2">
            <code>
              {`text = 'Python is fun'
end_slice = text[7:]
print(end_slice)  # Output: 'is fun'`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "text = 'Python is fun'\nend_slice = text[7:]\nprint(end_slice)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h4>Negative Indexing</h4>
          <p>
            To begin the slice from the end of the string, use negative indexes.
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`text = 'Python is fun'
last_character = text[-1]
print(last_character)  # Output: 'n'`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "text = 'Python is fun'\nlast_character = text[-1]\nprint(last_character)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h4>Additional Slicing Examples</h4>
          <pre className="bg-dark text-light p-2">
            <code>
              {`text = 'Python is fun'
# Slicing from the start to the 6th character
start_slice = text[:6]
# Slicing from the 7th character to the end
end_slice = text[7:]
# Negative indexing to get the last character
last_character = text[-1]
# Slicing with a step of 2 (every 2nd character)
step_slice = text[::2]

print(start_slice, end_slice, last_character, step_slice)
# Output: 'Python', 'is fun', 'n', 'Pto sfn'`}
            </code>
          </pre>
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "text = 'Python is fun'\nstart_slice = text[:6]\nend_slice = text[7:]\nlast_character = text[-1]\nstep_slice = text[::2]\n\nprint(start_slice, end_slice, last_character, step_slice)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>String Formatting</h3>
          <p>
            You can format strings using the <code>.format()</code> method or
            f-strings (formatted string literals):
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`name = 'Alice'
age = 30
formatted_string = 'My name is {} and I am {} years old'.format(name, age)
print(formatted_string)  # Output: 'My name is Alice and I am 30 years old'

# Using f-strings
name = 'Bob'
age = 25
formatted_string = f'My name is {name} and I am {age} years old'
print(formatted_string)  # Output: 'My name is Bob and I am 25 years old'`}
            </code>
          </pre>
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "name = 'Alice'\nage = 30\nformatted_string = 'My name is {} and I am {} years old'.format(name, age)\nprint(formatted_string)\n\n# Using f-strings\nname = 'Bob'\nage = 25\nformatted_string = f'My name is {name} and I am {age} years old'\nprint(formatted_string)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>String Methods</h3>
          <p>
            Python provides a wide range of string methods. Here are a few
            examples:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`text = '   Python is easy    '
# Removing leading and trailing whitespaces
cleaned_text = text.strip()
print(cleaned_text)  # Output: 'Python is easy'

# Converting to uppercase and lowercase
upper_text = text.upper()
lower_text = text.lower()
print(upper_text, lower_text)

# Checking if a string starts with or ends with a specific substring
startswith_python = text.startswith('Python')
ends_with_easy = text.endswith('easy')
print(startswith_python, ends_with_easy)

# Finding the index of a substring
index_of_is = text.find('is')
print(index_of_is)

# Replacing a substring
replaced_text = text.replace('easy', 'fun')
print(replaced_text)`}
            </code>
          </pre>
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "text = '   Python is easy    '\ncleaned_text = text.strip()\nprint(cleaned_text)\n\nupper_text = text.upper()\nlower_text = text.lower()\nprint(upper_text, lower_text)\n\nstartswith_python = text.startswith('Python')\nends_with_easy = text.endswith('easy')\nprint(startswith_python, ends_with_easy)\n\nindex_of_is = text.find('is')\nprint(index_of_is)\n\nreplaced_text = text.replace('easy', 'fun')\nprint(replaced_text)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>Escape Characters</h3>
          <p>
            Escape characters are used to represent special characters within
            strings. Here are some common escape characters:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`escaped_string = 'This is a single quote: /', and this is a new line:/n'
print(escaped_string)`}
            </code>
          </pre>
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "escaped_string = 'This is a single quote: /', and this is a new line:/n'\nprint(escaped_string)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>Looping Through Strings</h3>
          <p>You can loop through the characters of a string:</p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`text = 'Python'
for char in text:
    print(char)
# Output: 'P', 'y', 't', 'h', 'o', 'n'`}
            </code>
          </pre>
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "text = 'Python'\nfor char in text:\n    print(char)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>
        </div>
      </div>
    </div>
  );
};

export default PythonStrings;
