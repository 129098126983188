import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


const ScienceCard = ({ article }) => {
  const [showContent, setShowContent] = useState(false);

  const handleContentClick = () => {
    setShowContent(!showContent);
  };

  return (
    <Card className='shadow-lg mb-5 bg-body rounded newsCard '>
      <Card.Img variant="top" src={article.urlToImage}  />
      <Card.Body>
        <Card.Title>{article.title}</Card.Title>
        <Card.Text>{article.description}</Card.Text>
        <button onClick={handleContentClick} className='btn btn-dark'>
          {showContent ? 'Hide Content' : 'Read More'}
        </button>
        {showContent && (
          <div className='p-2'>
            <p>{article.content}</p>
            <Link to={article.url} className='text-decoration-none' target="_blank" rel="noreferrer">
              Read Full Article
            </Link>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};




ScienceCard.propTypes = {
  article: PropTypes.shape({
    urlToImage: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};

export default ScienceCard;
