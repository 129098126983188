
import React, { useState, useEffect } from "react";
// import { Button } from "react-bootstrap";
import Footer from "../Footer";
import NavBar from "../Navbar";

function WebCart() {
  const [paymentMade, setPaymentMade] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [web_email, setRegisteredEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [web_country, setWebCountry] = useState("");
  const [web_course, setWebCourse] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setPaymentMade(urlParams.get("payment") === "success");
  }, []);

  const makePayment = () => {
    window.location.href = "https://buy.stripe.com/cN217pbPFeK4dnaeV3";
  };

  const submitRequest = async (e) => {
    e.preventDefault();
    const response = await fetch(
      "https://esurde-backend.onrender.com/register/web/development",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          web_email,
          firstName,
          lastName,
          phoneNumber,
          web_country,
          web_course,
        }),
      }
    );
    const resData = await response.json();
    if (resData.status === "success") {
      alert("Your form Sent Sucessfully.");
    } else if (resData.status === "fail") {
      alert("Form failed to send.");
    }
    window.location.reload();
  };

  return (
    <div>
      <section>
        <NavBar />
        <br />
        <div className="container contact-container">
          <section className=" container">
            <p
              className="text-center w-responsive mx-auto mb-5 pt-5"
              style={{ color: "navy" }}
            >
              Please make payment before filling the form.
            </p>
            {paymentMade ? (
              <form
                onSubmit={submitRequest}
                className="row g-3 needs-validation"
                noValidate
              >
                {/* Rest of your form inputs here */}
                {/* Sample input: */}
                <div className="col-md-4 position-relative">
                  <label
                    htmlFor="validationTooltip01"
                    className="form-label"
                    style={{ color: "navy" }}
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="validationTooltip01"
                    placeholder="first name"
                    name="firstName"
                    required
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                  />
                  <div className="valid-tooltip">Looks good!</div>
                </div>

                 <div className="col-md-4 position-relative">
                <label
                  htmlFor="validationTooltip02"
                  className="form-label"
                  style={{ color: "navy" }}
                >
                  Last name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip03"
                  placeholder="last name"
                  name="lastName"
                  required
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                />
                <div className="valid-tooltip">Looks good!</div>
              </div>

              <div className="col-md-4 position-relative">
                <label
                  htmlFor="validationTooltip04"
                  className="form-label"
                  style={{ color: "navy" }}
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="validationTooltip03"
                  placeholder="example@gmail.com"
                  name="web_email"
                  required
                  onChange={(e) => setRegisteredEmail(e.target.value)}
                  value={web_email}
                />
                <div className="invalid-tooltip">please provide correct email</div>
              </div>

              <div className="col-md-4 position-relative">
                <label
                  htmlFor="validationTooltip05"
                  className="form-label"
                  style={{ color: "navy" }}
                >
                  Phone Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip07"
                  placeholder="+1 409-444-4444"
                  name="phoneNumber"
                  required
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  value={phoneNumber}
                />

                <div className="invalid-tooltip">
                  please provide correct phone number
                </div>
              </div>

              <div className="col-md-4 position-relative">
                <label
                  htmlFor="validationTooltip06"
                  className="form-label"
                  style={{ color: "navy" }}
                >
                  Country
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip07"
                  placeholder="country name"
                  name="web_country"
                  required
                  onChange={(e) => setWebCountry(e.target.value)}
                  value={web_country}
                />
                <div className="valid-tooltip">Looks good!</div>
              </div>

              <div className="col-md-4 position-relative">
                <label
                  htmlFor="validationTooltip08"
                  className="form-label"
                  style={{ color: "navy" }}
                >
                  Course
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="validationTooltip02"
                  placeholder=" enter web development..."
                  name="web_course"
                  required
                  onChange={(e) => setWebCourse(e.target.value)}
                  value={web_course}
                />
                <div className="valid-tooltip">Looks good!</div>
              </div>

                {/* End of sample input */}
                <div className="col-12 mb-5 me-3 d-flex justify-content-between">
                  <button
                    className="btn "
                    type="submit"
                    style={{ backgroundColor: "navy", color: "white" }}
                  >
                    Submit form
                  </button>
                </div>
              </form>
            ) : (
              <div className="col-12 mb-5 me-3 d-flex justify-content-between">
                <button
                  className="btn mb-4 text-center "
                  onClick={makePayment}
                  style={{ backgroundColor: "navy", color: "white" }}
                >
                  Pay Now
                </button>
              </div>
            )}
          </section>
        </div>
        <br /> <br /> <br />
        <Footer />
      </section>
    </div>
  );
}

export default WebCart;
