import React, { Component } from 'react';
import '../Python/python.css';
import ProgrammingTips from '../Python/topics/Tips';
import CodeNavigationBar from '../CodeNavigationBar';
import Footer from '../../components/Footer';
import IntroductionToCpp from './topics/IntroductionToCplus';
import CppSyntax from './topics/BasicSyntaxCpp';
import CppComments from './topics/CppComments';
import CppVariables from './topics/CppVariables';
import CppDataTypes from './topics/CppDataTypes';
import CppUserInput from './topics/CppUserInput';
import CppOperators from './topics/CppOperators';
import CppMath from './topics/CppMath';
import CppBooleans from './topics/CppBooleans';
import CppStrings from './topics/CppStrings';
import CppIfElse from './topics/CppIfElse';
import CppSwitchStatements from './topics/CppSwitch';
import CppForLoop from './topics/CppForLoop';
import CppWhileDoWhileLoop from './CppWhileLoop';
import CppArrays from './topics/CppArrays';
import CppReferences from './topics/CppReferences';
import CppPointers from './topics/CppPointers';
import CppFunctions from './topics/CppFunctions';
import CppStructures from './topics/CppStruct';
import CppClassesAndObjects from './topics/CppClasses';
import CppInheritance from './topics/CppInheritance';
import CppEncapsulation from './topics/CppEncapsulation';
import CppPolymorphism from './topics/CppPolymorphism';
import CppFileHandling from './topics/CppFileHandling';
import CppExceptions from './topics/CppExceptions';
import CppProjects from './topics/CppProjects';

class CplusPlusTutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTopic: null,
      isMobile: window.innerWidth < 768, // Define the breakpoint for mobile devices
      showMenu: true,
    };
  }
  scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  handleTopicClick = (topic) => {
    this.setState({ selectedTopic: topic, showMenu: false });
    this.scrollToTop();
  };

  handleMenuClick = () => {
    this.setState({ showMenu: true, selectedTopic: null });
  };

  render() {
    const topics = [
      "Introduction to C++",
      "C++ Syntax",
      "Comments",
      "Variables",
      "Data Types",
      "User Input",
      "Operators",
      "C++ Math",
      "C++ Booleans",
      "C++ Strings",
      "If and Else",
      "C++ Switch",
      "For Loop",
      "While Loop",
      "C++ Arrays",
      "C++ References",
      "C++ Pointers",
      "C++ Functions",
      "C++ Structures",
      "C++ Classes",
      "Encapsulation",
      "Inheritance",
      "Polymorphism",
      "C++ File Handling",
      "C++ Exceptions",
      "C++ Projects",
      
      
    ];

    return (
      <div>
        <CodeNavigationBar />

<div className="container py-5">
        <div className="row">
          {this.state.isMobile && (
            <div className="col-12">
              {this.state.showMenu ? (
                <button className="btn btn-primary btn-block mb-3" onClick={this.handleMenuClick}>
                  Show Menu
                </button>
              ) : (
                <button className="btn btn-primary btn-block mb-3" onClick={this.handleMenuClick}>
                  Back to Menu
                </button>
              )}
            </div>
          )}
          {this.state.isMobile ? (
            <div className="col-12">
              {this.state.showMenu && (
                <div className="navbar">
                  <h2>C++ Tutorial</h2>
                  <ul className="list-group">
                    {topics.map((topic, index) => (
                      <li
                        key={index}
                        onClick={() => this.handleTopicClick(topic)}
                        className={`list-group-item ${topic === this.state.selectedTopic ? "active" : ""}`}
                      >
                        {topic}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {!this.state.showMenu && (
                <div className="content">
                 {this.state.selectedTopic === "Introduction to C++" && <IntroductionToCpp />}
                 {this.state.selectedTopic === "C++ Syntax" && <CppSyntax />}
                 {this.state.selectedTopic === "Comments" && <CppComments />}
                 {this.state.selectedTopic === "Variables" && <CppVariables />}
                 {this.state.selectedTopic === "Data Types" && <CppDataTypes />}
                 {this.state.selectedTopic === "User Input" && <CppUserInput />}
                 {this.state.selectedTopic === "Operators" && <CppOperators />}
                 {this.state.selectedTopic === "C++ Math" && <CppMath />}
                 {this.state.selectedTopic === "C++ Booleans" && <CppBooleans />}
                 {this.state.selectedTopic === "C++ Strings" && <CppStrings />}
                 {this.state.selectedTopic === "If and Else" && <CppIfElse />}
                 {this.state.selectedTopic === "C++ Switch" && <CppSwitchStatements />}
                 {this.state.selectedTopic === "For Loop" && <CppForLoop />}
                 {this.state.selectedTopic === "While Loop" && <CppWhileDoWhileLoop />}
                 {this.state.selectedTopic === "C++ Arrays" && <CppArrays />}
                 {this.state.selectedTopic === "C++ References" && <CppReferences />}
                 {this.state.selectedTopic === "C++ Pointers" && <CppPointers />}
                 {this.state.selectedTopic === "C++ Functions" && <CppFunctions />}
                 {this.state.selectedTopic === "C++ Structures" && <CppStructures />}
                 {this.state.selectedTopic === "C++ Classes" && <CppClassesAndObjects />}
                 {this.state.selectedTopic === "Inheritance" && <CppInheritance />}
                 {this.state.selectedTopic === "Encapsulation" && <CppEncapsulation />}
                 {this.state.selectedTopic === "Polymorphism" && <CppPolymorphism />}
                 {this.state.selectedTopic === "C++ File Handling" && <CppFileHandling />}
                 {this.state.selectedTopic === "C++ Exceptions" && <CppExceptions />}
                 {this.state.selectedTopic === "C++ Projects" && <CppProjects />}
                 
                 
                  <button className="btn btn-primary btn-block mt-3" onClick={this.handleMenuClick}>
                    Back to Menu
                  </button>
                </div>
              )}
            </div>
          ) : (
            <React.Fragment>
              <div className="col-md-3">
                <div className="navbar">
                  <h2>C++ Tutorial</h2>
                  <ul className="list-group">
                    {topics.map((topic, index) => (
                      <li
                        key={index}
                        onClick={() => this.handleTopicClick(topic)}
                        className={`list-group-item ${topic === this.state.selectedTopic ? "active" : ""}`}
                      >
                        {topic}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="content">
                  {this.state.selectedTopic ? (
                    <div>
                      {this.state.selectedTopic === "Introduction to C++" && <IntroductionToCpp />}
                      {this.state.selectedTopic === "C++ Syntax" && <CppSyntax />}
                      {this.state.selectedTopic === "Comments" && <CppComments />}
                      {this.state.selectedTopic === "Variables" && <CppVariables />}
                      {this.state.selectedTopic === "Data Types" && <CppDataTypes />}
                      {this.state.selectedTopic === "User Input" && <CppUserInput />}
                      {this.state.selectedTopic === "Operators" && <CppOperators />}
                      {this.state.selectedTopic === "C++ Math" && <CppMath />}
                      {this.state.selectedTopic === "C++ Booleans" && <CppBooleans />}
                      {this.state.selectedTopic === "C++ Strings" && <CppStrings />}
                      {this.state.selectedTopic === "If and Else" && <CppIfElse />}
                      {this.state.selectedTopic === "C++ Switch" && <CppSwitchStatements />}
                      {this.state.selectedTopic === "For Loop" && <CppForLoop />}
                      {this.state.selectedTopic === "While Loop" && <CppWhileDoWhileLoop />}
                      {this.state.selectedTopic === "C++ Arrays" && <CppArrays />}
                      {this.state.selectedTopic === "C++ References" && <CppReferences />}
                      {this.state.selectedTopic === "C++ Pointers" && <CppPointers />}
                      {this.state.selectedTopic === "C++ Functions" && <CppFunctions />}
                      {this.state.selectedTopic === "C++ Structures" && <CppStructures />}
                      {this.state.selectedTopic === "C++ Classes" && <CppClassesAndObjects />}
                      {this.state.selectedTopic === "Inheritance" && <CppInheritance />}
                      {this.state.selectedTopic === "Encapsulation" && <CppEncapsulation />}
                      {this.state.selectedTopic === "Polymorphism" && <CppPolymorphism />}
                      {this.state.selectedTopic === "C++ File Handling" && <CppFileHandling />}
                      {this.state.selectedTopic === "C++ Exceptions" && <CppExceptions />}
                      {this.state.selectedTopic === "C++ Projects" && <CppProjects />}

                    
                    </div>
                  ) : (
                    <ProgrammingTips  />
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
                    <Footer />

      </div>
    );
  }
}

export default CplusPlusTutorial;
