import React,{useState,useEffect} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./components/Homepage";
import Programs from "./components/Programs";
import About from "./components/About";
import Contact from "./components/Contact";
import Physics from "./pages/Physics";
import "./App.css";
import './Forum/DiscussionForumStyle.css';
import OnlineTutors from "./components/OnlineTutors";
import WebCart from "./components/Carts/WebCart";
import GraphicDesignCart from "./components/Carts/GraphicDesignCart";
import AppDevCart from "./components/Carts/AppDevCart";
import DataScienceCart from "./components/Carts/DataScienceCart";
import AIcart from "./components/Carts/AIcart";
import PythonCart from "./components/Carts/PythonCart";
import CplusLanguageCart from "./components/Carts/CplusLanguageCart";
import JavaCart from "./components/Carts/JavaCart";
import MachineLearningCart from "./components/Carts/MachineLearningCart";
import CourseQuestions from "./components/CourseQuestions.js";
import Privacy from "./components/policies/Privacy";
import PhysicsTopicsPage from "./components/Questions/physics/PhysicsTopicsPage";
import PhysicsAndMeasurement from "./components/Questions/physics/PhysicsAndMeasurment";
import Chemistry from "./pages/chemistry";
import Mathematics from "./pages/maths";
import Biology from "./pages/Biology";
import ComputerScience from "./pages/ComputerScience";
import ComputerEngineering from "./pages/ComputerEngineering";
import Statistics from "./pages/Statistics";
import ElectricalEngineering from "./pages/ElectricalEngineering";
import ActuarialScience from "./pages/ActuarialScience";
import Biochemistry from "./pages/Biochemistry";
import Optometry from "./pages/Optometry";
// import Economics from "./pages/Economics";

import TermOfUse from "./components/policies/TermOfUse";
import Copyright from "./components/policies/Copyright";
import MotionOneDimension from "./components/Questions/physics/MotionOneDimension";
import Vectors from "./components/Questions/physics/Vectors";
import MotionTwoDimension from "./components/Questions/physics/MotionTwoDimension";
import LawOfMotion from "./components/Questions/physics/LawOfMotion";
import CircularMotion from "./components/Questions/physics/CircularMotion";
import WorkAndKineticEnergy from "./components/Questions/physics/WorkAndKineticEnergy";
import LinearMomentum from "./components/Questions/physics/LinearMomentum";
import RotationalMotion from "./components/Questions/physics/RotationalMotion";
import Elasticity from "./components/Questions/physics/Elasticity";
import FluidMechanics from "./components/Questions/physics/FluidMechanics";
import OscillationAndWaves from "./components/Questions/physics/OscillatonAndWaves";
import SoundWaves from "./components/Questions/physics/SoundWaves";
import TemperatureAndKineticTheory from "./components/Questions/physics/TemperatureAndKineticTheory";
import Heat from "./components/Questions/physics/Heat";
import LawsOfThermodynamics from "./components/Questions/physics/LawsOfThermodynamics";
import ElectricFields from "./components/Questions/physics/ElectricFields";
import ElectricPotential from "./components/Questions/physics/ElectricalPotential";
import ElectricCurrents from "./components/Questions/physics/ElectricCurrents";
import DcCircuits from "./components/Questions/physics/DcCircuits";
import Magnetism from "./components/Questions/physics/Magnetsim";
import Induction from "./components/Questions/physics/Induction";
import ElectromagneticWaves from "./components/Questions/physics/ElectromagneticWaves";
import GeometricOptics from "./components/Questions/physics/GeometricOptics";
import WaveNatureOfLight from "./components/Questions/physics/WaveNatureOfLight";
import OpticalInstruments from "./components/Questions/physics/OpticalInstruments";
import ComputerScienceTopics from "./components/Questions/computer_science/ComputerScienceTopics";
import NetworkModels from "./components/Questions/computer_science/NetworkModels";
import NetworkingBasics from "./components/Questions/computer_science/NetworkingBasics";
import PhysicalLayer from "./components/Questions/computer_science/PhysicalLayer";
import ApplicationLayer from "./components/Questions/computer_science/ApplicationLayer";
import DataLinkLayer from "./components/Questions/computer_science/DataLinkLayer";
import TransmissionMedia from "./components/Questions/computer_science/TransmissionMedia";
import NetworkLayer from "./components/Questions/computer_science/NetworkLayer";
import Multiplexing from "./components/Questions/computer_science/Multiplexing";
import TransportLayer from "./components/Questions/computer_science/TransportLayer";
import ClientServerModel from "./components/Questions/computer_science/ClientServerModel";
import NetworkSecurity from "./components/Questions/computer_science/NetworkSecurity";
import NetworkAttacks from "./components/Questions/computer_science/NetworkAttacks";
import ChemistryTopicsPage from "./components/Questions/chemistry/ChemistryTopicsPage";
import Introduction from "./components/Questions/chemistry/Introduction";
import AtomsMoleculesIons from "./components/Questions/chemistry/AtomsMoleculesIons";
import ChemicalReactions from "./components/Questions/chemistry/ChemicalReactions";
import Article from "./News/Article";
import NewsHome from "./News/NewsHome";
import LatestFullPage from "./News/LatestFullPage";
import ScienceFullPage from "./News/ScienceFullPage";
import TechFullPage from "./News/TechFullPage";
import HealthFullPage from "./News/HealthFullPage";
import ThermochemistryCards from "./components/Questions/chemistry/ThermochemistryCards";
import ElectronicStructureCards from "./components/Questions/chemistry/ElectronicStructureCards";
import ChemicalBondingCards from "./components/Questions/chemistry/ChemicalBondingCards";
import GasesCards from "./components/Questions/chemistry/GasesCards";
import LiquidAndSolidCards from "./components/Questions/chemistry/LiquidAndSolidCards";
import GTMContainer from "./GTMContainer";
import SolutionsAndColloids from "./components/Questions/chemistry/SolutionAndColloids";
import Kinetics from "./components/Questions/chemistry/Kinetics";
import FundamentalEquilibriumCards from "./components/Questions/chemistry/FundamentalEquilibrium";
import AcidBase from "./components/Questions/chemistry/AcidBaseCards";
import Thermodynamics from "./components/Questions/chemistry/Thermodynamics";
import Electrochemistry from "./components/Questions/chemistry/Electrochemistry";
import MetalsAndNonMetals from "./components/Questions/chemistry/MetalsAndNonMetals";
import OrganicChemistry from "./components/Questions/chemistry/OrganicChemistry";
import NuclearChemistry from "./components/Questions/chemistry/NuclearChemistry";
import MathsTopicsPage from "./components/Questions/maths/MathsTopicsPage";
import VectorSpace from "./components/Questions/maths/VectorSpace";
import MetricesAndSystems from "./components/Questions/maths/MetricesAndSytems";
import EigenvaluesAndVectors from "./components/Questions/maths/EigenvaluesAndVectors";
import DifferentialEquations from "./components/Questions/maths/DifferentialEquations";
import SecondAndHigher from "./components/Questions/maths/SecondAndHigher";
import LaplaceTransform from "./components/Questions/maths/LaplaceTransform";
import DifficultDEQuestions from "./components/Questions/maths/NewDiffEquation";
import DiscussionForum from "./Forum/DiscussionForum";
import SignIn from "./Forum/SignIn";
import { auth } from './Forum/Firebase';
import BiologyTopicsPage from "./components/Questions/biology/BiologyTopicsPage";
import AcidBaseBuffer from "./components/Questions/biology/AcidBaseBuffer";
import Bioenergetics from "./components/Questions/biology/Bioenergetics";
import Carbohydrates from "./components/Questions/biology/Carbohydrates";
import CellCards from "./components/Questions/biology/CellCards";
import EnergyRelease from "./components/Questions/biology/EnergyRelease";
import GCG from "./components/Questions/biology/GCG";
import Lipids from "./components/Questions/biology/Lipids";
import Metabolism from "./components/Questions/biology/Metabolism";
import MicrobialGrwoth from "./components/Questions/biology/MicrobialGrowth";
import MicrobialNutrition from "./components/Questions/biology/MicrobialNutrition";
import NucleiAcids from "./components/Questions/biology/NucleiAcids";
import Photosynthesis from "./components/Questions/biology/Photosynthesis";
import Proteins from "./components/Questions/biology/Proteins";
import QuantumMechanics from "./components/Questions/physics/QuantumMechanics";
import SpecialRelativity from "./components/Questions/physics/SpecialRelativity";
import ElectricalTopicsPage from "./components/Questions/electrical_engineering/ElectricalTopicsPage";
import MagneticCircuits from "./components/Questions/electrical_engineering/MagneticCircuits";
import RotatingMachines from "./components/Questions/electrical_engineering/RotatingMachines";
import MachineApplications from "./components/Questions/electrical_engineering/MachineApplications";
import ElectroMechanical from "./components/Questions/electrical_engineering/ElectroMechanical";
import DCMachines from "./components/Questions/electrical_engineering/DCMachines";
import DCGenerators from "./components/Questions/electrical_engineering/DCGenerators";
import DCMotors from "./components/Questions/electrical_engineering/DCMotors";
import CodeEditor from "./components/CodeEditor";
import PythonTutorial from "./Programming/Python/PythonTutorial";
import JavaScriptTutorial from "./Programming/JavaScript/JavascriptTutorial";
import CplusPlusTutorial from "./Programming/c++/CplusPlusTutorial";
import DataScienceTutorial from "./Programming/Data Science/DataScienceTutorial";
// import Landing from "./CodeIDE/components/Landing";
// import Home from "./CodeEditor/pages/Home";
// import Login from "./CodeEditor/pages/Login";

// import MyEditor from "./CodeIDE/MyEditor";
// import Editor from "./CodeIDE/components/Editor";




export default function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);



  return (
    <div>
        <GTMContainer containerId= "GTM-MJ6VNC2"/>
    <BrowserRouter >
    <Routes>
        <Route path="/" element={<Homepage />}>
          {" "}
        </Route>
        <Route path="courses" element={<Programs />}></Route>
        <Route path="about" element={<About />}></Route>
        <Route path="discussion-forum" element={user ? <DiscussionForum user={user} /> : <SignIn />}></Route> 
        
        <Route path="questions" element={<CourseQuestions />}></Route>
        <Route path="online_tutor" element={<OnlineTutors />}></Route>
        <Route path="web-development" element={<WebCart />}></Route>
        <Route path="graphic/designing" element={<GraphicDesignCart />}></Route>
        <Route path="app/development" element={<AppDevCart />}></Route>
        <Route path="data/science" element={<DataScienceCart />}></Route>
        <Route
          path="machine/learning"
          element={<MachineLearningCart />}
        ></Route>
        <Route path="artificial/intelligence" element={<AIcart />}></Route>
        <Route path="python" element={<PythonCart />}></Route>
        <Route path="c++" element={<CplusLanguageCart />}></Route>
        <Route path="java" element={<JavaCart />}></Route>
        <Route path="Contact-us" element={<Contact />}></Route>
        <Route path="physics" element={<Physics />}></Route>
        <Route path="chemistry" element={<Chemistry />}></Route>
        <Route path="maths" element={<Mathematics />}></Route>
        <Route path="statistics" element={<Statistics />}></Route>
        <Route path="actuarial-science" element={<ActuarialScience />}></Route>
        <Route path="biology" element={<Biology />}></Route>
        <Route path="optometry" element={<Optometry />}></Route>
        <Route path="computer-science" element={<ComputerScience />}></Route>
        <Route path="electrical-engineering"  element={<ElectricalEngineering />}
        ></Route>
        <Route path="biochemistry" element={<Biochemistry />}></Route>
        <Route path="computer-engineering" element={<ComputerEngineering />}
        ></Route>
        {/*
          <Route path="economics" element={<Economics />}></Route> */}
        <Route path="privacy-policy" element={<Privacy />}></Route>
        <Route path="term-of-use" element={<TermOfUse />}></Route>
        <Route path="disclaimer" element={<Copyright />}></Route>
        <Route path="physics/questions" element={<PhysicsTopicsPage />}></Route>
        <Route path="chemistry/questions" element={<ChemistryTopicsPage />}></Route>

        <Route path="biology/questions" element={<BiologyTopicsPage />}></Route>
        <Route path="maths/questions" element={<MathsTopicsPage />}></Route>
        <Route path="computer-science/questions" element={<ComputerScienceTopics />}></Route>
        <Route path="physics/measurement" element={<PhysicsAndMeasurement />} ></Route>
        <Route path="motion-in-one-dimension" element={<MotionOneDimension />} ></Route>
        <Route path="physics/vectors" element={<Vectors />} ></Route>
        <Route path="physics/law-of-motion" element={<LawOfMotion />} ></Route>
        <Route path="physics/circular-motion" element={<CircularMotion />} ></Route>
        <Route path="physics/work-and-kinetic-energy" element={<WorkAndKineticEnergy />} ></Route>
        <Route path="physics/motion-two-dimensions" element={<MotionTwoDimension />} ></Route>
        <Route path="physics/linear-momentum" element={<LinearMomentum />} ></Route>
        <Route path="physics/rotational-motion" element={<RotationalMotion />} ></Route>
        <Route path="physics/static-equilibrium/elasticity" element={<Elasticity />} ></Route>
        <Route path="physics/fluid-mechanics" element={<FluidMechanics />} ></Route>
        <Route path="physics/oscillations-and-waves" element={<OscillationAndWaves />} ></Route>
        <Route path="physics/sound-waves" element={<SoundWaves />} ></Route>
        <Route path="physics/heat" element={<Heat />} ></Route>
        <Route path="physics/laws-of-thermodynamics" element={<LawsOfThermodynamics />} ></Route>
        <Route path="physics/electric-charge-and-electric-field" element={<ElectricFields />} ></Route>
        <Route path="physics/electric-potential" element={<ElectricPotential />} ></Route>
        <Route path="physics/electric-currents" element={<ElectricCurrents />} ></Route>
        <Route path="physics/dc-circuits" element={<DcCircuits />} ></Route>
        <Route path="physics/magnetism" element={<Magnetism />} ></Route>
        <Route path="physics/electromagnetic/induction-and-faradays-law" element={<Induction />} ></Route>
        <Route path="physics/temperature-and-kinetic-theory" element={<TemperatureAndKineticTheory />} ></Route>
        <Route path="physics/electromagnetic-waves" element={<ElectromagneticWaves />} ></Route>
        <Route path="physics/geometric-optics" element={<GeometricOptics />} ></Route>
        <Route path="physics/wave-nature-of-light" element={<WaveNatureOfLight />} ></Route>
        <Route path="physics/optical-instruments" element={<OpticalInstruments />} ></Route>
        <Route path="physics/quantum-mechanics" element={<QuantumMechanics />} ></Route>
        <Route path="physics/special-relativity" element={<SpecialRelativity />} ></Route>


        <Route path="computer-science/network-models" element={<NetworkModels />} ></Route>
        <Route path="computer-science/networking-basics" element={<NetworkingBasics />} ></Route>
        <Route path="computer-science/physical-layer" element={<PhysicalLayer />} ></Route>
        <Route path="computer-science/application-layer" element={<ApplicationLayer />} ></Route>
        <Route path="computer-science/data-link-layer" element={<DataLinkLayer />} ></Route>
        <Route path="computer-science/transmission-media" element={<TransmissionMedia />} ></Route>
        <Route path="computer-science/network-layer" element={<NetworkLayer />} ></Route>
        <Route path="computer-science/multiplexing" element={<Multiplexing />} ></Route>
        <Route path="computer-science/transport-layer" element={<TransportLayer />} ></Route>
        <Route path="computer-science/client-server-model" element={<ClientServerModel />} ></Route>
        <Route path="computer-science/network-security" element={<NetworkSecurity />} ></Route>
        <Route path="computer-science/network-attacks" element={<NetworkAttacks />} ></Route>
        <Route path="chemistry/matter-energy-and-measurement" element={<Introduction />} ></Route>
        <Route path="chemistry/atoms-molecules-ions" element={<AtomsMoleculesIons />} ></Route>
        <Route path="chemistry/chemical-reactions-and-reactions-stoichiometry" element={<ChemicalReactions />} ></Route>
        <Route path="chemistry/thermochemistry" element={<ThermochemistryCards />} ></Route>
        <Route path="chemistry/electronic-structure-and-periodic-properties-of-elements" element={<ElectronicStructureCards />} ></Route>
        <Route path="chemistry/chemical-bonding-and-molecular-geometry" element={<ChemicalBondingCards />} ></Route>
        <Route path="chemistry/gases" element={<GasesCards />} ></Route>
        <Route path="chemistry/liquids-and-solids" element={<LiquidAndSolidCards />} ></Route>
        <Route path="chemistry/solutions-and-colloids" element={<SolutionsAndColloids />} ></Route>
        <Route path="chemistry/kinetics" element={<Kinetics />} ></Route>
        <Route path="chemistry/fundamental-and-equilibrium-concepts" element={<FundamentalEquilibriumCards />} ></Route>
        <Route path="chemistry/acid-base-equilibria" element={<AcidBase />} ></Route>
        <Route path="chemistry/electrochemistry" element={<Electrochemistry />} ></Route>
        <Route path="chemistry/thermodynamics" element={<Thermodynamics />} ></Route>
        <Route path="chemistry/metals-metalloid-and-non-metals" element={<MetalsAndNonMetals />} ></Route>
        <Route path="chemistry/organic-chemistry" element={<OrganicChemistry />} ></Route>
        <Route path="chemistry/nuclear-chemistry" element={<NuclearChemistry />} ></Route>
        <Route path="maths/vector-and-vector-spaces" element={<VectorSpace />} ></Route>
        <Route path="maths/metrices-and-systems-of-linear-equations" element={<MetricesAndSystems />} ></Route>
        <Route path="maths/eigenvalues-eigenvectors-and-diagonalization" element={<EigenvaluesAndVectors />} ></Route>
        <Route path="maths/first-order-differential-equations" element={<DifferentialEquations />} ></Route>
        <Route path="maths/second-and-higher-order-linear-differential-equations-and-systems" element={<SecondAndHigher />} ></Route>
        <Route path="maths/the-laplace-transform" element={<LaplaceTransform />} ></Route>
        <Route path="maths/differential-equations" element={<DifficultDEQuestions />} ></Route>
        <Route path="biology/acids-bases-and-buffers" element={<AcidBaseBuffer />} ></Route>
        <Route path="biology/bioenergetics" element={<Bioenergetics />} ></Route>
        <Route path="biology/carbohydrates" element={<Carbohydrates />} ></Route>
        <Route path="biology/cell" element={<CellCards />} ></Route>
        <Route path="biology/energy-release-and-conservation" element={<EnergyRelease />} ></Route>
        <Route path="biology/genes-chromosomes-and-genomes" element={<GCG />} ></Route>
        <Route path="biology/lipids" element={<Lipids />} ></Route>
        <Route path="biology/metabolisn" element={<Metabolism />} ></Route>
        <Route path="biology/microbial-nutrition" element={<MicrobialNutrition />} ></Route>
        <Route path="biology/microbial-growth" element={<MicrobialGrwoth />} ></Route>
        <Route path="biology/nuclei-acids" element={<NucleiAcids />} ></Route>
        <Route path="biology/photosynthesis" element={<Photosynthesis />} ></Route>
        <Route path="biology/proteins" element={<Proteins />} ></Route>
        <Route path="electrical-engineering/questions" element={<ElectricalTopicsPage />} ></Route>
        <Route path="electrical-engineering/magnetic-circuits" element={<MagneticCircuits />} ></Route>
        <Route path="electrical-engineering/rotating-machines" element={<RotatingMachines />} ></Route>
        <Route path="electrical-engineering/electrical-machine-applications" element={<MachineApplications />} ></Route>
        <Route path="electrical-engineering/electro-mechanical-energy-conservations" element={<ElectroMechanical />} ></Route>
        <Route path="electrical-engineering/dc-machines" element={<DCMachines />} ></Route>
        <Route path="electrical-engineering/dc-generators" element={<DCGenerators />} ></Route>
        <Route path="electrical-engineering/dc-motors" element={<DCMotors />} ></Route>
      

        {/* <Route path='/editor-login' element={<Login/>}/>
        <Route path='/:id' element={<Home/>}/> */}


          <Route path="esurde-code-editor" element= {<CodeEditor />}></Route>
          <Route path="language/python" element= {<PythonTutorial />}></Route>
          <Route path="language/javascript" element= {<JavaScriptTutorial />}></Route>
          <Route path="language/cplusplus" element= {<CplusPlusTutorial />}></Route>
          <Route path="language/data-science" element= {<DataScienceTutorial />}></Route>


          <Route path="news/latest" element= {<LatestFullPage />}></Route>
          <Route path="news/science" element= {<ScienceFullPage />}></Route>
          <Route path="news/technology" element= {<TechFullPage />}></Route>
          <Route path="news/health" element= {<HealthFullPage />}></Route>
          <Route path="news" element= {<NewsHome />}></Route>
          <Route path="article/:title" element= {<Article />}></Route>
        



      </Routes>
    </BrowserRouter>

    </div>
  );
}
