import React from "react";
import Footer from "../components/Footer";
import PagesNavBar from "../components/PagesNavBar";
import ScrollIntoView from "../components/ScrollIntoView";
import biochemistry from '../pdf/Biochemistry.pdf'
import physiology from '../pdf/essentials-of-medical-physiology-6th-edition.pdf'
import anatomy from '../pdf/Essentials of Anatomy and Physiology.pdf'
import MedicalBiochemistry from '../pdf/Medical Biochemistry, 4th edition (Medial Biochemistry).pdf'
import biochemgenetics from '../pdf/BIOCHEMISTRY, GENETICS _ MOLECULAR BIOLOGY.pdf'
import cellBiology from '../pdf/molecular_cell_biology_lodish_5th_ed.pdf'
import Biologytech from '../pdf/Cell,Molecular-Biology-and-Biotechnology.pdf'
import karpsCell from '../pdf/Karp’s Cell and Molecular Biology.pdf'
import enzymes from '../pdf/Enzymes_ Biochemistry, Biotechnology, Clinical Chemistry.pdf'
import Lehninger from '../pdf/Lehninger Principles of Biochemistry.pdf'
import IntroBiochem from '../pdf/Introduction to Practical Biochemistry.pdf'



class Biochemistry extends React.Component {
  openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };
  closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  render() {
    return (
      <ScrollIntoView>
        <div>
          <section>
            <div id="mySidenav" className="sidenav">
              <a
                href
                className="closebtn"
                style={{ cursor: "pointer" }}
                onClick={this.closeNav}
              >
                &times;
              </a>
             
              <a href={biochemistry} target="_blank" rel="noopener noreferrer">
                Biochemistry
              </a>
              
              <a href={physiology} target="_blank" rel="noopener noreferrer">
                Essential of Medical Physiology
              </a>
              
              <a href={anatomy} target="_blank" rel="noopener noreferrer">
                Essential of Anatomy and  Physiology
              </a>
              <a href={MedicalBiochemistry} target="_blank" rel="noopener noreferrer">
              Medical Biochemistry
              </a>
              <a href={biochemgenetics} target="_blank" rel="noopener noreferrer">
              Biochemistry, Genetics, and Molecular Biology
              </a>
              <a href={cellBiology} target="_blank" rel="noopener noreferrer">
               Molecular Cell Biology
              </a>

              <a href={Biologytech} target="_blank" rel="noopener noreferrer">
               Cell, Molecular Biology and Biotechnology
              </a>
              <a href={karpsCell} target="_blank" rel="noopener noreferrer">
               Karp&apos;s Cell and Molecular Biology
              </a>
              
              <a href={enzymes} target="_blank" rel="noopener noreferrer">
               Enzymes: Biochemistry, Biotechnology, Clinical Chemistry
              </a>

              <a href={Lehninger} target="_blank" rel="noopener noreferrer">
              Lehninger Principles of Biochemistry
              </a>

              <a href={IntroBiochem} target="_blank" rel="noopener noreferrer">
              Introduction to Practical Biochemistry
              </a>
              




            </div>
          </section>
          {/* top navbar */}
          <nav className="navbar navbar-expand-lg navbar-dark  navBarTop ">
            <div className="container-fluid opacity-75">
              <span
                style={{ fontSize: "20px", cursor: "pointer", color: "white" }}
                onClick={this.openNav}
              >
                &#9776; Topics
              </span>
            </div>
          </nav>

          <PagesNavBar />

          <section>
            {/* short words */}
            <section className="container-fluid">
              <hr />
              <div>
                <h5 style={{ color: "red", fontWeight: "15px" }}>
                  What is Biochemistry
                </h5>
                <p>
                  The study of chemical processes that occur within and relate
                  to living organisms is known as biochemistry or biological
                  chemistry. The fields of structural biology, enzymology, and
                  metabolism can be considered subfields of biochemistry, a
                  branch of both chemistry and biology.
                </p>
              </div>
            </section>

            <section className="container-fluid">
              <div>
                <h5 style={{ color: "red", fontWeight: "15px" }}>
                  How to study for Biochemistry
                </h5>
                <ol>
                  <li style={{ fontWeight: "bold" }}>
                    Learn the structures from the very beginning.
                  </li>
                  <p>
                    When you come across a new pathway, molecule, or reaction,
                    write it down and begin to memorize it right away. This
                    holds true for all cofactors and enzymes, amino acids,
                    glycolysis, lipid metabolism, the pentose phosphate pathway,
                    and nucleotides.
                  </p>
                  <li style={{ fontWeight: "bold" }}>
                    Review fundamental and organic chemistry.
                  </li>
                  <p>
                    Just to refresh your memory and give you a quick rundown. It
                    will make it easier to comprehend the more difficult
                    subjects! Don&apos;t be concerned about going too far.
                  </p>
                  <li style={{ fontWeight: "bold" }}>
                    look into your professor
                  </li>
                  <p>
                    Analyze their exam questions, their lecture or class notes,
                    or whether the class is worthwhile (some biochemistry
                    lecturers can be incredibly boring!)
                  </p>
                  <li style={{ fontWeight: "bold" }}>Extend things</li>
                  <p>
                    The best way to build metabolic pathways from scratch is in
                    this manner. Consider how molecules react and change in your
                    mind. Ensure that you comprehend each stage of the
                    procedure.
                  </p>
                  <li style={{ fontWeight: "bold" }}>
                    Read the prescribed texts
                  </li>
                  <p>
                    if it comes from a reliable source (or one that will likely
                    be put to the test). After class, review your lecture notes.
                  </p>
                </ol>
              </div>
            </section>
          </section>
          <br />
          <br />
          <Footer />
        </div>
      </ScrollIntoView>
    );
  }
}

export default Biochemistry;
