import React from 'react';

const JavaScriptConversions = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Type Conversions</h2>
      <p>JavaScript provides various methods for converting values from one data type to another. Here are some common type conversions:</p>

      <h3>String to Number Conversion</h3>
      <p>Use the <code>parseInt</code> and <code>parseFloat</code> functions to convert strings to numbers:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const strNum = '42';
const intNum = parseInt(strNum);
const floatNum = parseFloat('3.14');
console.log('Integer:', intNum);
console.log('Float:', floatNum);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const strNum = '42';\nconst intNum = parseInt(strNum);\nconst floatNum = parseFloat('3.14');\nconsole.log('Integer:', intNum);\nconsole.log('Float:', floatNum);"), '_blank')}>Run Code</button>

      <h3>Number to String Conversion</h3>
      <p>Use the <code>toString</code> method to convert numbers to strings:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const number = 42;
const strNum = number.toString();
console.log('String Number:', strNum);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const number = 42;\nconst strNum = number.toString();\nconsole.log('String Number:', strNum);"), '_blank')}>Run Code</button>

      <h3>String to Boolean Conversion</h3>
      <p>Use the <code>Boolean</code> function to convert strings to booleans:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const strTrue = 'true';
const boolTrue = Boolean(strTrue);
console.log('Boolean:', boolTrue);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const strTrue = 'true';\nconst boolTrue = Boolean(strTrue);\nconsole.log('Boolean:', boolTrue);"), '_blank')}>Run Code</button>

      <h3>Boolean to String Conversion</h3>
      <p>Use concatenation or template literals to convert booleans to strings:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const boolValue = true;
const strBool = 'Value is ' + boolValue;
console.log('String Bool:', strBool);
const templateStr = \`Value is \${boolValue}\`;
console.log('Template String:', templateStr);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl )}>Run Code</button>

      <h3>Array to String Conversion</h3>
      <p>Use the <code>join</code> method to convert arrays to strings:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
const strFruits = fruits.join(', ');
console.log('String Fruits:', strFruits);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nconst strFruits = fruits.join(', ');\nconsole.log('String Fruits:', strFruits);"), '_blank')}>Run Code</button>

      <h3>String to Array Conversion</h3>
      <p>Use the <code>split</code> method to convert strings to arrays:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const csvData = 'apple,banana,cherry';
const fruitsArray = csvData.split(',');
console.log('Fruits Array:', fruitsArray);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const csvData = 'apple,banana,cherry';\nconst fruitsArray = csvData.split(',');\nconsole.log('Fruits Array:', fruitsArray);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptConversions;
