import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer";
import NavBar from "../../Navbar";

function ElectricalTopicsPage() {
  const Topics = [
    {
      id: 1,
      topic: "MAGNETIC CIRCUITS",
      link: "/electrical-engineering/magnetic-circuits",
      
    },
    {
      id: 2,
      topic: "ROTATING MACHINES",
      link: "/electrical-engineering/rotating-machines",
    },
    {
      id: 3,
      topic: "ELECTRICAL MACHINE APPLICATIONS",
      link: "/electrical-engineering/electrical-machine-applications",
    },
    {
      id: 4,
      topic: "ELECTRO MECHANICAL ENERGY CONVERSIONS",
      link: "/electrical-engineering/electro-mechanical-energy-conservations",
    },
    {
      id: 5,
      topic: "DC MACHINES",
      link: "/electrical-engineering/dc-machines",
    },
    {
      id: 6,
      topic: "DC GENERATORS",
      link: "/electrical-engineering/dc-generators",
    },
    {
      id: 7,
      topic: "DC MOTORS",
      link: "/electrical-engineering/dc-motors",
    }
    
  ];

  return (
    <div>
      <NavBar />
      <div
        className="container mt-5 mb-5 p-3"
      
      >
        <h6 className="topics-border-btn">Topics</h6>
        {Topics.map((topic) => (
          <div key={topic.id}>
            <Link to={topic.link} style={{textDecoration:"none"}}>
              <p className = "p-1   topic-hover" > {topic.topic}</p>
            </Link>
           
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default ElectricalTopicsPage;
