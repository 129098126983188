import React from 'react';
import PropTypes from 'prop-types';

const Glucotrust = ({ productLink, imageUrl, altText }) => {
  return (
    <div className="container-fluid">
      <a href={productLink} target="_blank" rel="noopener noreferrer">
        <img src={imageUrl} alt={altText} />
      </a>
    </div>
  );
};

Glucotrust.propTypes = {
  productLink: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  
};

export default Glucotrust;
