import React from "react";
import ProgramsBg from "./ProgramsBg";
import Footer from "./Footer";
import Courses from "./Courses";
import NavBar from "./Navbar";
import { CardGroup, Card } from "react-bootstrap";
import WritingJobs from "../ads/WritingJobs";
import ForexTrading from "../ads/ForexTrading";
import LiveChat from "../ads/LiveChat";

function Programs() {
  return (
    <div>
      <NavBar />
      <ProgramsBg />
      <CardGroup className="container">
        <Card>
          <Card.Body>
            <WritingJobs
              productLink="https://hop.clickbank.net/?affiliate=okyerejr&vendor=easywriter&pid=instantapply&ifso="
              imageUrl="https://affiliatesstuff.s3.amazonaws.com/POWJ/POWJ%20-%20FB%20Ad%20Images/POWJ%20FB%20Ad%20image%202.png"
              altText="paid online writing jobs"
              title=""
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <ForexTrading
              productLink="https://hop.clickbank.net/?affiliate=okyerejr&vendor=1000PB"
              imageUrl="https://www.1000pipbuilder.com/wp-content/plugins/new-modules/images/1000PB-v01-300x250.jpg"
              altText="forex trading"
              title=""
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <LiveChat
              productLink="https://hop.clickbank.net/?affiliate=okyerejr&vendor=socialsrep"
              imageUrl="https://affiliatesstuff.s3.amazonaws.com/SSR/SSR%20-%20FB%20Ad%20Images/SSR%20FB%20Ad%20Image%205.png"
              altText="live chat support"
              title=""
            />
          </Card.Body>
        </Card>
    
      </CardGroup>
      <Courses />
      <Footer />
    </div>
  );
}

export default Programs;
