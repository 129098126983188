import React from 'react';

const JavaScriptBrowserBOM = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Browser Object Model (BOM)</h2>
      <p>The Browser Object Model (BOM) is a collection of browser-specific objects that provide interaction with the web browser. Here are some common BOM elements:</p>

      <h3>Alert Box</h3>
      <p>Use the alert() method to display an alert box with a message:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const message = 'This is an alert message';
alert(message);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const message = 'This is an alert message';\nalert(message);"), '_blank')}>Run Code</button>

      <h3>Confirm Box</h3>
      <p>Use the confirm() method to display a dialog box with a confirmation message:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const result = confirm('Do you want to proceed?');
console.log('User choice:', result); // true if OK, false if Cancel`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const result = confirm('Do you want to proceed?');\nconsole.log('User choice:', result);"), '_blank')}>Run Code</button>

      <h3>Prompt Box</h3>
      <p>Use the prompt() method to display a dialog box that prompts the user for input:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const userValue = prompt('Please enter your name:', 'John Doe');
console.log('User input:', userValue);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const userValue = prompt('Please enter your name:', 'John Doe');\nconsole.log('User input:', userValue);"), '_blank')}>Run Code</button>

      <h3>Window Size and Location</h3>
      <p>You can access window properties to get information about the browser window:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const windowWidth = window.innerWidth;
const windowHeight = window.innerHeight;
const windowURL = window.location.href;
console.log('Window size:', windowWidth, windowHeight);
console.log('Current URL:', windowURL);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const windowWidth = window.innerWidth;\nconst windowHeight = window.innerHeight;\nconst windowURL = window.location.href;\nconsole.log('Window size:', windowWidth, windowHeight);\nconsole.log('Current URL:', windowURL);"), '_blank')}>Run Code</button>

      <h3>Window History</h3>
      <p>Access the browser&apos;s history to navigate back and forward:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const historyLength = window.history.length;
console.log('History length:', historyLength);

// Navigate back
// window.history.back();

// Navigate forward
// window.history.forward();`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const historyLength = window.history.length;\nconsole.log('History length:', historyLength);\n\n// Navigate back\n// window.history.back();\n\n// Navigate forward\n// window.history.forward();"), '_blank')}>Run Code</button>

      <h3>Window Navigator</h3>
      <p>Access browser navigator properties for user agent information:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const browserName = window.navigator.appName;
const browserVersion = window.navigator.appVersion;
console.log('Browser name:', browserName);
console.log('Browser version:', browserVersion);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const browserName = window.navigator.appName;\nconst browserVersion = window.navigator.appVersion;\nconsole.log('Browser name:', browserName);\nconsole.log('Browser version:', browserVersion);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptBrowserBOM;
