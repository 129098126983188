import React from 'react';

const CppForLoop = () => {
  return (
    <div>
      <h2>C++ For Loop</h2>
      <p>
        In C++, the <code>for</code> loop is used to repeatedly execute a block of code a specified number of times. Here&apos;s the syntax:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
for (initialization; condition; increment) {
    // Code to be executed
}
          `}
        </code>
      </pre>

      <h3>Code Examples:</h3>

      <h3>Break Statement</h3>
      <p>
        The <code>break</code> statement is used to exit the loop prematurely when a certain condition is met:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    for (int i = 0; i < 5; i++) {
        if (i == 3) {
            cout << "Break at i = 3" << endl;
            break;
        }
        cout << "Iteration " << i << endl;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    for (int i = 0; i < 5; i++) {
        if (i == 3) {
            cout << "Break at i = 3" << endl;
            break;
        }
        cout << "Iteration " << i << endl;
    }

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Continue Statement</h3>
      <p>
        The <code>continue</code> statement is used to skip the current iteration and proceed to the next one:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    for (int i = 0; i < 5; i++) {
        if (i == 2) {
            cout << "Skipping i = 2" << endl;
            continue;
        }
        cout << "Iteration " << i << endl;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    for (int i = 0; i < 5; i++) {
        if (i == 2) {
            cout << "Skipping i = 2" << endl;
            continue;
        }
        cout << "Iteration " << i << endl;
    }

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Nested For Loops</h3>
      <p>You can use nested <code>for</code> loops for more complex iterations:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    for (int i = 0; i < 3; i++) {
        for (int j = 0; j < 2; j++) {
            cout << "Outer loop iteration " << i << ", Inner loop iteration " << j << endl;
        }
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary mb-2"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    for (int i = 0; i < 3; i++) {
        for (int j = 0; j < 2; j++) {
            cout << "Outer loop iteration " << i << ", Inner loop iteration " << j << endl;
        }
    }

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
      <p>
        The <code>for</code> loop is a powerful tool for controlling program flow, and you can use <code>break</code> and <code>continue</code> statements to customize its behavior. Additionally, nested loops are useful for more complex tasks.
      </p>
    </div>
  );
};

export default CppForLoop;
