import React from 'react';

const CppExceptions = () => {
  return (
    <div>
      <h2>C++ Exceptions</h2>
      <p>
        In C++, exceptions are used to handle runtime errors or exceptional situations. Exceptions provide a way to transfer control from one part of the code to another (e.g., from the point of error to an error-handling block).
      </p>

      <h3>Code Examples:</h3>

      <h3>Try-Catch Block</h3>
      <p>
        Use the <code>try-catch</code> block to handle exceptions. When an exception is thrown within the <code>try</code> block, the program jumps to the corresponding <code>catch</code> block for error handling:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    try {
        int x = 10;
        int y = 0;
        if (y == 0) {
            throw "Division by zero!";
        }
        int result = x / y;
        std::cout << "Result: " << result << std::endl;
    } catch (const char* error) {
        std::cout << "Error: " << error << std::endl;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    try {
        int x = 10;
        int y = 0;
        if (y == 0) {
            throw "Division by zero!";
        }
        int result = x / y;
        std::cout << "Result: " << result << std::endl;
    } catch (const char* error) {
        std::cout << "Error: " << error << std::endl;
    }

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Custom Exception Class</h3>
      <p>
        You can create custom exception classes by inheriting from the <code>std::exception</code> class and override the <code>what()</code> method to provide a custom error message:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
#include <exception>

class MyException : public std::exception {
public:
    const char* what() const noexcept {
        return "Custom Exception: Something went wrong!";
    }
};

int main() {
    try {
        throw MyException();
    } catch (const std::exception& e) {
        std::cout << "Error: " << e.what() << std::endl;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>
#include <exception>

class MyException : public std::exception {
public:
    const char* what() const noexcept {
        return "Custom Exception: Something went wrong!";
    }
};

int main() {
    try {
        throw MyException();
    } catch (const std::exception& e) {
        std::cout << "Error: " << e.what() << std::endl;
    }

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppExceptions;
