import React from "react";
import Footer from "../components/Footer";
import PagesNavBar from "../components/PagesNavBar";
import ScrollIntoView from "../components/ScrollIntoView";
import basic from '../pdf/Basic Electrical Engineering.pdf'
import basicCircuit from '../pdf/Basic Engineering Circuit Analysis.pdf'
import ElectronicsFundamentals from '../pdf/Electronics Fundamentals. Circuits, Devices, and Applications.pdf'
import ElectricalCircuit from '../pdf/Electrical circuit theory and technology, Third Edition (Electrical Circuit Theory and Technology).pdf'
import HigherMaths from '../pdf/Higher Engineering Mathematics.pdf'
import MathsPhysics from '../pdf/Mathematical Physics.pdf'
import MicroelectronicCircuits from '../pdf/Microelectronic Circuits.pdf'
import powerSystem from '../pdf/electric-power-system-planning-issues-algorithms-and-solutions-pdf.pdf'
import signals from '../pdf/Signals and Systems.pdf'
import solidstate from '../pdf/Solid State Electronic Devices, 6th Edition .pdf'
import NumericalModelling from '../pdf/Numerical Modelling and Design of Electrical Machines and Devices (Advances in Electrical and Electronic Engineering) (Advances in Electrical and Electronic Engineering, V. 1) .pdf'





class ElectricalEngineering extends React.Component {
  openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };
  closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  render() {
    return (
      <ScrollIntoView>
        <div>
          <section>
            <div id="mySidenav" className="sidenav">
              <a
                href
                className="closebtn"
                style={{ cursor: "pointer" }}
                onClick={this.closeNav}
              >
                &times;
              </a>
              
              <a href={basic} target="_blank" rel="noopener noreferrer">
                Basic Electrical Engineering
              </a>
              <a href={basicCircuit} target="_blank" rel="noopener noreferrer">
                Basic Engineering Circuit Analysis
              </a>
              <a href={ElectronicsFundamentals} target="_blank" rel="noopener noreferrer">
                Electronics Fundamentals, Circuits, Devices, and Applications
              </a>
              <a href={ElectricalCircuit} target="_blank" rel="noopener noreferrer">
                Electical Circuit Theory and Technology
              </a>
              <a href={HigherMaths} target="_blank" rel="noopener noreferrer">
                Higher Engineering Mathematics
              </a>
              <a href={MathsPhysics} target="_blank" rel="noopener noreferrer">
                Mathematical Physics
              </a>
              <a href={MicroelectronicCircuits} target="_blank" rel="noopener noreferrer">
                Microelectronic Circuits
              </a>
              <a href={powerSystem} target="_blank" rel="noopener noreferrer">
               Electronic Power System Planning Issues Algorithms and Solutions
              </a>
              <a href={signals} target="_blank" rel="noopener noreferrer">
                Signals and Systems
              </a>
              <a href={solidstate} target="_blank" rel="noopener noreferrer">
                Solid State Electronic Devices
              </a>
              
              <a href={NumericalModelling} target="_blank" rel="noopener noreferrer">
              Numerical Modelling and Design of Electrical Machines and Devices 
              </a>
              
              
            </div>
          </section>
          {/* top navbar */}
          <nav className="navbar navbar-expand-lg navbar-dark  navBarTop ">
            <div className="container-fluid opacity-75">
              <span
                style={{ fontSize: "20px", cursor: "pointer", color: "white" }}
                onClick={this.openNav}
              >
                &#9776; Topics
              </span>
            </div>
          </nav>

          <PagesNavBar />

          <section>
            {/* short words */}
            <section className="container-fluid">
              <hr />
              <div>
                <h5 style={{ color: "red", fontWeight: "15px" }}>
                  What is Electrical Engineering
                </h5>
                <p>
                  One of the more recent engineering specialties, electrical
                  engineering has roots in the late 19th century. It is the area
                  of engineering that deals with electricity-related technology.
                  From tiny microchips to enormous power plant generators,
                  electrical engineers work on a diverse range of parts,
                  gadgets, and systems. Early electricity experiments used
                  static charges and crude batteries. However, Michael Faraday&apos;s
                  Law of Induction, which essentially states that the voltage in
                  a circuit is proportional to the rate of change in the
                  magnetic field through the circuit, marked the beginning of
                  the actual design, construction, and manufacture of useful
                  devices and systems. The fundamental concepts of the electric
                  generator, electric motor, and transformer are all covered by
                  this law. The introduction of electricity to homes,
                  businesses, and industry, which was made possible by
                  electrical engineers, is a defining feature of the modern era.
                </p>
              </div>
            </section>

            <section className="container-fluid">
              {/* What does an electrical engineer do?  */}
              <div>
                <h5 style={{ color: "red", fontWeight: "15px" }}>
                  What does an electrical engineer do?
                </h5>
                <p>
                  According to the U.S. Bureau of Labor Statistics, &quot;Electrical
                  engineers design, develop, test, and oversee the manufacturing
                  of electrical equipment, such as electric motors, radar and
                  navigation systems, communications systems, and power
                  generation equipment. Electronics engineers create electronic
                  devices like portable music players and global positioning
                  systems (GPS), as well as broadcast and communications
                  systems. Most likely, an electrical engineer created any
                  practical, everyday device that generates, conducts, or uses
                  electricity. Engineers may also carry out or create the
                  specifications for destructive or nondestructive testing of a
                  device&apos;s or component&apos;s functionality, dependability, and
                  long-term durability. Using fundamental parts like conductors,
                  coils, magnets, batteries, switches, resistors, capacitors,
                  inductors, diodes, and transistors, electrical engineers of
                  today create electrical systems and devices. These few basic
                  components are found in almost all electrical and electronic
                  devices, including the microprocessors in your phone and the
                  generators at electric power plants.
                </p>
              </div>
            </section>
            <div>
          {/* Ads */}
          <iframe
          id="iframe_54"
          className="container"
          src="//a.impactradius-go.com/gen-ad-code/3874559/1446104/11346/"
          title="coinsmart"
          width="971"
          height="91"
          scrolling="no"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>
            <section className="container-fluid">
              <div>
                <h5 style={{ color: "red", fontWeight: "15px" }}>
                  Electrical engineering&apos;s future
                </h5>
                <p>
                  Electrical and electronics engineers&apos; employment is expected
                  to increase by 4% between now and 2022 as a result of their
                  &quot;versatility in developing and applying emerging
                  technologies,&quot; according to the BLS. These new technologies
                  can be used for a variety of things, such as studying the red
                  electrical flashes known as sprites that occasionally hover
                  above thunderstorms. Electrical engineer Victor Pasko from
                  Penn State and his associates have created a model to explain
                  how the peculiar lightning develops and vanishes.
                </p>
              </div>
            </section>

            <section className="container-fluid">
              <div>
                <h5 style={{ color: "red", fontWeight: "15px" }}>
                  Electrical and Electronics engineering specialties
                </h5>
                <p>
                  The electronic computer branch of electrical engineering, the
                  largest of its specialized subfields, was first developed
                  during World War II. Several disciplines besides electronics
                  have been drawn to the field of computer science and
                  engineering, most notably logicians, linguists, and applied
                  mathematicians. The study of electric power and light and
                  their uses is another extremely broad field. The design,
                  production, and use of turbines, generators, transmission
                  lines, transformers, motors, lighting systems, and appliances
                  are among the field&apos;s specialties. The transmission of voice
                  and data by laser signals through optical fiber networks is a
                  third important area in communications, which also includes
                  telephony and satellite communications. The exchange of
                  digital data among computers linked by wire, microwave, and
                  satellite circuits is now a significant business that has
                  forged a close relationship between experts in computers and
                  communications.
                </p>
              </div>
            </section>
            <div className="container text-center">
          <iframe id="iframe_569" src="//a.impactradius-go.com/gen-ad-code/3874559/512104/7452/"
          className="container text-center"
           width="728" height="90" 
           scrolling="no" 
           title="NordVpn"
           frameBorder="0" 
           marginHeight="0" 
           marginWidth="0"></iframe>
          </div>
            
          </section>
          <br />
          <br />
          <Footer />
        </div>
      </ScrollIntoView>
    );
  }
}

export default ElectricalEngineering;
