import React from "react";

const CppMath = () => {
  return (
    <div>
      <h2>C++ Math Operations</h2>
      <p>
        C++ provides a variety of mathematical operations and functions for
        performing common mathematical tasks. Here are some examples of math
        operations:
      </p>

      <h3>Basic Arithmetic Operations</h3>
      <p>Performing basic arithmetic operations:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
using namespace std;

int main() {
    int a = 10;
    int b = 20;

    cout << "Addition: " << a + b << endl;
    cout << "Subtraction: " << a - b << endl;
    cout << "Multiplication: " << a * b << endl;
    cout << "Division: " << a / b << endl;
    cout << "Modulus: " << a % b << endl;

    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>
using namespace std;

int main() {
    int a = 10;
    int b = 20;

    cout << "Addition: " << a + b << endl;
    cout << "Subtraction: " << a - b << endl;
    cout << "Multiplication: " << a * b << endl;
    cout << "Division: " << a / b << endl;
    cout << "Modulus: " << a % b << endl;

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Power and Square Root</h3>
      <p>Calculating the power of a number and square root:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
#include <cmath>
using namespace std;

int main() {
    double base = 2.0;
    double exponent = 3.0;

    cout << "Power: " << pow(base, exponent) << endl;
    cout << "Square Root: " << sqrt(base) << endl;

    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>
#include <cmath>
using namespace std;

int main() {
    double base = 2.0;
    double exponent = 3.0;

    cout << "Power: " << pow(base, exponent) << endl;
    cout << "Square Root: " << sqrt(base) << endl;

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Trigonometric Functions</h3>
      <p>Using trigonometric functions (sin, cos, tan):</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
#include <cmath>
using namespace std;

int main() {
    double angle = 45.0; // Angle in degrees
    double radian = angle * M_PI / 180.0; // Convert to radians

    cout << "Sine: " << sin(radian) << endl;
    cout << "Cosine: " << cos(radian) << endl;
    cout << "Tangent: " << tan(radian) << endl;

    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>
#include <cmath>
using namespace std;

int main() {
    double angle = 45.0; // Angle in degrees
    double radian = angle * M_PI / 180.0; // Convert to radians

    cout << "Sine: " << sin(radian) << endl;
    cout << "Cosine: " << cos(radian) << endl;
    cout << "Tangent: " << tan(radian) << endl;

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Random Number Generation</h3>
      <p>Generating random numbers:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
#include <cstdlib>
#include <ctime>
using namespace std;

int main() {
    // Seed the random number generator with the current time
    srand(static_cast<unsigned>(time(0)));

    // Generate a random number between 1 and 100
    int randomNum = rand() % 100 + 1;
    cout << "Random Number: " << randomNum << endl;

    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>
#include <cstdlib>
#include <ctime>
using namespace std;

int main() {
    // Seed the random number generator with the current time
    srand(static_cast<unsigned>(time(0)));

    // Generate a random number between 1 and 100
    int randomNum = rand() % 100 + 1;
    cout << "Random Number: " << randomNum << endl;

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>min() and max() Functions</h3>
      <p>Finding the minimum and maximum of two numbers:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
#include <algorithm>
using namespace std;

int main() {
    int a = 10;
    int b = 20;

    cout << "Minimum: " << min(a, b) << endl;
    cout << "Maximum: " << max(a, b) << endl;

    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>
#include <algorithm>
using namespace std;

int main() {
    int a = 10;
    int b = 20;

    cout << "Minimum: " << min(a, b) << endl;
    cout << "Maximum: " << max(a, b) << endl;

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Math Constants</h3>
      <p>
        Using mathematical constants like Pi (π) and Euler&apos;s number (e):
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
#include <cmath>
using namespace std;

int main() {
    cout << "Pi (π): " << M_PI << endl;
    cout << "Euler's Number (e): " << M_E << endl;

    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>
#include <cmath>
using namespace std;

int main() {
    cout << "Pi (π): " << M_PI << endl;
    cout << "Euler's Number (e): " << M_E << endl;

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppMath;
