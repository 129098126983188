import React from 'react';

const JavaScriptArrayMethods = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Array Methods</h2>
      <p>JavaScript provides a variety of methods for working with arrays. Let&apos;s explore some common array methods:</p>

      <h3>Creating Arrays</h3>
      <p>Create arrays using various methods:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
const numbers = new Array(1, 2, 3, 4, 5);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nconst numbers = new Array(1, 2, 3, 4, 5);"), '_blank')}>Run Code</button>

      <h3>Array Length</h3>
      <p>Find the length of an array:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
const length = fruits.length;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nconst length = fruits.length;"), '_blank')}>Run Code</button>

      <h3>Adding Elements</h3>
      <p>Add elements to an array:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
fruits.push('orange');
fruits.unshift('grape');`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nfruits.push('orange');\nfruits.unshift('grape');"), '_blank')}>Run Code</button>

      <h3>Removing Elements</h3>
      <p>Remove elements from an array:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
fruits.pop();
fruits.shift();
fruits.splice(1, 1);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nfruits.pop();\nfruits.shift();\nfruits.splice(1, 1);"), '_blank')}>Run Code</button>

      <h3>Iterating Arrays</h3>
      <p>Loop through arrays using different methods:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
fruits.forEach(fruit => console.log(fruit));
const filteredFruits = fruits.filter(fruit => fruit.length > 5);
const mappedFruits = fruits.map(fruit => fruit.toUpperCase());`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nfruits.forEach(fruit => console.log(fruit));\nconst filteredFruits = fruits.filter(fruit => fruit.length > 5);\nconst mappedFruits = fruits.map(fruit => fruit.toUpperCase());"), '_blank')}>Run Code</button>

      <h3>Array Sorting</h3>
      <p>Sort arrays in ascending and descending order:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numbers = [4, 2, 9, 1, 5];
numbers.sort((a, b) => a - b);
numbers.reverse();`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numbers = [4, 2, 9, 1, 5];\nnumbers.sort((a, b) => a - b);\nnumbers.reverse();"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptArrayMethods;
