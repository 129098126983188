import React from "react";

const JavaScriptWhileLoop = () => {
  // URL for the code editor
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>JavaScript While and Do...While Loops</h2>
      <p>
        The while and do...while loops are used to repeatedly execute a block of
        code as long as a specified condition is true. Here are some common
        usages:
      </p>

      <h3>Basic While Loop</h3>
      <p>
        Use a while loop to execute a block of code as long as a condition is
        true:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let count = 0;
while (count < 5) {
  console.log('Count:', count);
  count++;
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "let count = 0;\nwhile (count < 5) {\n  console.log('Count:', count);\n  count++;\n}"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Basic Do...While Loop</h3>
      <p>
        Use a do...while loop to execute a block of code at least once and then
        repeatedly if a condition is true:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let count = 0;
do {
  console.log('Count:', count);
  count++;
} while (count < 5);`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "let count = 0;\ndo {\n  console.log('Count:', count);\n  count++;\n} while (count < 5);"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>While Loop with User Input</h3>
      <p>
        Create a while loop that continues until the user enters a specific
        value:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let userInput;
while (userInput !== 'quit') {
  userInput = prompt('Enter something (type "quit" to exit):');
  console.log('You entered:', userInput);
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "let userInput;\nwhile (userInput !== 'quit') {\n  userInput = prompt('Enter something (type \"quit\" to exit):');\n  console.log('You entered:', userInput);\n}"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>While Loop with Random Numbers</h3>
      <p>
        Generate random numbers within a while loop until a specific condition
        is met:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let randomNum;
while (true) {
  randomNum = Math.random();
  console.log('Random Number:', randomNum);
  if (randomNum < 0.2) {
    break;
  }
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "let randomNum;\nwhile (true) {\n  randomNum = Math.random();\n  console.log('Random Number:', randomNum);\n  if (randomNum < 0.2) {\n    break;\n  }\n}"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>While Loop with User Confirmation</h3>
      <p>Use a while loop to repeatedly ask the user for confirmation:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let userConfirmed = false;
while (!userConfirmed) {
  const userInput = confirm('Are you sure?');
  if (userInput) {
    console.log('User is sure!');
    userConfirmed = true;
  } else {
    console.log('User is not sure.');
  }
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "let userConfirmed = false;\nwhile (!userConfirmed) {\n  const userInput = confirm('Are you sure?');\n  if (userInput) {\n    console.log('User is sure!');\n    userConfirmed = true;\n  } else {\n    console.log('User is not sure.');\n  }\n}"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Do...While Loop Example</h3>
      <p>Here&apos;s an example of a do...while loop:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let number = 1;
do {
  console.log('Number:', number);
  number++;
} while (number <= 5);`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "let number = 1;\ndo {\n  console.log('Number:', number);\n  number++;\n} while (number <= 5);"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default JavaScriptWhileLoop;
