import { PureComponent } from "react";
import PropTypes from "prop-types";

class ScrollIntoView extends PureComponent {
  componentDidMount = () => window.scrollTo(0, 0);

  render = () => this.props.children;
}

ScrollIntoView.propTypes = {
  children: PropTypes.node.isRequired, // Add prop validation for 'children'
};

export default ScrollIntoView;
