import React from 'react';
import PropTypes from 'prop-types';

const ForexTrading = ({ productLink, imageUrl, altText }) => {
  return (
    <div className="clickbank-ad container">
      <a href={productLink} target="_blank" rel="noopener noreferrer">
        <img src={imageUrl} alt={altText} />
      </a>
    </div>
  );
};

// Define and validate the expected prop types
ForexTrading.propTypes = {
  productLink: PropTypes.string.isRequired, // 'productLink' should be a string and is required
  imageUrl: PropTypes.string.isRequired, // 'imageUrl' should be a string and is required
  altText: PropTypes.string.isRequired, // 'altText' should be a string and is required
};

export default ForexTrading;
