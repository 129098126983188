import React from 'react';

const CppDataTypes = () => {
  return (
    <div>
      <h2>C++ Data Types</h2>
      <p>
        In C++, data types are used to define the type of data that a variable can hold. C++ provides several built-in data types to handle different kinds of values.
      </p>

      <h3>Integer Data Types</h3>
      <p>Integer data types are used to store whole numbers:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
int integerVar = 42;
unsigned int unsignedVar = 100;
short shortVar = 10;
long longVar = 12345;
long long longLongVar = 12345678;
          `}
        </code>
      </pre>

      <h3>Real Data Types</h3>
      <p>Real data types are used to store numbers with a decimal point:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
float floatVar = 3.14;
double doubleVar = 3.14159265359;
long double longDoubleVar = 3.14159265358979323846;
          `}
        </code>
      </pre>

      <h3>Character Data Types</h3>
      <p>Character data types are used to store single characters:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
char charVar = 'A';
          `}
        </code>
      </pre>

      <h3>Boolean Data Type</h3>
      <p>The boolean data type is used to store true or false values:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
bool boolVar = true;
          `}
        </code>
      </pre>

      <h3>Code Examples</h3>
      <p>Here are some examples of using data types:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int integerVar = 42;
    float floatVar = 3.14;
    char charVar = 'A';
    bool boolVar = true;

    std::cout << "Integer: " << integerVar << std::endl;
    std::cout << "Float: " << floatVar << std::endl;
    std::cout << "Character: " << charVar << std::endl;
    std::cout << "Boolean: " << boolVar << std::endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int integerVar = 42;
    float floatVar = 3.14;
    char charVar = 'A';
    bool boolVar = true;

    std::cout << "Integer: " << integerVar << std::endl;
    std::cout << "Float: " << floatVar << std::endl;
    std::cout << "Character: " << charVar << std::endl;
    std::cout << "Boolean: " << boolVar << std::endl;

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppDataTypes;
