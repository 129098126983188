import React from 'react';

const DataCollectionAndTypes = () => {
  return (
    <div>
      <h2>Data Collection and Data Types</h2>
      <p>Data collection is a critical step in the data science process. It involves gathering data from various sources and understanding different data types. Here are some key concepts:</p>

      <h3>Data Collection</h3>
      <p>Data can be collected from sources such as:</p>
      <ul>
        <li>Surveys and questionnaires.</li>
        <li>Web scraping and APIs.</li>
        <li>Sensors and IoT devices.</li>
        <li>Database records.</li>
        <li>Social media and user-generated content.</li>
      </ul>

      <h3>Data Types</h3>
      <p>Data can be categorized into various types:</p>
      <ul>
        <li><strong>Numeric (Quantitative) Data:</strong> Includes integers and floating-point numbers.</li>
        <li><strong>Categorical (Qualitative) Data:</strong> Represents categories or labels.</li>
        <li><strong>Ordinal Data:</strong> Combines elements of both numeric and categorical data.</li>
        <li><strong>Text Data:</strong> Unstructured textual information.</li>
        <li><strong>Time Series Data:</strong> Data collected at regular time intervals.</li>
      </ul>

      <h3>Code Examples</h3>
      <p>Here are some code examples to illustrate data collection and different data types:</p>

      <h4>Collect Data from a CSV File:</h4>
      <pre>
        {/* Example 1: Collect Data from a CSV File */}
        <code>
{`
import pandas as pd

# Load data from a CSV file
data = pd.read_csv('data.csv')
print(data.head())
`}
        </code>
      </pre>

      <h4>Numeric Data Example:</h4>
      <pre>
        {/* Example 2: Numeric Data */}
        <code>
{`
# Numeric data (integer)
age = 30
# Numeric data (float)
temperature = 98.6

print(age)
print(temperature)
`}
        </code>
      </pre>

      <h4>Categorical Data Example:</h4>
      <pre>
        {/* Example 3: Categorical Data */}
        <code>
{`
# Categorical data
fruit = 'apple'
country = 'USA'

print(fruit)
print(country)
`}
        </code>
      </pre>

      <h4>Text Data Example:</h4>
      <pre>
        {/* Example 4: Text Data */}
        <code>
{`
# Text data
message = 'Hello, World!'

print(message)
`}
        </code>
      </pre>

      <h4>Time Series Data Example:</h4>
      <pre>
        {/* Example 5: Time Series Data */}
        <code>
{`
# Time series data
import pandas as pd

# Generate a time series
dates = pd.date_range(start='2023-01-01', periods=5, freq='D')
print(dates)
`}
        </code>
      </pre>

      <h4>Ordinal Data Example:</h4>
      <pre>
        {/* Example 6: Ordinal Data */}
        <code>
{`
# Ordinal data
rating = 'High'
grades = ['Low', 'Medium', 'High']

print(rating)
print(grades)
`}
        </code>
      </pre>

    </div>
  );
}

export default DataCollectionAndTypes;
