import React from 'react';

const PythonUserInput = () => {
  return (
    <div>
      <h2>User Input in Python</h2>
      <p>In Python, you can get user input using the <code>input()</code> function. This allows you to interact with the user and receive text input.</p>

      <h3>Basic User Input</h3>
      <p>Use the <code>input()</code> function to capture user input as a string:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`user_input = input("Enter something: ")
print("You entered:", user_input)`}
        </code>
      </pre>

      <h3>Converting User Input</h3>
      <p>If you need user input as a number, you can convert it using functions like <code>int()</code> or <code>float()</code>:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`user_input = input("Enter a number: ")
number = float(user_input)
print("You entered:", number)`}
        </code>
      </pre>

      <h3>Handling User Input in a Loop</h3>
      <p>You can capture multiple inputs in a loop:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`numbers = []
for i in range(3):
    user_input = input("Enter a number: ")
    numbers.append(float(user_input))
print("Numbers entered:", numbers)`}
        </code>
      </pre>

      <h3>Using User Input in a Calculation</h3>
      <p>You can use user input to perform calculations:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`width = float(input("Enter width: "))
height = float(input("Enter height: "))
area = width * height
print("Area:", area)`}
        </code>
      </pre>

      <h3>Accepting Strings</h3>
      <p>Accepting a string from the user:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`name = input("Enter your name: ")
print("Hello, " + name)`}
        </code>
      </pre>

      <h3>Handling User Choices</h3>
      <p>Capturing and processing user choices (e.g., &quot;yes&quot; or &quot;no&quot;):</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`choice = input("Do you want to continue (yes/no)? ")
if choice.lower() == "yes":
    print("Continuing...")
else:
    print("Exiting...")`}
        </code>
      </pre>

      <h3>User Input Validation</h3>
      <p>Validating user input to ensure it meets specific criteria:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`while True:
    user_input = input("Enter a positive number: ")
    number = float(user_input)
    if number > 0:
        break
    else:
        print("Please enter a positive number.")`}
        </code>
      </pre>
    </div>
  );
}

export default PythonUserInput;
