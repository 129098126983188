import React from 'react';

const JavaScriptIfElse = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript If and Else Statements</h2>
      <p>Conditional statements like if and else allow you to make decisions in your code based on certain conditions. Here are some common usages:</p>

      <h3>If Statement</h3>
      <p>Use an if statement to execute code when a condition is true:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const age = 25;
if (age >= 18) {
  console.log('You are an adult.');
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const age = 25;\nif (age >= 18) {\n  console.log('You are an adult.');\n}"), '_blank')}>Run Code</button>

      <h3>If-Else Statement</h3>
      <p>Use an if-else statement to execute one block of code when a condition is true and another block when it&apos;s false:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const temperature = 22;
if (temperature > 20) {
  console.log('It's a warm day.');
} else {
  console.log('It's a cold day.');
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl )}>Run Code</button>

      <h3>Else-If Statement</h3>
      <p>Use an else-if statement to check multiple conditions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const hour = 12;
if (hour < 12) {
  console.log('Good morning.');
} else if (hour < 18) {
  console.log('Good afternoon.');
} else {
  console.log('Good evening.');
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const hour = 12;\nif (hour < 12) {\n  console.log('Good morning.');\n} else if (hour < 18) {\n  console.log('Good afternoon.');\n} else {\n  console.log('Good evening.');\n}"), '_blank')}>Run Code</button>

      <h3>Nested If Statements</h3>
      <p>You can nest if statements inside each other to create more complex conditions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const user = 'admin';
const authenticated = true;

if (authenticated) {
  if (user === 'admin') {
    console.log('Welcome, Admin!');
  } else {
    console.log('Welcome, User!');
  }
} else {
  console.log('Please log in.');
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const user = 'admin';\nconst authenticated = true;\n\nif (authenticated) {\n  if (user === 'admin') {\n    console.log('Welcome, Admin!');\n  } else {\n    console.log('Welcome, User!');\n  }\n} else {\n  console.log('Please log in.');\n}"), '_blank')}>Run Code</button>

      <h3>Ternary Operator</h3>
      <p>The ternary operator provides a concise way to write if-else statements:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const isRaining = true;
const weather = isRaining ? 'Bring an umbrella' : 'Enjoy the sun';
console.log(weather);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const isRaining = true;\nconst weather = isRaining ? 'Bring an umbrella' : 'Enjoy the sun';\nconsole.log(weather);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptIfElse;
