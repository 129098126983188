import React from "react";

const PythonIterators = () => {
  // URL for the code editor
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python Iterators</h2>
      <p>
        In Python, an iterator is an object that can be iterated (looped) over.
        It represents a sequence of data elements and allows you to access each
        element one at a time. Here&apos;s an introduction to iterators with
        code examples:
      </p>

      <h3>Creating an Iterator</h3>
      <p>
        You can create your own iterators by implementing two methods:{" "}
        <code>__iter__()</code> and <code>__next__()</code>:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class MyIterator:
    def __init__(self, max_num):
        self.max_num = max_num
        self.num = 0

    def __iter__(self):
        return self

    def __next__(self):
        if self.num < self.max_num:
            result = self.num
            self.num += 1
            return result
        else:
            raise StopIteration

my_iter = MyIterator(5)
for num in my_iter:
    print(num)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "class MyIterator:\n    def __init__(self, max_num):\n        self.max_num = max_num\n        self.num = 0\n\n    def __iter__(self):\n        return self\n\n    def __next__(self):\n        if self.num < self.max_num:\n            result = self.num\n            self.num += 1\n            return result\n        else:\n            raise StopIteration\n\nmy_iter = MyIterator(5)\nfor num in my_iter:\n    print(num)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Using Built-in Iterators</h3>
      <p>
        Python provides built-in iterators such as lists, tuples, and
        dictionaries:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`fruits = ['apple', 'banana', 'cherry']
for fruit in fruits:
    print(fruit)

my_dict = {'name': 'Alice', 'age': 30}
for key in my_dict:
    print(key, my_dict[key])`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "fruits = ['apple', 'banana', 'cherry']\nfor fruit in fruits:\n    print(fruit)\n\nmy_dict = {'name': 'Alice', 'age': 30}\nfor key in my_dict:\n    print(key, my_dict[key])"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Using Iterators with Generators</h3>
      <p>
        Generators are a convenient way to create iterators. You can use the{" "}
        <code>yield</code> keyword to yield values one at a time:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def my_generator(max_num):
    num = 0
    while num < max_num:
        yield num
        num += 1

gen = my_generator(3)
for value in gen:
    print(value)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "def my_generator(max_num):\n    num = 0\n    while num < max_num:\n        yield num\n        num += 1\n\ngen = my_generator(3)\nfor value in gen:\n    print(value)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Using Iterators with Itertools</h3>
      <p>
        The Python <code>itertools</code> module provides various functions for
        creating iterators:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from itertools import count, cycle, islice

# Counting iterator
counter = count(start=5, step=2)
print(list(islice(counter, 5)))

# Cycling iterator
colors = cycle(['red', 'green', 'blue'])
print(list(islice(colors, 7))`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "from itertools import count, cycle, islice\n\ncounter = count(start=5, step=2)\nprint(list(islice(counter, 5)))\n\ncolors = cycle(['red', 'green', 'blue'])\nprint(list(islice(colors, 7))"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Custom Iterator Using a Class</h3>
      <p>You can create custom iterators using a class:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class MyRange:
    def __init__(self, start, end, step=1):
        self.current = start
        self.end = end
        self.step = step

    def __iter__(self):
        return self

    def __next__(self):
        if self.current >= self.end:
            raise StopIteration
        value = self.current
        self.current += self.step
        return value

my_range = MyRange(1, 10, 2)
for num in my_range:
    print(num)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "class MyRange:\n    def __init__(self, start, end, step=1):\n        self.current = start\n        self.end = end\n        self.step = step\n\n    def __iter__(self):\n        return self\n\n    def __next__(self):\n        if self.current >= self.end:\n            raise StopIteration\n        value = self.current\n        self.current += self.step\n        return value\n\nmy_range = MyRange(1, 10, 2)\nfor num in my_range:\n    print(num)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Using List Comprehension</h3>
      <p>
        List comprehensions allow you to create iterators for generating lists:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`numbers = [x for x in range(5)]
print(numbers)

squared = [x ** 2 for x in range(5)]
print(squared)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "numbers = [x for x in range(5)]\nprint(numbers)\n\nsquared = [x ** 2 for x in range(5)]\nprint(squared)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default PythonIterators;
