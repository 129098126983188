import React from 'react';

const MachineLearningModels = () => {
  return (
    <div>
      <h2>Machine Learning Models</h2>
      <p>Machine learning involves creating models that can learn patterns from data and make predictions. Let&apos;s explore various machine learning models and their applications:</p>

      <h3>1. Linear Regression</h3>
      <p>Linear regression is used for modeling the relationship between a dependent variable and one or more independent variables:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.linear_model import LinearRegression

# Create a linear regression model
model = LinearRegression()

# Fit the model to the data
model.fit(X, y)

# Make predictions
predictions = model.predict(new_data)
`}
        </code>
      </pre>
      <p>This code demonstrates the use of linear regression for predictive modeling.</p>

      <h3>2. Logistic Regression</h3>
      <p>Logistic regression is used for binary classification tasks:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.linear_model import LogisticRegression

# Create a logistic regression model
model = LogisticRegression()

# Fit the model to the data
model.fit(X, y)

# Make binary classification predictions
predictions = model.predict(new_data)
`}
        </code>
      </pre>
      <p>This code shows how to use logistic regression for binary classification.</p>

      <h3>3. Decision Trees and Random Forest</h3>
      <p>Decision trees are used for classification and regression tasks, while random forests are an ensemble method based on decision trees:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.tree import DecisionTreeClassifier
from sklearn.ensemble import RandomForestClassifier

# Create a decision tree classifier
decision_tree = DecisionTreeClassifier()
decision_tree.fit(X, y)

# Create a random forest classifier
random_forest = RandomForestClassifier(n_estimators=100)
random_forest.fit(X, y)

# Make predictions using both models
tree_predictions = decision_tree.predict(new_data)
forest_predictions = random_forest.predict(new_data)
`}
        </code>
      </pre>
      <p>This example covers both decision trees and random forests for classification.</p>

      <h3>4. Support Vector Machines (SVM)</h3>
      <p>SVM is a powerful algorithm for classification and regression tasks:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.svm import SVC

# Create a support vector classifier
model = SVC()

# Fit the model to the data
model.fit(X, y)

# Make predictions
predictions = model.predict(new_data)
`}
        </code>
      </pre>
      <p>Support Vector Machines aim to find the hyperplane that best separates data points.</p>

      <h3>5. Clustering Algorithms (K-Means, DBSCAN)</h3>
      <p>Clustering algorithms are used to group data points based on similarity:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.cluster import KMeans, DBSCAN

# Create a K-Means clustering model
kmeans = KMeans(n_clusters=3)
kmeans.fit(X)

# Create a DBSCAN clustering model
dbscan = DBSCAN(eps=0.5, min_samples=5)
dbscan.fit(X)

# Assign data points to clusters
kmeans_labels = kmeans.labels_
dbscan_labels = dbscan.labels_
`}
        </code>
      </pre>
      <p>Here, we use K-Means and DBSCAN for clustering data points.</p>

      <h3>6. Natural Language Processing (NLP)</h3>
      <p>Natural Language Processing involves working with textual data for tasks like sentiment analysis and text classification:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import nltk
from nltk.tokenize import word_tokenize

# Tokenize text
text = "Natural Language Processing is fascinating."
tokens = word_tokenize(text)

# Perform NLP tasks
# (e.g., sentiment analysis, named entity recognition)
`}
        </code>
      </pre>
      <p>This code snippet showcases tokenization, a fundamental NLP task.</p>

      <h3>7. Time Series Analysis</h3>
      <p>Time series analysis is used to understand and predict data with a temporal component, such as stock prices or weather data:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import pandas as pd
import statsmodels.api as sm

# Load time series data
data = pd.read_csv('time_series_data.csv')

# Perform time series analysis (e.g., ARIMA modeling)
model = sm.tsa.ARIMA(data, order=(1, 1, 1))
results = model.fit()
`}
        </code>
      </pre>
      <p>This example introduces time series analysis using ARIMA modeling.</p>
    </div>
  );
}

export default MachineLearningModels;
