import React from 'react';
import TagManager from 'react-gtm-module';
import PropTypes from 'prop-types';

const GTMContainer = ({ containerId }) => {
  React.useEffect(() => {
    TagManager.initialize({ gtmId: containerId });
  }, [containerId]);

  return (
    <>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${containerId}`}
          height="0"
          title='GTM'
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      <script>
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${containerId}');
        `}
      </script>
    </>
  );
};


GTMContainer.propTypes = {
  containerId: PropTypes.string.isRequired, // Ensure containerId is a required string prop
};



export default GTMContainer;
