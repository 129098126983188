import React from 'react';

const PythonFileHandling = () => {
  return (
    <div>
      <h2>File Handling in Python</h2>
      <p>Python provides several methods for working with files. You can open, read, write, and manipulate files using built-in functions.</p>

      <h3>Opening a File</h3>
      <p>To open a file, use the <code>open()</code> function. You need to specify the file name and the mode (read, write, append, etc.).</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Opening a file for reading
file = open('example.txt', 'r')`}
        </code>
      </pre>

      <h3>Reading from a File</h3>
      <p>After opening a file, you can read its content using methods like <code>read()</code>, <code>readline()</code>, or <code>readlines()</code>.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Read the entire file
content = file.read()
print(content)

# Read one line at a time
line = file.readline()
print(line)

# Read all lines into a list
lines = file.readlines()
print(lines)`}
        </code>
      </pre>

      <h3>Writing to a File</h3>
      <p>To write to a file, open it in write mode (&apos;w&apos;) or append mode (&apos;a&apos;). Use methods like <code>write()</code> or <code>writelines()</code>.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Opening a file for writing
file = open('output.txt', 'w')

# Write content to the file
file.write('This is a line of text.')
file.writelines(['Line 2\\n', 'Line 3\\n'])`}
        </code>
      </pre>

      <h3>Closing a File</h3>
      <p>After working with a file, it&apos;s important to close it using the <code>close()</code> method.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Close the file
file.close()`}
        </code>
      </pre>

      <h3>Context Manager (with Statement)</h3>
      <p>A better practice is to use a context manager with the <code>with</code> statement to automatically close the file when you&apos;re done.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Using a context manager
with open('example.txt', 'r') as file:
    content = file.read()
    print(content)
# File is automatically closed when exiting the block`}
        </code>
      </pre>

      <h3>File Modes</h3>
      <p>Common file modes include:</p>
      <ul>
        <li>&apos;r&apos; - Read</li>
        <li>&apos;w&apos; - Write (creates a new file or overwrites existing content)</li>
        <li>&apos;a&apos; - Append (creates a new file or appends to existing content)</li>
        <li>&apos;b&apos; - Binary mode</li>
      </ul>

      <h3>File Navigation</h3>
      <p>You can move the file pointer to a specific position using the <code>seek()</code> method and get the current position using <code>tell()</code>.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`file.seek(0)  # Move to the beginning of the file
position = file.tell()  # Get the current position`}
        </code>
      </pre>
    </div>
  );
}

export default PythonFileHandling;
