import React from 'react';

const CppPointers = () => {
  return (
    <div>
      <h2>C++ Pointers</h2>
      <p>
        Pointers are variables that store the memory address of another variable. They provide a way to work with
        memory at a lower level. Here&apos;s how pointers work in C++:
      </p>

      <h3>Declaring Pointers</h3>
      <p>To declare a pointer, use the data type followed by an asterisk (*) and the pointer name:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
int* intPointer; // Pointer to an integer
double* doublePointer; // Pointer to a double
char* charPointer; // Pointer to a character
          `}
        </code>
      </pre>

      <h3>Initializing Pointers</h3>
      <p>
        Pointers should be initialized with the address of a variable. Use the address-of operator (&) to get the
        address of a variable:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
int number = 42;
int* numberPointer = &number; // Initialize pointer with the address of 'number'
          `}
        </code>
      </pre>

      <h3>Accessing Values</h3>
      <p>Use the dereference operator (*) to access the value a pointer points to:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
int value = *numberPointer; // Access the value pointed to by 'numberPointer'
          `}
        </code>
      </pre>

      <h3>Code Examples:</h3>

      <p>Let&apos;s explore some code examples with pointers:</p>

      <h3>Example 1: Basic Pointer Usage</h3>
      <p>
        In this example, we declare a pointer to an integer, initialize it with the address of an integer variable,
        and use the pointer to modify the variable.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int number = 42;
    int* numberPointer = &number; // Pointer initialization

    // Use the pointer to modify the variable
    *numberPointer = 10;

    std::cout << "Value of number: " << number << std::endl; // Output: 10
    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int number = 42;
    int* numberPointer = &number; // Pointer initialization

    // Use the pointer to modify the variable
    *numberPointer = 10;

    std::cout << "Value of number: " << number << std::endl; // Output: 10
    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Example 2: Pointers to Arrays</h3>
      <p>
        Pointers can be used to iterate through arrays. In this example, we declare an array, create a pointer to
        it, and use the pointer to access array elements.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int numbers[] = {1, 2, 3, 4, 5};
    int* pointerToFirstElement = numbers; // Pointer to the first element

    for (int i = 0; i < 5; i++) {
        std::cout << "Element " << i << ": " << *pointerToFirstElement << std::endl;
        pointerToFirstElement++; // Move to the next element
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int numbers[] = {1, 2, 3, 4, 5};
    int* pointerToFirstElement = numbers; // Pointer to the first element

    for (int i = 0; i < 5; i++) {
        std::cout << "Element " << i << ": " << *pointerToFirstElement << std::endl;
        pointerToFirstElement++; // Move to the next element
    }

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Example 3: Pointer to Pointer</h3>
      <p>
        Pointers can also point to other pointers. In this example, we declare a pointer to an integer, a pointer to a
        pointer, and access the value using both.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int number = 42;
    int* numberPointer = &number; // Pointer to an integer
    int** pointerToNumberPointer = &numberPointer; // Pointer to a pointer

    // Access the value using both pointers
    std::cout << "Value of number (via double pointer): " << **pointerToNumberPointer << std::endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int number = 42;
    int* numberPointer = &number; // Pointer to an integer
    int** pointerToNumberPointer = &numberPointer; // Pointer to a pointer

    // Access the value using both pointers
    std::cout << "Value of number (via double pointer): " << **pointerToNumberPointer << std::endl;

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Example 4: Passing Pointers to Functions</h3>
      <p>
        Pointers can be passed to functions, allowing functions to modify the original data. In this example, we pass a
        pointer to a function to increment a variable.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

void increment(int* value) {
    (*value)++; // Increment the value pointed to by the pointer
}

int main() {
    int number = 42;
    increment(&number); // Pass a pointer to the function

    std::cout << "Incremented number: " << number << std::endl; // Output: 43

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

void increment(int* value) {
    (*value)++; // Increment the value pointed to by the pointer
}

int main() {
    int number = 42;
    increment(&number); // Pass a pointer to the function

    std::cout << "Incremented number: " << number << std::endl; // Output: 43

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Example 5: Dynamic Memory Allocation</h3>
      <p>
        Pointers are often used for dynamic memory allocation. In this example, we allocate memory for an integer on
        the heap.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int* dynamicNumber = new int; // Allocate memory on the heap
    *dynamicNumber = 99; // Assign a value

    std::cout << "Dynamic Number: " << *dynamicNumber << std::endl;

    delete dynamicNumber; // Deallocate memory
    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int* dynamicNumber = new int; // Allocate memory on the heap
    *dynamicNumber = 99; // Assign a value

    std::cout << "Dynamic Number: " << *dynamicNumber << std::endl;

    delete dynamicNumber; // Deallocate memory
    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Example 6: Pointers to Functions</h3>
      <p>
        Pointers to functions can be used to switch between different functions at runtime. In this example, we declare a
        pointer to a function and call functions using the pointer.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

void sayHello() {
    std::cout << "Hello, World!" << std::endl;
}

void sayGoodbye() {
    std::cout << "Goodbye!" << std::endl;
}

int main() {
    void (*functionPointer)(); // Pointer to a function

    functionPointer = sayHello;
    functionPointer(); // Call the function

    functionPointer = sayGoodbye;
    functionPointer(); // Call another function

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

void sayHello() {
    std::cout << "Hello, World!" << std::endl;
}

void sayGoodbye() {
    std::cout << "Goodbye!" << std::endl;
}

int main() {
    void (*functionPointer)(); // Pointer to a function

    functionPointer = sayHello;
    functionPointer(); // Call the function

    functionPointer = sayGoodbye;
    functionPointer(); // Call another function

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
}

export default CppPointers;
