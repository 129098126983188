import React from 'react';

const CppBooleans = () => {
  return (
    <div>
      <h2>C++ Booleans</h2>
      <p>
        In C++, a boolean variable is used to store either <code>true</code> or <code>false</code>. C++ provides the <code>bool</code> data type for boolean variables.
      </p>

      <h3>Code Examples:</h3>
      <p>Here are some examples of using boolean variables in C++:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    bool isCPlusPlusFun = true;
    bool isPythonFun = false;

    cout << "Is C++ fun? " << isCPlusPlusFun << endl;
    cout << "Is Python fun? " << isPythonFun << endl;

    // Comparing boolean values
    bool isEqual = (3 == 3);
    bool isNotEqual = (4 != 8);

    cout << "3 equals 3? " << isEqual << endl;
    cout << "4 not equals 8? " << isNotEqual << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    bool isCPlusPlusFun = true;
    bool isPythonFun = false;

    cout << "Is C++ fun? " << isCPlusPlusFun << endl;
    cout << "Is Python fun? " << isPythonFun << endl;

    // Comparing boolean values
    bool isEqual = (3 == 3);
    bool isNotEqual = (4 != 8);

    cout << "3 equals 3? " << isEqual << endl;
    cout << "4 not equals 8? " << isNotEqual << endl;

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppBooleans;
