import React from 'react';

const JavaScriptNumberMethods = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Number Methods</h2>
      <p>JavaScript provides several methods to perform operations on numbers. Let&apos;s explore some of these methods:</p>

      <h3>toExponential()</h3>
      <p>Convert a number to its exponential notation:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const num = 12345.6789;
const exponentialNotation = num.toExponential(2);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const num = 12345.6789;\nconst exponentialNotation = num.toExponential(2);"), '_blank')}>Run Code</button>

      <h3>toFixed()</h3>
      <p>Format a number with a specified number of decimal places:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const num = 42.5678;
const formattedNumber = num.toFixed(2);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const num = 42.5678;\nconst formattedNumber = num.toFixed(2);"), '_blank')}>Run Code</button>

      <h3>toString()</h3>
      <p>Convert a number to a string with an optional base for conversion (e.g., binary, octal, hexadecimal):</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const num = 42;
const binaryString = num.toString(2);
const octalString = num.toString(8);
const hexString = num.toString(16);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const num = 42;\nconst binaryString = num.toString(2);\nconst octalString = num.toString(8);\nconst hexString = num.toString(16);"), '_blank')}>Run Code</button>

      <h3>Number Methods - isInteger()</h3>
      <p>Check if a number is an integer using the Number.isInteger() method:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const integer = 42;
const nonInteger = 42.5;

const isInteger1 = Number.isInteger(integer);
const isInteger2 = Number.isInteger(nonInteger);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const integer = 42;\nconst nonInteger = 42.5;\n\nconst isInteger1 = Number.isInteger(integer);\nconst isInteger2 = Number.isInteger(nonInteger);"), '_blank')}>Run Code</button>

      <h3>Number Methods - isNaN()</h3>
      <p>Check if a value is NaN (Not-a-Number) using the Number.isNaN() method:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const nanValue = NaN;
const nonNanValue = 42;

const isNan1 = Number.isNaN(nanValue);
const isNan2 = Number.isNaN(nonNanValue);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const nanValue = NaN;\nconst nonNanValue = 42;\n\nconst isNan1 = Number.isNaN(nanValue);\nconst isNan2 = Number.isNaN(nonNanValue);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptNumberMethods;
