import React from 'react'
import Navigation from './Navigation'
import LatestNews from './LatestNews'
import ScienceNews from './ScienceNews'
import TechnologyNews from './TechnologyNews'
import HealthNews from './HealthNews'
import Footer from './FooterNews'

function NewsHome() {
  return (
    <div>
        <Navigation />
        <LatestNews />
        <hr />
        <ScienceNews />
        <hr />
        <TechnologyNews />
        <hr />
        <HealthNews />
        <hr />
        <br />
        <Footer />
    </div>
  )
}

export default NewsHome