import React from "react";

const PythonTuples = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";
  return (
    <div>
      <h2>Python Tuples</h2>
      <p>
        Tuples are a fundamental concept in programming and data structures.
        They are ordered collections in which each element is identified by an
        index. Unlike lists, tuples are immutable, meaning their elements cannot
        be changed after creation. Tuples are often used to group related pieces
        of data together and are typically faster and consume less memory
        compared to lists. They can contain a mix of different data types and
        can be nested within one another.
      </p>
      <p>
        Tuples are commonly used in various programming languages and have
        diverse applications, such as representing coordinates, dates, key-value
        pairs, and more. They provide a way to ensure data integrity when you
        need to pass a collection of values that should remain constant
        throughout your program. In Python, tuples are defined by enclosing the
        elements in parentheses, like this: (item1, item2, item3).
      </p>
      <p>Here are common tuple operations:</p>

      <h3>Accessing Tuple Elements</h3>
      <p>You can access elements in a tuple by their index (0-based).</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_tuple = (10, 20, 30, 40, 50)
print(my_tuple[0])  # Access the first element
print(my_tuple[2])  # Access the third element`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <h3>Updating Tuples</h3>
      <p>
        Tuples are immutable, so you cannot change their elements. However, you
        can create a new tuple with updated values.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_tuple = (10, 20, 30)
new_tuple = my_tuple + (40, 50)  # Create a new tuple with updated values
print(new_tuple)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <h3>Unpacking Tuples</h3>
      <p>
        You can assign values from a tuple to multiple variables in a single
        line.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_tuple = (10, 20, 30)
a, b, c = my_tuple  # Unpack the tuple
print(a, b, c)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <h3>Looping Through a Tuple</h3>
      <p>Iterate through a tuple to access each element.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_tuple = (10, 20, 30, 40, 50)
for item in my_tuple:
    print(item)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <h3>Joining Tuples</h3>
      <p>
        You can join two or more tuples using the <code>+</code> operator.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`tuple1 = (10, 20, 30)
tuple2 = (40, 50, 60)
combined_tuple = tuple1 + tuple2  # Use + to combine tuples
print(combined_tuple)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <h3>Tuple Methods</h3>
      <p>
        Tuples have limited methods compared to lists, but there are a few,
        including <code>count()</code> and <code>index()</code>.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_tuple = (10, 20, 30, 20, 40)
count = my_tuple.count(20)  # Count occurrences of an element
index = my_tuple.index(30)  # Find the index of an element
print(count, index)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
    </div>
  );
};

export default PythonTuples;
