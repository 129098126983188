import React from 'react';

const DeepLearning = () => {
  return (
    <div>
      <h2>Deep Learning</h2>
      <p>Deep learning is a subset of machine learning where artificial neural networks learn and make predictions. It&apos;s used in various applications such as image and speech recognition. Let&apos;s explore some deep learning concepts and examples:</p>

      <h3>Neural Networks</h3>
      <p>Neural networks are the foundation of deep learning:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from tensorflow.keras.models import Sequential
from tensorflow.keras.layers import Dense

model = Sequential()
model.add(Dense(64, activation='relu', input_dim=100))
model add(Dense(10, activation='softmax')`}
        </code>
      </pre>
      <p>This code sets up a simple neural network with two layers using the TensorFlow library.</p>

      <h3>Convolutional Neural Networks (CNN)</h3>
      <p>CNNs are essential for image recognition tasks:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from tensorflow.keras.layers import Conv2D, MaxPooling2D

model = Sequential()
model.add(Conv2D(32, kernel_size=(3, 3), activation='relu', input_shape=(28, 28, 1))
model.add(MaxPooling2D(pool_size=(2, 2))
# ... add more layers`}
        </code>
      </pre>
      <p>Here, we set up a CNN for image processing.</p>

      <h3>Recurrent Neural Networks (RNN)</h3>
      <p>RNNs are used for sequential data like time series:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from tensorflow.keras.layers import SimpleRNN

model = Sequential()
model.add(SimpleRNN(32, input_shape=(None, 5)) # 5 features in each time step`}
        </code>
      </pre>
      <p>This code configures an RNN for sequential data analysis.</p>

      <h3>Transfer Learning</h3>
      <p>Transfer learning is the practice of using pre-trained models:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from tensorflow.keras.applications import VGG16

base_model = VGG16(weights='imagenet', include_top=false)
# ... build your classifier on top`}
        </code>
      </pre>
      <p>This example shows how to use a pre-trained VGG16 model for image classification.</p>

      <h3>Frameworks (TensorFlow, Keras, PyTorch)</h3>
      <p>Deep learning frameworks like TensorFlow, Keras, and PyTorch provide powerful tools to build and train neural networks. These frameworks simplify the implementation of deep learning models and are widely used in the field.</p>
    </div>
  );
}

export default DeepLearning;
