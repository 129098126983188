import React, { useState, useEffect } from "react";
import { Card, ListGroup, Form } from "react-bootstrap";
import questions from "./DCGenerators.json";
import NavBar from "../../Navbar";
import Footer from "../../Footer";


function DCGenerators() {
  const [questionData, setQuestionData] = useState([]);

  useEffect(() => {
    const formattedQuestions = questions.map((question, index) => {
      return {
        question: question.question,
        correct_answer: question.correct_answer,
        incorrect_answers: question.incorrect_answers,
        userAnswer: "",
        isCorrect: false,
        number: index + 1,
      };
    });
    setQuestionData(formattedQuestions);
  }, []);

  function handleAnswerChange(questionIndex, event) {
    const selectedAnswer = event.target.value;
    setQuestionData((prevQuestions) =>
      prevQuestions.map((question, index) => {
        if (index === questionIndex) {
          return {
            ...question,
            userAnswer: selectedAnswer,
            isCorrect: selectedAnswer === question.correct_answer,
          };
        } else {
          return question;
        }
      })
    );
  }

  return (
    
    <Card>
      <NavBar />
      <Card.Header as="h5" className="p-5">DC Generators Questions</Card.Header>
      <Card.Body>
        <ListGroup variant="flush">
          {questionData.map((question) => (
            <ListGroup.Item key={question.number}>
              <div className="d-flex align-items-start">
                <div className="mr-3">{question.number}.</div>
                <div>
                  <h6>{question.question}</h6>
                  <Form>
                    {shuffleArray([
                      ...question.incorrect_answers,
                      question.correct_answer,
                    ]).map((answer, i) => (
                      <Form.Check
                        key={i}
                        type="radio"
                        name={`question-${question.number}`}
                        label={answer}
                        value={answer}
                        checked={answer === question.userAnswer}
                        onChange={(event) =>
                          handleAnswerChange(question.number - 1, event)
                        }
                        className={
                          question.isCorrect
                            ? "text-success"
                            : answer === question.userAnswer
                            ? "text-danger"
                            : ""
                        }
                      />
                    ))}
                  </Form>
                  {question.userAnswer && (
                    <p className="mt-3">
                      Your answer:{" "}
                      <span
                        className={
                          question.isCorrect ? "text-success" : "text-danger"
                        }
                      >
                        {question.userAnswer}{" "}
                        {question.isCorrect ? "(Correct)" : "(Incorrect)"}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
      <Footer />
    </Card>
  );
}

function shuffleArray(array) {
  // Fisher-Yates shuffle algorithm
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default DCGenerators;
