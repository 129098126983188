import React from 'react';

const JavaScriptAndHTMLEvents = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  
  return (
    <div>
      <h2>JavaScript and HTML Events</h2>
      <p>JavaScript allows you to interact with HTML elements and respond to various events. Here are some common event examples:</p>

      <h3>Button Click Event</h3>
      <p>Handle a button click event:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`<button id="myButton">Click me</button>
<script>
  document.getElementById('myButton').addEventListener('click', () => {
    alert('Button clicked!');
  });
</script>`}
        </code>
      </pre>

      <h3>Mouse Over Event</h3>
      <p>Handle a mouse over event:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`<div id="myElement">Mouse over me</div>
<script>
  document.getElementById('myElement').addEventListener('mouseover', () => {
    console.log('Mouse over the element.');
  });
</script>`}
        </code>
      </pre>

      <h3>Key Press Event</h3>
      <p>Respond to a key press event:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`<input id="myInput" type="text">
<script>
  document.getElementById('myInput').addEventListener('keydown', (event) => {
    console.log('Key pressed:', event.key);
  });
</script>`}
        </code>
      </pre>

      <h3>Form Submission Event</h3>
      <p>Handle a form submission event:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`<form id="myForm">
  <input type="text" name="username">
  <input type="password" name="password">
  <button type="submit">Submit</button>
</form>
<script>
  document.getElementById('myForm').addEventListener('submit', (event) => {
    event.preventDefault();
    console.log('Form submitted');
  });
</script>`}
        </code>
      </pre>

      <h3>Event Handling in React</h3>
      <p>In a React component, you can handle events like this:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`<button onClick={handleButtonClick}>Click me</button>
<div onMouseOver={handleMouseOver}>Mouse over me</div>`}
        </code>
      </pre>

      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent(`<button onClick={handleButtonClick}>Click me</button>
<div onMouseOver={handleMouseOver}>Mouse over me</div>`), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptAndHTMLEvents;
