import React from "react";

const CppEncapsulation = () => {
  return (
    <div>
      <h2>C++ Encapsulation</h2>
      <p>
        Encapsulation is one of the fundamental concepts in object-oriented
        programming (OOP). It refers to the bundling of data and methods that
        operate on the data into a single unit, known as a class. The data is
        kept private within the class, and access to it is controlled through
        public methods. This concept helps to achieve data hiding and restrict
        unauthorized access to the class members.
      </p>

      <h3>Code Examples:</h3>

      <h3>Private Data Members</h3>
      <p>
        In C++, data members within a class can be marked as private, which
        means they are accessible only within the class. Here&apos;s an example:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

class Circle {
private:
    double radius;

public:
    void setRadius(double r) {
        if (r >= 0) {
            radius = r;
        }
    }

    double getRadius() {
        return radius;
    }
};

int main() {
    Circle myCircle;
    myCircle.setRadius(5.0);

    // Attempting to access a private member directly will result in an error.
    // cout << myCircle.radius; // Error: 'double Circle::radius' is private

    // We can use public methods to access and modify the private data.
    cout << "Radius: " << myCircle.getRadius() << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

class Circle {
private:
    double radius;

public:
    void setRadius(double r) {
        if (r >= 0) {
            radius = r;
        }
    }

    double getRadius() {
        return radius;
    }
};

int main() {
    Circle myCircle;
    myCircle.setRadius(5.0);

    // Attempting to access a private member directly will result in an error.
    // cout << myCircle.radius; // Error: 'double Circle::radius' is private

    // We can use public methods to access and modify the private data.
    cout << "Radius: " << myCircle.getRadius() << endl;

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Public Access Methods</h3>
      <p>
        Encapsulation allows you to control access to the private data members
        through public methods. This provides a level of abstraction and allows
        you to validate and manipulate the data. Here&apos;s an example:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

class Employee {
private:
    int salary;

public:
    void setSalary(int s) {
        if (s >= 0) {
            salary = s;
        }
    }

    int getSalary() {
        return salary;
    }
};

int main() {
    Employee emp;
    emp.setSalary(50000);

    // Attempting to access salary directly will result in an error.
    // cout << emp.salary; // Error: 'int Employee::salary' is private

    // We can use public methods to access and modify the private data.
    cout << "Salary: $" << emp.getSalary() << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

class Employee {
private:
    int salary;

public:
    void setSalary(int s) {
        if (s >= 0) {
            salary = s;
        }
    }

    int getSalary() {
        return salary;
    }
};

int main() {
    Employee emp;
    emp.setSalary(50000);

    // Attempting to access salary directly will result in an error.
    // cout << emp.salary; // Error: 'int Employee::salary' is private

    // We can use public methods to access and modify the private data.
    cout << "Salary: $" << emp.getSalary() << endl;

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppEncapsulation;
