import React from "react";
import Footer from "../../Footer";
import NavBar from "../../Navbar";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

function Induction() {
  return (
    <div>
      <NavBar />
      <div className="container-fluid mt-5 mb-5 p-3">
        <h6>Electromagnetic Induction And Faraday&apos;s Law </h6>
        <div>
          <ol>
            {/* Q1 */}
            <li>
              <p>
                What would be the advantage, in Faraday’s experiments, of using
                coils with many turns?
              </p>

              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              In Faraday&apos;s experiments, using a large number of
                              turns (N = large number) has the advantage that
                              the induced current and emf are proportional to N,
                              making it simpler to experimentally measure those
                              quantities.
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            <br />
            {/* Q2 */}
            <li>
              <p>
                Suppose you are holding a circular ring of wire in front of you
                and (a) suddenly thrust a magnet, south pole first, away from
                you toward the center of the circle. Is a current induced in the
                wire? (b) Is a current induced when the magnet is held steady
                within the ring? (c) Is a current induced when you withdraw the
                magnet? For each yes answer, specify the direction. Explain your
                answers.
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              Yes, when you move the south pole toward the ring,
                              a current is induced in the ring. Due to the
                              shifting magnetic flux (more magnetic field lines
                              pass through the ring as the magnet approaches
                              it), an emf and current are induced in the ring.
                              No, when the magnet is kept stationary inside the
                              ring, no current is induced. Since the magnetic
                              flux through the ring is constant while the magnet
                              is held stationary, no emf or current are induced
                              in the ring.
                              <br />
                              Yes, when you remove the magnet, a current is
                              induced in the ring. As you pull the magnet out of
                              the ring toward you, fewer magnetic field lines
                              are passing through the ring, inducing an emf and
                              current in the ring. from you through the loop
                              that points outward. This opposing current will be
                              generated by a clockwise current. magnetic sphere.
                              the Right Hand Rule and Lenz&apos;s law once more, the
                              direction of the induced When you remove the south
                              pole from the ring, the direction of current is
                              counterclockwise. Specifically, the There are
                              fewer magnetic field lines that are coming through
                              the loop and pointing in your direction.
                              <br />
                              The direction of the induced current when you move
                              the south pole toward the ring is clockwise
                              according to Lenz&apos;s law and the Right Hand Rule.
                              Specifically, the There are more magnetic field
                              lines entering the loop and pointing in your
                              direction. (Keep in mind that magnetic field lines
                              point in the direction of the magnet&apos;s south
                              pole.) Induced current in the loop will attempt to
                              counteract this change in flux and create More
                              magnetic field lines that are oriented toward you
                              pass through the loop. an induced counterclockwise
                              This magnetic field will be opposite because of
                              current. The resultant current in The loop will
                              attempt to generate magnetic field lines to
                              counteract this change in flux.
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            <br />
            {/* Q3 */}
            <li>
              <p>
                Suppose you are looking along a line through the centers of two
                circular (but separate) wire loops, one behind the other. A
                battery is suddenly connected to the front loop, establishing a
                clockwise current. <br />
                (a) Will a current be induced in the second loop? <br />
                (b) If so, when does this current start? <br />
                (c) When does it stop? <br />
                (d) In what direction is this current? <br />
                (e) Is there a force between the two loops? <br />
                (f) If so, in what direction?
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              (a) Yes. An increasing magnetic field that points
                              away from you and downward through the two loops
                              will be produced as soon as the battery is
                              connected to the front loop and current begins to
                              flow. The second loop will attempt to counteract
                              this rise in magnetic flux passing through it,
                              inducing an emf and current in the process.
                              <br />
                              (b) As soon as the front loop&apos;s current begins to
                              increase and generate a magnetic field (basically,
                              as soon as the battery is connected to the front
                              loop), the induced current in the second loop
                              begins to flow.
                              <br />
                              (c) As soon as the front loop&apos;s current
                              stabilizes, the second loop&apos;s current ceases to
                              flow. The magnetic field that the battery
                              generates is steady once the current in the front
                              loop has increased from zero to its steady-state
                              value. The induced current decreases to zero
                              because the magnetic flux through the second loop
                              is no longer fluctuating.
                              <br />
                              (d) The second loop&apos;s induced current runs
                              counterclockwise. Lenz&apos;s law predicts that the
                              second loop will attempt to counteract the change
                              in flux caused by the increasing clockwise current
                              in the front loop by increasing the number of
                              magnetic field lines that pass through it. The
                              Right Hand Rule predicts that a counterclockwise
                              current will be induced in the second loop to
                              counteract this change.
                              <br />
                              (e) Yes. Due to the fact that both loops produce
                              magnetic fields and carry currents, a force will
                              be exerted on each loop as the front loop&apos;s
                              current increases due to the battery.
                              <br />
                              (f) The two loops will repel one another due to
                              the force present. A magnetic field directed at
                              the second loop is being produced by the front
                              loop. In order to counteract the growing magnetic
                              field, this shifting magnetic field induces a
                              current in the second loop. This induced current
                              then produces a magnetic field that points in the
                              direction of the front loop. As if two north poles
                              were pointing in opposite directions, these two
                              magnetic fields would repel one another.
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            <br />
            {/* Q4 */}
            <li>
              <p>
                In the figure below, determine the direction of the induced
                current in resistor (a) when coil B is moved toward coil A, (b)
                when coil B is moved away from A, (c) when the resistance is
                increased but the coils remain fixed. Explain your answers.
                <br />
                <img
                  src={`${process.env.PUBLIC_URL}/img/Iq4.png`}
                  alt="Induction"
                  className="protect-image"
                  style={{ width: "40%" }}
                />
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              (a) As coil B is moved toward coil A, the induced
                              current in RA is to the right. There are more
                              magnetic field lines pointing to the left in coil
                              A as B gets closer to A, which causes the magnetic
                              flux through coil A to increase. When Coil A tries
                              to counteract this increase in flux, the induced
                              emf induces a current that generates a magnetic
                              field that points to the right through the coil&apos;s
                              center. This opposing field is created by a
                              right-directed current flowing through R
                              <sub>A</sub>.<br />
                              (b) As coil B is moved away from coil A, the
                              induced current in R<sub>A</sub> shifts to the
                              left. The magnetic flux through coil A decreases
                              as B moves away from coil A (there are now fewer
                              magnetic field lines in coil A pointing to the
                              left). A magnetic field pointing to the left is
                              produced through the coil&apos;s center as Coil A makes
                              an effort to counteract the decrease in flux. This
                              opposing field is created when a current flows
                              through RA and to the left.
                              <br />
                              (c) As the R<sub>B</sub> in coil B is increased,
                              the induced current in R<sub>A</sub> shifts to the
                              left. Coil B&apos;s current decreases as RB rises,
                              which also lowers the magnetic field it generates.
                              There are now fewer magnetic field lines in coil A
                              pointing to the left as the magnetic flux through
                              coil A decreases along with the magnetic field
                              from coil B. A magnetic field pointing to the left
                              is produced through the coil&apos;s center as Coil A
                              makes an effort to counteract the decrease in
                              flux. This opposing field is created when a
                              current flows through R<sub>A</sub> and to the
                              left.
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            {/* Q5 */}
            <li>
              <p>
                In situations where a small signal must travel over a distance,
                a shielded cable is used in which the signal wire is surrounded
                by an insulator and then enclosed by a cylindrical conductor
                (shield) carrying the return current. Why is a “shield”
                necessary?
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              Magnetic fields that emanate from the wire change
                              as the signal in it changes over time. The
                              magnetic fields of the signal wire may interact
                              with nearby external magnetic fields, causing
                              interference or noise in the signal. The signal
                              wire would not emit a magnetic field if the
                              &quot;shield&quot; surrounding it were in place because, to
                              the outside world, the net current in the wire
                              carrying the return current would be zero. As a
                              result, there would be no signal field for the
                              external magnetic fields to interact with, which
                              would lessen signal interference and noise.
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            {/* Q6 */}
            <li>
              <p>
                What is the advantage of placing the two insulated electric
                wires carrying ac close together or even twisted about each
                other?
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              One benefit of placing the two insulated wires
                              carrying ac close together is that any magnetic
                              fields produced by the current changing direction
                              while moving in one wire are roughly cancelled out
                              by the magnetic field produced by the current
                              changing direction while moving in the other wire.
                              Additionally, since large loops of wire in a
                              circuit can produce a significant amount of
                              self-induced back emf, the effective area of the
                              current loop is reduced and the induced current is
                              minimized by placing the two wires close to one
                              another or even twisting them around one another.
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            {/* Q7 */}
            <li>
              <p>
                Explain why, exactly, the lights may dim briefly when a
                refrigerator motor starts up. When an electric heater is turned
                on, the lights may stay dimmed as long as the heater is on.
                Explain the difference.
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              A significant amount of current can flow to the
                              refrigerator when the refrigerator motor first
                              starts up due to a small back emf in the circuit
                              (back emf is proportional to the motor&apos;s rotation
                              speed). This substantial current overwhelms the
                              source and reduces current to all the circuit&apos;s
                              components, including the lights. The back emf
                              rises to its normal level as the refrigerator
                              motor accelerates to its typical operational
                              speed, and the current delivered to the
                              refrigerator is now constrained to its typical
                              level.
                              <br />
                              All of the devices in the room return to normal
                              operation because the current is no longer
                              sufficient to overload the source. As a result, it
                              appears that the lights begin to flicker right
                              before the refrigerator motor turns on. A heater,
                              on the other hand, constantly draws a significant
                              amount of current because of its extremely low
                              resistance. As a result, the source is
                              continuously exhausted, and the other appliances
                              on the same circuit continue to be dimly lit while
                              the heater is running. In an The source would
                              ideally be able to supply any amount of current to
                              the entire circuit in either situation. However,
                              in practice, larger energy losses along the wires
                              result from the wires&apos; higher currents. the
                              gadgets and the lights go out.
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            {/* Q8 */}
            <li>
              <p>
                It has been proposed that eddy currents be used to help sort
                solid waste for recycling. The waste is first ground into tiny
                pieces and iron removed with a magnet. The waste then is allowed
                to slide down an incline over permanent magnets. How will this
                aid in the separation of nonferrous metals (Al, Cu, Pb, brass)
                from nonmetallic materials?
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              The &quot;free&quot; conduction electrons in the conducting
                              material pieces are compelled to produce eddy
                              currents as the pieces slide down the incline past
                              permanent magnets (the Right Hand Rule states that
                              moving charges in a magnetic field will experience
                              a magnetic force, making them move, and producing
                              an eddy current). The non-metallic pieces do not
                              produce these eddy currents. Additionally, these
                              eddy currents are moving through the Right and
                              permanent magnets&apos; magnetic fields. According to
                              the Hand Rule, these currents will encounter a
                              magnetic force that opposes the initial motion of
                              the metal pieces.
                              <br />
                              As a result, as the metallic pieces descend the
                              incline, they will slow down, while the
                              Non-metallic pieces will keep moving faster
                              throughout. The metallic pieces having been
                              removed slowed, they will essentially just drop
                              off the incline, so you would place a &quot;metal bin&quot;
                              there. to catch the metal pieces, immediately
                              below the end. Contrarily, the non-metallic
                              components will come off the end of the incline
                              quickly, miss the &quot;metal bin,&quot; and get stuck in a
                              a &quot;non-metal bin&quot; that is located further from the
                              end.
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            {/* Q9 */}
            <li>
              <p>
                The pivoted metal bar with slots in the figure below falls much
                more quickly through a magnetic field than does a solid bar.
                Explain.
                <br />
                <img
                  src={`${process.env.PUBLIC_URL}/img/Iq9.png`}
                  alt="figure 43"
                  className="protect-image"
                  style={{ width: "40%" }}
                />
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              Instead of just one large loop, the induced eddy
                              currents are constrained to a number of tiny loops
                              by the slots cut into the pivoting metal bar. Due
                              to their smaller areas, these smaller current
                              loops have less magnetic flux, which results in
                              lower emf and smaller eddy currents. The motion of
                              the metal bar is then opposed by a smaller
                              opposing force for the smaller eddy currents. As a
                              result, the slotted bar passes through the
                              magnetic field more quickly.
                            </p>
                            <br />
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            {/* Q10 */}
            <li>
              <p>
                If an aluminum sheet is held between the poles of a large bar
                magnet, it requires some force to pull it out of the magnetic
                field even though the sheet is not ferromagnetic and does not
                touch the pole faces. Explain.
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              Areas of the aluminum sheet that were previously
                              in B = 0 regions start to gain magnetic flux as
                              you attempt to move the sheet out of the magnetic
                              field. In order to counter the change, this
                              shifting flux will cause currents to flow through
                              the aluminum&apos;s &quot;free&quot; conduction electrons. The
                              magnetic field then interacts with these eddy
                              currents, creating a force that opposes the motion
                              of the aluminum sheet. As a result, removing the
                              sheet from in-between the poles requires some
                              force.
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
          </ol>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Induction;
