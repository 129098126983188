import React from 'react';

const BasicPythonSyntax = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div >
      <h2 >Basic Python Syntax</h2>
      <div >
        <div >
          <p>Python&apos;s syntax is known for its simplicity and readability. Let&apos;s explore some essential elements:</p>

          <h3>Print Statements</h3>
          <p>The <code>print()</code> function is used to display output in Python. Here&apos;s an example:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`# Displaying a message
print("Hello, Python!")`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("# Displaying a message\nprint('Hello, Python!')"), '_blank')}>Run Code</button>

          <h3>Variables</h3>
          <p>Variables are used to store data. Python is dynamically typed, meaning you don&apos;t need to declare a variable&apos;s data type. For example:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`# Creating variables
name = "Alice"
age = 30`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("# Creating variables\nname = 'Alice'\nage = 30"), '_blank')}>Run Code</button>

          <h3>Indentation</h3>
          <p>Indentation refers to the spaces at the beginning of a code line.</p>
          <p>Python uses indentation to define blocks of code. It&apos;s crucial for loops, conditionals, and functions. For example:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`# Using indentation to define a function
def greet(name):
    print("Hello, " + name)

# Calling the function
greet("Bob")`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("# Using indentation to define a function\ndef greet(name):\n    print('Hello, ' + name)\n\n# Calling the function\ngreet('Bob')"), '_blank')}>Run Code</button>

          <h3>Comments</h3>
          <p>Comments are used to explain code. In Python, you can use the <code>#</code> symbol to write comments. For example:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`# This is a comment
print("This is not a comment")`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("# This is a comment\nprint('This is not a comment')"), '_blank')}>Run Code</button>

          <h3>Basic Operators</h3>
          <p>Python supports various operators for arithmetic, comparison, and logical operations. For instance:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`# Arithmetic operators
x = 10
y = 5
addition = x + y
subtraction = x - y

# Comparison operators
is_equal = x == y
is_greater = x > y

# Logical operators
and_result = True and False
or_result = True or False`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("# Arithmetic operators\nx = 10\ny = 5\naddition = x + y\nsubtraction = x - y\n\n# Comparison operators\nis_equal = x == y\nis_greater = x > y\n\n# Logical operators\nand_result = True and False\nor_result = True or False"), '_blank')}>Run Code</button>

          <h3>Conditional Statements</h3>
          <p>Python uses <code>if</code>, <code>elif</code>, and <code>else</code> for conditional execution. For example:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`# Conditional statement
age = 25
if age < 18:
    print("You are a minor.")
elif age >= 18:
    print("You are an adult.")
else:
    print("Age is not specified.")`}
            </code>
          </pre>
          <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("# Conditional statement\nage = 25\nif age < 18:\n    print('You are a minor.')\nelif age >= 18:\n    print('You are an adult.')\nelse:\n    print('Age is not specified.')"), '_blank')}>Run Code</button>
        </div>
      </div>
    </div>
  );
}

export default BasicPythonSyntax;
