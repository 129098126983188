import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Container } from 'react-bootstrap';
import { API_KEY } from '../config';

const Article = () => {
  const { title } = useParams();
  const [article, setArticle] = useState({});

  useEffect(() => {
    const fetchArticle = async () => {
      const res = await axios.get(`https://newsapi.org/v2/everything?q=${title}&apiKey=${API_KEY}`);
      setArticle(res.data.articles[0]);
    };
    fetchArticle();
  }, [title]);

  return (
    <Container>
      <h1 className='my-4'>{article.title}</h1>
      <img className='img-fluid mb-4' src={article.urlToImage} alt={article.title} />
      <p>{article.content}</p>
      <a className='text-decoration-none' href={article.url} target="_blank" rel="noreferrer">
        Read Full Article
      </a>
    </Container>
  );
};

export default Article;
