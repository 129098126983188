import React from 'react';

const JavaScriptSets = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Sets</h2>
      <p>A Set is a built-in object in JavaScript that allows you to store unique values. Here are some common operations and use cases:</p>

      <h3>Creating a Set</h3>
      <p>You can create a new Set by using the `Set` constructor:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = new Set(['apple', 'banana', 'cherry']);
console.log(fruits);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = new Set(['apple', 'banana', 'cherry']);\nconsole.log(fruits);"), '_blank')}>Run Code</button>

      <h3>Adding and Deleting Elements</h3>
      <p>You can add elements to a Set using the `add` method and delete elements using the `delete` method:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const colors = new Set();
colors.add('red');
colors.add('green');
colors.add('blue');
console.log(colors);

colors.delete('green');
console.log(colors);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const colors = new Set();\ncolors.add('red');\ncolors.add('green');\ncolors.add('blue');\nconsole.log(colors);\n\ncolors.delete('green');\nconsole.log(colors);"), '_blank')}>Run Code</button>

      <h3>Checking Element Existence</h3>
      <p>You can check if an element exists in a Set using the `has` method:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const shapes = new Set(['circle', 'square', 'triangle']);
console.log(shapes.has('circle'));
console.log(shapes.has('rectangle'));`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const shapes = new Set(['circle', 'square', 'triangle']);\nconsole.log(shapes.has('circle'));\nconsole.log(shapes.has('rectangle'));"), '_blank')}>Run Code</button>

      <h3>Iterating Over a Set</h3>
      <p>You can loop through the elements in a Set using the `forEach` method or a `for...of` loop:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const planets = new Set(['Earth', 'Mars', 'Jupiter']);
planets.forEach(planet => console.log(planet));

for (const planet of planets) {
  console.log(planet);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const planets = new Set(['Earth', 'Mars', 'Jupiter']);\nplanets.forEach(planet => console.log(planet));\n\nfor (const planet of planets) {\n  console.log(planet);\n}"), '_blank')}>Run Code</button>

      <h3>Set Size and Clearing</h3>
      <p>You can get the number of elements in a Set using the `size` property and clear all elements using the `clear` method:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numbers = new Set([1, 2, 3, 4, 5]);
console.log(numbers.size);

numbers.clear();
console.log(numbers.size);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numbers = new Set([1, 2, 3, 4, 5]);\nconsole.log(numbers.size);\n\nnumbers.clear();\nconsole.log(numbers.size);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptSets;
