import React from 'react';

const JavaScriptArrayIteration = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Array Iteration</h2>
      <p>JavaScript provides various methods for iterating through arrays. Let&apos;s explore some common array iteration techniques:</p>

      <h3>For Loop</h3>
      <p>Use a for loop to iterate through an array:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
for (let i = 0; i < fruits.length; i++) {
  console.log(fruits[i]);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nfor (let i = 0; i < fruits.length; i++) {\n  console.log(fruits[i]);\n}"), '_blank')}>Run Code</button>

      <h3>For...of Loop</h3>
      <p>Use a for...of loop to iterate through an array&apos;s elements:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
for (const fruit of fruits) {
  console.log(fruit);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nfor (const fruit of fruits) {\n  console.log(fruit);\n}"), '_blank')}>Run Code</button>

      <h3>Array.forEach()</h3>
      <p>Use the forEach method to iterate through an array:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
fruits.forEach(function (fruit) {
  console.log(fruit);
});`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nfruits.forEach(function (fruit) {\n  console.log(fruit);\n});"), '_blank')}>Run Code</button>

      <h3>Array.map()</h3>
      <p>Use the map method to create a new array based on an existing one:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numbers = [1, 2, 3, 4];
const squaredNumbers = numbers.map(function (num) {
  return num * num;
});
console.log(squaredNumbers);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numbers = [1, 2, 3, 4];\nconst squaredNumbers = numbers.map(function (num) {\n  return num * num;\n});\nconsole.log(squaredNumbers);"), '_blank')}>Run Code</button>

      <h3>Array.filter()</h3>
      <p>Use the filter method to create a new array with elements that meet a certain condition:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numbers = [1, 2, 3, 4, 5];
const evenNumbers = numbers.filter(function (num) {
  return num % 2 === 0;
});
console.log(evenNumbers);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numbers = [1, 2, 3, 4, 5];\nconst evenNumbers = numbers.filter(function (num) {\n  return num % 2 === 0;\n});\nconsole.log(evenNumbers);"), '_blank')}>Run Code</button>

      <h3>Array.reduce()</h3>
      <p>Use the reduce method to accumulate a single value from an array:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numbers = [1, 2, 3, 4, 5];
const sum = numbers.reduce(function (total, num) {
  return total + num;
}, 0);
console.log(sum);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numbers = [1, 2, 3, 4, 5];\nconst sum = numbers.reduce(function (total, num) {\n  return total + num;\n}, 0);\nconsole.log(sum);"), '_blank')}>Run Code</button>

      {/* Additional array iteration methods can be added here. */}
    </div>
  );
}

export default JavaScriptArrayIteration;
