import React from 'react';

const JavaScriptLet = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>The JavaScript `let` Keyword</h2>
      <p>The `let` keyword is used to declare variables in JavaScript. It has block-level scope, which means it is limited to the block, statement, or expression where it is defined. Here are some examples of how `let` works:</p>

      <h3>Block-Level Scope</h3>
      <p>Variables declared with `let` are confined to the block where they are defined. They are not accessible outside of that block:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`if (true) {
  let x = 10;
}
console.log(x); // Error: x is not defined`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("if (true) {\n  let x = 10;\n}\nconsole.log(x); // Error: x is not defined"), '_blank')}>Run Code</button>

      <h3>Reassignment</h3>
      <p>Variables declared with `let` can be reassigned within the same block or scope:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let y = 5;
y = 7;
console.log(y); // 7`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("let y = 5;\ny = 7;\nconsole.log(y); // 7"), '_blank')}>Run Code</button>

      <h3>Temporal Dead Zone</h3>
      <p>Variables declared with `let` are not initialized until they are declared. Accessing a `let` variable before its declaration results in a &quot;Temporal Dead Zone&quot; error:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`console.log(z); // Error: Cannot access 'z' before initialization
let z = 3;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("console.log(z); // Error: Cannot access 'z' before initialization\nlet z = 3;"), '_blank')}>Run Code</button>

      <h3>Let in Loops</h3>
      <p>Using `let` in loops creates a separate variable for each iteration, preventing issues with closures:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`for (let i = 0; i < 3; i++) {
  setTimeout(function() {
    console.log(i);
  }, 1000);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("for (let i = 0; i < 3; i++) {\n  setTimeout(function() {\n    console.log(i);\n  }, 1000);\n}"), '_blank')}>Run Code</button>

      <h3>Let in Functions</h3>
      <p>Using `let` inside functions creates variables with function-level scope:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`function example() {
  let localVar = 'I am local!';
  console.log(localVar);
}

example();
console.log(localVar); // Error: localVar is not defined`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("function example() {\n  let localVar = 'I am local!';\n  console.log(localVar);\n}\n\nexample();\nconsole.log(localVar); // Error: localVar is not defined"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptLet;
