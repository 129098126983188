import React from 'react';

const PythonRegularExpression = () => {
  return (
    <div>
      <h2>Python Regular Expressions</h2>
      <p>Regular expressions (regex or regexp) are a powerful tool for pattern matching and searching in text. Python provides the <code>re</code> module for working with regular expressions. Let&apos;s explore some common use cases.</p>

      <h3>Importing the re Module</h3>
      <p>First, you need to import the <code>re</code> module:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import re`}
        </code>
      </pre>

      <h3>Basic Pattern Matching</h3>
      <p>You can use regular expressions to search for patterns in text. For example, to find all occurrences of the word &quot;apple&quot; in a text:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import re

text = "I have an apple and my friend has an apple too."
pattern = "apple"
matches = re.findall(pattern, text)
print(matches)`}
        </code>
      </pre>

      <h3>Using Metacharacters</h3>
      <p>Metacharacters are special characters that have a unique meaning in regular expressions. For example, the dot (.) matches any character except a newline:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import re

text = "cat, bat, hat, rat"
pattern = ".at"
matches = re.findall(pattern, text)
print(matches)`}
        </code>
      </pre>

      <h3>Character Classes</h3>
      <p>You can use character classes to match specific characters. For example, to match all vowels in a string:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import re

text = "Hello, how are you?"
pattern = "[aeiou]"
matches = re.findall(pattern, text)
print(matches)`}
        </code>
      </pre>

      <h3>Quantifiers</h3>
      <p>Quantifiers specify how many times a character or group must occur. For example, to match repeating characters:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import re

text = "Hellooo there"
pattern = "o{2,3}"
matches = re.findall(pattern, text)
print(matches)`}
        </code>
      </pre>

      <h3>Groups</h3>
      <p>Groups are sub-patterns enclosed in parentheses. You can use them for capturing and grouping parts of the text:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import re

text = "My phone number is 123-456-7890."
pattern = r"(/d{3})-(/d{3})-(/d{4})"
matches = re.findall(pattern, text)
print(matches)`}
        </code>
      </pre>

      <h3>Search and Replace</h3>
      <p>You can use regular expressions for searching and replacing text. For example, to hide sensitive information:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import re

text = "My email is john.doe@email.com."
pattern = r"(/w+/./w+)@(/w+/./w+)"
replacement = r"*****@*****"
new_text = re.sub(pattern, replacement, text)
print(new_text)`}
        </code>
      </pre>
    </div>
  );
}

export default PythonRegularExpression;
