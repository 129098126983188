import React from "react";
import Footer from "../components/Footer";
import PagesNavBar from "../components/PagesNavBar";
import ScrollIntoView from "../components/ScrollIntoView";
import ComputerAndSoftwareEngineering from "../pdf/Computing handbook _ computer science and software engineering.pdf";
import ComputerTrends from "../pdf/Computer Science, Engineering and Information Technology.pdf"
import EngineeringComputation from "../pdf/Quantum Computing_ A Gentle Introduction (Scientific and Engineering Computation).pdf"
import AdvancedComputer from "../pdf/Advanced Computer and Communication Engineering Technology_ Proceedings of the 1st International Conference on Communication and Computer Engineering .pdf"
import DataCommunications from "../pdf/Data Communications and Computer Networks_ For Computer Scientists and Engineers .pdf"
import HowToHackComputers from "../pdf/How to Hack Computers_ how to hack computers, hacking for beginners, penetration testing, hacking for dummies, computer security, computer hacking, hacking techniques, network scanning.pdf"
import UltimateHacking from "../pdf/Hacking_ The Ultimate Hacking for Beginners_ How to Hack_ Hacking Intelligence_Certified Hacking Book.pdf"
import HigherEngineering from "../pdf/Higher Engineering Mathematics.pdf"

class ComputerEngineering extends React.Component {
  openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };
  closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  render() {
    return (
      <ScrollIntoView>
        <div>
          <section>
            <div id="mySidenav" className="sidenav">
              <a
                href
                className="closebtn"
                style={{ cursor: "pointer" }}
                onClick={this.closeNav}
              >
                &times;
              </a>
              <a href={ ComputerAndSoftwareEngineering} target="_blank" rel="noopener noreferrer">
              Computer Science and Software Engineering
              </a>

              <a href={ ComputerTrends} target="_blank" rel="noopener noreferrer">
              Computer Science, Engineering and Information Technology
              </a>

              <a href={ EngineeringComputation} target="_blank" rel="noopener noreferrer">
              Quantum Computing A Gentle Introduction (Scientific and Engineering Computation)
              </a>
              <a href={ AdvancedComputer} target="_blank" rel="noopener noreferrer">
              Advanced Computer and Communication Engineering Technology
              </a>
              <a href={DataCommunications} target="_blank" rel="noopener noreferrer">
              Data Communications and Computer Networks
              </a>

              <a href={HowToHackComputers} target="_blank" rel="noopener noreferrer">
              How to Hack Computers
              </a>

              <a href={UltimateHacking} target="_blank" rel="noopener noreferrer">
              The Ultimate Hacking for Beginners
              </a>
              <a href={HigherEngineering} target="_blank" rel="noopener noreferrer">
              Higher Engineering Mathematics
              </a>
            </div>
          </section>
          {/* top navbar */}
          <nav className="navbar navbar-expand-lg navbar-dark  navBarTop ">
            <div className="container-fluid opacity-75">
              <span
                style={{ fontSize: "20px", cursor: "pointer", color: "white" }}
                onClick={this.openNav}
              >
                &#9776; Topics
              </span>
            </div>
          </nav>

          <PagesNavBar />
          {/* Physics Content */}
          <section>
            {/* short words */}
            <section className="container-fluid">
              <hr />
              <div>
                <h5 style={{ color: "red", fontWeight: "15px" }}>
                  What is Computer Engineering
                </h5>
                <p>
                  The combination of computer science and electronic engineering
                  is referred to as computer engineering. Computer engineers
                  build and create many of the software applications and
                  technology tools that we use on a daily basis at work, school,
                  and in our personal life by merging various disciplines.
                </p>
              </div>
            </section>

            <section className="container-fluid">
              {/* Development of computer science  */}
              <div>
                <h5 style={{ color: "red", fontWeight: "15px" }}>
                  Overview of computer engineering
                </h5>
                <p>
                  The purpose of computer engineering is to increase the
                  functionality and usefulness of computers. Computer engineers
                  might, for instance, strive to create devices that process
                  information more quickly than prior iterations; they might
                  also aim to reduce the size and weight of computers; develop
                  new features for computer technology; or try to improve
                  accessibility. A review of computer science and electronic
                  engineering is necessary to define computer engineering.
                  Indeed, the fusion of these two fields is frequently described
                  as computer engineering.
                  <ol>
                    <li>
                      Computer science is the study of computers and
                      computational systems, as well as the use of cutting-edge
                      methods to address typical computing issues.
                    </li>
                    <li>
                      Designing and creating the circuits, circuit boards, and
                      other electronic components necessary for computers to
                      operate is known as electronic engineering.
                    </li>
                  </ol>
                </p>
              </div>
            </section>
            <div>
          {/* Ads */}
          <iframe
          id="iframe_54"
          className="container"
          src="//a.impactradius-go.com/gen-ad-code/3874559/1446104/11346/"
          title="coinsmart"
          width="971"
          height="91"
          scrolling="no"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        ></iframe>
      </div>
            <section className="container-fluid">
              <div>
                <h5 style={{ color: "red", fontWeight: "15px" }}>
                  How to Get Ready for Computer Engineering
                </h5>
                <ol>
                  <li style={{ fontWeight: "bold" }}>
                    What Does Computer Engineering Mean to You?
                  </li>
                  <p>
                    Consider the following before deciding to continue your
                    education at a university: Are you doing it to earn a good
                    living? due to a childhood desire to create your very own
                    video game? Or awe at the beauty of bringing artificial
                    intelligence to life? Whatever Computer Engineering means to
                    you, it&apos;s important to hold onto it tightly because it will
                    give you the willpower you need when times are tough (when,
                    not if). Because Computer Engineering is a broad field, identify
                    your area of expertise and immerse yourself in it, whether
                    it be software engineering, computer security, or
                    algorithms. You&apos;ll see the big picture if you base your
                    studies on a primary interest, which will undoubtedly
                    increase your enthusiasm. Invest in books related to your
                    interest, watch YouTube channels, read news about it, etc.
                    They need not be technical, but they should give you a
                    better perspective and general understanding of the
                    discipline.
                  </p>
                  <li style={{ fontWeight: "bold" }}>
                    Develop Your Craft and Your Skill Set
                  </li>
                  <p>
                    Evidently, a computer scientist&apos;s substance comes from his
                    or her technical expertise. Being a high performer is
                    challenging for you because university courses move quickly
                    and some students may find it difficult to catch up.
                  </p>
                  <li style={{ fontWeight: "bold" }}>Become Resourceful</li>
                  <p>
                    Check out the less expensive e-book versions of popular
                    university textbooks if you&apos;re looking for even more extra
                    resources before you enroll in a real university. There are
                    a ton of resources and information available on the
                    Internet. Utilize them to your advantage! Programming
                    language fundamentals, such as operators, functions, and
                    libraries, are taught in online tutorials and manuals and
                    can be freely applied to your code and problem sets. You&apos;ll
                    also be expected to look these fundamentals up for reference
                    in college.
                  </p>
                  <li style={{ fontWeight: "bold" }}>Get to Know GitHub</li>
                  <p>
                    A platform for hosting open-source projects is called
                    GitHub. It is a method for developers to collaborate online
                    on a single project. Both in the workplace and in higher
                    education, GitHub is widely used. The ability to work on the
                    same code is a requirement for developers. It&apos;s important to
                    at least become familiar with how to navigate the GitHub
                    website, as some universities even use it to conduct their
                    courses.
                  </p>
                  <li style={{ fontWeight: "bold" }}>
                    Try Out Various Preparation Methods
                  </li>
                  <p>
                    Push yourself past your comfort zone. Learn what works and
                    what doesn&apos;t for you. Try out new and specialized methods of
                    preparation, such as designing websites for nonprofits as a
                    volunteer or producing YouTube videos to explain concepts.
                    Experimenting outside of your comfort zone allows you to
                    find opportunities that will help you achieve greater
                    heights. You will gain self-assurance so that when you
                    encounter new difficulties in college (such as a specific
                    programming error), you understand that it will take some
                    time for you to resolve them and continue to be motivated.
                  </p>
                  <li style={{ fontWeight: "bold" }}>
                    It&apos;s a lifestyle, not a competition.
                  </li>
                  <p>
                    The goal isn&apos;t to beat out everyone else to get first class
                    honors; take a moment to unwind. You find meaning in
                    Computer Engineering through the learning and degree-acquiring
                    processes; any accomplishments are a bonus. It&apos;s about the
                    relationships you build, the obstacles you overcome, and
                    your personal development. That is what inspires you to
                    pursue a career in Computer Engineering.
                  </p>
                </ol>
              </div>
            </section>
            <div>
          <iframe
            id="iframe_741"
            className="container text-center"
            src="//a.impactradius-go.com/gen-ad-code/3874559/1462105/13625/"
            width="728"
            height="90"
            title="booksio"
            scrolling="no"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          ></iframe>
        </div>
          </section>
          <br />
          <br />
          <Footer />
        </div>
      </ScrollIntoView>
    );
  }
}

export default ComputerEngineering;
