import React from 'react';

const JavaScriptDataTypes = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  

  return (
    <div>
      <h2>JavaScript Data Types</h2>
      <p>JavaScript has several data types to represent different kinds of values. Let&apos;s explore some of the common data types:</p>

      <h3>Numbers</h3>
      <p>JavaScript supports numeric values, including integers and floating-point numbers:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const integer = 42;
const float = 3.14;

customPrint(integer);
customPrint(float);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl)}>Run Code</button>
      <h3>Strings</h3>
      <p>Strings are used to represent text in JavaScript:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const greeting = 'Hello, JavaScript!';
const name = "Alice";

customPrint(greeting);
customPrint(name);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const greeting = 'Hello, JavaScript!';\nconst name = 'Alice';\ncustomPrint(greeting);\ncustomPrint(name);"), '_blank')}>Run Code</button>

      <h3>Booleans</h3>
      <p>Booleans represent true or false values:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const isTrue = true;
const isFalse = false;

customPrint(isTrue);
customPrint(isFalse);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const isTrue = true;\nconst isFalse = false;\ncustomPrint(isTrue);\ncustomPrint(isFalse);"), '_blank')}>Run Code</button>

      <h3>Arrays</h3>
      <p>Arrays are used to store collections of values:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numbers = [1, 2, 3, 4, 5];
const fruits = ['apple', 'banana', 'cherry'];

customPrint(numbers);
customPrint(fruits);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numbers = [1, 2, 3, 4, 5];\nconst fruits = ['apple', 'banana', 'cherry'];\ncustomPrint(numbers);\ncustomPrint(fruits);"), '_blank')}>Run Code</button>

      <h3>Objects</h3>
      <p>Objects are used to group related data and functions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const person = {
  name: 'Alice',
  age: 30,
  isStudent: false
};
const book = {
  title: 'JavaScript Programming',
  author: 'John Doe'
};

customPrint(person);
customPrint(book);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const person = {\n  name: 'Alice',\n  age: 30,\n  isStudent: false\n};\nconst book = {\n  title: 'JavaScript Programming',\n  author: 'John Doe'\n};\ncustomPrint(person);\ncustomPrint(book);"), '_blank')}>Run Code</button>

      <h3>Undefined and Null</h3>
      <p>Undefined and null are special values in JavaScript:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let variableUndefined;
const variableNull = null;

customPrint(variableUndefined);
customPrint(variableNull);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("let variableUndefined;\nconst variableNull = null;\ncustomPrint(variableUndefined);\ncustomPrint(variableNull);"), '_blank')}>Run Code</button>

      <h3>Functions</h3>
      <p>Functions are a data type in JavaScript and can be assigned to variables:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const greet = function(name) {
  return 'Hello, ' + name;
};
const add = (a, b) => a + b;

customPrint(greet('Alice'));
customPrint(add(5, 3));`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const greet = function(name) {\n  return 'Hello, ' + name;\n};\nconst add = (a, b) => a + b;\ncustomPrint(greet('Alice'));\ncustomPrint(add(5, 3));"), '_blank')}>Run Code</button>

      <h3>BigInt</h3>
      <p>JavaScript supports BigInt for working with large integers:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const bigIntValue = 1234567890123456789012345678901234567890n;

customPrint(bigIntValue);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const bigIntValue = 1234567890123456789012345678901234567890n;\ncustomPrint(bigIntValue);"), '_blank')}>Run Code</button>

      <h3>typeof Operator</h3>
      <p>The typeof operator is used to determine the data type of a value:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const exampleValue = 'Hello, JavaScript!';
const valueType = typeof exampleValue;

customPrint(valueType);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const exampleValue = 'Hello, JavaScript!';\nconst valueType = typeof exampleValue;\ncustomPrint(valueType);"), '_blank')}>Run Code</button>
    </div>
  );
};

export default JavaScriptDataTypes;
