import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import "./Navigation.css"
const Navigation = () => {
  return (
    <Navbar bg="dark" expand="lg" variant='dark' className='container-fluid '>
      <Navbar.Brand href="/news">ESURDE NEWS</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto ">
          <Nav.Link href="/news" className='news-nav'>Home</Nav.Link>
          <Nav.Link href="/news/latest" className='news-nav'>Latest</Nav.Link>
          <Nav.Link href="/news/science" className='news-nav'>Science</Nav.Link>
          <Nav.Link href="/news/technology" className='news-nav'>Technology</Nav.Link>
          <Nav.Link href="/news/health" className='news-nav'>Health</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
