import React from 'react';

const JavaScriptMath = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Math Operations</h2>
      <p>JavaScript provides a variety of math operations for working with numbers. Let&apos;s explore some of the common mathematical operations:</p>

      <h3>Basic Arithmetic</h3>
      <p>Perform basic arithmetic operations with numbers:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const num1 = 10;
const num2 = 5;
const sum = num1 + num2;
const difference = num1 - num2;
const product = num1 * num2;
const quotient = num1 / num2;
console.log(sum, difference, product, quotient);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const num1 = 10;\nconst num2 = 5;\nconst sum = num1 + num2;\nconst difference = num1 - num2;\nconst product = num1 * num2;\nconst quotient = num1 / num2;\nconsole.log(sum, difference, product, quotient);"), '_blank')}>Run Code</button>

      <h3>Rounding Numbers</h3>
      <p>Round numbers using functions like Math.round(), Math.ceil(), and Math.floor():</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const num = 5.6;
const rounded1 = Math.round(num);
const ceilValue = Math.ceil(num);
const floorValue = Math.floor(num);
console.log(rounded1, ceilValue, floorValue);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const num = 5.6;\nconst rounded1 = Math.round(num);\nconst ceilValue = Math.ceil(num);\nconst floorValue = Math.floor(num);\nconsole.log(rounded1, ceilValue, floorValue);"), '_blank')}>Run Code</button>

      <h3>Generating Random Numbers</h3>
      <p>Generate random numbers between a specified range:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const min = 1;
const max = 10;
const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
console.log(randomNum);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const min = 1;\nconst max = 10;\nconst randomNum = Math.floor(Math.random() * (max - min + 1)) + min;\nconsole.log(randomNum);"), '_blank')}>Run Code</button>

      <h3>Exponents and Square Roots</h3>
      <p>Calculate exponents and square roots using Math.pow() and Math.sqrt():</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const base = 2;
const exponent = 3;
const result = Math.pow(base, exponent);
const number = 25;
const squareRoot = Math.sqrt(number);
console.log(result, squareRoot);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const base = 2;\nconst exponent = 3;\nconst result = Math.pow(base, exponent);\nconst number = 25;\nconst squareRoot = Math.sqrt(number);\nconsole.log(result, squareRoot);"), '_blank')}>Run Code</button>

      <h3>Trigonometry</h3>
      <p>Perform trigonometric calculations using Math.sin(), Math.cos(), and Math.tan():</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const angle = 45;
const radians = (angle * Math.PI) / 180;
const sine = Math.sin(radians);
const cosine = Math.cos(radians);
const tangent = Math.tan(radians);
console.log(sine, cosine, tangent);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const angle = 45;\nconst radians = (angle * Math.PI) / 180;\nconst sine = Math.sin(radians);\nconst cosine = Math.cos(radians);\nconst tangent = Math.tan(radians);\nconsole.log(sine, cosine, tangent);"), '_blank')}>Run Code</button>

      <h3>Constants</h3>
      <p>Access mathematical constants like π and Euler&apos;s number using Math.PI and Math.E:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const pi = Math.PI;
const e = Math.E;
console.log(pi, e);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const pi = Math.PI;\nconst e = Math.E;\nconsole.log(pi, e);"), '_blank')}>Run Code</button>

      <h3>Math Methods</h3>
      <p>JavaScript&apos;s Math object provides various methods for performing mathematical calculations. Here are some examples:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const num = 4.5;

// Round up to the nearest integer
const roundedUp = Math.ceil(num);

// Round down to the nearest integer
const roundedDown = Math.floor(num);

// Get the absolute value
const absoluteValue = Math.abs(num);

// Calculate the square root
const squareRoot = Math.sqrt(num);

// Calculate the cube root
const cubeRoot = Math.cbrt(27);

console.log(roundedUp, roundedDown, absoluteValue, squareRoot, cubeRoot);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const num = 4.5;\nconst roundedUp = Math.ceil(num);\nconst roundedDown = Math.floor(num);\nconst absoluteValue = Math.abs(num);\nconst squareRoot = Math.sqrt(num);\nconst cubeRoot = Math.cbrt(27);\nconsole.log(roundedUp, roundedDown, absoluteValue, squareRoot, cubeRoot);"), '_blank')}>Run Code</button>

      <h3>Random Number</h3>
      <p>Generate a random number between 0 (inclusive) and 1 (exclusive) using Math.random():</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const randomValue = Math.random();
console.log(randomValue);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const pi = Math.PI;\nconst e = Math.E;\nconsole.log(pi, e);"), '_blank')}>Run Code</button>




    </div>
  );
}

export default JavaScriptMath;
