import React from 'react';

const JavaScriptClasses = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Classes</h2>
      <p>Classes in JavaScript are a way to create reusable objects with properties and methods. They provide a blueprint for creating objects with similar characteristics. Here are some common usages:</p>

      <h3>Creating a Class</h3>
      <p>Define a class and create instances of it:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Person {
  constructor(name, age) {
    this.name = name;
    this.age = age;
  }

  greet() {
    console.log('Hello, my name is ' + this.name + ' and I am ' + this.age + ' years old.');
  }
}

const person1 = new Person('Alice', 30);
const person2 = new Person('Bob', 25);

person1.greet();
person2.greet();`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Person {\n  constructor(name, age) {\n    this.name = name;\n    this.age = age;\n  }\n\n  greet() {\n    console.log('Hello, my name is ' + this.name + ' and I am ' + this.age + ' years old.');\n  }\n}\n\nconst person1 = new Person('Alice', 30);\nconst person2 = new Person('Bob', 25);\n\nperson1.greet();\nperson2.greet();"), '_blank')}>Run Code</button>

      <h3>Inheritance and Extending Classes</h3>
      <p>Create a subclass that inherits properties and methods from a parent class:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Animal {
  constructor(name) {
    this.name = name;
  }

  speak() {
    console.log(this.name + ' makes a sound.');
  }
}

class Dog extends Animal {
  constructor(name, breed) {
    super(name);
    this.breed = breed;
  }

  bark() {
    console.log(this.name + ' (a ' + this.breed + ') barks.');
  }
}

const dog = new Dog('Buddy', 'Golden Retriever');
dog.speak();
dog.bark();`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Animal {\n  constructor(name) {\n    this.name = name;\n  }\n\n  speak() {\n    console.log(this.name + ' makes a sound.');\n  }\n}\n\nclass Dog extends Animal {\n  constructor(name, breed) {\n    super(name);\n    this.breed = breed;\n  }\n\n  bark() {\n    console.log(this.name + ' (a ' + this.breed + ') barks.');\n  }\n}\n\nconst dog = new Dog('Buddy', 'Golden Retriever');\ndog.speak();\ndog.bark();"), '_blank')}>Run Code</button>

      <h3>Static Methods</h3>
      <p>Create static methods that belong to the class itself, not instances:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class MathUtils {
  static square(x) {
    return x * x;
  }
}

const number = 5;
const squared = MathUtils.square(number);
console.log('Square of ' + number + ' is ' + squared);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class MathUtils {\n  static square(x) {\n    return x * x;\n  }\n}\n\nconst number = 5;\nconst squared = MathUtils.square(number);\nconsole.log('Square of ' + number + ' is ' + squared);"), '_blank')}>Run Code</button>

      <h3>Using Constructors</h3>
      <p>Constructors can be used to set up initial object properties:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Rectangle {
  constructor(width, height) {
    this.width = width;
    this.height = height;
  }
}

const rectangle = new Rectangle(5, 3);
console.log('Width: ' + rectangle.width);
console.log('Height: ' + rectangle.height);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Rectangle {\n  constructor(width, height) {\n    this.width = width;\n    this.height = height;\n  }\n}\n\nconst rectangle = new Rectangle(5, 3);\nconsole.log('Width: ' + rectangle.width);\nconsole.log('Height: ' + rectangle.height);"), '_blank')}>Run Code</button>

      <h3>Encapsulation</h3>
      <p>Encapsulation restricts access to certain parts of an object, maintaining the integrity of data:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class BankAccount {
  #balance = 0; // Private field

  constructor(owner) {
    this.owner = owner;
  }

  deposit(amount) {
    this.#balance += amount;
  }

  getBalance() {
    return this.#balance;
  }
}

const account = new BankAccount('Alice');
account.deposit(1000);
console.log('Balance of ' + account.owner + ': $' + account.getBalance());`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class BankAccount {\n  #balance = 0; // Private field\n\n  constructor(owner) {\n    this.owner = owner;\n  }\n\n  deposit(amount) {\n    this.#balance += amount;\n  }\n\n  getBalance() {\n    return this.#balance;\n  }\n}\n\nconst account = new BankAccount('Alice');\naccount.deposit(1000);\nconsole.log('Balance of ' + account.owner + ': $' + account.getBalance());"), '_blank')}>Run Code</button>

      <h3>Getters and Setters</h3>
      <p>Use getters and setters to control access to object properties:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Circle {
  #radius = 0; // Private field

  constructor(radius) {
    this.#radius = radius;
  }

  get radius() {
    return this.#radius;
  }

  set radius(value) {
    if (value > 0) {
      this.#radius = value;
    }
  }

  get area() {
    return Math.PI * this.#radius ** 2;
  }
}

const circle = new Circle(5);
console.log('Radius: ' + circle.radius);
console.log('Area: ' + circle.area);

circle.radius = 8;
console.log('Updated Radius: ' + circle.radius);
console.log('Updated Area: ' + circle.area);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Circle {\n  #radius = 0; // Private field\n\n  constructor(radius) {\n    this.#radius = radius;\n  }\n\n  get radius() {\n    return this.#radius;\n  }\n\n  set radius(value) {\n    if (value > 0) {\n      this.#radius = value;\n    }\n  }\n\n  get area() {\n    return Math.PI * this.#radius ** 2;\n  }\n}\n\nconst circle = new Circle(5);\nconsole.log('Radius: ' + circle.radius);\nconsole.log('Area: ' + circle.area);\n\ncircle.radius = 8;\nconsole.log('Updated Radius: ' + circle.radius);\nconsole.log('Updated Area: ' + circle.area);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptClasses;
