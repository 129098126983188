import React from 'react'
import PagesNavBar from './PagesNavBar'
import Footer from './Footer'

function CodeEditor() {
  return (
    <div>
        <PagesNavBar />
        <br />
        <br />
        <div className='container shadow p-3 mb-5 bg-body rounded text-center mt-3' >

            <a href='https://run-code-editor.netlify.app/' className='text-decoration-none fs-3'>

            Click here to access Esurde Code Editor
            </a>
        </div>
        <br />
        <br />
        <Footer />


    </div>
  )
}

export default CodeEditor