import React from "react";

const PythonNumbers = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python Numbers</h2>
      <div>
        <div>
          <p>
            Python supports various types of numbers, including integers,
            floats, complex numbers, and random number generation. Let&apos;s
            explore Python&apos;s number data types:
          </p>

          <h3>Integers (int)</h3>
          <p>
            Integers are whole numbers without a decimal point. You can perform
            mathematical operations with them:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`x = 5
y = 3
addition = x + y
print(addition)
# The addition is 8

subtraction = x - y
print(subtraction)
# The subtraction is 2

multiplication = x * y
print(multiplication)
# The multiplication is 15

division = x / y
print(division)
# The division is 1.66667`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "x = 5\ny = 3\naddition = x + y\nsubtraction = x - y\nmultiplication = x * y\ndivision = x / y"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>Floats (float)</h3>
          <p>
            Floats are numbers with a decimal point. They are used for more
            precise calculations:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`x = 5.5
y = 2.5
addition = x + y
print(addition)
# The addition is 8.0

subtraction = x - y
print(subtraction)
# The subtraction is 3.0

multiplication = x * y
print(multiplication)
# The multiplication is 13.75

division = x / y
print(division)
# The division is 2.2`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "x = 5.5\ny = 2.5\naddition = x + y\nsubtraction = x - y\nmultiplication = x * y\ndivision = x / y"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>Complex Numbers (complex)</h3>
          <p>
            Complex numbers consist of a real part and an imaginary part. They
            are used in advanced mathematical calculations:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`z1 = 3 + 4j
z2 = 2 - 5j
addition = z1 + z2
print(addition)
# The addition is (5-1j)

multiplication = z1 * z2
print(multiplication)
# The multiplication is (26+7j)`}
            </code>
          </pre>
          <button
            className="btn btn-primary mb-3"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "z1 = 3 + 4j\nz2 = 2 - 5j\naddition = z1 + z2\nmultiplication = z1 * z2"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>

          <h3>Random Numbers (random)</h3>
          <p>
            Python provides a random module for generating random numbers. You
            can generate integers, floats, and random choices from a sequence:
          </p>
          <pre className="bg-dark text-light p-2">
            <code>
              {`import random

# Generate a random integer between 1 and 10
random_integer = random.randint(1, 10)

# Generate a random float between 0 and 1
random_float = random.random()

# Choose a random element from a list
fruits = ["apple", "banana", "cherry"]
random_fruit = random.choice(fruits)
print(random_fruit)`}
            </code>
          </pre>
          <button
            className="btn btn-primary"
            onClick={() =>
              window.open(
                codeEditorUrl +
                  "?code=" +
                  encodeURIComponent(
                    "import random\n\nrandom_integer = random.randint(1, 10)\nrandom_float = random.random()\nfruits = ['apple', 'banana', 'cherry']\nrandom_fruit = random.choice(fruits)"
                  ),
                "_blank"
              )
            }
          >
            Run Code
          </button>
        </div>
      </div>
    </div>
  );
};

export default PythonNumbers;
