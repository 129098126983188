import React from "react";

const PythonScope = () => {
  // URL for the code editor
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python Scope</h2>
      <p>
        In Python, the scope of a variable refers to where the variable is
        accessible or where it can be used. There are two main types of scope:
        global and local. Here&apos;s an overview of Python scope with code
        examples:
      </p>

      <h3>Global Scope</h3>
      <p>
        Variables declared outside functions have global scope and can be
        accessed from anywhere in the code:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`x = 10

def print_global():
    print(x)

print_global()
print(x)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "x = 10\ndef print_global():\n    print(x)\n\nprint_global()\nprint(x)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Local Scope</h3>
      <p>
        Variables declared inside a function have local scope and are only
        accessible within that function:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def print_local():
    y = 5
    print(y)

print_local()
# This will result in an error: print(y)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "def print_local():\n    y = 5\n    print(y)\n\nprint_local()\n# This will result in an error: print(y)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Global Keyword</h3>
      <p>
        You can use the <code>global</code> keyword to modify a global variable
        from within a function:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`z = 15

def modify_global():
    global z
    z = 20

modify_global()
print(z)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "z = 15\ndef modify_global():\n    global z\n    z = 20\n\nmodify_global()\nprint(z)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Enclosing Scope (Nested Functions)</h3>
      <p>
        Variables in enclosing (non-global) scopes can be accessed within nested
        functions:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def outer_function():
    a = 30

    def inner_function():
        print(a)

    inner_function()

outer_function()`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "def outer_function():\n    a = 30\n\n    def inner_function():\n        print(a)\n\n    inner_function()\n\nouter_function()"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Local vs. Global Variables</h3>
      <p>
        If a local variable has the same name as a global variable, the local
        variable takes precedence within its scope:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`num = 100

def example_function():
    num = 50
    print(num)

example_function()
print(num)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "num = 100\ndef example_function():\n    num = 50\n    print(num)\n\nexample_function()\nprint(num)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Nonlocal Keyword (Enclosing Scope)</h3>
      <p>
        The <code>nonlocal</code> keyword allows you to modify variables in an
        enclosing (non-global) scope:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`def outer_function():
    b = 25

    def inner_function():
        nonlocal b
        b = 35

    inner_function()
    print(b)

outer_function()`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "def outer_function():\n    b = 25\n\n    def inner_function():\n        nonlocal b\n        b = 35\n\n    inner_function()\n    print(b)\n\nouter_function()"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default PythonScope;
