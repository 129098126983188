import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  Hits,
  Configure,
} from 'react-instantsearch-dom';
import { Link } from 'react-router-dom';

const searchClient = algoliasearch(
  '3LY65JQ58K',
  '630497e1ef7d91c4e3d2420aed8ecc22'
);

const Search = () => {
  const [query, setQuery] = useState('');
  const handleInputChange = (event) => {
    setQuery(event.currentTarget.value);
  };
  return (
    <div className="search-bar-container container-fluid rounded">
      <InstantSearch indexName="esurde" searchClient={searchClient}>
        <Configure hitsPerPage={10} />
        <SearchBox submit={null}
          onChange={handleInputChange}
          reset={
            query.length > 0 ? (
              <button
                type="button"
                className="search-reset"
                onClick={() => setQuery('')}
              >
                x
              </button>
            ) : null
          }
          
        />
        {query.length > 0 && (
          <div className="suggestions-container  bg-body rounded">
            <Hits hitComponent={SuggestionResult} />
          </div>
        )}
       
      </InstantSearch>
    </div>
  );
};

const SuggestionResult = ({ hit }) => (
  <div className="suggestion-result">
    <Link to={hit.link} className='text-decoration-none'>
    <h5>{hit.mainContent}</h5>
    </Link>
   
  </div>
);

SuggestionResult.propTypes = {
  hit: PropTypes.shape({
    link: PropTypes.string.isRequired,
    mainContent: PropTypes.string.isRequired,
    // Add more PropTypes as needed for other properties in the 'hit' object
  }).isRequired,
};

export default Search;
