import React from "react";

const CppArrays = () => {
  return (
    <div>
      <h2>C++ Arrays</h2>
      <p>
        In C++, an array is a collection of elements of the same data type,
        stored in contiguous memory locations. The size of the array must be
        specified at the time of declaration. Here&apos;s how you can work with
        arrays:
      </p>

      <h3>Declaring and Initializing Arrays</h3>
      <p>
        Arrays are declared by specifying the data type of the elements and the
        array&apos;s name. You can also initialize the array at the time of
        declaration.
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int numbers[5]; // Declaring an integer array of size 5
    numbers[0] = 1; // Initializing individual elements
    numbers[1] = 2;
    numbers[2] = 3;
    numbers[3] = 4;
    numbers[4] = 5;

    int moreNumbers[] = {10, 20, 30, 40, 50}; // Declaring and initializing in one step

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int numbers[5]; // Declaring an integer array of size 5
    numbers[0] = 1; // Initializing individual elements
    numbers[1] = 2;
    numbers[2] = 3;
    numbers[3] = 4;
    numbers[4] = 5;

    int moreNumbers[] = {10, 20, 30, 40, 50}; // Declaring and initializing in one step

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Accessing Elements</h3>
      <p>
        You can access array elements by using the array name followed by the
        index of the element inside square brackets.
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int numbers[] = {1, 2, 3, 4, 5};

    cout << "Element at index 2: " << numbers[2] << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary mb-2"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int numbers[] = {1, 2, 3, 4, 5};

    cout << "Element at index 2: " << numbers[2] << endl;

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Modifying Array Elements</h3>
      <p>
        You can modify array elements by assigning a new value to a specific
        index.
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int numbers[] = {1, 2, 3, 4, 5};

    numbers[2] = 10; // Modifying element at index 2

    cout << "Modified element at index 2: " << numbers[2] << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary mb-2"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int numbers[] = {1, 2, 3, 4, 5};

    numbers[2] = 10; // Modifying element at index 2

    cout << "Modified element at index 2: " << numbers[2] << endl;

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Iterating Through Arrays</h3>
      <p>
        You can use loops like <code>for</code> to iterate through the elements
        of an array.
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int numbers[] = {1, 2, 3, 4, 5};

    for (int i = 0; i < 5; i++) {
        cout << "Element at index " << i << ": " << numbers[i] << endl;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary mb-2"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int numbers[] = {1, 2, 3, 4, 5};

    for (int i = 0; i < 5; i++) {
        cout << "Element at index " << i << ": " << numbers[i] << endl;
    }

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Multi-Dimensional Arrays</h3>
      <p>
        C++ allows you to create multi-dimensional arrays, like 2D arrays, which
        are essentially arrays of arrays.
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int matrix[3][3] = {
        {1, 2, 3},
        {4, 5, 6},
        {7, 8, 9}
    };

    cout << "Element at row 2, column 1: " << matrix[1][0] << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int matrix[3][3] = {
        {1, 2, 3},
        {4, 5, 6},
        {7, 8, 9}
    };

    cout << "Element at row 2, column 1: " << matrix[1][0] << endl;

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppArrays;
