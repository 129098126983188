import React from 'react';

const CppReferences = () => {
  return (
    <div>
      <h2>C++ References</h2>
      <p>
        In C++, references provide an alias to an existing variable. They allow you to work with the same variable by different names. Once a reference is initialized, it cannot be changed to refer to another variable.
      </p>

      <h3>Declaration of References</h3>
      <p>
        References are declared using the <code>&</code> symbol after the variable type.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
int original = 42;
int &ref = original; // ref is now an alias to original
          `}
        </code>
      </pre>

      <h3>Code Examples:</h3>

      <h3>Passing by Reference</h3>
      <p>
        You can pass variables to functions by reference, which allows the function to modify the original variable.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

void modify(int &num) {
    num = num * 2;
}

int main() {
    int value = 5;
    modify(value);
    cout << "Modified value: " << value << endl;
    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

void modify(int &num) {
    num = num * 2;
}

int main() {
    int value = 5;
    modify(value);
    cout << "Modified value: " << value << endl;
    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Returning a Reference</h3>
      <p>
        A function can return a reference to a variable, allowing the result of the function to be modified directly.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int &increment(int &num) {
    num++;
    return num;
}

int main() {
    int value = 5;
    int &result = increment(value);
    result = 42;
    cout << "Updated value: " << value << endl;
    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int &increment(int &num) {
    num++;
    return num;
}

int main() {
    int value = 5;
    int &result = increment(value);
    result = 42;
    cout << "Updated value: " << value << endl;
    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Reference to Array</h3>
      <p>
        You can create references to array elements, simplifying array manipulation.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int numbers[] = {1, 2, 3, 4, 5};
    int &third = numbers[2];
    third = 42;
    cout << "Updated array: ";
    for (int num : numbers) {
        cout << num << " ";
    }
    cout << endl;
    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int numbers[] = {1, 2, 3, 4, 5};
    int &third = numbers[2];
    third = 42;
    cout << "Updated array: ";
    for (int num : numbers) {
        cout << num << " ";
    }
    cout << endl;
    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppReferences;
