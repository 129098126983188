import React from 'react';

const JavaScriptJSON = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript JSON (JavaScript Object Notation)</h2>
      <p>JSON is a lightweight data interchange format that is easy for humans to read and write and easy for machines to parse and generate. It is often used to transmit data between a server and a web application as an alternative to XML. Here are some common usages:</p>

      <h3>Creating JSON</h3>
      <p>You can create JSON data using JavaScript objects:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const person = {
  name: 'Alice',
  age: 30,
  city: 'New York'
};
const jsonStr = JSON.stringify(person);
console.log('JSON string:', jsonStr);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const person = {\n  name: 'Alice',\n  age: 30,\n  city: 'New York'\n};\nconst jsonStr = JSON.stringify(person);\nconsole.log('JSON string:', jsonStr);"), '_blank')}>Run Code</button>

      <h3>Parsing JSON</h3>
      <p>You can parse a JSON string to convert it into a JavaScript object:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const jsonStr = '{"name":"Bob","age":25,"city":"London"}';
const obj = JSON.parse(jsonStr);
console.log('Parsed JSON:', obj);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const jsonStr = '{\"name\":\"Bob\",\"age\":25,\"city\":\"London\"}';\nconst obj = JSON.parse(jsonStr);\nconsole.log('Parsed JSON:', obj);"), '_blank')}>Run Code</button>

      <h3>Accessing JSON Data</h3>
      <p>You can access data in a JSON object using dot notation or bracket notation:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const person = {
  "name": "John",
  "age": 35,
  "address": {
    "street": "123 Main St",
    "city": "Los Angeles"
  }
};
const name = person.name;
const city = person["address"]["city"];
console.log('Name:', name);
console.log('City:', city);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const person = {\n  \"name\": \"John\",\n  \"age\": 35,\n  \"address\": {\n    \"street\": \"123 Main St\",\n    \"city\": \"Los Angeles\"\n  }\n};\nconst name = person.name;\nconst city = person[\"address\"][\"city\"];\nconsole.log('Name:', name);\nconsole.log('City:', city);"), '_blank')}>Run Code</button>

      <h3>Looping Through JSON</h3>
      <p>You can loop through JSON data just like you would with JavaScript objects:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const data = {
  "items": [
    { "name": "Item 1", "price": 10 },
    { "name": "Item 2", "price": 20 },
    { "name": "Item 3", "price": 15 }
  ]
};
data.items.forEach(item => {
  console.log('Item:', item.name);
  console.log('Price:', item.price);
});`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const data = {\n  \"items\": [\n    { \"name\": \"Item 1\", \"price\": 10 },\n    { \"name\": \"Item 2\", \"price\": 20 },\n    { \"name\": \"Item 3\", \"price\": 15 }\n  ]\n};\ndata.items.forEach(item => {\n  console.log('Item:', item.name);\n  console.log('Price:', item.price);\n});"), '_blank')}>Run Code</button>

      <h3>Modifying JSON</h3>
      <p>You can add, update, or delete properties in a JSON object:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const user = {
  "name": "Alice",
  "age": 30
};
user.email = "alice@example.com";
user.age = 31;
delete user.age;
console.log('Modified User:', user);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const user = {\n  \"name\": \"Alice\",\n  \"age\": 30\n};\nuser.email = \"alice@example.com\";\nuser.age = 31;\ndelete user.age;\nconsole.log('Modified User:', user);"), '_blank')}>Run Code</button>

      <h3>Working with Arrays in JSON</h3>
      <p>JSON can contain arrays of objects, and you can manipulate them like any other JavaScript array:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const products = [
  { "name": "Product 1", "price": 10 },
  { "name": "Product 2", "price": 20 },
  { "name": "Product 3", "price": 15 }
];
products.push({ "name": "Product 4", "price": 25 });
console.log('Products:', products);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const products = [\n  { \"name\": \"Product 1\", \"price\": 10 },\n  { \"name\": \"Product 2\", \"price\": 20 },\n  { \"name\": \"Product 3\", \"price\": 15 }\n];\nproducts.push({ \"name\": \"Product 4\", \"price\": 25 });\nconsole.log('Products:', products);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptJSON;
