import React from 'react';
import { Button } from 'react-bootstrap';
import { auth } from './Firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import NavBar from '../components/Navbar';
import Footer from '../components/Footer';
import {FcGoogle} from 'react-icons/fc'
import { CardGroup, Card } from "react-bootstrap";
import WritingJobs from "../ads/WritingJobs";
import ForexTrading from "../ads/ForexTrading";
import LiveChat from "../ads/LiveChat";

const SignIn = () => {
  const provider = new GoogleAuthProvider();

  const signInWithGoogle = async () => {
    try {
      await signInWithPopup(auth, provider);
    } catch (error) {
      console.error('Error signing in with Google:', error);
    }
  };

  return (
    <div>
      <NavBar/>
      <br /> <br /> <br />
      <div className="container text-center shadow p-5 mb-5 bg-body rounded">
      <h2 className='container p-3'>Join Esurde&apos;s vibrant Discussion Room!</h2>
      <p>   Connect with fellow learners, ask questions, and engage in insightful conversations. Expand your knowledge, gain valuable insights, and be part of a supportive community.

Come and be a part of the learning journey at Esurde!

Join the Discussion Room today!</p>
      <Button variant="primary" onClick={signInWithGoogle}>
        <FcGoogle className='fs-1 m-2'/> Sign in with Google
      </Button>
    </div>
    <br />
    <CardGroup className="container">
        <Card>
          <Card.Body>
            <WritingJobs
              productLink="https://hop.clickbank.net/?affiliate=okyerejr&vendor=easywriter&pid=instantapply&ifso="
              imageUrl="https://affiliatesstuff.s3.amazonaws.com/POWJ/POWJ%20-%20FB%20Ad%20Images/POWJ%20FB%20Ad%20image%202.png"
              altText="paid online writing jobs"
              title=""
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <ForexTrading
              productLink="https://hop.clickbank.net/?affiliate=okyerejr&vendor=1000PB"
              imageUrl="https://www.1000pipbuilder.com/wp-content/plugins/new-modules/images/1000PB-v01-300x250.jpg"
              altText="forex trading"
              title=""
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <LiveChat
              productLink="https://hop.clickbank.net/?affiliate=okyerejr&vendor=socialsrep"
              imageUrl="https://affiliatesstuff.s3.amazonaws.com/SSR/SSR%20-%20FB%20Ad%20Images/SSR%20FB%20Ad%20Image%205.png"
              altText="live chat support"
              title=""
            />
          </Card.Body>
        </Card>
    
      </CardGroup>
    <br /> <br /> <br />
    <Footer />
    </div>
  );
};

export default SignIn;
