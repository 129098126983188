import React from 'react';

const CppStrings = () => {
  return (
    <div>
      <h2>C++ Strings</h2>
      <p>
        In C++, a string is a sequence of characters, and C++ provides a convenient <code>string</code> data type to work with strings.
      </p>

      <h3>Code Examples:</h3>
      <p>Here are some examples of using strings in C++, including escape and special characters:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
#include <string>

using namespace std;

int main() {
    // Define a string variable
    string greeting = "Hello, C++!";

    cout << "Greeting: " << greeting << endl;

    // Concatenate strings
    string firstName = "John";
    string lastName = "Doe";
    string fullName = firstName + " " + lastName;

    cout << "Full Name: " << fullName << endl;

    // Length of a string
    cout << "Length of Greeting: " << greeting.length() << endl;

    // Accessing individual characters
    cout << "First character of Greeting: " << greeting[0] << endl;

    // Substring
    string partOfGreeting = greeting.substr(0, 5);
    cout << "Substring: " << partOfGreeting << endl;

    // Escape and special characters
    string specialChars = "Escape characters: \\n (newline), \\t (tab)";
    cout << specialChars << endl;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>
#include <string>

using namespace std;

int main() {
    // Define a string variable
    string greeting = "Hello, C++!";

    cout << "Greeting: " << greeting << endl;

    // Concatenate strings
    string firstName = "John";
    string lastName = "Doe";
    string fullName = firstName + " " + lastName;

    cout << "Full Name: " << fullName << endl;

    // Length of a string
    cout << "Length of Greeting: " << greeting.length() << endl;

    // Accessing individual characters
    cout << "First character of Greeting: " << greeting[0] << endl;

    // Substring
    string partOfGreeting = greeting.substr(0, 5);
    cout << "Substring: " << partOfGreeting << endl;

    // Escape and special characters
    string specialChars = "Escape characters: \\n (newline), \\t (tab)";
    cout << specialChars << endl;

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppStrings;
