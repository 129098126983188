import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBL9kMWuQYhVynI5Jzgm4HodFN0h0e86uU",
  authDomain: "esurde-83359.firebaseapp.com",
  projectId: "esurde-83359",
  storageBucket: "esurde-83359.appspot.com",
  messagingSenderId: "780078882720",
  appId: "1:780078882720:web:5196f3d40d89e744890ec5",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const storage = getStorage(app);

export { auth, firestore,storage };
