import React from "react";

const JavaScriptObjects = () => {
  // URL for the code editor
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>JavaScript Objects</h2>
      <p>
        Objects are one of the most important data types in JavaScript. They
        allow you to store and manipulate data as key-value pairs. Here&apos;s how
        you can work with objects:
      </p>

      <h3>Creating Objects</h3>
      <p>
        You can create objects using curly braces and define properties with
        key-value pairs:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const person = {
  name: 'Alice',
  age: 30,
  isStudent: false
};`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "const person = {\n  name: 'Alice',\n  age: 30,\n  isStudent: false\n};"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Accessing Object Properties</h3>
      <p>
        You can access object properties using dot notation or square brackets:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const person = {
  name: 'Alice',
  age: 30
};
console.log(person.name); // "Alice"
console.log(person['age']); // 30`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "const person = {\n  name: 'Alice',\n  age: 30\n};\nconsole.log(person.name); // 'Alice'\nconsole.log(person['age']); // 30"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Modifying Object Properties</h3>
      <p>You can change the value of an object property:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const person = {
  name: 'Alice',
  age: 30
};
person.age = 31;
console.log(person.age); // 31`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl 
          )
        }
      >
        Run Code
      </button>

      <h3>Adding Object Properties</h3>
      <p>You can add new properties to an object:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const person = {
  name: 'Alice',
  age: 30
};
person.city = 'New York';
console.log(person.city); // 'New York'`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "const person = {\n  name: 'Alice',\n  age: 30\n};\nperson.city = 'New York';\nconsole.log(person.city); // 'New York'"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Deleting Object Properties</h3>
      <p>
        You can delete properties from an object using the <code>delete</code>{" "}
        keyword:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const person = {
  name: 'Alice',
  age: 30
};
delete person.age;
console.log(person.age); // undefined`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "const person = {\n  name: 'Alice',\n  age: 30\n};\ndelete person.age;\nconsole.log(person.age); // undefined"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Object Methods</h3>
      <p>Objects can also contain methods (functions) as values:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const calculator = {
  add: function(a, b) {
    return a + b;
  },
  subtract(a, b) {
    return a - b;
  }
};
console.log(calculator.add(5, 3)); // 8
console.log(calculator.subtract(8, 2)); // 6`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "const calculator = {\n  add: function(a, b) {\n    return a + b;\n  },\n  subtract(a, b) {\n    return a - b;\n  }\n};\nconsole.log(calculator.add(5, 3)); // 8\nconsole.log(calculator.subtract(8, 2)); // 6"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>The &apos;this&apos; Keyword</h3>
      <p>
        The <code>this</code> keyword refers to the current object and is often
        used within methods to access object properties or call other methods:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const person = {
  name: 'Alice',
  greet() {
    console.log('Hello, ' + this.name);
  }
};
person.greet(); // "Hello, Alice"`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "const person = {\n  name: 'Alice',\n  greet() {\n    console.log('Hello, ' + this.name);\n  }\n};\nperson.greet(); // 'Hello, Alice'"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default JavaScriptObjects;
