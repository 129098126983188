import React from 'react';

const JavaScriptStrings = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Strings</h2>
      <p>In JavaScript, strings are used to represent and manipulate text. Here are some common operations with strings:</p>

      <h3>Creating Strings</h3>
      <p>You can create strings using single or double quotes:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const singleQuoted = 'This is a single-quoted string';
const doubleQuoted = "This is a double-quoted string";`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const singleQuoted = 'This is a single-quoted string';\nconst doubleQuoted = 'This is a double-quoted string';"), '_blank')}>Run Code</button>

      <h3>String Length</h3>
      <p>You can find the length of a string using the <code>length</code> property:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const text = 'JavaScript';
const length = text.length;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const text = 'JavaScript';\nconst length = text.length;"), '_blank')}>Run Code</button>

      <h3>String Concatenation</h3>
      <p>You can concatenate strings using the <code>+</code> operator:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const firstName = 'John';
const lastName = 'Doe';
const fullName = firstName + ' ' + lastName;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const firstName = 'John';\nconst lastName = 'Doe';\nconst fullName = firstName + ' ' + lastName;"), '_blank')}>Run Code</button>

      <h3>String Methods</h3>
      <p>JavaScript provides various string methods for manipulation:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const text = 'JavaScript';
const upperCase = text.toUpperCase(); // 'JAVASCRIPT'
const lowerCase = text.toLowerCase(); // 'javascript'
const sliced = text.slice(0, 4); // 'Java'`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const text = 'JavaScript';\nconst upperCase = text.toUpperCase();\nconst lowerCase = text.toLowerCase();\nconst sliced = text.slice(0, 4);"), '_blank')}>Run Code</button>

      <h3>String Searching</h3>
      <p>You can search for substrings within a string using <code>indexOf()</code> or <code>includes()</code>:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const text = 'JavaScript is a powerful language';
const search = 'powerful';
const isFound = text.includes(search); // true
const position = text.indexOf(search); // 12`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const text = 'JavaScript is a powerful language';\nconst search = 'powerful';\nconst isFound = text.includes(search);\nconst position = text.indexOf(search);"), '_blank')}>Run Code</button>

      <h3>String Templates</h3>
      <p>Template literals allow you to create strings with embedded expressions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const name = 'Alice';
const greeting = \`Hello, \${name}!\`; // 'Hello, Alice!'`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl )}>Run Code</button>
    </div>
  );
}

export default JavaScriptStrings;
