import React from 'react';

const JavaScriptSwitchStatements = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Switch Statements</h2>
      <p>A switch statement is used for decision-making in JavaScript. It evaluates an expression and executes a block of code based on the matching case label. Here are some examples:</p>

      <h3>Basic Switch Statement</h3>
      <p>Use a basic switch statement to match an expression with different cases:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const day = 'Monday';
switch (day) {
  case 'Monday':
    console.log('It is Monday.');
    break;
  case 'Tuesday':
    console.log('It is Tuesday.');
    break;
  default:
    console.log('It is some other day.');
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const day = 'Monday';\nswitch (day) {\n  case 'Monday':\n    console.log('It is Monday.');\n    break;\n  case 'Tuesday':\n    console.log('It is Tuesday.');\n    break;\n  default:\n    console.log('It is some other day.');\n}"), '_blank')}>Run Code</button>

      <h3>Switch Statement with Fall-Through</h3>
      <p>Switch statements can fall through cases without using break:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const num = 2;
switch (num) {
  case 1:
    console.log('Number is 1.');
  case 2:
    console.log('Number is 2.');
  default:
    console.log('Number is not 1 or 2.');
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const num = 2;\nswitch (num) {\n  case 1:\n    console.log('Number is 1.');\n  case 2:\n    console.log('Number is 2.');\n  default:\n    console.log('Number is not 1 or 2.');\n}"), '_blank')}>Run Code</button>

      <h3>Switch Statement with Default Only</h3>
      <p>You can use a switch statement with only a default case:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const language = 'French';
switch (language) {
  default:
    console.log('Unknown language.');
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const language = 'French';\nswitch (language) {\n  default:\n    console.log('Unknown language.');\n}"), '_blank')}>Run Code</button>

      <h3>Switch Statement with Multiple Values</h3>
      <p>You can use multiple values in a single case:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const num = 5;
switch (num) {
  case 1:
  case 3:
  case 5:
    console.log('Odd number.');
    break;
  case 2:
  case 4:
    console.log('Even number.');
    break;
  default:
    console.log('Some other number.');
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const num = 5;\nswitch (num) {\n  case 1:\n  case 3:\n  case 5:\n    console.log('Odd number.');\n    break;\n  case 2:\n  case 4:\n    console.log('Even number.');\n    break;\n  default:\n    console.log('Some other number.');\n}"), '_blank')}>Run Code</button>

      <h3>Switch Statement with Ternary Operator</h3>
      <p>You can use a ternary operator in conjunction with a switch statement:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const number = 5;
const result = (number % 2 === 0) ? 'Even' : 'Odd';
console.log(result);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const number = 5;\nconst result = (number % 2 === 0) ? 'Even' : 'Odd';\nconsole.log(result);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptSwitchStatements;
