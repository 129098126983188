import React from 'react';

const JavaScriptMaps = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Maps</h2>
      <p>In JavaScript, a Map is a collection of key-value pairs where each key is unique. It provides an easy way to store and manage data. Here are some common usages:</p>

      <h3>Creating a Map</h3>
      <p>Use the Map constructor to create a new Map:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = new Map();
fruits.set('apple', 5);
fruits.set('banana', 3);
fruits.set('cherry', 7);

console.log(fruits);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = new Map();\nfruits.set('apple', 5);\nfruits.set('banana', 3);\nfruits.set('cherry', 7);\n\nconsole.log(fruits);"), '_blank')}>Run Code</button>

      <h3>Accessing Map Values</h3>
      <p>Retrieve values from a Map using the get method:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = new Map();
fruits.set('apple', 5);
fruits.set('banana', 3);

const appleCount = fruits.get('apple');
console.log('Apple count:', appleCount);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = new Map();\nfruits.set('apple', 5);\nfruits.set('banana', 3);\n\nconst appleCount = fruits.get('apple');\nconsole.log('Apple count:', appleCount);"), '_blank')}>Run Code</button>

      <h3>Iterating Over a Map</h3>
      <p>Use a for...of loop to iterate through the keys and values of a Map:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = new Map();
fruits.set('apple', 5);
fruits.set('banana', 3);

for (const [fruit, count] of fruits) {
  console.log(fruit, count);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = new Map();\nfruits.set('apple', 5);\nfruits.set('banana', 3);\n\nfor (const [fruit, count] of fruits) {\n  console.log(fruit, count);\n}"), '_blank')}>Run Code</button>

      <h3>Checking if a Key Exists</h3>
      <p>Use the has method to check if a key exists in a Map:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = new Map();
fruits.set('apple', 5);
fruits.set('banana', 3);

const hasBanana = fruits.has('banana');
console.log('Has banana:', hasBanana);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = new Map();\nfruits.set('apple', 5);\nfruits.set('banana', 3);\n\nconst hasBanana = fruits.has('banana');\nconsole.log('Has banana:', hasBanana);"), '_blank')}>Run Code</button>

      <h3>Removing a Key from a Map</h3>
      <p>Use the delete method to remove a key-value pair from a Map:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = new Map();
fruits.set('apple', 5);
fruits.set('banana', 3);

fruits.delete('apple');
console.log(fruits);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = new Map();\nfruits.set('apple', 5);\nfruits.set('banana', 3);\n\nfruits.delete('apple');\nconsole.log(fruits);"), '_blank')}>Run Code</button>

      <h3>Getting the Size of a Map</h3>
      <p>Use the size property to get the number of key-value pairs in a Map:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = new Map();
fruits.set('apple', 5);
fruits.set('banana', 3);

const mapSize = fruits.size;
console.log('Map size:', mapSize);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = new Map();\nfruits.set('apple', 5);\nfruits.set('banana', 3);\n\nconst mapSize = fruits.size;\nconsole.log('Map size:', mapSize);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptMaps;
