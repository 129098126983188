import React from 'react';

const DataAnalysis = () => {
  return (
    <div>
      <h2>Data Analysis</h2>
      <p>Data analysis is a fundamental part of data science. It involves examining, cleaning, transforming, and interpreting data to discover insights and patterns. Let&apos;s explore some key concepts and code examples to understand data analysis better.</p>

      <h3>1. Data Loading and Examination</h3>
      <p>Before analyzing data, we need to load and examine it:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import pandas as pd

# Load data from a CSV file
data = pd.read_csv('data.csv')

# Display the first few rows
print(data.head())`}
        </code>
      </pre>
      <p>In this code, we use the Pandas library to load data from a CSV file and display the first few rows.</p>

      <h3>2. Data Cleaning</h3>
      <p>Cleaning data involves handling missing values and inconsistencies:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Handle missing values
data.dropna()

# Correct inconsistent values
data['column_name'].replace('incorrect', 'correct', inplace=True)`}
        </code>
      </pre>
      <p>We remove rows with missing values and correct inconsistent data in this example.</p>

      <h3>3. Data Exploration</h3>
      <p>Exploring data helps us visualize and understand it better:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import matplotlib.pyplot as plt

# Create a histogram
plt.hist(data['column_name'], bins=10)
plt.xlabel('Values')
plt ylabel('Frequency')
plt.show()`}
        </code>
      </pre>
      <p>Here, we use Matplotlib to create a histogram, which visualizes the distribution of data.</p>

      <h3>4. Data Transformation</h3>
      <p>Transformation involves altering data to fit analysis requirements:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Standardize data
data['column_name'] = (data['column_name'] - data['column_name'].mean()) / data['column_name'].std()
`}
        </code>
      </pre>
      <p>We standardize data in this code to make it compatible with various algorithms.</p>

      <h3>5. Data Interpretation</h3>
      <p>Interpretation involves drawing conclusions from data analysis:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Calculate summary statistics
summary = data.describe()
print(summary)
`}
        </code>
      </pre>
      <p>We calculate summary statistics to gain insights into the data&apos;s central tendencies.</p>

      <h3>6. Visualization</h3>
      <p>Visualizations help communicate findings:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Create a scatter plot
plt.scatter(data['column1'], data['column2'])
plt.xlabel('Column 1')
plt.ylabel('Column 2')
plt.show()
`}
        </code>
      </pre>
      <p>This code generates a scatter plot to visualize the relationship between two columns.</p>

      <h3>Statistical Analysis</h3>
      <p>Statistical analysis involves calculating various statistics for data insights:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Calculate mean, median, and standard deviation
mean = data['column_name'].mean()
median = data['column_name'].median()
std_dev = data['column_name'].std()
print("Mean:", mean)
print("Median:", median)
print("Standard Deviation:", std_dev)
`}
        </code>
      </pre>
      <p>We compute mean, median, and standard deviation as basic statistical measures.</p>

      <h3>Hypothesis Testing</h3>
      <p>Hypothesis testing is used to determine whether observed differences are significant:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import scipy.stats as stats

# Perform a t-test
t_stat, p_value = stats.ttest_ind(data_group1, data_group2)
print("T-statistic:", t_stat)
print("P-value:", p_value)
`}
        </code>
      </pre>
      <p>In this example, we conduct a t-test to compare two groups of data.</p>

      <h3>Regression Analysis</h3>
      <p>Regression analysis is used for modeling relationships between variables:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import statsmodels.api as sm

# Perform linear regression
X = data['independent_variable']
Y = data['dependent_variable']
X = sm.add_constant(X)
model = sm.OLS(Y, X).fit()
print(model.summary())
`}
        </code>
      </pre>
      <p>This code demonstrates linear regression analysis using the Statsmodels library.</p>

      <h3>Machine Learning</h3>
      <p>Machine learning includes supervised, unsupervised, and reinforcement learning. Here&apos;s an example of supervised learning:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.model_selection import train_test_split
from sklearn.ensemble import RandomForestClassifier

# Split data into training and testing sets
X_train, X_test, y_train, y_test = train_test_split(X, y, test_size=0.2)

# Create and train a random forest classifier
model = RandomForestClassifier(n_estimators=100)
model.fit(X_train, y_train)

# Make predictions
predictions = model.predict(X_test)
`}
        </code>
      </pre>
      <p>This code demonstrates supervised learning using a Random Forest Classifier.</p>

    </div>
  );
}

export default DataAnalysis;
