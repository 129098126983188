import React from 'react';

const PythonLambdaFunctions = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>Python Lambda Functions</h2>
      <p>A lambda function is a small, anonymous function defined using the <code>lambda</code> keyword. Lambda functions can take any number of arguments but can only have one expression. They are often used when you need a simple function for a short period.</p>

      <h3>Basic Lambda Function</h3>
      <p>Here&apos;s a basic lambda function that returns the square of a number:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`square = lambda x: x * x
result = square(5)
print("Square:", result)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("square = lambda x: x * x\nresult = square(5)\nprint(\"Square:\", result)"), '_blank')}>Run Code</button>

      <h3>Using Lambda with Map</h3>
      <p>Lambda functions are commonly used with built-in functions like <code>map</code>. Here&apos;s an example that squares a list of numbers:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`numbers = [1, 2, 3, 4, 5]
squared = list(map(lambda x: x * x, numbers))
print("Squared:", squared)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("numbers = [1, 2, 3, 4, 5]\nsquared = list(map(lambda x: x * x, numbers))\nprint(\"Squared:\", squared)"), '_blank')}>Run Code</button>

      <h3>Using Lambda with Filter</h3>
      <p>You can also use lambda functions with the <code>filter</code> function to filter a list based on a condition. Here&apos;s an example that filters even numbers:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`numbers = [1, 2, 3, 4, 5, 6]
evens = list(filter(lambda x: x % 2 == 0, numbers))
print("Evens:", evens)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("numbers = [1, 2, 3, 4, 5, 6]\nevens = list(filter(lambda x: x % 2 == 0, numbers))\nprint(\"Evens:\", evens)"), '_blank')}>Run Code</button>

      <h3>Using Lambda for Sorting</h3>
      <p>Lambda functions can be used to define custom sorting criteria. Here&apos;s an example that sorts a list of tuples based on the second element:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`students = [('Alice', 95), ('Bob', 87), ('Eve', 92)]
students.sort(key=lambda x: x[1])
print("Sorted Students:", students)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("students = [('Alice', 95), ('Bob', 87), ('Eve', 92)]\nstudents.sort(key=lambda x: x[1])\nprint(\"Sorted Students:\", students)"), '_blank')}>Run Code</button>

      <h3>Using Lambda with Reduce</h3>
      <p>The <code>reduce</code> function from the <code>functools</code> module can be used with lambda functions to perform a cumulative operation on a list. Here&apos;s an example that calculates the product of all elements:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from functools import reduce

numbers = [1, 2, 3, 4, 5]
product = reduce(lambda x, y: x * y, numbers)
print("Product:", product)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("from functools import reduce\n\nnumbers = [1, 2, 3, 4, 5]\nproduct = reduce(lambda x, y: x * y, numbers)\nprint(\"Product:\", product)"), '_blank')}>Run Code</button>

      <h3>Using Lambda in Expressions</h3>
      <p>Lambda functions are often used in expressions where a small function is needed. Here&apos;s an example that adds two numbers using a lambda expression:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`add = lambda x, y: x + y
result = add(3, 4)
print("Sum:", result)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("add = lambda x, y: x + y\nresult = add(3, 4)\nprint(\"Sum:\", result)"), '_blank')}>Run Code</button>
    </div>
  );
}

export default PythonLambdaFunctions;
