import React from "react";

const CppSyntax = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>C++ Syntax</h2>
      <p>
        C++ is a powerful programming language known for its versatile syntax.
        Let&quot;s explore some essential elements and syntax rules in C++:
      </p>

      <h3>Hello World</h3>
      <p>A traditional &quot;Hello, World!&quot; program in C++:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`#include <iostream>
using namespace std;

int main() {
    cout << "Hello, World!" << endl;
    return 0;
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                '#include <iostream>\nusing namespace std;\n\nint main() {\n    cout << "Hello, World!" << endl;\n    return 0;\n}'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Variables</h3>
      <p>Declaring and using variables in C++:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`int age = 30;
double price = 19.99;
char grade = 'A';`}
        </code>
      </pre>
      <p>Example with `cout` output:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`int main() {
    int age = 30;
    double price = 19.99;
    char grade = 'A';

    cout << "Age: " << age << endl;
    cout << "Price: " << price << endl;
    cout << "Grade: " << grade << endl;

    return 0;
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'int main() {\n    int age = 30;\n    double price = 19.99;\n    char grade = \'A\';\n\n    cout << "Age: " << age << endl;\n    cout << "Price: " << price << endl;\n    cout << "Grade: " << grade << endl;\n\n    return 0;\n}'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Conditional Statements</h3>
      <p>Using `if` and `else` statements in C++:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`int number = 42;

if (number > 0) {
    cout << "Number is positive." << endl;
} else if (number < 0) {
    cout << "Number is negative." << endl;
} else {
    cout << "Number is zero." << endl;
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'int main() {\n    int number = 42;\n\n    if (number > 0) {\n        cout << "Number is positive." << endl;\n    } else if (number < 0) {\n        cout << "Number is negative." << endl;\n    } else {\n        cout << "Number is zero." << endl;\n    }\n\n    return 0;\n}'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Loops</h3>
      <p>Using `for` and `while` loops in C++:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`for (int i = 0; i < 5; i++) {
    cout << "Iteration " << i << endl;
}

int j = 0;
while (j < 3) {
    cout << "While loop: " << j << endl;
    j++;
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'int main() {\n    for (int i = 0; i < 5; i++) {\n        cout << "Iteration " << i << endl;\n    }\n\n    int j = 0;\n    while (j < 3) {\n        cout << "While loop: " << j << endl;\n        j++;\n    }\n\n    return 0;\n}'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Functions</h3>
      <p>Defining and calling functions in C++:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`int add(int a, int b) {
    return a + b;
}

int main() {
    int result = add(5, 3);
    cout << "Result: " << result << endl;
    return 0;
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'int add(int a, int b) {\n    return a + b;\n}\n\nint main() {\n    int result = add(5, 3);\n    cout << "Result: " << result << endl;\n    return 0;\n}'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppSyntax;
