import React from 'react';

const PythonModules = () => {
  return (
    <div>
      <h2>Working with Python Modules</h2>
      <p>Modules are Python files containing definitions and statements. They allow you to organize code and reuse functionality. Python provides several built-in modules, and you can create your own custom modules.</p>

      <h3>Importing Modules</h3>
      <p>To use a module, you need to import it. Here&apos;s how to import Python&apos;s built-in <code>math</code> module and calculate the square root:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import math

result = math.sqrt(25)
print(result)`}
        </code>
      </pre>

      <h3>Create a Custom Module</h3>
      <p>You can create your own module by placing Python code in a `.py` file. For example, create a file named <code>my_module.py</code> with the following content:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# my_module.py
def greet(name):
    return f"Hello, {name}"`}
        </code>
      </pre>

      <p>Now, you can import and use your custom module:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import my_module

message = my_module.greet("Alice")
print(message)`}
        </code>
      </pre>

      <h3>Alias for Modules</h3>
      <p>You can use an alias when importing modules:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import math as m

result = m.sqrt(16)
print(result)`}
        </code>
      </pre>

      <h3>Import Specific Items</h3>
      <p>You can import specific items from a module:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from math import sqrt

result = sqrt(9)
print(result)`}
        </code>
      </pre>

      <h3>Built-in Modules</h3>
      <p>Python provides numerous built-in modules for various tasks, such as <code>random</code> for random number generation and <code>os</code> for interacting with the operating system. Here&apos;s an example using the <code>random</code> module:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import random

random_number = random.randint(1, 10)
print(random_number)`}
        </code>
      </pre>

      <h3>List of Commonly Used Modules</h3>
      <p>Here are some commonly used built-in modules:</p>
      <ul>
        <li><code>math</code>: Mathematical functions</li>
        <li><code>random</code>: Random number generation</li>
        <li><code>os</code>: Operating system interfaces</li>
        <li><code>datetime</code>: Date and time functions</li>
        <li><code>json</code>: JSON encoding and decoding</li>
        <li><code>requests</code>: HTTP requests</li>
      </ul>

      <p>You can explore and use these modules as per your requirements.</p>
    </div>
  );
}

export default PythonModules;
