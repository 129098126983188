import React from 'react';

const JavaScriptFunctions = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Functions</h2>
      <p>Functions are blocks of reusable code in JavaScript. They are used to perform specific tasks and can take parameters and return values. Let&apos;s explore some common usages:</p>

      <h3>Defining Functions</h3>
      <p>You can define functions using the <code>function</code> keyword or arrow functions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`function greet(name) {
  return 'Hello, ' + name;
}

const add = (a, b) => a + b;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("function greet(name) {\n  return 'Hello, ' + name;\n}\n\nconst add = (a, b) => a + b;"), '_blank')}>Run Code</button>

      <h3>Function Parameters</h3>
      <p>Functions can accept parameters which are values you pass to the function when calling it:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`function multiply(a, b) {
  return a * b;
}

const result = multiply(5, 3); // Returns 15`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("function multiply(a, b) {\n  return a * b;\n}\n\nconst result = multiply(5, 3); // Returns 15"), '_blank')}>Run Code</button>

      <h3>Function Return</h3>
      <p>Functions can return values using the <code>return</code> statement:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`function subtract(a, b) {
  return a - b;
}

const result = subtract(10, 3); // Returns 7`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("function subtract(a, b) {\n  return a - b;\n}\n\nconst result = subtract(10, 3); // Returns 7"), '_blank')}>Run Code</button>

      <h3>Anonymous Functions</h3>
      <p>Functions can be assigned to variables and used as anonymous functions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const multiply = function(a, b) {
  return a * b;
};

const product = multiply(4, 5); // Returns 20`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const multiply = function(a, b) {\n  return a * b;\n};\n\nconst product = multiply(4, 5); // Returns 20"), '_blank')}>Run Code</button>

      <h3>Function Expression</h3>
      <p>Function expressions are used as arguments to other functions or are assigned to variables:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numbers = [1, 2, 3, 4, 5];

const squared = numbers.map(function(num) {
  return num * num;
});`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numbers = [1, 2, 3, 4, 5];\n\nconst squared = numbers.map(function(num) {\n  return num * num;\n});"), '_blank')}>Run Code</button>

      <h3>Function with Default Parameters</h3>
<p>You can provide default values for function parameters:</p>
<pre className="bg-dark text-light p-2">
  <code>
    {`function greet(name, greeting = 'Hello') {
  return greeting + ', ' + name;
}

const message = greet('Alice'); // Returns 'Hello, Alice'`}
  </code>
</pre>
<button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("function greet(name, greeting = 'Hello') {\n  return greeting + ', ' + name;\n}\n\nconst message = greet('Alice'); // Returns 'Hello, Alice'"), '_blank')}>Run Code</button>

<h3>Recursive Functions</h3>
<p>JavaScript functions can call themselves to solve problems recursively:</p>
<pre className="bg-dark text-light p-2">
  <code>
    {`function factorial(n) {
  if (n === 0) {
    return 1;
  }
  return n * factorial(n - 1);
}

const result = factorial(5); // Returns 120`}
  </code>
</pre>
<button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("function factorial(n) {\n  if (n === 0) {\n    return 1;\n  }\n  return n * factorial(n - 1);\n}\n\nconst result = factorial(5); // Returns 120"), '_blank')}>Run Code</button>

<h3>Arrow Function with Multiple Lines</h3>
<p>Arrow functions can be used for concise expressions or multiple lines of code:</p>
<pre className="bg-dark text-light p-2">
  <code>
    {`const calculate = (a, b) => {
  const sum = a + b;
  const product = a * b;
  return { sum, product };
};

const result = calculate(3, 4); // Returns { sum: 7, product: 12 }`}
  </code>
</pre>
<button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const calculate = (a, b) => {\n  const sum = a + b;\n  const product = a * b;\n  return { sum, product };\n};\n\nconst result = calculate(3, 4); // Returns { sum: 7, product: 12 }"), '_blank')}>Run Code</button>

<h3>Immediately Invoked Function Expression (IIFE)</h3>
<p>IIFE is a function that runs as soon as it&apos;s defined:</p>
<pre className="bg-dark text-light p-2">
  <code>
    {`(function() {
  console.log('This is an IIFE');
})(); // Outputs 'This is an IIFE'`}
  </code>
</pre>
<button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("(function() {\n  console.log('This is an IIFE');\n})(); // Outputs 'This is an IIFE'"), '_blank')}>Run Code</button>





    </div>
  );
}

export default JavaScriptFunctions;
