import React, { useState, useEffect } from "react";
import { Card, ListGroup, Form,CardGroup} from "react-bootstrap";
import questions from "./SpecialRelativity.json";
import NavBar from "../../Navbar";
import Footer from "../../Footer";
import WritingJobs from "../../../ads/WritingJobs";
import ForexTrading from "../../../ads/ForexTrading";
import LiveChat from "../../../ads/LiveChat";



function SpecialRelativity() {
  const [questionData, setQuestionData] = useState([]);

  useEffect(() => {
    const formattedQuestions = questions.map((question, index) => {
      return {
        question: question.question,
        correct_answer: question.correct_answer,
        incorrect_answers: question.incorrect_answers,
        userAnswer: "",
        isCorrect: false,
        number: index + 1,
      };
    });
    setQuestionData(formattedQuestions);
  }, []);

  function handleAnswerChange(questionIndex, event) {
    const selectedAnswer = event.target.value;
    setQuestionData((prevQuestions) =>
      prevQuestions.map((question, index) => {
        if (index === questionIndex) {
          return {
            ...question,
            userAnswer: selectedAnswer,
            isCorrect: selectedAnswer === question.correct_answer,
          };
        } else {
          return question;
        }
      })
    );
  }

  return (
    
    <Card>
      <NavBar />
      <Card.Header as="h5" className="p-5">Special Relativity Questions</Card.Header>
      <CardGroup className="container">
        <Card>
          <Card.Body>
            <WritingJobs
              productLink="https://hop.clickbank.net/?affiliate=okyerejr&vendor=easywriter&pid=instantapply&ifso="
              imageUrl="https://affiliatesstuff.s3.amazonaws.com/POWJ/POWJ%20-%20FB%20Ad%20Images/POWJ%20FB%20Ad%20image%202.png"
              altText="paid online writing jobs"
              title=""
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <ForexTrading
              productLink="https://hop.clickbank.net/?affiliate=okyerejr&vendor=1000PB"
              imageUrl="https://www.1000pipbuilder.com/wp-content/plugins/new-modules/images/1000PB-v01-300x250.jpg"
              altText="forex trading"
              title=""
            />
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <LiveChat
              productLink="https://hop.clickbank.net/?affiliate=okyerejr&vendor=socialsrep"
              imageUrl="https://affiliatesstuff.s3.amazonaws.com/SSR/SSR%20-%20FB%20Ad%20Images/SSR%20FB%20Ad%20Image%205.png"
              altText="live chat support"
              title=""
            />
          </Card.Body>
        </Card>
    
      </CardGroup>
      <Card.Body>
        <ListGroup variant="flush">
          {questionData.map((question) => (
            <ListGroup.Item key={question.number}>
              <div className="d-flex align-items-start">
                <div className="mr-3">{question.number}.</div>
                <div>
                  <h6>{question.question}</h6>
                  <Form>
                    {shuffleArray([
                      ...question.incorrect_answers,
                      question.correct_answer,
                    ]).map((answer, i) => (
                      <Form.Check
                        key={i}
                        type="radio"
                        name={`question-${question.number}`}
                        label={answer}
                        value={answer}
                        checked={answer === question.userAnswer}
                        onChange={(event) =>
                          handleAnswerChange(question.number - 1, event)
                        }
                        className={
                          question.isCorrect
                            ? "text-success"
                            : answer === question.userAnswer
                            ? "text-danger"
                            : ""
                        }
                      />
                    ))}
                  </Form>
                  {question.userAnswer && (
                    <p className="mt-3">
                      Your answer:{" "}
                      <span
                        className={
                          question.isCorrect ? "text-success" : "text-danger"
                        }
                      >
                        {question.userAnswer}{" "}
                        {question.isCorrect ? "(Correct)" : "(Incorrect)"}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
      <Footer />
    </Card>
  );
}

function shuffleArray(array) {
  // Fisher-Yates shuffle algorithm
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export default SpecialRelativity;
