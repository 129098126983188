import React from 'react';

const ModelEvaluationValidation = () => {
  return (
    <div>
      <h2>Model Evaluation and Validation</h2>
      <p>Model evaluation is crucial to determine how well machine learning models perform. Validation techniques help assess model accuracy and generalization. Let&apos;s explore some evaluation and validation methods:</p>

      <h3>1. Train-Test Split</h3>
      <p>Splitting the dataset into training and testing sets helps assess a model&apos;s performance:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.model_selection import train_test_split

# Split data into 70% training and 30% testing
X_train, X_test, y_train, y_test = train_test_split(X, y, test_size=0.3)

# Train the model on the training set
model.fit(X_train, y_train)

# Evaluate the model on the testing set
accuracy = model.score(X_test, y_test)
`}
        </code>
      </pre>
      <p>This code shows how to split data for training and testing and calculate model accuracy.</p>

      <h3>2. Cross-Validation</h3>
      <p>Cross-validation assesses model performance using multiple train-test splits:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.model_selection import cross_val_score

# Perform 5-fold cross-validation
scores = cross_val_score(model, X, y, cv=5)

# Calculate mean accuracy
mean_accuracy = scores.mean()
`}
        </code>
      </pre>
      <p>In this example, a 5-fold cross-validation is used to estimate model accuracy.</p>

      <h3>3. Confusion Matrix</h3>
      <p>A confusion matrix helps assess the performance of classification models:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.metrics import confusion_matrix

# Generate a confusion matrix
conf_matrix = confusion_matrix(true_labels, predicted_labels)
`}
        </code>
      </pre>
      <p>This code snippet demonstrates how to create and analyze a confusion matrix.</p>

      <h3>4. Precision, Recall, F1-Score</h3>
      <p>Precision, recall, and F1-score provide insights into classification model performance:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.metrics import precision_score, recall_score, f1_score

# Calculate precision
precision = precision_score(true_labels, predicted_labels)

# Calculate recall
recall = recall_score(true_labels, predicted_labels)

# Calculate F1-score
f1 = f1_score(true_labels, predicted_labels)
`}
        </code>
      </pre>
      <p>This code calculates precision, recall, and F1-score for classification models.</p>

      <h3>5. ROC Curve and AUC</h3>
      <p>Receiver Operating Characteristic (ROC) curves and Area Under the Curve (AUC) assess binary classification models:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.metrics import roc_curve, roc_auc_score
import matplotlib.pyplot as plt

# Generate ROC curve
fpr, tpr, thresholds = roc_curve(true_labels, predicted_probs)

# Calculate AUC
auc = roc_auc_score(true_labels, predicted_probs)

# Plot ROC curve
plt.figure()
plt.plot(fpr, tpr, label='ROC curve (AUC = {:.2f})'.format(auc))
plt.xlabel('False Positive Rate')
plt.ylabel('True Positive Rate')
plt.title('Receiver Operating Characteristic (ROC)')
plt.legend(loc='lower right')
plt.show()
`}
        </code>
      </pre>
      <p>This code showcases the creation and visualization of ROC curves and AUC calculation.</p>

      <h3>6. Hyperparameter Tuning</h3>
      <p>Grid search helps find optimal hyperparameters for machine learning models:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.model_selection import GridSearchCV

# Define hyperparameter grid
param_grid = {'C': [0.1, 1, 10], 'gamma': [0.001, 0.01, 0.1]}

# Create a grid search model
grid_search = GridSearchCV(SVM_model, param_grid, cv=5)

# Find the best hyperparameters
grid_search.fit(X, y)

# Get the best model
best_model = grid_search.best_estimator_
`}
        </code>
      </pre>
      <p>This code example demonstrates hyperparameter tuning using grid search.</p>

      <h3>Overfitting and Underfitting</h3>
      <p>Understanding overfitting and underfitting is essential for model evaluation:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Example of overfitting
overfit_model = DecisionTree(max_depth=None)
overfit_model.fit(X_train, y_train)

# Example of underfitting
underfit_model = DecisionTree(max_depth=1)
underfit_model.fit(X_train, y_train)`}
        </code>
      </pre>
      <p>These examples illustrate the concepts of overfitting (complex model) and underfitting (simple model).</p>

      <h3>Model Selection and Tuning</h3>
      <p>Selecting the right model and tuning hyperparameters are critical steps:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Example of model selection
best_model = grid_search.best_estimator_

# Example of hyperparameter tuning
param_grid = {'C': [0.1, 1, 10], 'gamma': [0.001, 0.01, 0.1]}
grid_search = GridSearchCV(SVM_model, param_grid, cv=5)
grid_search.fit(X, y)`}
        </code>
      </pre>
      <p>These code snippets demonstrate how to select the best model and perform hyperparameter tuning using grid search.</p>

      <h3>Model Deployment</h3>
      <p>Deploying a model for real-world use is the final step in machine learning:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Deploy a model using Flask (example)
from flask import Flask, request, jsonify

app = Flask(__name__)

@app.route('/predict', methods=['POST'])
def predict():
    data = request.get_json()
    prediction = best_model.predict(data)
    return jsonify({'prediction': prediction})

if __name__ == '__main__':
    app.run(debug=True)`}
        </code>
      </pre>
      <p>This code showcases a simple model deployment using Flask as an example.</p>


    </div>
  );
}

export default ModelEvaluationValidation;
