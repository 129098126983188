import React from 'react';

const CppPolymorphism = () => {
  return (
    <div>
      <h2>C++ Polymorphism</h2>
      <p>
        Polymorphism is one of the fundamental concepts in object-oriented programming (OOP). It allows objects of different classes to be treated as objects of a common base class. C++ supports polymorphism through virtual functions and function overriding.
      </p>

      <h3>Code Examples:</h3>

      <h3>Function Overriding</h3>
      <p>
        Function overriding occurs when a derived class provides a specific implementation of a function that is already defined in its base class. The base class function must be declared as <code>virtual</code> in the base class and overridden in the derived class.
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

class Animal {
public:
    virtual void speak() {
        std::cout << "Animal speaks" << std::endl;
    }
};

class Dog : public Animal {
public:
    void speak() override {
        std::cout << "Dog barks" << std::endl;
    }
};

int main() {
    Animal* a = new Dog();
    a->speak(); // Calls the Dog class's speak method
    delete a;

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

class Animal {
public:
    virtual void speak() {
        std::cout << "Animal speaks" << std::endl;
    }
};

class Dog : public Animal {
public:
    void speak() override {
        std::cout << "Dog barks" << std::endl;
    }
};

int main() {
    Animal* a = new Dog();
    a->speak(); // Calls the Dog class's speak method
    delete a;

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Polymorphism with Abstract Base Class</h3>
      <p>
        An abstract base class contains pure virtual functions that must be overridden in derived classes. Instances of derived classes can be treated as instances of the abstract base class.
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

class Shape {
public:
    virtual void draw() = 0; // Pure virtual function
};

class Circle : public Shape {
public:
    void draw() override {
        std::cout << "Drawing a circle" << std::endl;
    }
};

class Rectangle : public Shape {
public:
    void draw() override {
        std::cout << "Drawing a rectangle" << std::endl;
    }
};

int main() {
    Shape* shapes[] = {new Circle(), new Rectangle()};
    for (Shape* shape : shapes) {
        shape->draw();
    }
    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

class Shape {
public:
    virtual void draw() = 0; // Pure virtual function
};

class Circle : public Shape {
public:
    void draw() override {
        std::cout << "Drawing a circle" << std::endl;
    }
};

class Rectangle : public Shape {
public:
    void draw() override {
        std::cout << "Drawing a rectangle" << std::endl;
    }
};

int main() {
    Shape* shapes[] = {new Circle(), new Rectangle()};
    for (Shape* shape : shapes) {
        shape->draw();
    }
    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppPolymorphism;
