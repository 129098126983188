import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";


function Background() {
  return (
    <div>
      <Card>
        <Card.Img
          src="img/learnBg.png"
          className="backgroundHeight"
          alt="Group Learning"
        />
        <Card.ImgOverlay className="linearBg"></Card.ImgOverlay>

        <Card.ImgOverlay className="centered  ">
          <Card.Title className="mainText">
            A great place for learning.
          </Card.Title>
          <Card.Text className="headText">
            The best and cheapest way of getting to know learning to make a
            better tomorrow.
          </Card.Text>

          <Link to="/courses">
            <button type="button" className="btn btnBg">
              Get Started
            </button>
          </Link>
        </Card.ImgOverlay>
      
        <br />
        
      </Card>

      <p className="container-fluid animation text-center fs-5">
        Welcome to esurde learning platform
     
      </p>
    </div>
  );
}

export default Background;
