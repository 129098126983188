import React from 'react';

const JavaScriptRegExp = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Regular Expressions (RegEx)</h2>
      <p>Regular expressions are patterns used to match character combinations in strings. They provide powerful text-search and text-manipulation capabilities. Here are some common usages:</p>

      <h3>Basic RegExp Example</h3>
      <p>Use a regular expression to match a specific pattern in a string:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const text = 'Hello, World!';
const pattern = /Hello/;
const result = pattern.test(text);
console.log('Pattern matched:', result); // Output: true`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const text = 'Hello, World!';\nconst pattern = /Hello/;\nconst result = pattern.test(text);\nconsole.log('Pattern matched:', result);"), '_blank')}>Run Code</button>

      <h3>Regular Expression Flags</h3>
      <p>Flags are used to perform case-insensitive, global, or multi-line searches:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const text = 'Hello, World! Hello again.';
const pattern = /hello/i;
const result = text.match(pattern);
console.log('Matched text:', result); // Output: 'Hello', 'hello'`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const text = 'Hello, World! Hello again.';\nconst pattern = /hello/i;\nconst result = text.match(pattern);\nconsole.log('Matched text:', result);"), '_blank')}>Run Code</button>

      <h3>Character Classes</h3>
      <p>Character classes allow you to match any character from a specified set:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const text = 'Color: red, green, blue.';
const pattern = /[a-z]+/g;
const result = text.match(pattern);
console.log('Matched words:', result); // Output: 'ed', 'red', 'green', 'blue'`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const text = 'Color: red, green, blue.';\nconst pattern = /[a-z]+/g;\nconst result = text.match(pattern);\nconsole.log('Matched words:', result);"), '_blank')}>Run Code</button>

      <h3>Quantifiers</h3>
      <p>Quantifiers specify how many instances of a character, group, or character class should be matched:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const text = 'aaabbbccc';
const pattern = /a{3}/;
const result = pattern.test(text);
console.log('Pattern matched:', result); // Output: true`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const text = 'aaabbbccc';\nconst pattern = /a{3}/;\nconst result = pattern.test(text);\nconsole.log('Pattern matched:', result);"), '_blank')}>Run Code</button>

      <h3>Replacing Text</h3>
      <p>Regular expressions can be used to replace text with a specified string:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const text = 'Hello, World! Hello again.';
const pattern = /hello/gi;
const replacedText = text.replace(pattern, 'Hi');
console.log('Replaced text:', replacedText); // Output: 'Hi, World! Hi again.'`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const text = 'Hello, World! Hello again.';\nconst pattern = /hello/gi;\nconst replacedText = text.replace(pattern, 'Hi');\nconsole.log('Replaced text:', replacedText);"), '_blank')}>Run Code</button>

      <h3>Validating Email</h3>
      <p>Use a regular expression to validate an email address:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const email = 'test@example.com';
const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$/;
const isValid = pattern.test(email);
console.log('Valid email:', isValid); // Output: true`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const email = 'test@example.com';\nconst pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$/;\nconst isValid = pattern.test(email);\nconsole.log('Valid email:', isValid);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptRegExp;
