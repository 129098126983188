import React from "react";

const CppWhileDoWhileLoop = () => {
  return (
    <div>
      <h2>C++ While and Do-While Loops</h2>
      <p>
        In C++, <code>while</code> and <code>do-while</code> loops are used to
        repeatedly execute a block of code as long as a specified condition is
        met. Here&quot;s the syntax:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
while (condition) {
    // Code to be executed
}
          `}
        </code>
      </pre>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
do {
    // Code to be executed
} while (condition);
          `}
        </code>
      </pre>

      <h3>Code Examples:</h3>

      <h3>While Loop</h3>
      <p>
        The <code>while</code> loop will execute the code block as long as the
        specified condition is true:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int i = 0;
    while (i < 5) {
        cout << "Iteration " << i << endl;
        i++;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int i = 0;
    while (i < 5) {
        cout << "Iteration " << i << endl;
        i++;
    }

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Do-While Loop</h3>
      <p>
        The <code>do-while</code> loop will execute the code block at least
        once, and then it will continue as long as the specified condition is
        true:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int i = 0;
    do {
        cout << "Iteration " << i << endl;
        i++;
    } while (i < 5);

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int i = 0;
    do {
        cout << "Iteration " << i << endl;
        i++;
    } while (i < 5);

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Break and Continue Statements</h3>
      <p>
        You can use <code>break</code> to exit a loop prematurely and{" "}
        <code>continue</code> to skip the current iteration in both{" "}
        <code>while</code> and <code>do-while</code> loops. Here are examples of
        using these statements:
      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int i = 0;
    while (i < 5) {
        if (i == 3) {
            cout << "Break at i = 3" << endl;
            break;
        }
        cout << "Iteration " << i << endl;
        i++;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int i = 0;
    do {
        i++;
        if (i == 3) {
            cout << "Continue at i = 3" << endl;
            continue;
        }
        cout << "Iteration " << i << endl;
    } while (i < 5);

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int i = 0;
    while (i < 5) {
        if (i == 3) {
            cout << "Break at i = 3" << endl;
            break;
        }
        cout << "Iteration " << i << endl;
        i++;
    }

    return 0;
}
`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppWhileDoWhileLoop;
