import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer";
import NavBar from "../../Navbar";

function MathsTopicsPage() {
  const Topics = [
    {
      id: 1,
      topic: "VECTOR AND VECTOR SPACES",
      link: "/maths/vector-and-vector-spaces",
    },
    {
      id:2 ,
      topic: "METRICES AND SYSTEMS OF LINEAR EQUATIONS",
      link: "/maths/metrices-and-systems-of-linear-equations",
    },
    {
      id:3 ,
      topic: "EIGENVALUES, EIGENVECTORS AND DIAGONALIZATION",
      link: "/maths/eigenvalues-eigenvectors-and-diagonalization",
    },
    {
      id:4 ,
      topic: "FIRST ORDER DIFFERENTIAL EQUATIONS",
      link: "/maths/first-order-differential-equations",
    },
    {
      id:5 ,
      topic: "SECOND AND HIGHER ORDER LINEAR DIFFERENTIAL EQUATIONS AND SYSTEMS",
      link: "/maths/second-and-higher-order-linear-differential-equations-and-systems",
    },
    {
      id:6 ,
      topic: "THE LAPLACE TRANSFORM",
      link: "/maths/the-laplace-transform",
    },
    {
      id:7 ,
      topic: "DIFFERENTIAL EQUATIONS",
      link: "/maths/differential-equations",
    }
  ];

  return (
    <div>
      <NavBar />
      <div
        className="container mt-5 mb-5 p-3"
      
      >
        <h6 className="topics-border-btn">Topics</h6>
        {Topics.map((topic) => (
          <div key={topic.id}>
            <Link to={topic.link} style={{textDecoration:"none"}}>
              <p className = "p-1   topic-hover" > {topic.topic}</p>
            </Link>
           
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default MathsTopicsPage;
