import React from 'react';

const JavaScriptOperators = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Operators</h2>
      <p>Operators are symbols or keywords that perform operations on operands. JavaScript includes various types of operators. Let&apos;s explore some of the common operators:</p>

      <h3>Arithmetic Operators</h3>
      <p>Arithmetic operators perform mathematical operations:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let a = 10;
let b = 5;
const sum = a + b;
const difference = a - b;
const product = a * b;
const quotient = a / b;
const remainder = a % b;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("let a = 10;\nlet b = 5;\nconst sum = a + b;\nconst difference = a - b;\nconst product = a * b;\nconst quotient = a / b;\nconst remainder = a % b;"), '_blank')}>Run Code</button>

      <h3>Comparison Operators</h3>
      <p>Comparison operators compare values and return true or false:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const x = 5;
const y = 10;
const isEqual = x === y;
const isNotEqual = x !== y;
const isGreaterThan = x > y;
const isLessThan = x < y;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const x = 5;\nconst y = 10;\nconst isEqual = x === y;\nconst isNotEqual = x !== y;\nconst isGreaterThan = x > y;\nconst isLessThan = x < y;"), '_blank')}>Run Code</button>

      <h3>Logical Operators</h3>
      <p>Logical operators are used to combine and manipulate boolean values:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const isTrue = true;
const isFalse = false;
const andResult = isTrue && isFalse;
const orResult = isTrue || isFalse;
const notResult = !isTrue;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const isTrue = true;\nconst isFalse = false;\nconst andResult = isTrue && isFalse;\nconst orResult = isTrue || isFalse;\nconst notResult = !isTrue;"), '_blank')}>Run Code</button>

      <h3>Assignment Operators</h3>
      <p>Assignment operators assign values to variables:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let num = 5;
num += 2;
num -= 1;
num *= 3;
num /= 2;
num %= 4;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("let num = 5;\nnum += 2;\nnum -= 1;\nnum *= 3;\nnum /= 2;\nnum %= 4;"), '_blank')}>Run Code</button>

      <h3>String Operators</h3>
      <p>String operators can concatenate strings using the + operator:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const firstName = 'John';
const lastName = 'Doe';
const fullName = firstName + ' ' + lastName;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const firstName = 'John';\nconst lastName = 'Doe';\nconst fullName = firstName + ' ' + lastName;"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptOperators;
