import React from 'react';

const JavaScriptArrays = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Arrays</h2>
      <p>JavaScript arrays are used to store multiple values in a single variable. Let&apos;s explore some common array operations:</p>

      <h3>Creating Arrays</h3>
      <p>Create arrays with different elements:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
const numbers = [1, 2, 3, 4, 5];
const mixedArray = ['apple', 42, true];`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nconst numbers = [1, 2, 3, 4, 5];\nconst mixedArray = ['apple', 42, true];"), '_blank')}>Run Code</button>

      <h3>Accessing Array Elements</h3>
      <p>Access elements by their index:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
const firstFruit = fruits[0];  // 'apple'
const secondFruit = fruits[1]; // 'banana'`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nconst firstFruit = fruits[0];  // 'apple'\nconst secondFruit = fruits[1]; // 'banana'"), '_blank')}>Run Code</button>

      <h3>Array Length</h3>
      <p>Get the length of an array:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
const length = fruits.length;  // 3`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nconst length = fruits.length;  // 3"), '_blank')}>Run Code</button>

      <h3>Adding and Removing Elements</h3>
      <p>Add or remove elements from an array:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
fruits.push('date'); // Add at the end
fruits.pop();        // Remove from the end

const numbers = [1, 2, 3, 4, 5];
numbers.unshift(0);   // Add at the beginning
numbers.shift();      // Remove from the beginning`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nfruits.push('date'); // Add at the end\nfruits.pop();        // Remove from the end\n\nconst numbers = [1, 2, 3, 4, 5];\nnumbers.unshift(0);   // Add at the beginning\nnumbers.shift();      // Remove from the beginning"), '_blank')}>Run Code</button>

      <h3>Iterating Through Arrays</h3>
      <p>Loop through array elements:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const fruits = ['apple', 'banana', 'cherry'];
fruits.forEach((fruit) => {
    console.log(fruit);
});

const numbers = [1, 2, 3, 4, 5];
for (let i = 0; i < numbers.length; i++) {
    console.log(numbers[i]);
}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const fruits = ['apple', 'banana', 'cherry'];\nfruits.forEach((fruit) => {\n    console.log(fruit);\n});\n\nconst numbers = [1, 2, 3, 4, 5];\nfor (let i = 0; i < numbers.length; i++) {\n    console.log(numbers[i]);\n}"), '_blank')}>Run Code</button>

      <h3>Array Methods</h3>
      <p>Use various array methods to manipulate and transform arrays:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numbers = [1, 2, 3, 4, 5];
const doubledNumbers = numbers.map((number) => number * 2);
const filteredNumbers = numbers.filter((number) => number % 2 === 0);
const sumOfNumbers = numbers.reduce((total, number) => total + number, 0);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numbers = [1, 2, 3, 4, 5];\nconst doubledNumbers = numbers.map((number) => number * 2);\nconst filteredNumbers = numbers.filter((number) => number % 2 === 0);\nconst sumOfNumbers = numbers.reduce((total, number) => total + number, 0);"), '_blank')}>Run Code</button>

      <h3>Multi-Dimensional Arrays</h3>
      <p>Create arrays of arrays for more complex data structures:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const matrix = [
    [1, 2, 3],
    [4, 5, 6],
    [7, 8, 9]
];`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const matrix = [\n    [1, 2, 3],\n    [4, 5, 6],\n    [7, 8, 9]\n];"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptArrays;
