import React from 'react';

const FeatureEngineering = () => {
  return (
    <div>
      <h2>Feature Engineering</h2>
      <p>Feature engineering involves creating, modifying, or selecting features from your data to improve the performance of machine learning models. Let&apos;s explore some key concepts and code examples related to feature engineering.</p>

      <h3>1. Feature Selection</h3>
      <p>Feature selection is the process of choosing relevant features for your model:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.feature_selection import SelectKBest, f_classif

# Select the top k' features using ANOVA F-statistic
selector = SelectKBest(score_func=f_classif, k=5)
X_new = selector.fit_transform(X, y)
`}
        </code>
      </pre>
      <p>In this code, we use scikit-learn to select the top &apos;k&apos; features based on ANOVA F-statistics.</p>

      <h3>2. Feature Scaling</h3>
      <p>Feature scaling ensures that features have similar scales, which is important for many machine learning algorithms:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.preprocessing import StandardScaler

# Standardize features
scaler = StandardScaler()
X_scaled = scaler.fit_transform(X)
`}
        </code>
      </pre>
      <p>This code standardizes features using the StandardScaler from scikit-learn.</p>

      <h3>3. Dimensionality Reduction</h3>
      <p>Dimensionality reduction reduces the number of features while retaining as much information as possible:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.decomposition import PCA

# Perform Principal Component Analysis (PCA)
pca = PCA(n_components=2)
X_pca = pca.fit_transform(X)
`}
        </code>
      </pre>
      <p>Here, we use Principal Component Analysis (PCA) to reduce the feature dimensions to 2 components.</p>

      <h3>4. More Feature Engineering Techniques</h3>
      <p>Feature engineering includes various techniques like encoding categorical variables, creating interaction terms, and more. Here&apos;s an example of one-hot encoding:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import pandas as pd

# Perform one-hot encoding on categorical variables
data = pd.get_dummies(data, columns=['categorical_column'])
`}
        </code>
      </pre>
      <p>We use Pandas to perform one-hot encoding on categorical variables.</p>

      <h3>5. Feature Engineering for Text Data</h3>
      <p>Text data requires specific feature engineering, such as TF-IDF transformation:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from sklearn.feature_extraction.text import TfidfVectorizer

# Perform TF-IDF transformation on text data
tfidf_vectorizer = TfidfVectorizer()
X_tfidf = tfidf_vectorizer.fit_transform(text_data)
`}
        </code>
      </pre>
      <p>This code shows how to apply TF-IDF transformation to text data using scikit-learn.</p>

      <h3>6. Feature Engineering for Image Data</h3>
      <p>Image data may require preprocessing techniques like resizing and normalization:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`from skimage import io, transform

# Load and preprocess an image
image = io.imread('image.jpg')
resized_image = transform.resize(image, (128, 128))
`}
        </code>
      </pre>
      <p>This code loads an image, resizes it, and performs preprocessing using scikit-image.</p>
    </div>
  );
}

export default FeatureEngineering;
