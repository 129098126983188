import React from "react";

const PythonJSON = () => {
  return (
    <div>
      <h2>Working with JSON in Python</h2>
      <p>
        JSON (JavaScript Object Notation) is a lightweight data-interchange
        format used for data exchange between a server and a web application.
        Python provides built-in support for working with JSON through the{" "}
        <code>json</code> module.
      </p>

      <h3>Importing the json Module</h3>
      <p>
        To get started, import the <code>json</code> module:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>{`import json`}</code>
      </pre>

      <h3>Encoding (Serializing) to JSON</h3>
      <p>
        You can convert Python data structures into a JSON format using the{" "}
        <code>json.dumps()</code> function:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import json

data = {
    "name": "Alice",
    "age": 30,
    "city": "New York"
}

json_data = json.dumps(data)
print(json_data)`}
        </code>
      </pre>

      <h3>Decoding (Deserializing) from JSON</h3>
      <p>
        To convert JSON data back into Python data structures, use the{" "}
        <code>json.loads()</code> function:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import json

json_data = '{"name": "Bob", "age": 25, "city": "Los Angeles"}'
data = json.loads(json_data)
print(data)`}
        </code>
      </pre>

      <h3>Working with JSON Files</h3>
      <p>
        You can also read from and write to JSON files using the{" "}
        <code>json.dump()</code> and <code>json.load()</code> methods:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import json

# Writing to a JSON file
data = {
    "name": "Charlie",
    "age": 35,
    "city": "Chicago"
}

with open("data.json", "w") as json_file:
    json.dump(data, json_file)

# Reading from a JSON file
with open("data.json", "r") as json_file:
    loaded_data = json.load(json_file)

print(loaded_data)`}
        </code>
      </pre>

      <h3>Handling JSON Errors</h3>
      <p>
        When working with JSON, it&apos;s essential to handle exceptions in case
        of invalid JSON data:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import json

json_data = 'invalid_json_data'
try:
    data = json.loads(json_data)
    print(data)
except json.JSONDecodeError as e:
    print("JSON decoding error:", str(e))`}
        </code>
      </pre>

      <h3>Nested JSON Objects</h3>
      <p>
        JSON can represent nested objects. Here&apos;s an example of nested
        JSON:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import json

nested_json = '{"person": {"name": "David", "age": 40}}'
data = json.loads(nested_json)
print(data)`}
        </code>
      </pre>

      <h3>JSON Array</h3>
      <p>JSON can also represent arrays (lists in Python):</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import json

json_array = '[1, 2, 3, 4, 5]'
data = json.loads(json_array)
print(data)`}
        </code>
      </pre>
    </div>
  );
};

export default PythonJSON;
