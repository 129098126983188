import React from 'react';

const PythonComments = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div >
      <h2 >Python Comments</h2>
      <div >
        <div >
          <p>In Python, comments are used to provide explanations within your code. Comments are ignored by the Python interpreter and are only meant for human readers. Here&apos;s how you can use comments effectively:</p>

          <h3>Single-Line Comments</h3>
          <p>Single-line comments are created using the `#` character. Everything after `#` on the same line is treated as a comment. For example:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`# This is a single-line comment
print("This is not a comment")`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("# This is a single-line comment\nprint('This is not a comment')"), '_blank')}>Run Code</button>

          <h3>Multi-Line Comments</h3>
          <p>Python doesn&apos;t have a dedicated syntax for multi-line comments like some other languages. However, you can use triple-quotes (either single or double) to create multi-line comments. For example:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`'''
This is a multi-line comment.
It spans across multiple lines.
'''`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("'''\nThis is a multi-line comment.\nIt spans across multiple lines.\n'''"), '_blank')}>Run Code</button>

          <h3>Comments for Code Documentation</h3>
          <p>Comments are essential for documenting your code, especially for functions and modules. Using triple-quotes for documentation is a common practice. Here&apos;s an example:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`def add(a, b):
    '''
    This function adds two numbers.

    Parameters:
        a (int): The first number.
        b (int): The second number.

    Returns:
        int: The sum of a and b.
    '''
    return a + b`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("def add(a, b):\n    '''\n    This function adds two numbers.\n\n    Parameters:\n        a (int): The first number.\n        b (int): The second number.\n\n    Returns:\n        int: The sum of a and b.\n    '''\n    return a + b"), '_blank')}>Run Code</button>

          <h3>Commenting Best Practices</h3>
          <p>When writing comments in your Python code, consider the following best practices:</p>
          <ul>
            <li>Keep comments concise and to the point.</li>
            <li>Use comments to explain complex code or provide context.</li>
            <li>Update comments when you modify the code to keep them accurate.</li>
            <li>Avoid excessive commenting for obvious or self-explanatory code.</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PythonComments;
