import React from "react";

const PythonIfElse = () => {
  // URL for the code editor
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python If and Else Statements</h2>
      <p>
        The if and else statements are used for decision-making in Python. Here
        are some common usages:
      </p>

      <h3>If Statement</h3>
      <p>
        Use the if statement to execute a block of code only if the condition is
        true.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`x = 10
if x > 5:
    print("x is greater than 5")`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "x = 10\nif x > 5:\n    print('x is greater than 5')"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Else Statement</h3>
      <p>
        Use the else statement to execute a block of code if the condition is
        false.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`x = 3
if x > 5:
    print("x is greater than 5")
else:
    print("x is not greater than 5")`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "x = 3\nif x > 5:\n    print('x is greater than 5')\nelse:\n    print('x is not greater than 5')"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Elif Statement</h3>
      <p>
        Use the elif statement to specify a new condition to test if the first
        condition is false.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`x = 5
if x > 10:
    print("x is greater than 10")
elif x > 5:
    print("x is greater than 5 but not 10")
else:
    print("x is not greater than 5")`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "x = 5\nif x > 10:\n    print('x is greater than 10')\nelif x > 5:\n    print('x is greater than 5 but not 10')\nelse:\n    print('x is not greater than 5')"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
      <h3>Indentation Rule</h3>
      <p>
        Python uses indentation to define blocks of code. Proper indentation is
        essential for the code to work correctly. Indentation is typically done
        using spaces or tabs, but it must be consistent within the same block.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`x = 10
if x > 5:
    print("x is greater than 5")  # Correct indentation
  print("This will cause an error")  # Incorrect indentation`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "x = 10\nif x > 5:\n    print('x is greater than 5')  # Correct indentation\n  print('This will cause an error')  # Incorrect indentation"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Nested If Statements</h3>
      <p>You can use nested if statements for more complex decision-making.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`x = 10
if x > 5:
    if x > 7:
        print("x is greater than 7")
    else:
        print("x is greater than 5 but not 7")
else:
    print("x is not greater than 5")`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "x = 10\nif x > 5:\n    if x > 7:\n        print('x is greater than 7')\n    else:\n        print('x is greater than 5 but not 7')\nelse:\n    print('x is not greater than 5')"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Ternary Operator</h3>
      <p>The ternary operator is a concise way to write if-else statements.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`x = 7
message = "x is greater than 5" if x > 5 else "x is not greater than 5"
print(message)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "x = 7\nmessage = 'x is greater than 5' if x > 5 else 'x is not greater than 5'\nprint(message)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Short Hand If</h3>
      <p>
        Use short-hand if to execute a single statement when the condition is
        true:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`x = 10
if x > 5: print("x is greater than 5")`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "x = 10\nif x > 5: print('x is greater than 5')"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Short Hand If and Else</h3>
      <p>
        You can also use short-hand if and else to return a value based on a
        condition:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`x = 7
message = "x is greater than 5" if x > 5 else "x is not greater than 5"
print(message)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "x = 7\nmessage = 'x is greater than 5' if x > 5 else 'x is not greater than 5'\nprint(message)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>pass Statement</h3>
      <p>
        The pass statement is a null operation that does nothing. It can be used
        when no action is required:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`x = 10
if x > 5:
    pass  # Do nothing
else:
    print("x is not greater than 5")`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "x = 10\nif x > 5:\n    pass  # Do nothing\nelse:\n    print('x is not greater than 5')"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default PythonIfElse;
