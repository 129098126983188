import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { Form, Button, ListGroup } from "react-bootstrap";
import { auth, firestore, storage } from "./Firebase";
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import NavBar from "../components/Navbar";
import Footer from "../components/Footer";
import ReplyForm from "./ReplyForm";

const DiscussionForum = ({ user }) => {
  const [inputValue, setInputValue] = useState("");
  const [posts, setPosts] = useState([]);
  const [attachedFile, setAttachedFile] = useState(null);

  useEffect(() => {
    const postsRef = collection(firestore, "posts");
    const orderedPosts = query(postsRef, orderBy("timestamp", "desc"));
    const unsubscribe = onSnapshot(orderedPosts, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        data: doc.data(),
      }));
      setPosts(data);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValue.trim()) {
      let fileURL = null;
      if (attachedFile) {
        const storageRef = ref(storage, `images/${attachedFile.name}`);
        await uploadBytes(storageRef, attachedFile);
        fileURL = await getDownloadURL(storageRef);
      }

      const newPost = {
        content: inputValue,
        author: user ? user.displayName || user.email : "Anonymous",
        replies: [],
        fileURL: fileURL,
        timestamp: new Date(),
      };

      // Display the new post immediately
      setPosts([newPost, ...posts]);

      // Store the new post in Firestore
      await addDoc(collection(firestore, "posts"), newPost);

      setInputValue("");
      setAttachedFile(null);
    }
  };

  const handleReply = async (postId, reply) => {
    const postRef = doc(firestore, "posts", postId);
    await updateDoc(postRef, {
      replies: [
        ...posts.find((post) => post.id === postId).data.replies,
        {
          content: reply,
          author: user ? user.displayName || user.email : "Anonymous",
        },
      ],
    });
  };

  const handleSignOut = async () => {
    try {
      await auth.signOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div>
      <NavBar />
      <section className="forum-container">
        <div className="d-flex justify-content-around align-items-center">
          <h2 className="h2">Discussion Forum</h2>

          <div>
          {user && (
            <Button variant="danger" onClick={handleSignOut}>
              Sign Out
            </Button>
          )}
          </div>
        </div>
        <Form onSubmit={handleSubmit} className="forum-form">
          <Form.Group controlId="postContent">
            <Form.Label>Post:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={inputValue}
              onChange={handleInputChange}
              placeholder="Type your post here..."
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="m-2">
            Post
          </Button>
        </Form>
        <ListGroup className="mt-4">
          {posts.map((post) => (
            <ListGroup.Item key={post.id}>
              {post.data && post.data.author && (
                <strong>{post.data.author}: </strong>
              )}
              {post.data && post.data.content}
              {post.data && post.data.fileURL && (
                <div className="mt-2">
                  {post.data.fileURL.match(/\.(jpeg|jpg|gif|png)$/i) !==
                    null && (
                    <img
                      src={post.data.fileURL}
                      alt="Attached file"
                      style={{ maxWidth: "100%" }}
                    />
                  )}
                </div>
              )}
              <div className="reply">
                {post.data &&
                  post.data.replies.map((reply, replyIndex) => (
                    <p key={replyIndex}>
                      {reply.author && <strong>{reply.author}:</strong>}
                      {reply.content}
                    </p>
                  ))}
              </div>
              <ReplyForm postId={post.id} onReply={handleReply} />
            </ListGroup.Item>
          ))}
        </ListGroup>
      </section>
      <Footer />
    </div>
  );
};



DiscussionForum.propTypes = {
  user: PropTypes.shape({
    displayName: PropTypes.string,
    email: PropTypes.string,
  }),
};




export default DiscussionForum;
