import React from 'react';

const PythonForLoop = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>Python For Loop</h2>
      <p>The for loop is used to iterate over a sequence (list, tuple, string, or range) or other iterable objects. Here are some common usages:</p>

      <h3>For Loop Over a List</h3>
      <p>Use a for loop to iterate over the elements of a list:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`fruits = ['apple', 'banana', 'cherry']
for fruit in fruits:
    print(fruit)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("fruits = ['apple', 'banana', 'cherry']\nfor fruit in fruits:\n    print(fruit)"), '_blank')}>Run Code</button>

      <h3>For Loop Over a String</h3>
      <p>You can use a for loop to iterate over the characters of a string:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`text = 'Python'
for char in text:
    print(char)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("text = 'Python'\nfor char in text:\n    print(char)"), '_blank')}>Run Code</button>

      <h3>For Loop Using range()</h3>
      <p>The range() function generates a sequence of numbers that can be used in a for loop:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`for num in range(5):
    print(num)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-2" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("for num in range(5):\n    print(num)"), '_blank')}>Run Code</button>

      <pre className="bg-dark text-light p-2">
  <code>
    {`# Using start and end
for num in range(3, 6):
    print(num)

# Using start, end, and increment
for num in range(2, 10, 2):
    print(num)`}
  </code>
</pre>
<button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("# Using start and end\nfor num in range(3, 6):\n    print(num)\n\n# Using start, end, and increment\nfor num in range(2, 10, 2):\n    print(num)"), '_blank')}>Run Code</button>

<h3>Break Statement</h3>
<p>The <code>break</code> statement is used to exit the loop prematurely when a certain condition is met:</p>
<pre className="bg-dark text-light p-2">
  <code>
    {`fruits = ['apple', 'banana', 'cherry']
for fruit in fruits:
    if fruit == 'banana':
        break
    print(fruit)`}
  </code>
</pre>
<button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("fruits = ['apple', 'banana', 'cherry']\nfor fruit in fruits:\n    if fruit == 'banana':\n        break\n    print(fruit)"), '_blank')}>Run Code</button>

<h3>Continue Statement</h3>
<p>The <code>continue</code> statement is used to skip the current iteration and proceed to the next one:</p>
<pre className="bg-dark text-light p-2">
  <code>
    {`fruits = ['apple', 'banana', 'cherry']
for fruit in fruits:
    if fruit == 'banana':
        continue
    print(fruit)`}
  </code>
</pre>
<button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("fruits = ['apple', 'banana', 'cherry']\nfor fruit in fruits:\n    if fruit == 'banana':\n        continue\n    print(fruit)"), '_blank')}>Run Code</button>

<h3>Pass Statement</h3>
<p>The <code>pass</code> statement is a null operation that does nothing. It can be used when no action is required within a loop:</p>
<pre className="bg-dark text-light p-2">
  <code>
    {`fruits = ['apple', 'banana', 'cherry']
for fruit in fruits:
    pass  # Do nothing
    print(fruit)`}
  </code>
</pre>
<button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("fruits = ['apple', 'banana', 'cherry']\nfor fruit in fruits:\n    pass  # Do nothing\n    print(fruit)"), '_blank')}>Run Code</button>


      <h3>Nested For Loops</h3>
      <p>You can use nested for loops for more complex iterations:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`adj = ['red', 'big', 'tasty']
fruits = ['apple', 'banana', 'cherry']

for a in adj:
    for b in fruits:
        print(a, b)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("adj = ['red', 'big', 'tasty']\nfruits = ['apple', 'banana', 'cherry']\n\nfor a in adj:\n    for b in fruits:\n        print(a, b)"), '_blank')}>Run Code</button>

      <h3>Looping Through Dictionary</h3>
      <p>You can loop through the keys of a dictionary using a for loop:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`person = {'name': 'Alice', 'age': 30, 'city': 'New York'}
for key in person:
    print(key, person[key])`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("person = {'name': 'Alice', 'age': 30, 'city': 'New York'}\nfor key in person:\n    print(key, person[key])"), '_blank')}>Run Code</button>

      <h3>Using Enumerate</h3>
      <p>The enumerate() function can be used to get both the index and value in a for loop:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`fruits = ['apple', 'banana', 'cherry']
for index, fruit in enumerate(fruits):
    print(index, fruit)`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("fruits = ['apple', 'banana', 'cherry']\nfor index, fruit in enumerate(fruits):\n    print(index, fruit)"), '_blank')}>Run Code</button>
    </div>
  );
}

export default PythonForLoop;
