import React from 'react';

const PythonOperators = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python Operators and Expressions</h2>
      <div>
        <div>
          <p>Python operators are used to perform operations on variables and values. Let&apos;s explore different types of operators and expressions in Python with code examples:</p>

          <h3>Arithmetic Operators</h3>
          <p>Arithmetic operators are used to perform mathematical operations:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`x = 10
y = 5

# Addition
addition = x + y
print(addition)  # Output: 15

# Subtraction
subtraction = x - y
print(subtraction)  # Output: 5

# Multiplication
multiplication = x * y
print(multiplication)  # Output: 50

# Division
division = x / y
print(division)  # Output: 2.0`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("x = 10\ny = 5\naddition = x + y\nsubtraction = x - y\nmultiplication = x * y\ndivision = x / y"), '_blank')}>Run Code</button>

          <h3>Comparison Operators</h3>
          <p>Comparison operators are used to compare values and return Boolean results:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`a = 10
b = 5

# Equal
is_equal = a == b
print(is_equal)  # Output: False

# Not Equal
not_equal = a != b
print(not_equal)  # Output: True

# Greater Than
greater_than = a > b
print(greater_than)  # Output: True

# Less Than
less_than = a < b
print(less_than)  # Output: False`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("a = 10\nb = 5\nis_equal = a == b\nnot_equal = a != b\ngreater_than = a > b\nless_than = a < b"), '_blank')}>Run Code</button>

          <h3>Logical Operators</h3>
          <p>Logical operators are used to combine Boolean values:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`p = True
q = False

# AND
and_result = p and q
print(and_result)  # Output: False

# OR
or_result = p or q
print(or_result)  # Output: True

# NOT
not_result = not p
print(not_result)  # Output: False`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("p = True\nq = False\nand_result = p and q\nor_result = p or q\nnot_result = not p"), '_blank')}>Run Code</button>

          <h3>Bitwise Operators</h3>
          <p>Bitwise operators are used to manipulate individual bits within an integer:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`a = 5  # 101 in binary
b = 3  # 011 in binary

# Bitwise AND
bitwise_and = a & b
print(bin(bitwise_and))  # Output: 0b1 (1 in binary)

# Bitwise OR
bitwise_or = a | b
print(bin(bitwise_or))  # Output: 0b111 (7 in binary)

# Bitwise XOR
bitwise_xor = a ^ b
print(bin(bitwise_xor))  # Output: 0b110 (6 in binary)

# Bitwise NOT
bitwise_not_a = ~a
print(bin(bitwise_not_a))  # Output: -0b110 (Complement of 5 in binary)
`}
            </code>
          </pre>
          <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("a = 5\nb = 3\nbitwise_and = a & b\nbitwise_or = a | b\nbitwise_xor = a ^ b\nbitwise_not_a = ~a"), '_blank')}>Run Code</button>
        </div>
      </div>
    </div>
  );
}

export default PythonOperators;
