import React from 'react';

const JavaScriptBooleans = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Booleans</h2>
      <p>Booleans in JavaScript represent two values: <code>true</code> and <code>false</code>. They are often used for making decisions in code. Let&apos;s explore some common use cases and examples:</p>

      <h3>Boolean Variables</h3>
      <p>Assigning boolean values to variables:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const isTrue = true;
const isFalse = false;

console.log(isTrue); // true
console.log(isFalse); // false`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const isTrue = true;\nconst isFalse = false;\n\nconsole.log(isTrue); // true\nconsole.log(isFalse); // false"), '_blank')}>Run Code</button>

      <h3>Boolean Expressions</h3>
      <p>Using boolean expressions for conditional statements:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const x = 5;
const y = 10;

const isGreaterThan = x > y;
const isEqual = x === y;

console.log(isGreaterThan); // false
console.log(isEqual); // false`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const x = 5;\nconst y = 10;\n\nconst isGreaterThan = x > y;\nconst isEqual = x === y;\n\nconsole.log(isGreaterThan); // false\nconsole.log(isEqual); // false"), '_blank')}>Run Code</button>

      <h3>Logical Operators</h3>
      <p>Using logical operators (AND, OR, NOT) with booleans:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const isSunny = true;
const isWeekend = false;

const goToBeach = isSunny && isWeekend;
const goShopping = isSunny || isWeekend;
const stayHome = !isSunny;

console.log(goToBeach); // false
console.log(goShopping); // true
console.log(stayHome); // false`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const isSunny = true;\nconst isWeekend = false;\n\nconst goToBeach = isSunny && isWeekend;\nconst goShopping = isSunny || isWeekend;\nconst stayHome = !isSunny;\n\nconsole.log(goToBeach); // false\nconsole.log(goShopping); // true\nconsole.log(stayHome); // false"), '_blank')}>Run Code</button>

      <h3>Comparison Operators</h3>
      <p>Using comparison operators (equality, inequality) with booleans:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const num1 = 5;
const num2 = 5;

const areEqual = num1 === num2;
const notEqual = num1 !== num2;

console.log(areEqual); // true
console.log(notEqual); // false`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const num1 = 5;\nconst num2 = 5;\n\nconst areEqual = num1 === num2;\nconst notEqual = num1 !== num2;\n\nconsole.log(areEqual); // true\nconsole.log(notEqual); // false"), '_blank')}>Run Code</button>

      <h3>Truthy and Falsy Values</h3>
      <p>Understanding truthy and falsy values in JavaScript:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const value1 = 42;
const value2 = 'Hello';

console.log(!!value1); // true
console.log(!!value2); // true
console.log(!!0); // false
console.log(!!''); // false`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const value1 = 42;\nconst value2 = 'Hello';\n\nconsole.log(!!value1); // true\nconsole.log(!!value2); // true\nconsole.log(!!0); // false\nconsole.log(!!''); // false"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptBooleans;
