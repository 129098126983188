import React from "react";

const PythonWhileLoop = () => {
  // URL for the code editor
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python While Loop</h2>
      <p>
        The while loop is used to repeatedly execute a block of code as long as
        a certain condition is met. Here are some common usages:
      </p>

      <h3>While Loop with a Condition</h3>
      <p>
        Use a while loop to execute a block of code as long as a condition is
        true:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`count = 0
while count < 5:
    print(count)
    count += 1`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "count = 0\nwhile count < 5:\n    print(count)\n    count += 1"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>While Loop with Break</h3>
      <p>
        You can use the <code>break</code> statement to exit the while loop
        prematurely when a certain condition is met:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`count = 0
while count < 5:
    if count == 3:
        break
    print(count)
    count += 1`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "count = 0\nwhile count < 5:\n    if count == 3:\n        break\n    print(count)\n    count += 1"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>While Loop with Continue</h3>
      <p>
        The <code>continue</code> statement is used to skip the current
        iteration and proceed to the next one within a while loop:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`count = 0
while count < 5:
    if count == 2:
        count += 1
        continue
    print(count)
    count += 1`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "count = 0\nwhile count < 5:\n    if count == 2:\n        count += 1\n        continue\n    print(count)\n    count += 1"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Infinite Loop</h3>
      <p>
        Be cautious when using while loops to avoid infinite loops. Here&quot;s
        an example of an infinite loop:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`while True:
    print("This is an infinite loop")`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                'while True:\n    print("This is an infinite loop")'
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default PythonWhileLoop;
