import React from "react";

const PythonDictionaries = () => {
  // URL for the code editor
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python Dictionaries</h2>
      <p>
        Dictionaries are unordered collections of key-value pairs. Here are some
        common dictionary operations:
      </p>

      <h3>Duplicates Not Allowed</h3>
      <p>
        Dictionaries cannot have two items with the same key. If you try to add
        a duplicate key, the last occurrence will overwrite the existing one.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }
my_dict['age'] = 31  # The 'age' key is updated to 31
print(my_dict)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }\nmy_dict['age'] = 31\nprint(my_dict)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>The dict() Constructor</h3>
      <p>
        You can also create dictionaries using the <code>dict()</code>{" "}
        constructor.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_dict = dict(name='Alice', age=30, city='New York')
print(my_dict)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "my_dict = dict(name='Alice', age=30, city='New York')\nprint(my_dict)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Accessing Dictionary Elements</h3>
      <p>You can access dictionary values using their keys.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }
print(my_dict['name'])  # Access the 'name' key
print(my_dict['age'])   # Access the 'age' key`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }\nprint(my_dict['name'])\nprint(my_dict['age'])"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Adding Elements to a Dictionary</h3>
      <p>You can add new key-value pairs to a dictionary.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_dict = { 'name': 'Alice', 'age': 30 }
my_dict['city'] = 'New York'  # Add a new key-value pair
print(my_dict)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "my_dict = { 'name': 'Alice', 'age': 30 }\nmy_dict['city'] = 'New York'\nprint(my_dict)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Removing Elements from a Dictionary</h3>
      <p>
        You can remove key-value pairs using the <code>pop()</code> method.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }
my_dict.pop('age')  # Remove the 'age' key-value pair
print(my_dict)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }\nmy_dict.pop('age')\nprint(my_dict)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Looping Through a Dictionary</h3>
      <p>
        You can iterate through keys, values, or key-value pairs in a
        dictionary.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }
# Loop through keys
for key in my_dict:
    print(key)

# Loop through values
for value in my_dict.values():
    print(value)

# Loop through key-value pairs
for key, value in my_dict.items():
    print(key, value)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }\nfor key in my_dict:\n    print(key)\nfor value in my_dict.values():\n    print(value)\nfor key, value in my_dict.items():\n    print(key, value)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Copying a Dictionary</h3>
      <p>
        You can create a copy of a dictionary using the <code>copy()</code>{" "}
        method.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }
copied_dict = my_dict.copy()
print(copied_dict)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }\ncopied_dict = my_dict.copy()\nprint(copied_dict)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Nested Dictionaries</h3>
      <p>
        You can create dictionaries within dictionaries, forming nested
        dictionaries.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`person = {
  'name': 'Alice',
  'contact': {
    'email': 'alice@example.com',
    'phone': '123-456-7890'
  }
}
print(person['contact']['email'])  # Access nested value`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "person = {\n  'name': 'Alice',\n  'contact': {\n    'email': 'alice@example.com',\n    'phone': '123-456-7890'\n  }\n}\nprint(person['contact']['email'])"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Dictionary Methods</h3>
      <p>Here are some commonly used dictionary methods:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }
keys = my_dict.keys()          # Get a list of keys
values = my_dict.values()      # Get a list of values
items = my_dict.items()        # Get a list of key-value pairs
my_dict.update({'age': 31})    # Update a value by key
my_dict.clear()                # Clear all key-value pairs
print(my_dict)`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "my_dict = { 'name': 'Alice', 'age': 30, 'city': 'New York' }\nkeys = my_dict.keys()\nvalues = my_dict.values()\nitems = my_dict.items()\nmy_dict.update({'age': 31})\nmy_dict.clear()\nprint(my_dict)"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default PythonDictionaries;
