import React from 'react';

const CppOperators = () => {
  return (
    <div>
      <h2>C++ Operators</h2>
      <p>
        Operators in C++ are symbols used to perform operations on variables and values. They include arithmetic operators, comparison operators, logical operators, and more.
      </p>

      <h3>Arithmetic Operators</h3>
      <p>Arithmetic operators are used to perform basic mathematical operations:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
int a = 10, b = 5;
int addition = a + b;
int subtraction = a - b;
int multiplication = a * b;
int division = a / b;
int modulus = a % b;
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `int a = 10, b = 5;
int addition = a + b;
int subtraction = a - b;
int multiplication = a * b;
int division = a / b;
int modulus = a % b;

cout << "Addition: " << addition << endl;
cout << "Subtraction: " << subtraction << endl;
cout << "Multiplication: " << multiplication << endl;
cout << "Division: " << division << endl;
cout << "Modulus: " << modulus << endl;
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Comparison Operators</h3>
      <p>Comparison operators are used to compare values:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
int x = 5, y = 10;
bool isEqual = x == y;
bool isNotEqual = x != y;
bool isGreaterThan = x > y;
bool isLessThan = x < y;
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `int x = 5, y = 10;
bool isEqual = x == y;
bool isNotEqual = x != y;
bool isGreaterThan = x > y;
bool isLessThan = x < y;

cout << "Is Equal: " << isEqual << endl;
cout << "Is Not Equal: " << isNotEqual << endl;
cout << "Is Greater Than: " << isGreaterThan << endl;
cout << "Is Less Than: " << isLessThan << endl;
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Logical Operators</h3>
      <p>Logical operators are used to combine conditional statements:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
bool condition1 = true;
bool condition2 = false;
bool andOperator = condition1 && condition2;
bool orOperator = condition1 || condition2;
bool notOperator = !condition1;
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `bool condition1 = true;
bool condition2 = false;
bool andOperator = condition1 && condition2;
bool orOperator = condition1 || condition2;
bool notOperator = !condition1;

cout << "AND Operator: " << andOperator << endl;
cout << "OR Operator: " << orOperator << endl;
cout << "NOT Operator: " << notOperator << endl;
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Assignment Operators</h3>
      <p>Assignment operators are used to assign values to variables:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
int a = 5;
a += 3; // a is now 8
a -= 2; // a is now 6
a *= 4; // a is now 24
a /= 2; // a is now 12
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `int a = 5;
a += 3; // a is now 8
a -= 2; // a is now 6
a *= 4; // a is now 24
a /= 2; // a is now 12

cout << "Final Value of a: " << a << endl;
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppOperators;
