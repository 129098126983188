import React from 'react';

const PythonDataTypes = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>Python Data Types</h2>
      <div>
        <div>
          <p>Python supports various data types to represent different kinds of information. Let&apos;s explore some common data types:</p>

          <h3>Integer (int)</h3>
          <p>Integers are whole numbers. You can perform mathematical operations with them:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`x = 5
y = 3
result = x + y
print(result)
# The result is 8`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("x = 5\ny = 3\nresult = x + y"), '_blank')}>Run Code</button>

          <h3>Float (float)</h3>
          <p>Floats represent decimal numbers. They are used in calculations involving fractional values:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`x = 5.5
y = 2.5
result = x * y
print(result)
# The result is 13.75`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("x = 5.5\ny = 2.5\nresult = x * y"), '_blank')}>Run Code</button>

          <h3>String (str)</h3>
          <p>Strings represent text. You can manipulate and concatenate them:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`name = "Alice"
greeting = "Hello, " + name
print(greeting)
# The greeting is "Hello, Alice"`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("name = 'Alice'\ngreeting = 'Hello, ' + name"), '_blank')}>Run Code</button>

          <h3>Boolean (bool)</h3>
          <p>Booleans represent True or False values. They are often used in conditional statements:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`is_student = True
is_adult = False
result = is_student and not is_adult
print(result)
# The result is True`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("is_student = True\nis_adult = False\nresult = is_student and not is_adult"), '_blank')}>Run Code</button>

          <h3>List (list)</h3>
          <p>Lists can store multiple items in an ordered sequence. You can access elements by index:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`fruits = ["apple", "banana", "cherry"]
first_fruit = fruits[0]
print(first_fruit)
# The first_fruit is "apple"`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("fruits = ['apple', 'banana', 'cherry']\nfirst_fruit = fruits[0]"), '_blank')}>Run Code</button>

          <h3>Tuple (tuple)</h3>
          <p>Tuples are similar to lists but are immutable, meaning their elements cannot be changed after creation:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`coordinates = (3, 4)
# You cannot change the elements of a tuple`}
            </code>
          </pre>
          <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("coordinates = (3, 4)\n# You cannot change the elements of a tuple"), '_blank')}>Run Code</button>

          <h3>Dictionary (dict)</h3>
          <p>Dictionaries store key-value pairs and are used to represent data in a structured way:</p>
          <pre className="bg-dark text-light p-2">
            <code>
{`person = {"name": "Alice", "age": 30}
print(person["name"])
# Access values by key: person["name"] returns "Alice"`}
            </code>
          </pre>
          <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent('person = {"name": "Alice", "age": 30}\n# Access values by key: person["name"] returns "Alice"'), '_blank')}>Run Code</button>
        </div>
      </div>
    </div>
  );
}

export default PythonDataTypes;
