import React from 'react';

const PythonPolymorphism = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>Python Polymorphism</h2>
      <p>Polymorphism is one of the four fundamental principles of object-oriented programming (OOP). It allows objects of different classes to be treated as objects of a common superclass. This provides flexibility and simplifies code. Here&apos;s an introduction to polymorphism with code examples:</p>

      <h3>Polymorphism with Inheritance</h3>
      <p>Polymorphism often involves method overriding in subclasses:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Animal:
    def speak(self):
        pass

class Dog(Animal):
    def speak(self):
        return "Woof!"

class Cat(Animal):
    def speak(self):
        return "Meow!"

def animal_sound(animal):
    return animal.speak()

dog = Dog()
cat = Cat()

print(animal_sound(dog))
print(animal_sound(cat))`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Animal:\n    def speak(self):\n        pass\n\nclass Dog(Animal):\n    def speak(self):\n        return 'Woof!'\n\nclass Cat(Animal):\n    def speak(self):\n        return 'Meow!'\n\ndef animal_sound(animal):\n    return animal.speak()\n\ndog = Dog()\ncat = Cat()\n\nprint(animal_sound(dog))\nprint(animal_sound(cat))"), '_blank')}>Run Code</button>

      <h3>Polymorphism with a Common Interface</h3>
      <p>Polymorphism can also be achieved by implementing a common interface in multiple classes:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Shape:
    def area(self):
        pass

class Circle(Shape):
    def __init__(self, radius):
        self.radius = radius

    def area(self):
        return 3.14 * self.radius * self.radius

class Rectangle(Shape):
    def __init__(self, width, height):
        self.width = width
        self.height = height

    def area(self):
        return self.width * self.height

shapes = [Circle(5), Rectangle(4, 6)]

for shape in shapes:
    print(f"Area: {shape.area()}")`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Shape:\n    def area(self):\n        pass\n\nclass Circle(Shape):\n    def __init__(self, radius):\n        self.radius = radius\n\n    def area(self):\n        return 3.14 * self.radius * self.radius\n\nclass Rectangle(Shape):\n    def __init__(self, width, height):\n        self.width = width\n        self.height = height\n\n    def area(self):\n        return self.width * self.height\n\nshapes = [Circle(5), Rectangle(4, 6)]\n\nfor shape in shapes:\n    print(f'Area: {shape.area()}')"), '_blank')}>Run Code</button>

      <h3>Polymorphism with a Common Method Name</h3>
      <p>Polymorphism can be demonstrated by different classes implementing a method with the same name:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class MathOperation:
    def operate(self, a, b):
        pass

class Addition(MathOperation):
    def operate(self, a, b):
        return a + b

class Subtraction(MathOperation):
    def operate(self, a, b):
        return a - b

class Multiplication(MathOperation):
    def operate(self, a, b):
        return a * b

operations = [Addition(), Subtraction(), Multiplication()]

for operation in operations:
    print(f"Result: {operation.operate(5, 3)}")`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class MathOperation:\n    def operate(self, a, b):\n        pass\n\nclass Addition(MathOperation):\n    def operate(self, a, b):\n        return a + b\n\nclass Subtraction(MathOperation):\n    def operate(self, a, b):\n        return a - b\n\nclass Multiplication(MathOperation):\n    def operate(self, a, b):\n        return a * b\n\noperations = [Addition(), Subtraction(), Multiplication()]\n\nfor operation in operations:\n    print(f'Result: {operation.operate(5, 3)}')"), '_blank')}>Run Code</button>

      <h3>Polymorphism with Built-In Functions</h3>
      <p>Polymorphism can also be applied to built-in functions, such as <code>len()</code> and <code>str()</code>:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Square:
    def __init__(self, side_length):
        self.side_length = side_length

    def __str__(self):
        return f'Square with side length {self.side_length}'

class Circle:
    def __init__(self, radius):
        self.radius = radius

    def __str__(self):
        return f'Circle with radius {self.radius}'

shapes = [Square(4), Circle(3)]

for shape in shapes:
    print(len(shape))
    print(str(shape)}`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Square:\n    def __init__(self, side_length):\n        self.side_length = side_length\n\n    def __str__(self):\n        return f'Square with side length {self.side_length}'\n\nclass Circle:\n    def __init__(self, radius):\n        self.radius = radius\n\n    def __str__(self):\n        return f'Circle with radius {self.radius}'\n\nshapes = [Square(4), Circle(3)]\n\nfor shape in shapes:\n    print(len(shape))\n    print(str(shape)"), '_blank')}>Run Code</button>
    </div>
  );
}

export default PythonPolymorphism;
