import React from "react";

const PythonDates = () => {
  return (
    <div>
      <h2>Working with Dates in Python</h2>
      <p>
        Python offers the <code>datetime</code> module for working with dates
        and times. You can use it to manipulate, format, and perform
        calculations with dates.
      </p>

      <h3>Importing the datetime Module</h3>
      <p>
        To get started, import the <code>datetime</code> module:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>{`import datetime`}</code>
      </pre>

      <h3>Current Date and Time</h3>
      <p>
        You can obtain the current date and time using the <code>datetime</code>{" "}
        class:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import datetime

current_datetime = datetime.datetime.now()
print(current_datetime)`}
        </code>
      </pre>

      <h3>Date Formatting</h3>
      <p>
        Use the <code>strftime()</code> method to format a date as a string:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import datetime

current_datetime = datetime.datetime.now()
formatted_date = current_datetime.strftime("%Y-%m-%d %H:%M:%S")
print(formatted_date)`}
        </code>
      </pre>

      <h3>Create a Date</h3>
      <p>
        You can create a specific date using the <code>datetime()</code>{" "}
        constructor:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import datetime

custom_date = datetime.datetime(2023, 10, 15)
print(custom_date)`}
        </code>
      </pre>

      <h3>Calculating Date Differences</h3>
      <p>You can calculate the difference between two dates:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import datetime

date1 = datetime.datetime(2023, 10, 15)
date2 = datetime.datetime(2023, 10, 20)
date_difference = date2 - date1
print(date_difference.days)`}
        </code>
      </pre>

      <h3>Working with Time Zones</h3>
      <p>
        If you need to work with time zones, consider using the{" "}
        <code>pytz</code> library in combination with the <code>datetime</code>{" "}
        module. You&apos;ll need to install the <code>pytz</code> library first.
      </p>

      <p>
        Remember that working with time zones can be complex, so it&apos;s
        important to manage time zone information correctly in your
        applications.
      </p>
    </div>
  );
};

export default PythonDates;
