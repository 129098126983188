import React from 'react';
import { Container } from 'react-bootstrap';
import './Footer.css';
import { Link } from 'react-router-dom';
import { useScrollToTop } from "../components/ScrollToTop";
import { BsFillArrowUpSquareFill } from "react-icons/bs";

const Footer = () => {
  const setScrollToTop = useScrollToTop(true);


  return (
    <footer className="footer">
      <Container>
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <h5>About Us</h5>
            <p>
            A great place for learning. The best and cheapest way of getting to know learning to make a better tomorrow.
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <h5>Contact Us</h5>
            <p>
              Email: orionty@esurde.com
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <h5>Follow Us</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="https://web.facebook.com/profile.php?id=100039105502161"  target="_blank" rel="noreferrer">Facebook</Link>
              </li>
              <li>
                <Link to="https://twitter.com/elearning273"  target="_blank" rel="noreferrer">Twitter</Link>
              </li>
              <li>
                <Link to="https://www.linkedin.com/company/elearning-orgaization/"  target="_blank" rel="noreferrer">Linkedin</Link>
              </li>
              <li>
                <Link to="https://chat.whatsapp.com/Cli4Yx9O0d6AgGFJEPE7l7"  target="_blank" rel="noreferrer">WhatsApp</Link>
              </li>
            </ul>
          </div>
        </div>
      </Container>
      <div className="footer-bottom">
        <Container>
          <div className="row">
            <div className="col-md-12 d-flex justify-content-around align-items-center">
              <p className="text-center">
              esurde copyright &copy; {new Date().getFullYear()}  || All right reserved.
              </p>
              <li
          className="list-inline-item float-end"
          onClick={() => setScrollToTop(true)}
        >
          <BsFillArrowUpSquareFill className="fs-2 p-1" />
        </li>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

export default Footer;
