import React from 'react';

const DatabaseManagement = () => {
  return (
    <div>
      <h2>Database Management</h2>
      <p>Database management is a fundamental aspect of data science. Here are some common tasks you might perform:</p>

      <h3>Connecting to a Database</h3>
      <p>Establishing a connection to a database is the first step. This example shows connecting to a SQLite database:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import sqlite3

# Connect to a database (creates a new one if it doesn't exist)
conn = sqlite3.connect('mydatabase.db')`}
        </code>
      </pre>
      <p>This code demonstrates connecting to an SQLite database named &apos;mydatabase.db&apos;.</p>

      <h3>Executing SQL Queries</h3>
      <p>You can execute SQL queries to interact with the database:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Create a table
cursor = conn.cursor()
cursor.execute('CREATE TABLE students (id INT, name TEXT)')`}
        </code>
      </pre>
      <p>In this example, a table named &apos;students&apos; with columns &apos;id&apos; and &apos;name&apos; is created.</p>

      <h3>Inserting Data</h3>
      <p>Inserting data into the database is a common operation:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Insert data into the table
cursor.execute('INSERT INTO students VALUES (1, "Alice")')
cursor.execute('INSERT INTO students VALUES (2, "Bob")')`}
        </code>
      </pre>
      <p>Here, data for two students is inserted into the &apos;students&apos; table.</p>

      <h3>Retrieving Data</h3>
      <p>Retrieving data from the database is essential for analysis:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Retrieve data
cursor.execute('SELECT * FROM students')
data = cursor.fetchall()
for row in data:
    print(row)`}
        </code>
      </pre>
      <p>This code fetches all student records and prints them.</p>

      <h3>Updating and Deleting Data</h3>
      <p>You can also update and delete data as needed:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Update data
cursor.execute('UPDATE students SET name = "Alicia" WHERE id = 1')

# Delete data
cursor.execute('DELETE FROM students WHERE id = 2')`}
        </code>
      </pre>
      <p>Here, data is updated and deleted from the &apos;students&apos; table.</p>

      <h3>Closing the Connection</h3>
      <p>Don&apos;t forget to close the database connection after use:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Close the connection
conn.close()`}
        </code>
      </pre>
      <p>Closing the connection ensures data integrity and releases resources.</p>
    </div>
  );
}

export default DatabaseManagement;
