import React from 'react';
import PropTypes from 'prop-types';

const ForexSignals = ({ productLink, imageUrl, altText }) => {
  return (
    <div className="clickbank-ad container">
      <a href={productLink} target="_blank" rel="noopener noreferrer">
        <img src={imageUrl} alt={altText} />
      </a>
    </div>
  );
};

// Define prop type validation for the component
ForexSignals.propTypes = {
  productLink: PropTypes.string.isRequired, // Expect a string and it's required
  imageUrl: PropTypes.string.isRequired, // Expect a string and it's required
  altText: PropTypes.string.isRequired, // Expect a string and it's required
};

export default ForexSignals;
