import React from 'react';

const JavaScriptNumbers = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Numbers</h2>
      <p>JavaScript supports various operations with numbers. Let&apos;s explore some of the common number-related operations:</p>

      <h3>Basic Number Operations</h3>
      <p>Perform basic arithmetic operations with numbers:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const num1 = 5;
const num2 = 3;

const sum = num1 + num2;
const difference = num1 - num2;
const product = num1 * num2;
const quotient = num1 / num2;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const num1 = 5;\nconst num2 = 3;\n\nconst sum = num1 + num2;\nconst difference = num1 - num2;\nconst product = num1 * num2;\nconst quotient = num1 / num2;"), '_blank')}>Run Code</button>

      <h3>Math Functions</h3>
      <p>JavaScript provides various math functions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const number = -3.75;

const absoluteValue = Math.abs(number);
const roundedValue = Math.round(number);
const squaredValue = Math.pow(number, 2);
const squareRoot = Math.sqrt(16);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const number = -3.75;\n\nconst absoluteValue = Math.abs(number);\nconst roundedValue = Math.round(number);\nconst squaredValue = Math.pow(number, 2);\nconst squareRoot = Math.sqrt(16);"), '_blank')}>Run Code</button>

      <h3>Random Numbers</h3>
      <p>Generate random numbers in JavaScript:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const randomBetween0And1 = Math.random();
const randomBetween1And10 = Math.floor(Math.random() * 10) + 1;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const randomBetween0And1 = Math.random();\nconst randomBetween1And10 = Math.floor(Math.random() * 10) + 1;"), '_blank')}>Run Code</button>

      <h3>Constants</h3>
      <p>JavaScript provides useful constants:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const pi = Math.PI;
const euler = Math.E;
const positiveInfinity = Number.POSITIVE_INFINITY;
const negativeInfinity = Number.NEGATIVE_INFINITY;
const notANumber = Number.NaN;`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const pi = Math.PI;\nconst euler = Math.E;\nconst positiveInfinity = Number.POSITIVE_INFINITY;\nconst negativeInfinity = Number.NEGATIVE_INFINITY;\nconst notANumber = Number.NaN;"), '_blank')}>Run Code</button>

      <h3>Number Conversions</h3>
      <p>Convert numbers to strings and vice versa:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numberAsString = '42';
const stringToNumber = parseInt(numberAsString);
const numberToFloat = parseFloat(numberAsString);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numberAsString = '42';\nconst stringToNumber = parseInt(numberAsString);\nconst numberToFloat = parseFloat(numberAsString);"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptNumbers;
