import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Loader from './Loader';
// import { API_KEY } from '../config';
import HealthCard from './HealthCard';
import {MdHealthAndSafety} from 'react-icons/md'

const HealthNews = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://esurde-backend.onrender.com/news/health');
        setNews(response.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <h3 className="my-5 text-center">Health <MdHealthAndSafety /></h3>
      <hr />
  <div className="container">
     
      {loading ? (
        <Loader />
      ) : (
        
        <div className="row">
          {news.map((article) => (
            <div className="col-md-3 my-3" key={article.url}>
              <HealthCard article={article} />
        </div>
      ))}
    </div>
  )}
</div>
    </div>
  
  )
     }
export default HealthNews
