import React from "react";

const JavaScriptSyntax = () => {
  // URL for the code editor
  const codeEditorUrl = "https://run-code-editor.netlify.app/";

  return (
    <div>
      <h2>JavaScript Syntax</h2>
      <p>
        JavaScript syntax refers to the rules and structure of the JavaScript
        programming language. Let&apos;s explore some essential elements:
      </p>

      <h3>Statements and Semicolons</h3>
      <p>
        JavaScript programs are made up of statements. Statements are separated
        by semicolons:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let x = 5;
let y = 10;`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent("let x = 5;\nlet y = 10;"),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Comments</h3>
      <p>JavaScript supports single-line and multi-line comments:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`// This is a single-line comment
/* This is a
multi-line comment */`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "// This is a single-line comment\n/* This is a\nmulti-line comment */"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Variables</h3>
      <p>
        Variables are used to store data. In JavaScript, you can use keywords
        like <code>let</code> and <code>const</code> to declare variables:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let name = 'Alice';
const age = 30;`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent("let name = 'Alice';\nconst age = 30;"),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Data Types</h3>
      <p>
        JavaScript supports various data types, including numbers, strings,
        booleans, and objects:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let num = 42;
let text = 'Hello, JavaScript!';
let isTrue = true;
let person = { name: 'Alice', age: 30 };`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "let num = 42;\nlet text = 'Hello, JavaScript!';\nlet isTrue = true;\nlet person = { name: 'Alice', age: 30 };"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Operators</h3>
      <p>
        JavaScript supports various operators for arithmetic, comparison, and
        logical operations:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`let x = 10;
let y = 5;
let addition = x + y;
let isEqual = x === y;
let andResult = true && false;`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "let x = 10;\nlet y = 5;\nlet addition = x + y;\nlet isEqual = x === y;\nlet andResult = true && false;"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Conditional Statements</h3>
      <p>
        JavaScript uses <code>if</code>, <code>else if</code>, and{" "}
        <code>else</code> for conditional execution:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const age = 25;
if (age < 18) {
  console.log('You are a minor.');
} else if (age >= 18) {
  console.log('You are an adult.');
} else {
  console.log('Age is not specified.');
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "const age = 25;\nif (age < 18) {\n  console.log('You are a minor.');\n} else if (age >= 18) {\n  console.log('You are an adult.');\n} else {\n  console.log('Age is not specified.');\n}"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Functions</h3>
      <p>
        Functions in JavaScript allow you to encapsulate blocks of code for
        reusability:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`function greet(name) {
  console.log('Hello, ' + name);
}

greet('Bob');`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            codeEditorUrl +
              "?code=" +
              encodeURIComponent(
                "function greet(name) {\n  console.log('Hello, ' + name);\n}\n\ngreet('Bob');"
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default JavaScriptSyntax;
