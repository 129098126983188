import React from 'react';

const PythonInheritance = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>Python Inheritance</h2>
      <p>Inheritance is a fundamental concept in object-oriented programming that allows you to create a new class by inheriting properties and methods from an existing class. The new class is known as the child class or subclass, and the existing class is the parent class or superclass. Here&apos;s an introduction to inheritance with code examples:</p>

      <h3>Creating a Parent Class</h3>
      <p>Let&apos;s start by creating a parent class (superclass):</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Vehicle:
    def __init__(self, make, model):
        self.make = make
        self.model = model

    def display_info(self):
        return f"{self.make} {self.model}"`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Vehicle:\n    def __init__(self, make, model):\n        self.make = make\n        self.model = model\n\nclass Car(Vehicle):\n    pass\n\ncar1 = Car('Ford', 'Mustang')\nprint(car1.display_info())"), '_blank')}>Run Code</button>

      <h3>Creating a Child Class</h3>
      <p>Create a child class (subclass) that inherits from the parent class:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Car(Vehicle):
    pass`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Vehicle:\n    def __init__(self, make, model):\n        self.make = make\n        self.model = model\n    \n    def display_info(self):\n        return f'{self.make} {self.model}'\n\nclass Car(Vehicle):\n    pass\ncar1 = Car('Ford', 'Mustang')\nprint(car1.display_info())"), '_blank')}>Run Code</button>

      <h3>Child Class with Additional Attributes</h3>
      <p>Add attributes to the child class:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Car(Vehicle):
    def __init__(self, make, model, year):
        super().__init__(make, model)
        self.year = year`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Vehicle:\n    def __init__(self, make, model):\n        self.make = make\n        self.model = model\n    \n    def display_info(self):\n        return f'{self.make} {self.model}'\n\nclass Car(Vehicle):\n    def __init__(self, make, model, year):\n        super().__init__(make, model)\n        self.year = year\ncar1 = Car('Ford', 'Mustang', 2020)\nprint(car1.display_info())"), '_blank')}>Run Code</button>

      <h3>Child Class with Additional Methods</h3>
      <p>Add methods to the child class:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Car(Vehicle):
    def __init__(self, make, model, year):
        super().__init__(make, model)
        self.year = year

    def start_engine(self):
        return 'Engine started'`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Vehicle:\n    def __init__(self, make, model):\n        self.make = make\n        self.model = model\n    \n    def display_info(self):\n        return f'{self.make} {self.model}'\n\nclass Car(Vehicle):\n    def __init__(self, make, model, year):\n        super().__init__(make, model)\n        self.year = year\n\n    def start_engine(self):\n        return 'Engine started'\ncar1 = Car('Ford', 'Mustang', 2020)\nprint(car1.display_info())\nprint(car1.start_engine())"), '_blank')}>Run Code</button>

      <h3>Overriding Methods</h3>
      <p>Child classes can override methods from the parent class:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Car(Vehicle):
    def __init__(self, make, model, year):
        super().__init__(make, model)
        self.year = year

    def display_info(self):
        return f"{self.year} {self.make} {self.model}"`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Vehicle:\n    def __init__(self, make, model):\n        self.make = make\n        self.model = model\n    \n    def display_info(self):\n        return f'{self.make} {self.model}'\n\nclass Car(Vehicle):\n    def __init__(self, make, model, year):\n        super().__init__(make, model)\n        self.year = year\n\n    def display_info(self):\n        return f'{self.year} {self.make} {self.model}'\ncar1 = Car('Ford', 'Mustang', 2020)\nprint(car1.display_info())"), '_blank')}>Run Code</button>

      <h3>Multiple Inheritance</h3>
      <p>Python supports multiple inheritance, where a child class can inherit from more than one parent class. Here&apos;s a simple example:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`class Parent1:
    def method1(self):
        return 'Method 1 from Parent 1'

class Parent2:
    def method2(self):
        return 'Method 2 from Parent 2'

class Child(Parent1, Parent2):
    pass`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("class Parent1:\n    def method1(self):\n        return 'Method 1 from Parent 1'\n\nclass Parent2:\n    def method2(self):\n        return 'Method 2 from Parent 2'\n\nclass Child(Parent1, Parent2):\n    pass\nchild1 = Child()\nprint(child1.method1())\nprint(child1.method2())"), '_blank')}>Run Code</button>
    </div>
  );
}

export default PythonInheritance;
