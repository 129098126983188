import React from 'react';

const ProgrammingTips = () => {
  return (
    <div className="container mt-5 alert  p-5">
      <h2 className="mb-4">Top Tips for Programming</h2>
      <div className="row">
        <div className="col-md-6">
          <ol>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Start with the Basics: Begin your programming journey by learning the fundamentals of programming.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Choose the Right Language: Start with a beginner-friendly language like Python.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Practice Regularly: Dedicate time to coding regularly, even if it&quot;s just a small project or exercise.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Break Problems into Smaller Steps: When faced with a complex problem, break it down into smaller, manageable steps.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Learn to Read and Write Code: Reading code from others is just as important as writing your own.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Use Online Resources: Utilize free online resources like tutorials, forums, and documentation.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Debugging Skills: Learn how to find and fix errors in your code.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Collaborate and Share: Don&quot;t hesitate to collaborate with others on coding projects.</li>
          </ol>
        </div>
        <div className="col-md-6">
          <ol start="9">
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Document Your Code: Write clear and concise comments in your code.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Stay Updated: Keep up with the latest developments and trends in the programming world.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Ask for Help: If you&quot;re stuck on a problem, don&quot;t be afraid to seek help from online forums, communities, and mentors.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Learn Data Structures and Algorithms: Understanding data structures and algorithms is essential for solving complex problems efficiently.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Don&quot;t Fear Failure: You will encounter errors and challenges. Don&quot;t be discouraged; see them as opportunities to learn and improve.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Work on Real Projects: Apply your programming skills to real-world projects.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Take Breaks: Regular breaks help prevent burnout and keep your mind fresh for problem-solving.</li>
            <li className='shadow-lg bg-body rounded mb-3 p-3'>Explore Specializations: Explore different specializations to find your area of interest.</li>
          </ol>
        </div>
      </div>
    </div>
  );
}

export default ProgrammingTips;
