import React from 'react';

const JavaScriptModules = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Modules</h2>
      <p>Modules in JavaScript allow you to split your code into separate files, making it more organized and maintainable. Here are some key concepts and examples:</p>

      <h3>Exporting from a Module</h3>
      <p>You can export functions, variables, or objects from a module using the <code>export</code> keyword:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`// module.js
export const greeting = 'Hello, ';
export function sayHello(name) {
  return greeting + name;
}`}

        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("// module.js\nexport const greeting = 'Hello, ';\nexport function sayHello(name) {\n  return greeting + name;\n}"), '_blank')}>Run Code</button>

      <h3>Importing from a Module</h3>
      <p>To use functions and variables from a module, you can import them into another script:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`// main.js
import { greeting, sayHello } from './module.js';

const message = sayHello('Alice');
console.log(message); // Output: 'Hello, Alice'`}

        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("// main.js\nimport { greeting, sayHello } from './module.js';\n\nconst message = sayHello('Alice');\nconsole.log(message);"), '_blank')}>Run Code</button>

      <h3>Default Exports</h3>
      <p>A module can have a default export, which is a single export per module:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`// math.js
const add = (a, b) => a + b;
export default add;`}
        </code>
      </pre>
      <button className="btn btn-primary mb-2" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("// math.js\nconst add = (a, b) => a + b;\nexport default add;"), '_blank')}>Run Code</button>

      <pre className="bg-dark text-light p-2">
        <code>
          {`// main.js
import add from './math.js';

const result = add(5, 3);
console.log(result); // Output: 8`}

        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("// main.js\nimport add from './math.js';\n\nconst result = add(5, 3);\nconsole.log(result);"), '_blank')}>Run Code</button>

      <h3>CommonJS Modules (Node.js)</h3>
      <p>In Node.js, you can use <code>require()</code> to import modules:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`// math.js
const subtract = (a, b) => a - b;
module.exports = subtract;`}
        </code>
      </pre>
      <pre className="bg-dark text-light p-2">
        <code>
          {`// main.js (Node.js)
const subtract = require('./math.js');

const result = subtract(8, 3);
console.log(result); // Output: 5`}

        </code>
      </pre>
      

      <h3>ES6 Modules (Browser)</h3>
      <p>For web browsers, you can use ES6 modules with the <code>type=&quot;module&quot;</code> attribute in your HTML:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`<!-- index.html -->
<!DOCTYPE html>
<html>
<head>
  <script type="module" src="main.js"></script>
</head>
<body>
</body>
</html>`}

        </code>
      </pre>
    </div>
  );
}

export default JavaScriptModules;
