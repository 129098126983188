import React from 'react';

const CppComments = () => {
  return (
    <div>
      <h2>C++ Comments</h2>
      <p>
        Comments in C++ are essential for code documentation and explanation. They are not executed by the compiler and are only meant for human readers. C++ supports both single-line and multi-line comments.
      </p>

      <h3>Single-Line Comments</h3>
      <p>
        Single-line comments are created using <code>{`//`}</code>. Everything after <code>{`//`}</code> on the same line is treated as a comment. For example:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`#include <iostream>

int main() {
    // This is a single-line comment
    std::cout << "This is not a comment" << std::endl;
    return 0;
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                '#include <iostream>\n\nint main() {\n    // This is a single-line comment\n    std::cout << "This is not a comment" << std::endl;\n    return 0;\n}',
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Multi-Line Comments</h3>
      <p>
        Multi-line comments in C++ are enclosed within <code>{`/* */`}</code>. For example:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`#include <iostream>

int main() {
    /* This is
       a multi-line
       comment */
    std::cout << "This is not a comment" << std::endl;
    return 0;
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                '#include <iostream>\n\nint main() {\n    /* This is\n       a multi-line\n       comment */\n    std::cout << "This is not a comment" << std::endl;\n    return 0;\n}',
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Commented Code in C++</h3>
      <p>
        Comments are often used to explain code or temporarily disable a block of code. Here&apos;s an example of commented code:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`#include <iostream>

int main() {
    std::cout << "This code will run." << std::endl;

    // This code is commented and won't run
    // std::cout << "This code is commented and won't run." << std::endl;

    std::cout << "This code will run again." << std::endl;

    return 0;
}`}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                '#include <iostream>\n\nint main() {\n    std::cout << "This code will run." << std::endl;\n\n    // This code is commented and won\'t run\n    // std::cout << "This code is commented and won\'t run." << std::endl;\n\n    std::cout << "This code will run again." << std::endl;\n\n    return 0;\n}',
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppComments;
