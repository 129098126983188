import React from 'react';

const JavaScriptArrowFunctions = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Arrow Functions</h2>
      <p>Arrow functions are a concise way to write anonymous functions in JavaScript. They are often used for short, simple functions. Here are some common usages:</p>

      <h3>Basic Arrow Function</h3>
      <p>Create a simple arrow function:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const greet = () => 'Hello, World!';
console.log(greet());`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const greet = () => 'Hello, World!';\nconsole.log(greet());"), '_blank')}>Run Code</button>

      <h3>Arrow Function with Parameters</h3>
      <p>Create an arrow function that takes parameters:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const add = (a, b) => a + b;
console.log(add(5, 3));`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const add = (a, b) => a + b;\nconsole.log(add(5, 3));"), '_blank')}>Run Code</button>

      <h3>Arrow Function in Map</h3>
      <p>Use arrow functions in array mapping:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numbers = [1, 2, 3, 4, 5];
const squared = numbers.map((num) => num * num);
console.log(squared);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numbers = [1, 2, 3, 4, 5];\nconst squared = numbers.map((num) => num * num);\nconsole.log(squared);"), '_blank')}>Run Code</button>

      <h3>Arrow Function with Object</h3>
      <p>Create an arrow function that returns an object:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const createUser = (name, age) => ({ name, age });
console.log(createUser('Alice', 30));`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const createUser = (name, age) => ({ name, age });\nconsole.log(createUser('Alice', 30));"), '_blank')}>Run Code</button>

      <h3>Arrow Function in Timeout</h3>
      <p>Use arrow functions in setTimeout:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`setTimeout(() => {
  console.log('Timeout finished!');
}, 2000);`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("setTimeout(() => {\n  console.log('Timeout finished!');\n}, 2000);"), '_blank')}>Run Code</button>

      <h3>Arrow Function in Callbacks</h3>
      <p>Pass an arrow function as a callback:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`const numbers = [1, 2, 3, 4, 5];
numbers.forEach((num) => {
  console.log('Number:', num);
});`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("const numbers = [1, 2, 3, 4, 5];\nnumbers.forEach((num) => {\n  console.log('Number:', num);\n});"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptArrowFunctions;
