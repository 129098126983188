import React from 'react';

const JavaScriptAsync = () => {
  // URL for the code editor
  const codeEditorUrl = 'https://run-code-editor.netlify.app/';

  return (
    <div>
      <h2>JavaScript Asynchronous Programming (async/await)</h2>
      <p>Asynchronous programming in JavaScript allows you to perform operations without blocking the main execution thread. Promises and the <code>async/await</code> syntax are commonly used. Here are some common usages:</p>

      <h3>Basic Async/Await Example</h3>
      <p>Use <code>async/await</code> to wait for asynchronous operations to complete:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`async function fetchData() {
  const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
  const data = await response.json();
  return data;
}

fetchData().then(result => {
  console.log('Fetched Data:', result);
});`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("async function fetchData() {\n  const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');\n  const data = await response.json();\n  return data;\n}\n\nfetchData().then(result => {\n  console.log('Fetched Data:', result);\n});"), '_blank')}>Run Code</button>

      <h3>Promises</h3>
      <p>Promises provide a way to handle asynchronous operations:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`function fetchData() {
  return new Promise((resolve, reject) => {
    fetch('https://jsonplaceholder.typicode.com/todos/1')
      .then(response => response.json())
      .then(data => resolve(data))
      .catch(error => reject(error));
  });
}

fetchData().then(result => {
  console.log('Fetched Data (Promise):', result);
});`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("function fetchData() {\n  return new Promise((resolve, reject) => {\n    fetch('https://jsonplaceholder.typicode.com/todos/1')\n      .then(response => response.json())\n      .then(data => resolve(data))\n      .catch(error => reject(error));\n  });\n}\n\nfetchData().then(result => {\n  console.log('Fetched Data (Promise):', result);\n});"), '_blank')}>Run Code</button>

      <h3>Error Handling</h3>
      <p>Handle errors in asynchronous operations using <code>try/catch</code>:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`async function fetchData() {
  try {
    const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error:', error);
  }
}

fetchData().then(result => {
  console.log('Fetched Data:', result);
});`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("async function fetchData() {\n  try {\n    const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');\n    const data = await response.json();\n    return data;\n  } catch (error) {\n    console.error('Error:', error);\n  }\n}\n\nfetchData().then(result => {\n  console.log('Fetched Data:', result);\n});"), '_blank')}>Run Code</button>

      <h3>Parallel Async Operations</h3>
      <p>You can run multiple asynchronous operations in parallel using <code>Promise.all</code>:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`async function fetchDataMultiple() {
  const promises = [
    fetch('https://jsonplaceholder.typicode.com/posts/1').then(response => response.json()),
    fetch('https://jsonplaceholder.typicode.com/posts/2').then(response => response.json()),
    fetch('https://jsonplaceholder.typicode.com/posts/3').then(response => response.json())
  ];

  const results = await Promise.all(promises);
  return results;
}

fetchDataMultiple().then(results => {
  console.log('Fetched Data (Parallel):', results);
});`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("async function fetchDataMultiple() {\n  const promises = [\n    fetch('https://jsonplaceholder.typicode.com/posts/1').then(response => response.json()),\n    fetch('https://jsonplaceholder.typicode.com/posts/2').then(response => response.json()),\n    fetch('https://jsonplaceholder.typicode.com/posts/3').then(response => response.json())\n  ];\n\n  const results = await Promise.all(promises);\n  return results;\n}\n\nfetchDataMultiple().then(results => {\n  console.log('Fetched Data (Parallel):', results);\n});"), '_blank')}>Run Code</button>

      <h3>Async/Await with Axios</h3>
      <p>Use the Axios library to perform async operations:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`async function fetchDataAxios() {
  try {
    const response = await axios.get('https://jsonplaceholder.typicode.com/todos/1');
    return response.data;
  } catch (error) {
    console.error('Error:', error);
  }
}

fetchDataAxios().then(result => {
  console.log('Fetched Data (Axios):', result);
});`}
        </code>
      </pre>
      <button className="btn btn-primary" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("async function fetchDataAxios() {\n  try {\n    const response = await axios.get('https://jsonplaceholder.typicode.com/todos/1');\n    return response.data;\n  } catch (error) {\n    console.error('Error:', error);\n  }\n}\n\nfetchDataAxios().then(result => {\n  console.log('Fetched Data (Axios):', result);\n});"), '_blank')}>Run Code</button>
    </div>
  );
}

export default JavaScriptAsync;
