import React from 'react';

const DataExplorationVisualization = () => {
  return (
    <div>
      <h2>Data Exploration and Visualization</h2>
      <p>Data exploration and visualization are essential steps in understanding your data and uncovering insights. In this section, we&apos;ll explore some basic techniques and code examples.</p>

      <h3>Code Example 1: Loading Data</h3>
      <p>Before exploring data, we need to load it. Here&apos;s how you can load a CSV file in Python using the Pandas library:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import pandas as pd

# Load data from a CSV file
data = pd.read_csv('data.csv')`}
        </code>
      </pre>
      <p>Explanation: We use the Pandas library to read a CSV file (&apos;data.csv&apos;) and store it in a variable called &apos;data&apos;.</p>

      <h3>Code Example 2: Data Summary</h3>
      <p>You can generate a summary of your data to get a quick overview:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Get summary statistics
summary = data.describe()

# Display the summary
print(summary)`}
        </code>
      </pre>
      <p>Explanation: &apos;describe()&apos; provides statistics like mean, min, max, etc., for numeric columns, giving you an initial insight into the data.</p>

      <h3>Code Example 3: Data Visualization</h3>
      <p>Visualizing data helps identify trends. Let&apos;s create a scatter plot:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import matplotlib.pyplot as plt

# Scatter plot
plt.scatter(data['X'], data['Y'])
plt.xlabel('X-axis')
plt.ylabel('Y-axis')
plt.title('Scatter Plot')
plt.show()`}
        </code>
      </pre>
      <p>Explanation: We use Matplotlib to create a scatter plot, which displays data points on X and Y axes.</p>

      <h3>Code Example 4: Histogram</h3>
      <p>Histograms help visualize data distributions:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Histogram
plt.hist(data['Age'], bins=10, edgecolor='k')
plt.xlabel('Age')
plt.ylabel('Frequency')
plt.title('Age Distribution')
plt.show()`}
        </code>
      </pre>
      <p>Explanation: This code generates a histogram of the &apos;Age&apos; column with 10 bins and displays the age distribution.</p>

      <h3>Code Example 5: Box Plot</h3>
      <p>Box plots provide insights into data spread:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`# Box plot
plt.boxplot(data['Value'])
plt.xlabel('Variable')
plt.ylabel('Value')
plt.title('Box Plot')
plt.show()`}
        </code>
      </pre>
      <p>Explanation: This code creates a box plot for &apos;Value&apos;, visualizing its distribution and any outliers.</p>

      <h3>Code Example 6: Pair Plot</h3>
      <p>A pair plot shows relationships between numeric columns:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`import seaborn as sns

# Pair plot
sns.pairplot(data)
plt.show()`}
        </code>
      </pre>
      <p>Explanation: This code uses Seaborn to generate a pair plot, which visualizes relationships between numeric columns.</p>
    </div>
  );
}

export default DataExplorationVisualization;
