import React from "react";

const PythonSets = () => {
  const codeEditorUrl = "https://run-code-editor.netlify.app/";
  return (
    <div>
      <h2>Python Sets</h2>
      <p>
        Certainly, here&apos;s the revised summary with the addition: Python sets are
        collections of unique, unordered elements. Duplicates are not allowed.
        They&apos;re mutable, support common set operations, and are useful for tasks
        like eliminating duplicates, quick membership testing, and set
        operations. You can create sets using curly braces or the `set{"{}"}`
        constructor. Python also offers immutable sets called &quot;frozensets.&quot;
      </p>
      <p>Here are some common set operations:</p>

      <h3>Duplicates Not Allowed</h3>
      <p>
        Sets automatically eliminate duplicate values. If you try to add a value
        that is already present, it won&apos;t be added again.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_set = {1, 2, 2, 3, 3, 3}
print(my_set)  # Output: {1, 2, 3}, duplicates are removed automatically`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <h3>Accessing Set Elements</h3>
      <p>
        Since sets are unordered, you cannot access elements by index. To check
        if an element is in a set, use membership testing.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_set = {1, 2, 3, 4, 5}
element = 3
print(element in my_set)  # Check if an element is in the set`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <h3>Adding Elements to a Set</h3>
      <p>
        You can add elements to a set using the <code>add()</code> method.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_set = {1, 2, 3}
my_set.add(4)  # Add 4 to the set
print(my_set)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <h3>Removing Elements from a Set</h3>
      <p>
        You can remove elements from a set using the <code>remove()</code> or{" "}
        <code>discard()</code> methods. The difference is that{" "}
        <code>remove()</code> raises an error if the element is not in the set,
        while <code>discard()</code> does not.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_set = {1, 2, 3, 4, 5}
my_set.remove(3)  # Remove 3 from the set
my_set.discard(6)  # Try to remove 6 (element not in set)
print(my_set)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <h3>Looping Through a Set</h3>
      <p>You can iterate through the elements of a set using a loop.</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_set = {1, 2, 3, 4, 5}
for element in my_set:
    print(element)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <h3>Joining Sets</h3>
      <p>
        You can combine two sets using the <code>union()</code> method or the{" "}
        <code>|</code> operator.
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`set1 = {1, 2, 3}
set2 = {3, 4, 5}
union_set = set1.union(set2)  # Using union()
or_set = set1 | set2  # Using the | operator
print(union_set)
print(or_set)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <h3>Set Methods</h3>
      <p>Here are some commonly used set methods:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_set = {1, 2, 3}
my_set.add(4)         # Add an element
my_set.remove(2)      # Remove an element
my_set.discard(5)     # Remove an element safely
my_set.union(set2)    # Union of two sets
my_set.intersection(set2)  # Intersection of two sets
my_set.difference(set2)    # Set difference
my_set.clear()        # Clear all elements
print(my_set)`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
      <p>Here are more examples for commonly used set methods:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`my_set = {1, 2, 3, 4}
set2 = {3, 4, 5, 6}

# Union of two sets
union_set = my_set.union(set2)
print("Union:", union_set)

# Intersection of two sets
intersection_set = my_set.intersection(set2)
print("Intersection:", intersection_set)

# Set difference (elements in my_set but not in set2)
difference_set = my_set.difference(set2)
print("Difference:", difference_set)

# Symmetric difference (elements in either my_set or set2, but not in both)
symmetric_diff_set = my_set.symmetric_difference(set2)
print("Symmetric Difference:", symmetric_diff_set)

# Checking if a set is a subset or superset of another set
subset_check = {1, 2}.issubset(my_set)
superset_check = my_set.issuperset({3, 4})
print("Is {1, 2} a subset of my_set?", subset_check)
print("Is my_set a superset of {3, 4}?", superset_check)
`}
        </code>
      </pre>
      <button className="btn btn-primary mb-3" onClick={() => window.open(codeEditorUrl + '?code=' + encodeURIComponent("single_quoted = 'Hello, Python'\ndouble_quoted = \"Python is great\"\ntriple_quoted = '''This is a\nmultiline string'''"), '_blank')}>Run Code</button>
    </div>
  );
};

export default PythonSets;
