import React from "react";

const CppClassesAndObjects = () => {
  return (
    <div>
      <h2>C++ Classes and Objects</h2>
      <p>
        In C++, a class is a blueprint for creating objects. Objects are
        instances of classes, and they can have attributes (data members) and
        methods (functions). Here&apos;s an introduction to classes and objects
        in C++:
      </p>

      <h3>Code Examples:</h3>

      <h3>Creating a Class and Object</h3>
      <p>Here&apos;s how you create a class and an object:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
using namespace std;

class Car {
public:
    string brand;
    string model;
    int year;
};

int main() {
    Car myCar;
    myCar.brand = "Ford";
    myCar.model = "Mustang";
    myCar.year = 2022;

    cout << "My car is a " << myCar.year << " " << myCar.brand << " " << myCar.model << "." << endl;
    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>
using namespace std;

class Car {
public:
    string brand;
    string model;
    int year;
};

int main() {
    Car myCar;
    myCar.brand = "Ford";
    myCar.model = "Mustang";
    myCar.year = 2022;

    cout << "My car is a " << myCar.year << " " << myCar.brand << " " << myCar.model << "." << endl;
    return 0;
}`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Class Methods</h3>
      <p>Classes can have methods. Here&apos;s an example:</p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
using namespace std;

class Calculator {
public:
    int add(int a, int b) {
        return a + b;
    }
};

int main() {
    Calculator calc;
    int result = calc.add(5, 3);

    cout << "5 + 3 = " << result << endl;
    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>
using namespace std;

class Calculator {
public:
    int add(int a, int b) {
        return a + b;
    }
};

int main() {
    Calculator calc;
    int result = calc.add(5, 3);

    cout << "5 + 3 = " << result << endl;
    return 0;
}`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>

      <h3>Access Control</h3>
      <p>
        You can control the access to class members using access specifiers (
        <code>public</code>, <code>private</code>, <code>protected</code>).
        Here&apos;s an example:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
using namespace std;

class Circle {
private:
    double radius;

public:
    void setRadius(double r) {
        radius = r;
    }

    double getArea() {
        return 3.14159 * radius * radius;
    }
};

int main() {
    Circle myCircle;
    myCircle.setRadius(5);

    cout << "Circle Area: " << myCircle.getArea() << endl;
    // You cannot access myCircle.radius here (private member).
    return 0;
}
          `}
        </code>
      </pre>
      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            "https://run-code-editor.netlify.app/?code=" +
              encodeURIComponent(
                `#include <iostream>
using namespace std;

class Circle {
private:
    double radius;

public:
    void setRadius(double r) {
        radius = r;
    }

    double getArea() {
        return 3.14159 * radius * radius;
    }
};

int main() {
    Circle myCircle;
    myCircle.setRadius(5);

    cout << "Circle Area: " << myCircle.getArea() << endl;
    // You cannot access myCircle.radius here (private member).
    return 0;
}`
              ),
            "_blank"
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppClassesAndObjects;
