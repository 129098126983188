import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaGraduationCap } from "react-icons/fa";
// import { FaSearch } from "react-icons/fa";
// import { RiCloseFill } from "react-icons/ri";
import { MdDateRange } from "react-icons/md";
import { BiTime } from "react-icons/bi";
// import BookData from "../Data";
import { useScrollToTop } from "../components/ScrollToTop";
import { Container, Navbar, Nav } from "react-bootstrap";
// import NavDropdown from "react-bootstrap/NavDropdown";
import Search from "./AlgoliaSearch";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";

function HomeSearchBar() {
  const setScrollToTop = useScrollToTop(true);
  // const [wordEntered, setWordEntered] = useState("");
  // const [filteredData, setFilteredData] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = React.useState(false);

  const handleOffcanvasClose = () => setShowOffcanvas(false);
  const handleOffcanvasShow = () => setShowOffcanvas(true);

  var [date, setDate] = useState(new Date());

  useEffect(() => {
    var timer = setInterval(() => setDate(new Date()), 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  const programmingLanguages1 = [
    { name: "Python", link: "/language/python" },
    { name: "JavaScript", link: "/language/javascript" },
    { name: "Java", link: "#/language/java" },
    { name: "C++", link: "/language/cplusplus" },
    { name: "HTML", link: "#/language/html" },
    { name: "CSS", link: "#/language/css" },
    { name: "R", link: "#/language/r" },
    { name: "PHP", link: "#/language/php" },
    { name: "Data Science", link: "/language/data-science" },
    { name: "Machine Learning", link: "#/language/machine-learning" },
    { name: "React", link: "#/language/react" },
    { name: "Node Js", link: "#/language/nodejs" },
  ];

  const programmingLanguages2 = [
    { name: "Artificial Intelligence", link: "#/language/artificial-intelligence" },
    { name: "Cyber Security", link: "#/language/cyber-security" },
    { name: "Django", link: "#/language/django" },
    { name: "C#", link: "#/language/csharp" },
    { name: "SQL", link: "#/language/sql" },
    { name: "Pandas", link: "#/language/pandas" },
    { name: "Numpy", link: "#/language/numpy" },
    { name: "Matplotlib", link: "#/language/matplotlib" },
    { name: "Seaborn", link: "#/language/seaborn" },
    { name: "TypeScript", link: "#/language/typscript" },
    { name: "SciPy", link: "#/language/scipy" },
    { name: "C", link: "#/language/c" },
  ];

  const programmingLanguages3 = [
    { name: "Flutter", link: "#/language/flutter" },
    { name: "Dart", link: "#/language/dart" },
    { name: "React Native", link: "#/language/react-native" },
    { name: "Go", link: "#/language/go" },
    { name: "Ruby", link: "#/language/ruby" },
    { name: "Swift", link: "#/language/swift" },
    { name: "Rust", link: "#/language/rust" },
    { name: "MongoDb", link: "#/language/mongodb" },
    { name: "Kotlin", link: "#/language/kotlin" },
    { name: "Perl", link: "#/language/perl" },
    { name: "JQuery", link: "#/language/jquery" },
    { name: "LaTex", link: "#/language/latex" },
  ];
  const programmingLanguages4 = [
    { name: "Other", link: "#/" },
    { name: "Code Editor", link: "/esurde-code-editor" },
    { name: "Forum", link: "/discussion-forum" },
    { name: "News", link: "/news" },
    { name: "Physics Classroom", link: "https://physicsclassroom.esurde.com/" },
    { name: "Chemistry Classroom", link: "https://chemistryclassroom.esurde.com/" },
    { name: "Biology Classroom", link: "https://biologyclassroom.esurde.com/" },
    { name: "Maths Classroom", link: "https://mathsclassroom.esurde.com/" },
    { name: "Computer Classroom", link: "https://computerclassroom.esurde.com/" },
    { name: "Statistics Classroom", link: "https://statisticsclassroom.esurde.com/" },
    { name: "Engineering Classroom", link: "https://engineeringclassroom.esurde.com/" },
    
    
    
  ];

  // const handleFilter = (event) => {
  //   const searchWord = event.target.value;
  //   setWordEntered(searchWord);
  //   const newFilter = BookData.filter((value) => {
  //     return value.mainContent.toLowerCase().includes(searchWord.toLowerCase());
  //   });

  //   if (searchWord === "") {
  //     setFilteredData([]);
  //   } else {
  //     setFilteredData(newFilter);
  //   }
  // };

  // const clearInput = () => {
  //   setFilteredData([]);
  //   setWordEntered("");
  // };

  return (
    <div onClick={() => setScrollToTop(true)}>
      <Offcanvas
        show={showOffcanvas}
        onHide={handleOffcanvasClose}
        placement="top"
        className = "color-change text-light"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Learn Code</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="OffCanvas-flex ">

          <div >
          <ul >
            {programmingLanguages1.map((language, index) => (
              <li className="list-unstyled" key={index}>
                <a href={language.link} className="text-decoration-none  OffCanvas-flex-children" >
                  {language.name}
                </a>
              </li>
            ))}
          </ul>

          </div>

          <div >
          <ul className="OffcanvasList">
            {programmingLanguages2.map((language, index) => (
              <li className="list-unstyled"  key={index}>
                <a href={language.link} className="text-decoration-none  OffCanvas-flex-children" >
                  {language.name}
                </a>
              </li>
            ))}
          </ul>
          </div>

          <div >
          <ul className="OffcanvasList">
            {programmingLanguages3.map((language, index) => (
              <li  className="list-unstyled" key={index}>
                <a href={language.link} className="text-decoration-none  OffCanvas-flex-children" >
                  {language.name}
                </a>
              </li>
            ))}
          </ul>
          </div>
          <div >
          <ul className="OffcanvasList">
            {programmingLanguages4.map((language, index) => (
              <li  className="list-unstyled" key={index}>
                <a href={language.link} className="text-decoration-none  OffCanvas-flex-children" >
                  {language.name}
                </a>
              </li>
            ))}
          </ul>
          </div>

          </div>

          
        



        </Offcanvas.Body>
      </Offcanvas>

      {/* top navbar */}
      <nav className="navbar navbar-expand-lg navbar-dark  navBarTop  ">
        <div className="container-fluid opacity-100 justify-content-even align-items-center">
          <div className=" text-light cusText">
            <MdDateRange className="fs-2 p-1" />
            {date.toLocaleDateString()}
          </div>

          <div>
            <p className="text-light ">
              <BiTime className="fs-2 p-1" />
              {date.toLocaleTimeString()}
            </p>
          </div>
        </div>
      </nav>

      {/* down navbar */}
      <Navbar
        collapseOnSelect
        expand="lg"
        variant="light "
        bg="light"
        className="navBar p-3 d-flex justify-content-around align-items-center"
      >
        <Container fluid>
          <Link to="/" className="text-decoration-none navitem">
            <Navbar.Brand className="navBrand ">
              <FaGraduationCap className="fs-1 p-1 mb-2" />
              ESURDE
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="justify-content-center"
          >
            <Search />
          </Navbar.Collapse>
          <Navbar.Collapse
            id="responsive-navbar-nav "
            className="justify-content-around"
          >
            <div className=" d-flex">
              {/* <div className=" d-flex">
                <input
                  type="text"
                  placeholder="search here"
                  value={wordEntered}
                  onChange={handleFilter}
                  className="form-control search-width"
                />
                <div className="ms-2 searchIcon">
                  {filteredData.length === 0 ? (
                    <FaSearch  className="text-dark"/>
                  ) : (
                    <RiCloseFill className="text-dark" id="clearBtn" onClick={clearInput} />
                  )}
                </div>
              </div> */}

              <Nav>
                <Nav.Link className="navLinkHover">
                  <Link
                    to="/"
                    className="text-decoration-none navitem"
                    id="#home"
                  >
                    Home
                  </Link>
                </Nav.Link>
                <Nav.Link className="navLinkHover">
                  <Link to="/courses" className="text-decoration-none navitem">
                    Courses
                  </Link>
                </Nav.Link>

                <Nav.Link className="navLinkHover">
                  <Link
                    to="/questions"
                    className="text-decoration-none navitem"
                  >
                    Questions
                  </Link>
                </Nav.Link>
              

               

                <Nav.Link className="navLinkHover">
                  <Link
                    to="/contact-us"
                    className="text-decoration-none navitem"
                  >
                    Contact
                  </Link>
                </Nav.Link>
                <Nav.Link className="navLinkHover">
                  <Link to="/about" className="text-decoration-none navitem">
                    About
                  </Link>
                </Nav.Link>
                <Button style={{color:"white",backgroundColor:"navy"}} onClick={handleOffcanvasShow}>
                  Learn Code
                </Button>
              </Nav>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* {filteredData.length !== 0 && (
        <div className="container-fluid dataResult">
          {filteredData.slice(0, 2).map((value, key) => {
            return (
              <div>
                <Link to={value.link} className="text-decoration-none ">
                  <p
                    key={value.id}
                    style={{ cursor: "pointer", color: "navy" }}
                  >
                    {value.mainContent}{" "}
                  </p>
                </Link>
              </div>
            );
          })}
        </div>
      )} */}
    </div>
  );
}

export default HomeSearchBar;
