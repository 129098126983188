import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer";
import NavBar from "../../Navbar";

function BiologyTopicsPage() {
  const Topics = [
    {
      id: 1,
      topic: "CELL",
      link: "/biology/cell",
      
    },
    {
      id: 2,
      topic: "LIPIDS",
      link: "/biology/lipids",
    },
    {
      id: 3,
      topic: "PROTEINS",
      link: "/biology/proteins",
    },
    {
      id: 4,
      topic: "CARBOHYDRATES",
      link: "/biology/carbohydrates",
    },
    {
      id: 5,
      topic: "ACIDS, BASES, AND BUFFERS",
      link: "/biology/acids-bases-and-buffers",
    },
    {
      id: 6,
      topic: "GENES, CHROMOSOMES, AND GENOMES",
      link: "/biology/genes-chromosomes-and-genomes",
    },
    {
      id: 7,
      topic: "METABOLISM",
      link: "/biology/metabolism",
    },
    {
      id: 8,
      topic: "MICROBIAL NUTRITION",
      link: "/biology/microbial-nutrition",
    },
    {
      id: 9,
      topic: "MICROBIAL GROWTH",
      link: "/biology/microbial-growth",
    },
    {
      id: 10,
      topic: "NUCLEI ACIDS",
      link: "/biology/nuclei-acids",
    },
    {
      id: 11,
      topic: "ENERGY RELEASE AND CONSERVATION",
      link: "/biology/energy-release-and-conservation",
    },
    {
      id: 12,
      topic: "BIOENERGETICS",
      link: "/biology/bioenergetics",
    },
    {
      id: 13,
      topic: "PHOTOSYNTHESIS",
      link: "/biology/photosynthesis",
    }
  ];

  return (
    <div>
      <NavBar />
      <div
        className="container mt-5 mb-5 p-3"
      
      >
        <h6 className="topics-border-btn">Topics</h6>
        {Topics.map((topic) => (
          <div key={topic.id}>
            <Link to={topic.link} style={{textDecoration:"none"}}>
              <p className = "p-1   topic-hover" > {topic.topic}</p>
            </Link>
           
          </div>
        ))}
      </div>

      <Footer />
    </div>
  );
}

export default BiologyTopicsPage;
