import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import './code.css'; 

const CodeNavigationBar = () => {
  return (
    <Navbar  expand="lg" className='code-navbar'>
      <div className="container">
        <Navbar.Brand as={Link} to="/" className=' text-light'>Home</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav " />
        <Navbar.Collapse id="basic-navbar-nav ">
          <Nav className="ml-auto ">
            <Nav.Link as={Link} to="/language/python"  className='nav-link text-light'>Python</Nav.Link>
            <Nav.Link as={Link} to="#/language/java" className='nav-link text-light'>Java</Nav.Link>
            <Nav.Link as={Link} to="#/language/c" className='nav-link text-light'>C</Nav.Link>
            <Nav.Link as={Link} to="/language/cplusplus" className='nav-link text-light'>C++</Nav.Link>
            <Nav.Link as={Link} to="#/language/html" className='nav-link text-light'>HTML</Nav.Link>
            <Nav.Link as={Link} to="#/language/css" className='nav-link text-light'>CSS</Nav.Link>
            <Nav.Link as={Link} to="/language/javascript" className='nav-link text-light'>JavaScript</Nav.Link>
            <Nav.Link as={Link} to="#/language/csharp" className='nav-link text-light'>C#</Nav.Link>
            <Nav.Link as={Link} to="#/language/php" className='nav-link text-light'>PHP</Nav.Link>
            <Nav.Link as={Link} to="#/language/react" className='nav-link text-light'>React</Nav.Link>
            <Nav.Link as={Link} to="#/language/nodejs" className='nav-link text-light'>Node Js</Nav.Link>
            <Nav.Link as={Link} to="/language/data-science" className='nav-link text-light'>Data Science</Nav.Link>
            <Nav.Link as={Link} to="#/language/machine-learning" className='nav-link text-light'>Machine Learning</Nav.Link>
            <Nav.Link as={Link} to="#/language/artficial-intelligence" className='nav-link text-light'>AI</Nav.Link>
            <Nav.Link as={Link} to="#/language/r" className='nav-link text-light'>R</Nav.Link>
            <Nav.Link as={Link} to="#/language/sql" className='nav-link text-light'>SQL</Nav.Link>
            <Nav.Link as={Link} to="#/language/django" className='nav-link text-light'>Django</Nav.Link>
            <Nav.Link as={Link} to="#/language/cyber-security" className='nav-link text-light'>Cyber Security</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}

export default CodeNavigationBar;
