import React from 'react';

const CppIfElse = () => {
  return (
    <div>
      <h2>C++ If and Else Statements</h2>
      <p>
        In C++, conditional statements like <code>if</code> and <code>else</code> are used to make decisions in your code. You can use them to execute specific blocks of code based on whether a condition is true or false.
      </p>

      <h3>Code Examples:</h3>
      <p>Here are some examples of using if and else statements in C++:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int number = 42;

    // Check if a number is even
    if (number % 2 == 0) {
        std::cout << number << " is even." << std::endl;
    } else {
        std::cout << number << " is odd." << std::endl;
    }

    // Check if a number is positive, negative, or zero
    if (number > 0) {
        std::cout << number << " is positive." << std::endl;
    } else if (number < 0) {
        std::cout << number << " is negative." << std::endl;
    } else {
        std::cout << number << " is zero." << std::endl;
    }

    // Using the ternary operator
    bool isEven = (number % 2 == 0);
    std::cout << number << " is " << (isEven ? "even" : "odd") << std::endl;

    // Nested if and else statements
    int age = 25;
    if (age >= 18) {
        if (age >= 21) {
            std::cout << "You can legally purchase alcohol." << std::endl;
        } else {
            std::cout << "You're an adult but cannot purchase alcohol yet." << std::endl;
        }
    } else {
        std::cout << "You're a minor." << std::endl;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int number = 42;

    // Check if a number is even
    if (number % 2 == 0) {
        std::cout << number << " is even." << std::endl;
    } else {
        std::cout << number << " is odd." << std::endl;
    }

    // Check if a number is positive, negative, or zero
    if (number > 0) {
        std::cout << number << " is positive." << std::endl;
    } else if (number < 0) {
        std::cout << number << " is negative." << std::endl;
    } else {
        std::cout << number << " is zero." << std::endl;
    }

    // Using the ternary operator
    bool isEven = (number % 2 == 0);
    std::cout << number << " is " << (isEven ? "even" : "odd") << std::endl;

    // Nested if and else statements
    int age = 25;
    if (age >= 18) {
        if (age >= 21) {
            std::cout << "You can legally purchase alcohol." << std::endl;
        } else {
            std::cout << "You're an adult but cannot purchase alcohol yet." << std::endl;
        }
    } else {
        std::cout << "You're a minor." << std::endl;
    }

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppIfElse;
