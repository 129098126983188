import React, { Component } from 'react';
import IntroductionToPython from './topics/IntroductionToPython';
import './python.css';
import BasicPythonSyntax from './topics/BasicPythonSyntax';
import PythonComments from './topics/PythonComment';
import PythonVariables from './topics/Variables';
import PythonDataTypes from './topics/DataTypes';
import PythonNumbers from './topics/Numbers';
import PythonCasting from './topics/Casting';
import PythonBooleans from './topics/Booleans';
import PythonOperators from './topics/Operators';
import PythonStrings from './topics/Strings';
import PythonLists from './topics/Lists';
import PythonTuples from './topics/Tuples';
import PythonSets from './topics/Sets';
import PythonDictionaries from './topics/Dictionaries';
import PythonIfElse from './topics/IfElseStatement';
import PythonForLoop from './topics/ForLoop';
import PythonWhileLoop from './topics/WhileLoop';
import PythonFunctions from './topics/Functions';
import PythonLambdaFunctions from './topics/Lambda';
import PythonClassesAndObjects from './topics/Classes';
import PythonInheritance from './topics/Inheritance';
import PythonPolymorphism from './topics/Polymorphism';
import PythonIterators from './topics/Iterators';
import PythonScope from './Scope';
import PythonMath from './topics/Math';
import PythonDates from './topics/Dates';
import PythonJSON from './topics/JSON';
import PythonRegularExpression from './topics/RegExp';
import PythonModules from './topics/Modules';
import PythonUserInput from './topics/UserInput';
import PythonTryExcept from './topics/TryExcept';
import PythonFileHandling from './topics/FileHandling';
import ProgrammingTips from './topics/Tips';
import CodeNavigationBar from '../CodeNavigationBar';
import Footer from '../../components/Footer';
import PythonProjects from './topics/PythonProjects';

class PythonTutorial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTopic: null,
      isMobile: window.innerWidth < 768, // Define the breakpoint for mobile devices
      showMenu: true,
    };
  }
  scrollToTop = () => {
    window.scrollTo(0, 0);
  };


  handleTopicClick = (topic) => {
    this.setState({ selectedTopic: topic, showMenu: false });
    this.scrollToTop();
  };

  handleMenuClick = () => {
    this.setState({ showMenu: true, selectedTopic: null });
  };

  render() {
    const topics = [
      "Introduction to Python",
      "Basic Python Syntax",
      "Comments",
      "Variables",
      "Data Types",
      "Numbers",
      "Casting",
      "Booleans",
      "Operators",
      "Strings",
      "Lists",
      "Tuples",
      "Sets",
      "Dictionaries",
      "If and Else",
      "For Loop",
      "While Loop",
      "Functions",
      "Lambda Functions",
      "Classes and Objects",
      "Inheritance",
      "Polymorphism",
      "Iterators",
      "Python Scope",
      "Python Math",
      "Python Dates",
      "Python JSON",
      "Regular Expression",
      "Python Modules",
      "Python User Input",
      "Python Try Except",
      "Python File Handling",
      "Python Projects",
      
    ];

    return (
      <div>
        <CodeNavigationBar />

<div className="container py-5">
        <div className="row">
          {this.state.isMobile && (
            <div className="col-12">
              {this.state.showMenu ? (
                <button className="btn btn-primary btn-block mb-3" onClick={this.handleMenuClick}>
                  Show Menu
                </button>
              ) : (
                <button className="btn btn-primary btn-block mb-3" onClick={this.handleMenuClick}>
                  Back to Menu
                </button>
              )}
            </div>
          )}
          {this.state.isMobile ? (
            <div className="col-12">
              {this.state.showMenu && (
                <div className="navbar">
                  <h2>Python Tutorial</h2>
                  <ul className="list-group">
                    {topics.map((topic, index) => (
                      <li
                        key={index}
                        onClick={() => this.handleTopicClick(topic)}
                        className={`list-group-item ${topic === this.state.selectedTopic ? "active" : ""}`}
                      >
                        {topic}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {!this.state.showMenu && (
                <div className="content">
                  {this.state.selectedTopic === "Introduction to Python" && <IntroductionToPython />}
                  {this.state.selectedTopic === "Basic Python Syntax" && <BasicPythonSyntax />}
                  {this.state.selectedTopic === "Comments" && <PythonComments />}
                  {this.state.selectedTopic === "Variables" && <PythonVariables />}
                  {this.state.selectedTopic === "Data Types" && <PythonDataTypes />}
                  {this.state.selectedTopic === "Numbers" && <PythonNumbers />}
                  {this.state.selectedTopic === "Casting" && <PythonCasting />}
                  {this.state.selectedTopic === "Booleans" && <PythonBooleans />}
                  {this.state.selectedTopic === "Operators" && <PythonOperators />}
                  {this.state.selectedTopic === "Strings" && <PythonStrings />}
                  {this.state.selectedTopic === "Lists" && <PythonLists />}
                  {this.state.selectedTopic === "Tuples" && <PythonTuples />}
                  {this.state.selectedTopic === "Sets" && <PythonSets />}
                  {this.state.selectedTopic === "Dictionaries" && <PythonDictionaries />}
                  {this.state.selectedTopic === "If and Else" && <PythonIfElse />}
                  {this.state.selectedTopic === "For Loop" && <PythonForLoop />}
                  {this.state.selectedTopic === "While Loop" && <PythonWhileLoop />}
                  {this.state.selectedTopic === "Functions" && <PythonFunctions />}
                  {this.state.selectedTopic === "Lambda Functions" && <PythonLambdaFunctions />}
                  {this.state.selectedTopic === "Classes and Objects" && <PythonClassesAndObjects />}
                  {this.state.selectedTopic === "Inheritance" && <PythonInheritance />}
                  {this.state.selectedTopic === "Polymorphism" && <PythonPolymorphism />}
                  {this.state.selectedTopic === "Iterators" && <PythonIterators />}
                  {this.state.selectedTopic === "Python Scope" && <PythonScope />}
                  {this.state.selectedTopic === "Python Math" && <PythonMath />}
                  {this.state.selectedTopic === "Python Dates" && <PythonDates />}
                  {this.state.selectedTopic === "Python JSON" && <PythonJSON />}
                  {this.state.selectedTopic === "Regular Expression" && <PythonRegularExpression />}
                  {this.state.selectedTopic === "Python Modules" && <PythonModules />}
                  {this.state.selectedTopic === "Python User Input" && <PythonUserInput />}
                  {this.state.selectedTopic === "Python Try Except" && <PythonTryExcept />}
                  {this.state.selectedTopic === "Python File Handling" && <PythonFileHandling />}
                  {this.state.selectedTopic === "Python Projects" && <PythonProjects />}

                 
                  <button className="btn btn-primary btn-block mt-3" onClick={this.handleMenuClick}>
                    Back to Menu
                  </button>
                </div>
              )}
            </div>
          ) : (
            <React.Fragment>
              <div className="col-md-3">
                <div className="navbar">
                  <h2>Python Tutorial</h2>
                  <ul className="list-group">
                    {topics.map((topic, index) => (
                      <li
                        key={index}
                        onClick={() => this.handleTopicClick(topic)}
                        className={`list-group-item ${topic === this.state.selectedTopic ? "active" : ""}`}
                      >
                        {topic}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="content">
                  {this.state.selectedTopic ? (
                    <div>
                      {this.state.selectedTopic === "Introduction to Python" && <IntroductionToPython />}
                      {this.state.selectedTopic === "Basic Python Syntax" && <BasicPythonSyntax />}
                      {this.state.selectedTopic === "Comments" && <PythonComments />}
                      {this.state.selectedTopic === "Variables" && <PythonVariables />}
                      {this.state.selectedTopic === "Data Types" && <PythonDataTypes />}
                      {this.state.selectedTopic === "Numbers" && <PythonNumbers />}
                      {this.state.selectedTopic === "Casting" && <PythonCasting />}
                      {this.state.selectedTopic === "Booleans" && <PythonBooleans />}
                      {this.state.selectedTopic === "Operators" && <PythonOperators />}
                      {this.state.selectedTopic === "Strings" && <PythonStrings />}
                      {this.state.selectedTopic === "Lists" && <PythonLists />}
                      {this.state.selectedTopic === "Tuples" && <PythonTuples />}
                      {this.state.selectedTopic === "Sets" && <PythonSets />}
                      {this.state.selectedTopic === "Dictionaries" && <PythonDictionaries />}
                      {this.state.selectedTopic === "If and Else" && <PythonIfElse />}
                      {this.state.selectedTopic === "For Loop" && <PythonForLoop />}
                      {this.state.selectedTopic === "While Loop" && <PythonWhileLoop />}
                      {this.state.selectedTopic === "Functions" && <PythonFunctions />}
                      {this.state.selectedTopic === "Lambda Functions" && <PythonLambdaFunctions />}
                      {this.state.selectedTopic === "Classes and Objects" && <PythonClassesAndObjects />}
                      {this.state.selectedTopic === "Inheritance" && <PythonInheritance />}
                      {this.state.selectedTopic === "Polymorphism" && <PythonPolymorphism />}
                      {this.state.selectedTopic === "Iterators" && <PythonIterators />}
                      {this.state.selectedTopic === "Python Scope" && <PythonScope />}
                      {this.state.selectedTopic === "Python Math" && <PythonMath />}
                      {this.state.selectedTopic === "Python Dates" && <PythonDates />}
                      {this.state.selectedTopic === "Python JSON" && <PythonJSON />}
                      {this.state.selectedTopic === "Regular Expression" && <PythonRegularExpression />}
                      {this.state.selectedTopic === "Python Modules" && <PythonModules />}
                      {this.state.selectedTopic === "Python User Input" && <PythonUserInput />}
                      {this.state.selectedTopic === "Python Try Except" && <PythonTryExcept />}
                      {this.state.selectedTopic === "Python File Handling" && <PythonFileHandling />}
                      {this.state.selectedTopic === "Python Projects" && <PythonProjects />}
                    </div>
                  ) : (
                    <ProgrammingTips  />
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
                    <Footer />

      </div>
    );
  }
}

export default PythonTutorial;
