import React from 'react';

const CppSwitchStatements = () => {
  return (
    <div>
      <h2>C++ Switch Statements</h2>
      <p>
        A switch statement is used for decision-making in C++. It allows you to select from a set of options based on the value of an expression. Here&apos;s the basic syntax:
      </p>
      <pre className="bg-dark text-light p-2">
        <code>
          {`
switch (expression) {
    case value1:
        // Code to execute if expression equals value1
        break;
    case value2:
        // Code to execute if expression equals value2
        break;
    // more cases...
    default:
        // Code to execute if expression doesn't match any case
}
          `}
        </code>
      </pre>

      <h3>Code Examples:</h3>
      <p>Let&apos;s explore some examples of switch statements:</p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    int day = 4;
    switch (day) {
        case 1:
            cout << "Monday" << endl;
            break;
        case 2:
            cout << "Tuesday" << endl;
            break;
        case 3:
            cout << "Wednesday" << endl;
            break;
        case 4:
            cout << "Thursday" << endl;
            break;
        case 5:
            cout << "Friday" << endl;
            break;
        default:
            cout << "Weekend" << endl;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary mb-2"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    int day = 4;
    switch (day) {
        case 1:
            cout << "Monday" << endl;
            break;
        case 2:
            cout << "Tuesday" << endl;
            break;
        case 3:
            cout << "Wednesday" << endl;
            break;
        case 4:
            cout << "Thursday" << endl;
            break;
        case 5:
            cout << "Friday" << endl;
            break;
        default:
            cout << "Weekend" << endl;
    }

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>

int main() {
    char grade = 'B';
    switch (grade) {
        case 'A':
            cout << "Excellent" << endl;
            break;
        case 'B':
            cout << "Good" << endl;
            break;
        case 'C':
            cout << "Fair" << endl;
            break;
        default:
            cout << "Invalid Grade" << endl;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary mb-2"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>

int main() {
    char grade = 'B';
    switch (grade) {
        case 'A':
            cout << "Excellent" << endl;
            break;
        case 'B':
            cout << "Good" << endl;
            break;
        case 'C':
            cout << "Fair" << endl;
            break;
        default:
            cout << "Invalid Grade" << endl;
    }

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>

      <h3>Fall-Through</h3>
      <p>
        By default, C++ switch statements &quot;fall through&quot; each case. To prevent this, use the <code>break</code> statement as shown in the examples above.

      </p>

      <h3>Character and String Cases</h3>
      <p>
        You can use characters and strings in switch cases as well:

      </p>

      <pre className="bg-dark text-light p-2">
        <code>
          {`
#include <iostream>
#include <string>

int main() {
    char grade = 'A';
    switch (grade) {
        case 'A':
            cout << "You got an 'A'" << endl;
            break;
        case 'B':
            cout << "You got a 'B'" << endl;
            break;
    }

    string color = "red";
    switch (color) {
        case "red":
            cout << "The color is red" << endl;
            break;
        case "blue":
            cout << "The color is blue" << endl;
            break;
    }

    return 0;
}
          `}
        </code>
      </pre>

      <button
        className="btn btn-primary"
        onClick={() =>
          window.open(
            'https://run-code-editor.netlify.app/?code=' +
              encodeURIComponent(
                `#include <iostream>
#include <string>

int main() {
    char grade = 'A';
    switch (grade) {
        case 'A':
            cout << "You got an 'A'" << endl;
            break;
        case 'B':
            cout << "You got a 'B'" << endl;
            break;
    }

    string color = "red";
    switch (color) {
        case "red":
            cout << "The color is red" << endl;
            break;
        case "blue":
            cout << "The color is blue" << endl;
            break;
    }

    return 0;
}
`,
              ),
            '_blank'
          )
        }
      >
        Run Code
      </button>
    </div>
  );
};

export default CppSwitchStatements;
