import React from "react";
import Footer from "../../Footer";
import NavBar from "../../Navbar";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

function ChemicalReactions() {
  return (
    <div>
      <NavBar />
      <div className="container-fluid mt-5 mb-5 p-3">
        <h6>Chemical Reactions and Reaction Stoichiometry </h6>
        <div>
          <ol>
            {/* Q1 */}
            <li>
              <p>
                The following diagram represents a chemical reaction in which
                the red spheres are oxygen atoms and the blue spheres are
                nitrogen atoms. (a) Write the chemical formulas for the
                reactants and products. (b) Write a balanced equation for the
                reaction. (c) Is the diagram consistent with the law of
                conservation of mass?
                <br />
                <img
                  src={`${process.env.PUBLIC_URL}/img/cq1.png`}
                  alt=""
                  className="protect-image"
                  style={{ width: "40%" }}
                />
              </p>

              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              (a) There are two types of molecules in the left
                              box, which represents reactants: those with two
                              oxygen atoms (O<sub>2</sub>), and those with one
                              nitrogen atom and one oxygen atom. (NO). The only
                              items in the right box, which indicates
                              merchandise, are one particular type of molecule,
                              which has one nitrogen atom and two atoms of
                              oxygen (NO<sub>2</sub>).
                              <br />
                              (b) O<sub>2</sub> + 2NO &#8594; 2NO<sub>2</sub>{" "}
                              (balanced)
                              <br />
                              (c) There are four O<sup>2</sup> and eight NO in
                              the reactants box. The molecular ratio is
                              therefore one O<sub>2</sub> to every two NO, as
                              needed by the equation&apos;s balanced condition.
                              Because there are eight NO<sub>2</sub> molecules
                              in the products box, the number of NO<sub>2</sub>{" "}
                              product molecules and NO reactant molecules are
                              equal, as required by the balanced equation.
                              <br />
                              The eight NO molecules in the reactants box each
                              contain eight N atoms. In addition, the O
                              <sub>2</sub> and NO molecules each contain 8 O
                              atoms, for a total of 16 O atoms, or 4 X 2 = 8.
                              Eight NO2 molecules with eight N atoms and 8 X 2 =
                              16 O atoms can be found in the products box. The
                              illustration complies with the law of conservation
                              of mass since there are an equal number of N and O
                              atoms in each of the two boxes.
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            <br />
            {/* Q2 */}
            <li>
              <p>
                Balance the equation Na<sub>(s)</sub> + H<sub>2</sub>O
                <sub>(l)</sub> &#8594; NaOH<sub>(aq)</sub> + H<sub>2</sub>
                <sub>(g)</sub>
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              Na<sub>(s)</sub> + 2H<sub>2</sub>O<sub>(l)</sub>
                              &#8594; NaOH<sub>(aq)</sub> + H<sub>2</sub>
                              <sub>(g)</sub>
                              <br />
                              Na<sub>(s)</sub> + 2H<sub>2</sub>O<sub>(l)</sub>
                              &#8594; 2NaOH<sub>(aq)</sub> + H<sub>2</sub>
                              <sub>(g)</sub>
                              <br />
                              2Na<sub>(s)</sub> + 2H<sub>2</sub>O<sub>(l)</sub>
                              &#8594; 2NaOH<sub>(aq)</sub> + H<sub>2</sub>
                              <sub>(g)</sub>
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            <br />
            {/* Q3 */}
            <li>
              <p>
                Write a balanced equation for (a) the combination reaction
                between lithium metal and fluorine gas and (b) the decomposition
                reaction that occurs when solid barium carbonate is heated (two
                products form, a solid and a gas).
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              (a) 2Li<sub>(s)</sub> + F<sub>2</sub>
                              <sub>(g)</sub>
                              &#8594; 2LiF<sub>(s)</sub>
                              <br />
                              (b) BaCO<sub>3</sub>(s) &#8594; BaO(s) + CO
                              <sub>2</sub>(g)
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            <br />
            {/* Q4 */}
            <li>
              <p>
                Write the balanced equation for the reaction that occurs when
                methanol, CH<sub>3</sub>OH<sub>(l)</sub>, is burned in air.
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <p>
                              CH<sub>3</sub>OH<sub>(l)</sub> + O<sub>2</sub>
                              <sub>(g)</sub> &#8594; CO<sub>2</sub>(g) + H
                              <sub>2</sub>O<sub>(g)</sub>
                              <br />
                              CH<sub>3</sub>OH<sub>(l)</sub> + O<sub>2</sub>
                              <sub>(g)</sub> &#8594; CO<sub>2</sub>(g) + 2H
                              <sub>2</sub>O<sub>(g)</sub>
                              <br />
                              CH<sub>3</sub>OH<sub>(l)</sub> + 3/2 O<sub>2</sub>
                              <sub>(g)</sub> &#8594; CO<sub>2</sub>(g) + 2H
                              <sub>2</sub>O<sub>(g)</sub>
                              <br />
                              2CH<sub>3</sub>OH<sub>(l)</sub> + 3 O<sub>2</sub>
                              <sub>(g)</sub> &#8594; 2CO<sub>2</sub>(g) + 4H
                              <sub>2</sub>O<sub>(g)</sub>
                            </p>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            {/* Q5 */}
            <li>
              <p>
                Calculate the formula weight of (a) sucrose, C<sub>12</sub>H
                <sub>22</sub>O<sub>11</sub> (table sugar); and (b) calcium
                nitrate, Ca(NO<sub>3</sub>)<sub>2</sub>.
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <img
                              src={`${process.env.PUBLIC_URL}/img/cq5.png`}
                              alt=""
                              className="protect-image"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            {/* Q6 */}
            <li>
              <p>
                Calculate the percentage of carbon, hydrogen, and oxygen (by
                mass) in C<sub>12</sub>H<sub>22</sub>O<sub>11</sub>.
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <img
                              src={`${process.env.PUBLIC_URL}/img/crq6.png`}
                              alt=""
                              className="protect-image"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            {/* Q7 */}
            <li>
              <p>
                Calculate the number of H atoms in 0.350 mol of C<sub>6</sub>H
                <sub>12</sub>O<sub>6</sub>.
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <img
                              src={`${process.env.PUBLIC_URL}/img/crq7.png`}
                              alt=""
                              className="protect-image"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            {/* Q8 */}
            <li>
              <p>
                (a) How many glucose molecules are in 5.23 g of C6H12O6? <br /> (b) How
                many oxygen atoms are in this sample?
              </p>
              <>
                {["bottom"].map((placement) => (
                  <OverlayTrigger
                    trigger="click"
                    key={placement}
                    placement={placement}
                    overlay={
                      <Popover
                        id={`popover-positioned-${placement}`}
                        className="container-fluid CustomPopover"
                      >
                        <Popover.Body>
                          <div className="container-fluid  p-1 mb-1 ">
                            <img
                              src={`${process.env.PUBLIC_URL}/img/crq8.png`}
                              alt=""
                              className="protect-image"
                              style={{ width: "100%" }}
                            />
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <Button variant="success">solution</Button>
                  </OverlayTrigger>
                ))}
              </>
            </li>
            
          </ol>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default ChemicalReactions;
